<p-table
  #dt
  [value]="invites"
  [lazy]="true"
  (onLazyLoad)="loadInvites($event)"
  dataKey="id"
  [paginator]="true"
  [rows]="10"
  [totalRecords]="totalRecords"
  [showCurrentPageReport]="true"
  [loading]="isLoading"
  responsiveLayout="scroll"
  currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
  [rowsPerPageOptions]="[5, 10, 25, 50]"
  [globalFilterFields]="['name']"
>
  <ng-template pTemplate="caption">
    <div class="flex flex-wrap gap-2 align-items-center justify-content-between pb-3">
      <span class="p-input-icon-left w-full sm:w-20rem flex-order-1 sm:flex-order-0">
        <i class="pi pi-search"></i>
        <input pInputText type="text" (input)="onGlobalFilter(dt, $event)" placeholder="Global Search" class="w-full" />
      </span>
      <p-button
        (click)="openUserInviteDialog()"
        outlined
        class="w-full sm:w-auto flex-order-0 sm:flex-order-1"
        icon="pi pi-user-plus"
        label="Invite"
      ></p-button>
    </div>
  </ng-template>

  <ng-template pTemplate="header">
    <tr>
      <th pSortableColumn="email" class="white-space-nowrap" style="width: 25%">
        Email
        <p-sortIcon field="email" />
      </th>
      <th class="white-space-nowrap" style="width: 25%">Storefronts</th>
      <th pSortableColumn="status" class="white-space-nowrap" style="width: 25%">
        Status
        <p-sortIcon field="status" />
      </th>
      <th class="white-space-nowrap" style="width: 25%">Actions</th>
    </tr>
  </ng-template>

  <ng-template pTemplate="body" let-invite>
    <tr>
      <td>{{ invite.email }}</td>
      <td>/</td>
      <td><app-status-badge [status]="invite.status" /></td>
      <td>
        @if (invite.status !== InviteStatusType.accepted) {
          <div class="flex gap-2">
            <p-button icon="pi pi-replay" outlined (click)="openResendModal($event, invite.id)" />
            <p-button
              icon="pi pi-times"
              outlined
              severity="danger"
              (click)="openDeleteModal($event, invite.id)"
            ></p-button>
          </div>
        } @else {
          not allowed
        }
      </td>
    </tr>
  </ng-template>
</p-table>
<p-confirmDialog
  header="Confirmation"
  key="confirmDelete"
  icon="pi pi-exclamation-triangle"
  [style]="{ width: '350px' }"
  acceptButtonStyleClass="p-button-danger p-button-text"
  rejectButtonStyleClass="p-button-text"
></p-confirmDialog>
<p-confirmDialog
  header="Confirmation"
  key="resendInvite"
  [style]="{ width: '350px' }"
  acceptButtonStyleClass="p-button-success p-button-text"
  rejectButtonStyleClass="p-button-text"
></p-confirmDialog>
