<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <div class="grid grid-nogutter justify-content-end p-fluid">
    <div class="col-12">
      <div class="field">
        <label for="amount">Amount to transfer</label>
        <input
          type="number"
          pInputText
          formControlName="amount"
          id="amount"
          [class.ng-dirty]="form.get('amount')?.touched"
        />
        @if (form.get('amount')?.touched) {
          @if (form.get('amount')?.errors?.['required']) {
            <div>
              <small class="p-text-danger">Amount is required</small>
            </div>
          } @else if (form.get('amount')?.errors?.['min']) {
            <div>
              <small class="p-text-danger">
                Entered amount must be at least {{ form.get('amount')?.errors?.['min'].min | currency: 'USD' }}
              </small>
            </div>
          }
        }
      </div>
    </div>

    <div class="col-12 flex justify-content-end gap-3">
      <p-button
        type="button"
        label="Cancel"
        icon="pi pi-times"
        class="p-button-text"
        (click)="hideDialog()"
        outlined
      ></p-button>
      <p-button type="submit" label="Save" icon="pi pi-check" class="p-button-text"></p-button>
    </div>
  </div>
</form>
