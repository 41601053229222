import { CurrencyPipe } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';

import { ButtonModule } from 'primeng/button';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';

import { DepositMoneyService } from 'app/_shared/services/deposit-money.service';
import { markAllAsTouched } from 'app/_shared/services/helper.service';
import { ToastService } from 'app/_shared/services/toast.service';

@Component({
  selector: 'app-add-transaction-service-dialog',
  imports: [ButtonModule, ReactiveFormsModule, CurrencyPipe, InputTextModule, InputTextareaModule],
  templateUrl: './add-transaction-service-dialog.component.html',
  styleUrl: './add-transaction-service-dialog.component.scss'
})
export class AddTransactionServiceDialogComponent implements OnInit {
  dialogConfig = inject(DynamicDialogConfig);
  dialogRef = inject(DynamicDialogRef);
  toastService = inject(ToastService);
  depositMoneyService = inject(DepositMoneyService);

  companyId: string;
  form: FormGroup;

  ngOnInit() {
    this.companyId = this.dialogConfig.data.companyId;
    this.buildForm();
  }

  buildForm() {
    this.form = new FormGroup({
      amount: new FormControl(null, [Validators.required, Validators.min(0.5)]),
      description: new FormControl(null, [Validators.required]),
      company_id: new FormControl(this.companyId)
    });
  }

  hideDialog() {
    this.dialogRef.close();
  }

  superadminCreateService(formData: any) {
    this.depositMoneyService.superAdminCreateService(+this.companyId, formData).subscribe({
      next: () => {
        this.toastService.success('Successfully created transaction service!');
        this.dialogRef.close(true);
      },
      error: err => {
        console.error(err);
      }
    });
  }

  onSubmit() {
    markAllAsTouched(this.form);
    if (this.form.invalid) {
      this.toastService.error('Please fill all required fields correctly!');
      return;
    }

    const formData = { ...this.form.value };
    formData.amount = formData.amount * 100;

    this.superadminCreateService(formData);
  }
}
