import { Component, inject, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { MenuItem } from 'primeng/api';
import { CardModule } from 'primeng/card';
import { DropdownModule } from 'primeng/dropdown';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { TableLazyLoadEvent } from 'primeng/table';
import { TabMenuModule } from 'primeng/tabmenu';
import { finalize, map } from 'rxjs';

import {
  ListProductsComponent,
  ProductPaginatorOptions
} from 'app/_shared/components/list-products/list-products.component';
import { handleSupplierMapping } from 'app/_shared/helpers/suppliers-helper';
import { SupplierProduct } from 'app/_shared/models/supplier-product.model';
import { SupplierService } from 'app/_shared/services/supplier.service';

@Component({
  selector: 'app-create-new-catalog-product',
  imports: [CardModule, ListProductsComponent, DropdownModule, FormsModule, ProgressSpinnerModule, TabMenuModule],
  templateUrl: './catalog-supplier-list.component.html',
  styleUrl: './catalog-supplier-list.component.scss'
})
export class CatalogSupplierListComponent implements OnInit {
  router = inject(Router);
  supplierService = inject(SupplierService);
  activatedRoute = inject(ActivatedRoute);
  isLoadingSuppliers: boolean = true;
  isLoadingProducts: boolean = false;
  slug = 'hit-promo';
  cols = [
    { element: 'image', field: 'image', header: 'Image', width: '20%' },
    { field: 'name', header: 'Name', width: '65%' },
    {
      element: 'actions',
      field: 'actions',
      header: 'Actions',
      width: '15%',
      actions: [
        {
          icon: 'pi pi-file-export',
          label: 'Add to catalog',
          onClick: ($event: MouseEvent, product: SupplierProduct) => {
            $event.stopPropagation();
            this.router.navigate([this.slug, 'products', product.id], {
              relativeTo: this.activatedRoute
            });
          }
        }
      ]
    }
  ];
  paginatorOptions: ProductPaginatorOptions = { total: 0, rows: 10, first: 0 };
  supplierProducts: SupplierProduct[];
  suppliersList: MenuItem[] | undefined;
  activeSupplier: MenuItem | undefined;

  ngOnInit() {
    this.fetchSuppliers();
    this.isLoadingProducts = true;
  }

  private fetchSuppliers() {
    this.isLoadingSuppliers = true;
    this.supplierService
      .getSuppliers()
      .pipe(finalize(() => (this.isLoadingSuppliers = false)))
      .subscribe({
        next: res => {
          this.suppliersList = res.data.map(supplier => ({
            label: supplier.name,
            value: supplier.slug
          }));
          this.activeSupplier = this.suppliersList[0];
          this.slug = res.data[0].slug;
        },
        error: err => console.error(err)
      });
  }

  fetchProducts(paginatorOptions: ProductPaginatorOptions | TableLazyLoadEvent) {
    this.isLoadingProducts = true;
    const { rows, first, globalFilter } = paginatorOptions;
    const params = {
      rows,
      ...(globalFilter && { global_filter: globalFilter }),
      page: first && rows ? Math.floor(first / rows) + 1 : 1,
      'relations[]': ['featuredImage']
    };
    this.supplierService
      .getSupplierProducts(this.slug, params)
      .pipe(
        finalize(() => (this.isLoadingProducts = false)),
        map(products => ({
          ...products.data,
          data: products.data.data.map((product: SupplierProduct) => handleSupplierMapping(this.slug, product))
        }))
      )
      .subscribe({
        next: products => {
          this.supplierProducts = products.data;
          this.paginatorOptions = {
            total: products.total,
            rows: products.per_page,
            first: products.from > 0 ? products.from - 1 : products.from,
            ...(globalFilter && { globalFilter: globalFilter })
          };
        },
        error: err => {
          console.error(err);
        }
      });
  }

  handleLazyLoadProducts($event: TableLazyLoadEvent) {
    this.paginatorOptions = {
      ...this.paginatorOptions,
      globalFilter: ($event.globalFilter as string) ?? ''
    };
    this.fetchProducts($event);
  }

  handleSupplierChange($event: MenuItem) {
    const newValue = $event;

    this.slug = newValue.value;
    if (this.activeSupplier?.value !== newValue.value) {
      this.activeSupplier = newValue;
      this.fetchProducts(this.paginatorOptions);
    }
  }
}
