<div class="card p-0">
  <div class="form-wrap">
    @if (editMode()) {
      <p>
        Product status:
        <b>{{ form.value.status.toUpperCase() }}</b>
      </p>
    }
    <h3>Product Creator</h3>
    @if (form) {
      <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <div class="form">
          @if (selectedPrintArea().prints.length) {
            <div class="decorations-list">
              <table class="custom-table">
                <thead>
                  <tr>
                    <th>Image</th>
                    <th>Position</th>
                    <th>Method</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  @for (print of selectedPrintArea().prints; track print.printId) {
                    <tr>
                      <td>
                        <img [src]="print.library?.library_thumbnail?.full_path" alt="print" width="50" />
                      </td>
                      <td>{{ print.position }}</td>
                      <td>{{ print.decorationMethod?.name }}</td>
                      <td>
                        <img
                          class="cursor-pointer"
                          (click)="removePrint.emit(print.printId)"
                          src="assets/images/icons/trash-dark.svg"
                          alt="print"
                          width="20"
                        />
                      </td>
                    </tr>
                  }
                </tbody>
              </table>
            </div>
          }
          <!--            <h5>Decoration Method</h5>-->
          <!--            <div class="full-width-select">-->
          <!--              <p-selectButton [options]="decorationMethodOptions" optionLabel="name"></p-selectButton>-->
          <!--            </div>-->
          <!--          @if (latestUsedMedias?.length) {-->
          <!--            <p>Recently used files</p>-->
          <!--          }-->
          <!--          <ul class="list-none p-0 m-0">-->
          <!--            @for (file of latestUsedMedias; track i; let i = $index) {-->
          <!--              <li class="flex align-items-center p-3 mb-3 border-bottom-1 surface-border hover:surface-hover">-->
          <!--                <img [src]="file.mockup_file?.full_path" alt="{{ file.name }}" class="w-3rem flex-shrink-0 mr-3" />-->
          <!--                <div class="flex flex-column">-->
          <!--                  <span>date</span>-->
          <!--                </div>-->
          <!--                <button-->
          <!--                  type="button"-->
          <!--                  pButton-->
          <!--                  pRipple-->
          <!--                  icon="pi pi-upload"-->
          <!--                  aria-label="Toggle"-->
          <!--                  class="ml-auto p-button-text p-button-rounded"-->
          <!--                ></button>-->
          <!--              </li>-->
          <!--            }-->
          <!--          </ul>-->

          <div>
            <p-button
              class="add-decoration"
              iconPos="left"
              icon="pi pi-plus"
              label="Add decoration"
              type="button"
              outlined
              [style]="{ width: '100%', display: 'flex', justifyContent: 'center' }"
              (click)="handleOpenMediaLibraryModal.emit()"
            ></p-button>
          </div>
          <div class="product-colors">
            <div class="flex align-items-center gap-3 mb-5">
              <img class="title-icon" src="assets/images/icons/clipboard-gray.svg" alt="Colors" />
              <p class="header-text">Product Colors</p>
            </div>
            <div class="color-list">
              @for (variant of productVariants(); track variant.id; let i = $index) {
                <div
                  [pTooltip]="variant.color"
                  tooltipPosition="top"
                  class="color-circle"
                  [style.background-color]="variant.supplier_product_variant?.primary_hex_color"
                  (click)="variantSelectChange(variant)"
                >
                  @if (variant.checked) {
                    <i class="pi pi-check"></i>
                  }
                </div>
              }
            </div>
          </div>
          <div class="form-inputs relative">
            <app-input
              [fullWidth]="true"
              name="name"
              label="Name"
              [control]="this.form.get('name')!"
              placeholder="Product name"
            ></app-input>
          </div>
          <p-panel
            #panelName
            toggler="header"
            [toggleable]="true"
            [collapsed]="true"
            [ngClass]="{
              'panel-with-error':
                panelName.collapsed &&
                form.get('name')?.invalid &&
                (form.get('name')?.touched || form.get('name')?.dirty)
            }"
          >
            <ng-template pTemplate="headericons" let-context>
              @if (context) {
                <img src="assets/images/icons/arrow-down-dark.svg" alt="Show more" />
              } @else {
                <img
                  src="assets/images/icons/arrow-down-dark.svg"
                  alt="Show more"
                  [style.transform]="'rotate(180deg)'"
                />
              }
            </ng-template>
            <ng-template pTemplate="header">
              <div class="flex align-items-center gap-3 custom-panel-header">
                <img class="title-icon" src="assets/images/icons/briefcase-dark.svg" alt="Colors" />
                <p class="header-text">Product details</p>
              </div>
            </ng-template>
            <div class="edit-slug flex gap-2" (click)="visibleSlugInput = !visibleSlugInput">
              <img src="assets/images/icons/edit-dark.svg" alt="Edit slug" />
              <p class="m-0">Show slug</p>
            </div>
            @if (visibleSlugInput) {
              <div class="form-inputs mt-4">
                <app-input
                  [fullWidth]="true"
                  name="slug"
                  label="Slug"
                  [control]="this.form.get('slug')!"
                  placeholder="Slug"
                ></app-input>
              </div>
            }
            <div class="flex flex-column gap-2 mt-4">
              <p-editor
                formControlName="short_description"
                [style]="{ height: '100px' }"
                placeholder="Short description (optional)"
              >
                <ng-template pTemplate="header">
                  <span class="ql-formats">
                    <button type="button" class="ql-bold" aria-label="Bold"></button>
                    <button type="button" class="ql-italic" aria-label="Italic"></button>
                    <button type="button" class="ql-underline" aria-label="Underline"></button>
                  </span>
                </ng-template>
              </p-editor>
              <p-editor
                formControlName="copy_underneath_description"
                [style]="{ height: '100px' }"
                placeholder="Copy underneath `Add to cart` section"
              >
                <ng-template pTemplate="header">
                  <span class="ql-formats">
                    <button type="button" class="ql-bold" aria-label="Bold"></button>
                    <button type="button" class="ql-italic" aria-label="Italic"></button>
                    <button type="button" class="ql-underline" aria-label="Underline"></button>
                  </span>
                </ng-template>
              </p-editor>
            </div>
          </p-panel>
          <p-panel toggler="header" [toggleable]="true" [collapsed]="true">
            <ng-template pTemplate="headericons" let-context>
              @if (context) {
                <img src="assets/images/icons/arrow-down-dark.svg" alt="Show more" />
              } @else {
                <img
                  src="assets/images/icons/arrow-down-dark.svg"
                  alt="Show more"
                  [style.transform]="'rotate(180deg)'"
                />
              }
            </ng-template>
            <ng-template pTemplate="header">
              <div class="flex align-items-center gap-3 custom-panel-header">
                <img class="title-icon" src="assets/images/icons/setting-3-dark.svg" alt="Categories" />
                <p class="header-text">Categories</p>
              </div>
            </ng-template>
            <p-button (click)="openCategoryEditor()" outlined label="Add category" class="w-full category-button" />
            <div class="pt-2 flex gap-2">
              @for (category of productCategoryOptions(); track category.name; let i = $index) {
                <p-chip [label]="category.name" [removable]="true" (onRemove)="categoryRemove(i)" />
              }
            </div>
          </p-panel>
          <!--          <p-panel-->
          <!--            #panelStatus-->
          <!--            toggler="header"-->
          <!--            header="Product Status"-->
          <!--            [toggleable]="true"-->
          <!--            [collapsed]="true"-->
          <!--            [ngClass]="{-->
          <!--              'panel-with-error':-->
          <!--                panelStatus.collapsed &&-->
          <!--                ((form.get('status')?.invalid && (form.get('status')?.touched || form.get('status')?.dirty)) ||-->
          <!--                  (form.get('start_publish_date')?.invalid &&-->
          <!--                    (form.get('start_publish_date')?.touched || form.get('start_publish_date')?.dirty)))-->
          <!--            }"-->
          <!--          >-->
          <!--            <ng-template pTemplate="headericons" let-context>-->
          <!--              @if (context) {-->
          <!--                <img src="assets/images/icons/arrow-down-dark.svg" alt="Show more" />-->
          <!--              } @else {-->
          <!--                <img-->
          <!--                  src="assets/images/icons/arrow-down-dark.svg"-->
          <!--                  alt="Show more"-->
          <!--                  [style.transform]="'rotate(180deg)'"-->
          <!--                />-->
          <!--              }-->
          <!--            </ng-template>-->
          <!--            <ng-template pTemplate="header">-->
          <!--              <div class="flex align-items-center gap-3 custom-panel-header">-->
          <!--                <img class="title-icon" src="assets/images/icons/briefcase-dark.svg" alt="Colors" />-->
          <!--                <p class="header-text">Product Colors</p>-->
          <!--              </div>-->
          <!--            </ng-template>-->
          <!--            <div class="formgrid grid">-->
          <!--              @for (productStatus of productStatusOptions; track productStatus) {-->
          <!--                <div class="field-radiobutton col-6">-->
          <!--                  <p-radioButton-->
          <!--                    formControlName="status"-->
          <!--                    [value]="productStatus.value"-->
          <!--                    [inputId]="productStatus.value"-->
          <!--                  ></p-radioButton>-->
          <!--                  <label [for]="productStatus.value">{{ productStatus.name }}</label>-->
          <!--                </div>-->
          <!--              }-->
          <!--            </div>-->
          <!--            @if (form.get('status')?.value === 'scheduled') {-->
          <!--              <div class="flex gap-1">-->
          <!--                <div class="flex flex-column field w-full">-->
          <!--                  <label for="startDate" class="block text-900 font-semibold">Start Date</label>-->
          <!--                  <span class="p-input-icon-left w-full" style="height: 3.5rem">-->
          <!--                    <i class="pi pi-clock z-1" style="left: 1.5rem"></i>-->
          <!--                    <p-calendar-->
          <!--                      formControlName="start_publish_date"-->
          <!--                      name="startDate"-->
          <!--                      [dateFormat]="displayDateFormat"-->
          <!--                      hourFormat="12"-->
          <!--                      [showTime]="true"-->
          <!--                      [required]="form.get('status')?.value === 'scheduled'"-->
          <!--                      inputId="startDate"-->
          <!--                      id="startDate"-->
          <!--                      inputStyleClass="w-full pl-7 text-900 font-semibold"-->
          <!--                      styleClass="w-full"-->
          <!--                      [inputStyle]="{ height: '3.5rem' }"-->
          <!--                    ></p-calendar>-->
          <!--                  </span>-->
          <!--                  @if (-->
          <!--                    form.get('start_publish_date')?.invalid &&-->
          <!--                    (form.get('start_publish_date')?.touched || form.get('start_publish_date')?.dirty)-->
          <!--                  ) {-->
          <!--                    <div class="flex align-items-center">-->
          <!--                      <small class="p-text-danger">A start date is required when scheduling this product</small>-->
          <!--                    </div>-->
          <!--                  }-->
          <!--                </div>-->
          <!--                <div class="flex flex-column field w-full">-->
          <!--                  <label for="endDate" class="block text-900 font-semibold">End Date</label>-->
          <!--                  <span class="p-input-icon-left w-full" style="height: 3.5rem">-->
          <!--                    <i class="pi pi-clock z-1" style="left: 1.5rem"></i>-->
          <!--                    <p-calendar-->
          <!--                      formControlName="end_publish_date"-->
          <!--                      name="endDate"-->
          <!--                      [dateFormat]="displayDateFormat"-->
          <!--                      hourFormat="12"-->
          <!--                      [showTime]="true"-->
          <!--                      [required]="true"-->
          <!--                      inputId="endDate"-->
          <!--                      id="endDate"-->
          <!--                      inputStyleClass="w-full pl-7 text-900 font-semibold"-->
          <!--                      styleClass="w-full"-->
          <!--                      [inputStyle]="{ height: '3.5rem' }"-->
          <!--                      [minDate]="form.get('start_publish_date')?.value"-->
          <!--                    ></p-calendar>-->
          <!--                  </span>-->
          <!--                </div>-->
          <!--              </div>-->
          <!--            }-->
          <!--          </p-panel>-->
          <p-panel toggler="header" [toggleable]="true" [collapsed]="true">
            <ng-template pTemplate="headericons" let-context>
              @if (context) {
                <img src="assets/images/icons/arrow-down-dark.svg" alt="Show more" />
              } @else {
                <img
                  src="assets/images/icons/arrow-down-dark.svg"
                  alt="Show more"
                  [style.transform]="'rotate(180deg)'"
                />
              }
            </ng-template>
            <ng-template pTemplate="header">
              <div class="flex align-items-center gap-3 custom-panel-header">
                <img class="title-icon" src="assets/images/icons/gallery-dark.svg" alt="Additional photos" />
                <p class="header-text">Additional Photos</p>
              </div>
            </ng-template>
            <p-fileUpload
              #fileUploader
              (onSelect)="onAdditionalPhotosUpload($event)"
              [multiple]="true"
              [customUpload]="true"
              [auto]="true"
              accept="image/*"
              maxFileSize="1000000"
              showUploadButton="false"
            >
              <ng-template pTemplate="header">
                <div class="flex flex-wrap justify-content-between align-items-center flex-1 gap-2">
                  <div class="w-full flex justify-content-between gap-2">
                    <p-button
                      (click)="fileUploader.advancedFileInput.nativeElement.click()"
                      outlined
                      label="Upload Photos"
                      class="w-full"
                    />
                  </div>
                </div>
              </ng-template>
              <ng-template pTemplate="files"></ng-template>
              <ng-template pTemplate="content">
                @for (photo of additionalPhotos; let i = $index; track i) {
                  @if (photo?.objectURL) {
                    <div class="flex justify-content-between align-items-center image-list">
                      <div class="flex gap-3 align-items-center">
                        <img width="50" [src]="photo?.objectURL" alt="Uploaded Image" style="width: 50px" />
                        <p>{{ photo?.fileName }}</p>
                      </div>
                      <div class="cursor-pointer">
                        <img src="assets/images/icons/trash-dark.svg" alt="Remove" (click)="removeAdditionalPhoto(i)" />
                      </div>
                    </div>
                  }
                }
              </ng-template>
            </p-fileUpload>
          </p-panel>

          <p-panel
            #panelDescription
            toggler="header"
            [toggleable]="true"
            [collapsed]="true"
            [ngClass]="{
              'panel-with-error':
                panelDescription.collapsed &&
                form.get('description')?.invalid &&
                (form.get('description')?.touched || form.get('description')?.dirty)
            }"
          >
            <ng-template pTemplate="headericons" let-context>
              @if (context) {
                <img src="assets/images/icons/arrow-down-dark.svg" alt="Show more" />
              } @else {
                <img
                  src="assets/images/icons/arrow-down-dark.svg"
                  alt="Show more"
                  [style.transform]="'rotate(180deg)'"
                />
              }
            </ng-template>
            <ng-template pTemplate="header">
              <div class="flex align-items-center gap-3 custom-panel-header">
                <img class="title-icon" src="assets/images/icons/briefcase-dark.svg" alt="Description" />
                <p class="header-text">Description</p>
              </div>
            </ng-template>
            <p-editor formControlName="description" [style]="{ height: '100px' }" placeholder="Product information">
              <ng-template pTemplate="header">
                <span class="ql-formats">
                  <button type="button" class="ql-bold" aria-label="Bold"></button>
                  <button type="button" class="ql-italic" aria-label="Italic"></button>
                  <button type="button" class="ql-underline" aria-label="Underline"></button>
                </span>
              </ng-template>
            </p-editor>
            @if (
              form.get('description')?.invalid && (form.get('description')?.dirty || form.get('description')?.touched)
            ) {
              @if (form.get('description')?.errors?.['required']) {
                <div class="flex align-items-center">
                  <small class="p-text-danger">Description is required</small>
                </div>
              }
            }
          </p-panel>
          <div class="grid">
            <div class="col-12 sm:col-6">
              <app-input
                [fullWidth]="true"
                name="total_cost"
                label="Total Cost"
                [control]="this.form.get('total_cost')!"
                placeholder="Total Cost"
                [readonly]="true"
              ></app-input>
            </div>
            <div class="col-12 sm:col-6">
              <app-input
                [fullWidth]="true"
                name="margin"
                label="Margin"
                [control]="this.form.get('margin')!"
                placeholder="Margin"
                appendSymbol="%"
                type="number"
              ></app-input>
            </div>
          </div>
          <app-input
            [fullWidth]="true"
            name="final_price"
            label="Final Price"
            [control]="this.form.get('final_price')!"
            placeholder="Final Price"
            [readonly]="true"
          ></app-input>
          <div class="footer-actions mt-6">
            <div class="grid">
              <div class="col-12 sm:col-6">
                <p-button
                  [outlined]="true"
                  type="button"
                  (click)="onSubmit('draft')"
                  class="w-full"
                  [label]="'Save as draft'"
                  aria-label="Create product"
                ></p-button>
              </div>
              <div class="col-12 sm:col-6">
                <p-button
                  [loading]="isSubmitting()"
                  type="submit"
                  class="w-full"
                  [label]="
                    editMode()
                      ? product().status === 'draft'
                        ? 'Publish product'
                        : 'Update product'
                      : productsInQue()?.length
                        ? productsInQue()?.length === 1
                          ? 'Save and finish'
                          : 'Save and next'
                        : 'Create product'
                  "
                  aria-label="Create product"
                ></p-button>
              </div>
            </div>
          </div>
        </div>
      </form>
    }
  </div>
</div>
