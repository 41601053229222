import { isPlatformBrowser } from '@angular/common';
import { inject, isSignal, PLATFORM_ID } from '@angular/core';

import { patchState, signalStoreFeature, withMethods, withState } from '@ngrx/signals';

export const withLocalStorageSync = (storageKey: string) =>
  signalStoreFeature(
    withState({}),
    withMethods(state => {
      const isBrowser = isPlatformBrowser(inject(PLATFORM_ID));
      return {
        saveToLocalStorage() {
          const stateValue: Record<string, unknown> = {};
          for (const key in state as Record<string, unknown>) {
            const sliceSignal = (<Record<string, unknown>>state)[key];
            if (isSignal(sliceSignal)) {
              stateValue[key] = sliceSignal();
            }
          }
          if (isBrowser) {
            window.localStorage.setItem(storageKey, JSON.stringify(stateValue));
          }
        },
        removeFromLocalStorage() {
          if (isBrowser) {
            window.localStorage.removeItem(storageKey);
          }
        },
        loadFromLocalStorage(): boolean {
          if (!isBrowser) {
            return false;
          }
          const stateValue = window.localStorage.getItem(storageKey);
          if (!stateValue) {
            return false;
          }
          patchState(state, JSON.parse(stateValue));
          return true;
        }
      };
    })
  );
