@if (form) {
  <form [formGroup]="form" (ngSubmit)="formSubmit()">
    <h5>Gift Card Pack Name</h5>

    <div class="flex mb-3">
      <app-input
        name="name"
        label="Gift Card Pack Name"
        placeholder="Gift Card Pack Name"
        [control]="form.controls.name"
        [fullWidth]="true"
      />
    </div>

    <h5>Recipients</h5>

    <div class="mb-3">
      <div class="field-checkbox">
        <p-radioButton inputId="specKey" [value]="true" formControlName="specified_recipients" />
        <label for="specKey" class="ml-2">
          Specify Recipients
          <div>
            <small>
              Every created code will be account bound. If a specified recipient does not have an account, they will
              have to register using proper information.
            </small>
          </div>
        </label>
      </div>

      @if (form.get('specified_recipients')?.value) {
        <div class="ml-5 mb-5">
          <div class="mb-3">Total Recipients: {{ form.get('codes')?.value?.length || 0 }}</div>
          <div class="flex gap-2">
            <p-button label="Manage Recipients" (onClick)="openRecipientsModal()" />
          </div>
        </div>
      }

      <div class="field-checkbox">
        <p-radioButton inputId="dontSpecKey" [value]="false" formControlName="specified_recipients" />
        <label for="specKey" class="ml-2">
          Do not Specify Recipients
          <div>
            <small>
              Gift Card codes will not be bound to any account. Once code is used by a new or existing customer, it will
              be attached to this account.
            </small>
          </div>
        </label>
      </div>

      @if (!form.get('specified_recipients')?.value) {
        <div class="ml-5 mb-5" formArrayName="tiers">
          <div class="mb-3">You can create multiple gift cards with different values (tiers).</div>
          <div class="flex gap-2 flex-column">
            @for (tier of tiers.controls; track tier; let i = $index) {
              <div [formGroupName]="i">
                <div class="flex gap-2">
                  <div>
                    <input pInputText name="quantity" placeholder="Quantity" formControlName="quantity" />
                  </div>
                  <div>
                    <p-inputGroup>
                      <p-inputGroupAddon>$</p-inputGroupAddon>
                      <input pInputText name="amount" placeholder="Amount" formControlName="amount" />
                      <p-inputGroupAddon>.00</p-inputGroupAddon>
                    </p-inputGroup>
                  </div>
                  <div>
                    <p-button
                      styleClass="h-full"
                      icon="pi pi-trash"
                      severity="danger"
                      [disabled]="tiers.controls.length < 2"
                      (onClick)="removeTier(i)"
                    />
                  </div>
                </div>
              </div>
            }

            <div class="mt-3 flex align-items-center">
              <p-button label="Add Tier" (onClick)="addNewTier()" />
              <span class="ml-2">
                @if (totalAmount > 0) {
                  Total value: {{ totalAmount | currency }}
                }
              </span>
            </div>
          </div>
        </div>
      }
    </div>

    <h5>Additional Options</h5>

    <div class="mb-3">
      <p-checkbox binary formControlName="cover_sales_tax" inputId="cover_sales_tax" />
      <label class="ml-2" for="cover_sales_tax">Store covers sales tax</label>
    </div>

    <div class="mb-3">
      <p-checkbox binary formControlName="cover_shipping" inputId="cover_shipping" />
      <label class="ml-2" for="cover_shipping">Store covers shipping cost</label>
    </div>

    <div class="mb-3">
      <p-checkbox binary formControlName="single_use" inputId="single_use" />
      <label class="ml-2" for="single_use">
        Single use
        <br />
        <small>
          Gift Card will be only available for one-time purchase. If not used entirely, the remaining amount will be
          lost.
        </small>
      </label>
    </div>

    <div class="mb-3">
      <label for="expiration_date">Expiration Date (optional)</label>
      <div>
        <p-calendar
          formControlName="expiration_date"
          inputId="expiration_date"
          [showButtonBar]="true"
          [iconDisplay]="'input'"
          [showIcon]="true"
          [minDate]="todayDate"
          [readonlyInput]="true"
          [dateFormat]="displayDateFormat"
        />
      </div>
    </div>

    <p-button [disabled]="isLoading" type="submit" label="Submit" />
  </form>
}
