import { Component, inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { ConfirmationService, MenuItem } from 'primeng/api';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { TableLazyLoadEvent } from 'primeng/table';
import { BehaviorSubject, combineLatest, debounceTime, distinctUntilChanged, finalize } from 'rxjs';

import { StorefrontEditorDialogComponent } from 'app/_shared/components/storefront-editor-dialog/storefront-editor-dialog.component';
import { AccessControlService } from 'app/_shared/helpers/access-control.service';
import { PaginatorOptions } from 'app/_shared/models/PaginatorOptions';
import { User } from 'app/_shared/models/User';
import { RoleName } from 'app/_shared/models/UserRole';
import { AuthService } from 'app/_shared/services/auth.service';
import { CompanyService } from 'app/_shared/services/company.service';
import { AuthStore } from 'app/_store/auth.store';
import { StorefrontStore } from 'app/_store/storefront.store';

import { StorefrontsTableComponent } from '../../../_shared/components/storefronts-table/storefronts-table.component';
import { Storefront, StorefrontStatus } from '../../../_shared/models/Storefront';
import { StorefrontService } from '../../../_shared/services/storefront.service';
import { ToastService } from '../../../_shared/services/toast.service';

@Component({
  selector: 'app-storefront-list',
  imports: [StorefrontsTableComponent],
  providers: [ConfirmationService, DialogService],
  templateUrl: './storefront-list.component.html',
  styleUrls: ['./storefront-list.component.scss']
})
export class StorefrontListComponent implements OnInit {
  storefrontStore = inject(StorefrontStore);
  storefrontService = inject(StorefrontService);
  companyService = inject(CompanyService);
  toastService = inject(ToastService);
  route = inject(ActivatedRoute);
  router = inject(Router);
  dialogService = inject(DialogService);
  authStore = inject(AuthStore);
  authService = inject(AuthService);
  accessControlService = inject(AccessControlService);

  dialogRef: DynamicDialogRef | undefined;

  storefronts: Storefront[] = [];
  companyId: string;

  paginatorOptions: PaginatorOptions = {
    total: 0,
    rows: 10,
    first: 0
  };

  isLoading: boolean;
  splitButtonItemsMap: { [storefrontId: number]: MenuItem[] } = {};
  globalFilter: string;
  selectedStatus: string | null;
  filtersSubject = new BehaviorSubject<any>(null);
  filtersChanged = false;
  lastLazyLoadEvent: TableLazyLoadEvent | undefined;
  account: User | null = null;
  UserRoleNames = RoleName;
  userCompanyOrSuperAdmin: boolean;

  constructor() {
    combineLatest([this.filtersSubject.pipe(debounceTime(300), distinctUntilChanged())]).subscribe(() => {
      if (this.filtersChanged) {
        this.loadStorefronts(this.lastLazyLoadEvent);
      }
      this.filtersChanged = false;
    });
  }

  ngOnInit(): void {
    this.storefrontStore.unsetSelectedStorefront();
    const companyId = this.route.snapshot.paramMap.get('id');
    if (!companyId) {
      console.error('Company ID is null.');
      return;
    }
    this.companyId = companyId;
    this.account = this.authStore.user();
    this.userCompanyOrSuperAdmin =
      this.account?.role?.name === this.UserRoleNames.CompanyAdmin ||
      this.account?.role?.name === this.UserRoleNames.SuperAdmin;
  }

  loadStorefronts(event?: TableLazyLoadEvent) {
    if (event) {
      this.lastLazyLoadEvent = event;
    }
    const filters = this.filtersSubject.value || {};

    this.isLoading = true;
    this.companyService
      .companyStorefrontsPaginated(+this.companyId, event, filters)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe({
        next: res => {
          this.storefronts = res.data.data;
          this.paginatorOptions = {
            total: res.data.total,
            rows: res.data.per_page,
            first: res.data.from - 1
          };
          this.populateSplitButtonItems();
        },
        error: error => console.error(error)
      });
  }

  loadUserAndUpdatePermissions() {
    this.authService.authCheck().subscribe({
      next: res => {
        if (res.data.user.companies) {
          this.accessControlService.populatePermissionsMap(res.data.user.companies);
        }
      },
      error: error => console.log(error)
    });
  }

  populateSplitButtonItems() {
    this.storefronts.forEach(storefront => {
      this.splitButtonItemsMap[storefront.id] = [
        ...(storefront.status === StorefrontStatus.INACTIVE && this.userCompanyOrSuperAdmin
          ? [
              {
                label: 'Reactive',
                icon: 'pi pi-file',
                command: () => {
                  this.activateStore(storefront);
                }
              },
              { separator: true }
            ]
          : []),
        {
          label: 'Edit',
          icon: 'pi pi-pencil',
          command: () => {
            this.handleOpenStorefrontEditor(storefront);
          }
        },
        { separator: true },
        {
          label: 'Settings',
          icon: 'pi pi-cog',
          command: () => {
            this.navigateToStorefrontSettings(storefront);
          }
        }
      ];
    });
  }

  onFilterChange(event: { key: string; value: string }): void {
    const { key, value } = event;
    const updatedFilters = { ...this.filtersSubject.value };

    if (event.value !== null && value !== undefined && value !== '') {
      updatedFilters[key] = value;
    } else {
      delete updatedFilters[key];
    }

    if (this.lastLazyLoadEvent) {
      this.lastLazyLoadEvent.first = 0;
    }

    this.filtersSubject.next(updatedFilters);
    this.filtersChanged = true;
  }

  handleOpenStorefrontEditor(storefront?: Storefront) {
    this.dialogRef = this.dialogService.open(StorefrontEditorDialogComponent, {
      header: storefront ? 'Edit Storefront' : 'Create new Storefront',
      width: '600px',
      data: {
        storefront,
        companyId: this.companyId
      }
    });
    this.dialogRef.onClose.subscribe((res: { id: number | undefined; data: FormData }) => {
      if (res) {
        if (res.id) {
          this.updateStorefront(res.data, res.id);
        } else {
          this.createStorefront(res.data);
        }
      }
    });
  }

  openInNewTab(storefront: Storefront) {
    const isLiveOrDemo = ['live', 'demo'].includes(storefront.status);
    const hash = isLiveOrDemo ? '' : `?hash=${storefront.hash}`;
    window.open(`${storefront.url}${hash}`, '_blank');
  }

  updateStorefront(data: FormData, id: number) {
    this.isLoading = true;

    this.storefrontService
      .update(id, data)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe({
        next: () => {
          this.toastService.success('Successfully updated storefront!');
          this.loadStorefronts();
        },
        error: err => {
          console.error('Update failed:', err);
        }
      });
  }

  createStorefront(data: FormData) {
    this.isLoading = true;
    this.storefrontService
      .create(data)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe({
        next: () => {
          this.toastService.success('Successfully created storefront!');
          this.loadStorefronts();
          if (this.account?.role.name !== this.UserRoleNames.SuperAdmin) {
            this.loadUserAndUpdatePermissions();
          }
        },
        error: err => {
          console.error('Creation failed:', err);
        }
      });
  }

  activateStore(storefront: Storefront) {
    this.storefrontService.updateStatus({ status: StorefrontStatus.LIVE }, storefront.id).subscribe({
      next: () => {
        this.toastService.success('Successfully activated storefront!');
        this.loadStorefronts();
      },
      error: err => {
        console.error('Update failed:', err);
      }
    });
  }

  navigateToStorefrontSettings(storefront: Storefront) {
    this.router.navigate([storefront.id, 'settings'], { relativeTo: this.route });
  }

  navigateToStorefrontProducts(torefront: Storefront) {
    this.router.navigate([torefront.id, 'products'], { relativeTo: this.route });
  }
}
