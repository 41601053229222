import { Component, computed, inject, OnInit, signal, ViewChild } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { SharedModule } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { Editor, EditorModule } from 'primeng/editor';
import { FileUploadModule } from 'primeng/fileupload';
import { RadioButtonModule } from 'primeng/radiobutton';

import { Page, Section } from 'app/_shared/interfaces/designer/page';
import { DesignerService } from 'app/_shared/services/designer.service';
import { SectionDefaultImports } from 'app/admin/storefront/designer/page-builder/page-sections/section-default-imports';

@Component({
  selector: 'app-banner',
  imports: [
    ButtonModule,
    FileUploadModule,
    SharedModule,
    RadioButtonModule,
    FormsModule,
    EditorModule,
    ReactiveFormsModule
  ],
  templateUrl: './banner.component.html',
  styleUrl: './banner.component.scss'
})
export class BannerComponent extends SectionDefaultImports implements OnInit {
  @ViewChild('editor') editor: Editor;
  private designerService: DesignerService = inject(DesignerService);

  image: any;
  selectedPage: Page;
  selectedSection: Section;

  imagePosition = signal<string>('left');
  storefrontId = computed(() => this.storefrontStore.selectedStorefront?.()?.id);

  ngOnInit() {
    this.selectedPage = this.dynamicConfig.data.selectedPage;
    this.selectedSection = this.dynamicConfig.data.section;
    this.image = this.dynamicConfig.data.section.content?.img;
  }

  onUpload(event: any) {
    const file = event.files[0];

    const formData = new FormData();
    formData.append('image', file);
    this.designerService.uploadSectionImage(formData, this.storefrontId()!).subscribe(next => {
      this.image = {
        url: next.data.full_path,
        id: next.data.id
      };
      const pages = this.designerHelperService.saveSectionData(this.selectedPage, this.selectedSection, {
        img: this.image
      });
      this.designerStore.saveConfig(this.storefrontId()!, { pages }, true).subscribe(next => {
        this.designerHelperService.sendToPreview(this.storefrontId()!, next);
      });
    });
  }

  removeImage() {
    if (this.image.id) {
      this.designerService.removeSectionImage(this.storefrontId()!, this.image.id).subscribe(next => {
        this.image = null;
        const pages = this.designerHelperService.saveSectionData(this.selectedPage, this.selectedSection, {
          img: this.image
        });
        this.designerStore.saveConfig(this.storefrontId()!, { pages }, true).subscribe(next => {
          this.designerHelperService.sendToPreview(this.storefrontId()!, next);
        });
      });
    } else {
      this.image = null;
      const pages = this.designerHelperService.saveSectionData(this.selectedPage, this.selectedSection, {
        img: this.image
      });
      this.designerStore.saveConfig(this.storefrontId()!, { pages }, true).subscribe(next => {
        this.designerHelperService.sendToPreview(this.storefrontId()!, next);
      });
    }
  }
}
