import { Component, inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

import { ButtonModule } from 'primeng/button';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';

import { InputComponent } from 'app/_shared/components/input/input.component';
import { FilePrepRequest } from 'app/_shared/models/FilePrepRequest';
import { markAllAsTouched } from 'app/_shared/services/helper.service';
import { LibraryService } from 'app/_shared/services/library.service';
import { ToastService } from 'app/_shared/services/toast.service';

@Component({
  selector: 'app-library-request-file-prep',
  imports: [ButtonModule, ReactiveFormsModule, InputTextareaModule, InputTextModule, InputComponent],
  templateUrl: './library-request-file-prep.component.html',
  styleUrl: './library-request-file-prep.component.scss'
})
export class LibraryRequestFilePrepComponent implements OnInit {
  private fb = inject(FormBuilder);
  private dialogRef = inject(DynamicDialogRef);
  private toastService = inject(ToastService);
  private libraryService = inject(LibraryService);
  private dialogConfig = inject(DynamicDialogConfig);
  private route = inject(ActivatedRoute);

  form: FormGroup;
  isLoading = false;
  libraryId: number;
  storefrontId: string;

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.storefrontId = params.storefrontId;
      this.libraryId = this.dialogConfig.data.libraryId;
      this.buildForm();
    });
  }

  buildForm() {
    this.form = this.fb.group({
      email: [null, [Validators.required, Validators.email]],
      value: [null, [Validators.required]],
      library_id: [this.libraryId],
      additional_info: [null],
      additional_message: [null]
    });
  }

  closeDialog() {
    this.dialogRef.close();
  }

  onSubmit() {
    if (this.form.invalid) {
      markAllAsTouched(this.form);
      this.toastService.error('Please fill all required fields correctly!');
      return;
    }
    this.createPrepFileReq(this.form.value);
  }

  createPrepFileReq(preparedData: FilePrepRequest) {
    this.isLoading = true;
    this.libraryService.createPrepFileRequest(+this.storefrontId, preparedData).subscribe({
      next: () => {
        this.dialogRef.close(true);
      },
      error: err => {
        console.error('Operation failed:', err);
      },
      complete: () => {
        this.isLoading = false;
      }
    });
  }
}
