import { CommonModule } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { ButtonModule } from 'primeng/button';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { InputNumberModule } from 'primeng/inputnumber';
import { SkeletonModule } from 'primeng/skeleton';
import { TabViewModule } from 'primeng/tabview';
import { finalize } from 'rxjs';

import { Company } from 'app/_shared/models/Company';
import { Storefront } from 'app/_shared/models/Storefront';
import { RoleName } from 'app/_shared/models/UserRole';
import { AuthStore } from 'app/_store/auth.store';
import { CompanyStore } from 'app/_store/company.store';

import { User } from '../../models/User';
import { CompanyService } from '../../services/company.service';
import { ToastService } from '../../services/toast.service';
import { UserService } from '../../services/user.service';
import { UserPerrmissionsModalComponent } from '../user-permissions-modal/user-permissions-modal.component';
import { UserStorefrontPermissionsModalComponent } from '../user-storefront-permissions-modal/user-storefront-permissions-modal.component';

@Component({
    selector: 'app-user-details',
    imports: [CommonModule, FormsModule, InputNumberModule, ButtonModule, TabViewModule, SkeletonModule],
    providers: [DialogService],
    templateUrl: './user-details.component.html'
})
export class UserDetailsComponent implements OnInit {
  userService = inject(UserService);
  companyService = inject(CompanyService);
  authStore = inject(AuthStore);

  route = inject(ActivatedRoute);
  router = inject(Router);
  toastService = inject(ToastService);
  companyStore = inject(CompanyStore);
  dialogService = inject(DialogService);

  dialogRef: DynamicDialogRef | undefined;
  dialogRefStorefrontPermission: DynamicDialogRef | undefined;
  isLoading = false;
  loadingRows = Array(10)
    .fill(0)
    .map((_, index) => index + 1);

  user: User;
  account: User | null = null;
  companyUser = false;
  UserRoleNames = RoleName;

  ngOnInit(): void {
    this.account = this.authStore.user();
    this.companyUser = this.route.snapshot.data.companyUser;
    this.checkUrlForUserId();
  }

  checkUrlForUserId(): void {
    const userId = this.route.snapshot.paramMap.get('id');

    if (!userId) {
      this.router.navigate([`../`], { relativeTo: this.route });
      return;
    }

    if (!this.companyUser) {
      this.fetchUser(+userId);
    } else {
      const companyId = this.companyStore.workingCompany()?.id;
      this.fetchCompanyUser(+userId, companyId);
    }
  }

  fetchUser(id: number) {
    this.isLoading = true;
    this.userService
      .getById(id)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe({
        next: response => {
          this.user = response.data;
        },
        error: error => {
          console.error(error);
          this.toastService.error('Error occurred on user fetch. Please try again!');
          this.router.navigate(['../'], { relativeTo: this.route });
        }
      });
  }

  fetchCompanyUser(userId: number, workingCompanyId?: number, openDialog = false) {
    this.isLoading = true;
    this.companyService
      .getSingleCompanyUser(userId, workingCompanyId)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe({
        next: response => {
          if (openDialog) {
            this.openPermissionEditor(response.data.companies![0]);
            return;
          }
          this.user = response.data;
        },
        error: error => {
          console.error(error);
          this.toastService.error('Error occurred on user fetch. Please try again!');
          this.router.navigate(['../'], { relativeTo: this.route });
        }
      });
  }

  openPermissionEditor(company?: Company) {
    this.dialogRef = this.dialogService.open(UserPerrmissionsModalComponent, {
      header: 'Change User Permissions',
      width: '540px',
      data: {
        userCompany: company ? company : this.user?.companies![0],
        userId: this.user.id
      }
    });
    this.dialogRef.onClose.subscribe((res: boolean) => {
      if (res) {
        this.toastService.success('The user permissions have been successfully updated.');
        if (!this.companyUser) {
          this.fetchUser(this.user.id);
        } else {
          const companyId = company ? company.id : this.user?.companies![0].id;
          this.fetchCompanyUser(this.user.id, companyId);
        }
      }
    });
  }

  fetchCompanyAndOpenPermissionDialog(companyId: number) {
    this.fetchCompanyUser(this.user.id, companyId, true);
  }

  noStorefronts() {
    return this.user?.companies?.every(company => company.storefronts.length === 0);
  }

  openStorefrontPermissionEditor(companyId: number, storefront: Storefront) {
    this.dialogRef = this.dialogService.open(UserStorefrontPermissionsModalComponent, {
      header: 'Change User Storefront Permissions',
      width: '540px',
      data: {
        allStorefrontPermissions: storefront.permissions,
        userId: this.user.id,
        storefrontId: storefront.id,
        companyId: companyId
      }
    });
    this.dialogRef.onClose.subscribe((res: boolean) => {
      if (res) {
        this.toastService.success('The user storefront permissions have been successfully updated.');
        if (!this.companyUser) {
          this.fetchUser(this.user.id);
        } else {
          this.fetchCompanyUser(this.user.id, companyId);
        }
      }
    });
  }
}
