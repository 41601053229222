@if (!loading) {
  <div class="card">
    <div class="grid grid-nogutter">
      @if (createMode || (account?.role?.name !== UserRoleNames.SuperAdmin && storefrontLibraryFolders)) {
        <div class="col-6">
          <app-library-file-uploader
            [storefrontLibraryFolders]="storefrontLibraryFolders"
            [library]="library"
            [isLoading]="isLoading"
            (formSubmitted)="onSubmit($event)"
            (startDownloadLibraryFiles)="downloadAllLibraryFiles()"
            [showRequestPrepButton]="library && !library.preparation_request"
          />
        </div>
      } @else {
        <div class="col-12">
          <app-superadmin-file-prep [libraryId]="libraryId" [storefrontId]="storefrontId" />
        </div>
      }
    </div>
  </div>
}
