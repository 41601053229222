@if (root && item.visible !== false && item.state?.hidden !== true) {
  <div
    [routerLink]="item.routerLink"
    routerLinkActive="active-route-div"
    [routerLinkActiveOptions]="{ exact: true }"
    class="layout-menuitem-root-text cursor-pointer flex align-items-center gap-2"
    [style.padding-left]="item.icon ? null : '16px'"
  >
    @if (item.icon) {
      <img width="16px" height="16px" [src]="item.icon" alt="" />
    }
    {{ item.label }}
  </div>
}

@if ((!item.routerLink || item.items) && item.visible !== false) {
  <a
    [attr.href]="item.url"
    (click)="itemClick($event)"
    (mouseenter)="onMouseEnter()"
    [ngClass]="item['class']"
    [attr.target]="item.target"
    tabindex="0"
    pRipple
    [pTooltip]="item.label"
    [tooltipDisabled]="!(isSlim && root && !active)"
  >
    <div class="flex align-items-center gap-2" [style.padding-left]="item.icon ? 0 : '16px'">
      @if (item.icon) {
        <img [src]="item.icon" width="16px" height="16px" alt="" />
      }
      <span class="layout-menuitem-text">{{ item.label }}</span>
      @if (item.items) {
        <i class="pi pi-fw pi-angle-down layout-submenu-toggler"></i>
      }
    </div>
  </a>
}
@if (item.routerLink && !item.items && item.visible !== false) {
  <a
    (click)="itemClick($event)"
    (mouseenter)="onMouseEnter()"
    [ngClass]="item['class']"
    [routerLink]="item.routerLink"
    routerLinkActive="active-route"
    [routerLinkActiveOptions]="
      item.routerLinkActiveOptions || {
        exact: true,
        paths: 'subset',
        queryParams: 'ignored',
        matrixParams: 'ignored',
        fragment: 'ignored'
      }
    "
    [fragment]="item.fragment"
    [queryParamsHandling]="item.queryParamsHandling"
    [preserveFragment]="item.preserveFragment"
    [skipLocationChange]="item.skipLocationChange"
    [replaceUrl]="item.replaceUrl"
    [state]="item.state"
    [queryParams]="item.queryParams"
    [attr.target]="item.target"
    tabindex="0"
    pRipple
    [pTooltip]="item.label"
    [tooltipDisabled]="!(isSlim && root)"
  >
    <div class="flex align-items-center gap-2" [style.padding-left]="item.icon ? 0 : '16px'">
      @if (item.icon) {
        <img [src]="item.icon" width="16px" height="16px" alt="" />
      }
      <span class="layout-menuitem-text">{{ item.label }}</span>
      @if (item.items) {
        <i class="pi pi-fw pi-angle-down layout-submenu-toggler"></i>
      }
    </div>
  </a>
}

@if (item.items && item.visible !== false) {
  <ul #submenu [@children]="submenuAnimation" (@children.done)="onSubmenuAnimated($event)">
    @for (child of item.items; track i; let i = $index) {
      <li app-nav-item [item]="child" [index]="i" [parentKey]="key" [class]="child.badgeClass"></li>
    }
  </ul>
}
