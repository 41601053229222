import { Component, inject, OnInit } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { InputTextModule } from 'primeng/inputtext';
import { RippleModule } from 'primeng/ripple';
import { TableLazyLoadEvent } from 'primeng/table';
import { ToastModule } from 'primeng/toast';
import { finalize, map } from 'rxjs';

import { InputComponent } from 'app/_shared/components/input/input.component';
import {
  ProductPaginatorOptions,
  ListProductsComponent,
  Columns
} from 'app/_shared/components/list-products/list-products.component';
import { handleSupplierMapping } from 'app/_shared/helpers/suppliers-helper';
import { SupplierProductStatusChangedEvent } from 'app/_shared/interfaces/sockets/SupplierProductStatusChangedEvent';
import {
  AlphaBroaderSupplierProduct,
  HitSupplierProduct,
  SanmarSupplierProduct,
  SsActiveSupplierProduct,
  Supplier,
  SupplierNameFromSlug
} from 'app/_shared/interfaces/supplier';
import { SupplierProduct } from 'app/_shared/models/supplier-product.model';
import { EchoService } from 'app/_shared/services/echo.service';
import { SupplierService } from 'app/_shared/services/supplier.service';

@Component({
  selector: 'app-supplier-products',
  imports: [
    InputTextModule,
    ReactiveFormsModule,
    ButtonModule,
    RippleModule,
    ToastModule,
    CardModule,
    ListProductsComponent
  ],
  templateUrl: './supplier-products.component.html',
  styleUrl: './supplier-products.component.scss'
})
export class SupplierProductsComponent implements OnInit {
  protected readonly SupplierNameFromSlug = SupplierNameFromSlug;
  private activatedRoute = inject(ActivatedRoute);
  private supplierService = inject(SupplierService);
  private router = inject(Router);
  private echoService: EchoService = inject(EchoService);
  slug: string;
  isLoading: boolean = false;
  supplierProducts: SupplierProduct[];
  supplier: Supplier;
  cols: Columns[] = [
    { element: 'image', field: 'image', header: 'Image', width: '0%' },
    { field: 'external_id', header: 'External ID', width: '20%' },
    { field: 'name', header: 'Name', width: '45%' },
    { field: 'status', header: 'Status', width: '5%' },
    {
      element: 'actions',
      field: 'actions',
      header: 'Actions',
      width: '15%',
      actions: [
        {
          icon: 'pi pi-eye',
          label: '',
          severity: 'secondary',
          onClick: ($event: MouseEvent, product: SupplierProduct) => {
            $event.stopPropagation();
            this.router.navigate([product.id], {
              relativeTo: this.activatedRoute
            });
          }
        },
        {
          icon: 'pi pi-file-export',
          label: 'Add to catalog',
          disabled: (product: SupplierProduct) => this.handleCreateButtonDisable(product),
          onClick: ($event: MouseEvent, product: SupplierProduct) => {
            $event.stopPropagation();
            this.router.navigate([product.id, 'create'], {
              relativeTo: this.activatedRoute
            });
          }
        }
      ]
    }
  ];
  paginatorOptions: ProductPaginatorOptions = {
    total: 0,
    rows: 10,
    first: 0
  };

  ngOnInit(): void {
    this.slug = this.activatedRoute.snapshot.paramMap.get('slug') ?? '';
    this.getSupplierBySlug();
    this.isLoading = true;
  }

  importNewProduct() {
    this.router.navigate(['suppliers', this.slug, 'products', 'import']);
  }

  fetchProducts(paginatorOptions: ProductPaginatorOptions | TableLazyLoadEvent) {
    this.isLoading = true;
    const { rows, first, globalFilter } = paginatorOptions;
    const params = {
      rows,
      ...(globalFilter && { global_filter: globalFilter }),
      page: first && rows ? Math.floor(first / rows) + 1 : 1,
      'relations[]': ['featuredImage']
    };
    this.supplierService
      .getSupplierProducts(this.slug, params)
      .pipe(
        finalize(() => (this.isLoading = false)),
        map(products => {
          return {
            ...products.data,
            data: products.data.data.map(
              (
                product:
                  | HitSupplierProduct
                  | SanmarSupplierProduct
                  | SsActiveSupplierProduct
                  | AlphaBroaderSupplierProduct
              ) => {
                return handleSupplierMapping(this.slug, product);
              }
            )
          };
        })
      )
      .subscribe({
        next: products => {
          this.supplierProducts = products.data;
          this.paginatorOptions = {
            total: products.total,
            rows: products.per_page,
            first: products.from - 1
          };
        },
        error: err => {
          console.error(err);
        }
      });
  }

  handleLazyLoadProducts($event: TableLazyLoadEvent) {
    this.fetchProducts($event);
  }

  private listenToPreviewChanges() {
    this.echoService.listen(
      'App.Models.Supplier.' + this.supplier.id,
      SupplierProductStatusChangedEvent.eventName,
      (e: SupplierProductStatusChangedEvent) => {
        const updatedProductId = e.supplierProductId;
        const updatedProductStatus = e.supplierProductStatus;

        const productIndex = this.supplierProducts.findIndex((product: SupplierProduct) => {
          return product.id === updatedProductId;
        });

        if (productIndex !== -1) {
          this.supplierProducts[productIndex].import_status = updatedProductStatus;
        }
      }
    );
  }

  private getSupplierBySlug() {
    this.isLoading = true;

    this.supplierService.getSupplierBySlug(this.slug).subscribe({
      next: res => {
        this.supplier = res.data;
        this.listenToPreviewChanges();
      },
      error: error => console.error(error),
      complete: () => (this.isLoading = false)
    });
  }

  private handleCreateButtonDisable(product: SupplierProduct): boolean {
    return !!(product.import_status && product.import_status.toLowerCase() !== 'complete');
  }
}
