<div class="grid">
  <div class="col-12">
    <div class="card">
      <h4>Users Overview</h4>
      <p>{{ tabContents[activeTabIndex] }}</p>
      <p-tabView orientation="left" (onChange)="onTabChange($event)">
        <p-tabPanel header="Company Users" class="line-height-3 m-0">
          @if (activeTabIndex === 0) {
            <app-user-table-list
              [users]="isLoading || isLoadingEdit || isLoadingDelete ? [] : users"
              [loading]="isLoading || isLoadingEdit || isLoadingDelete"
              [totalRecords]="totalRecords"
              [loading]="isLoading || isLoadingEdit || isLoadingDelete"
              [rows]="10"
              [globalFilterFields]="['first_name', 'last_name', 'email', 'phone']"
              (lazyLoadUsers)="loadCompanyUsers($event)"
              (editUserEmitter)="editCompanyUser($event)"
              (deleteUserEmmiter)="deleteCompanyUser($event)"
            ></app-user-table-list>
          }
        </p-tabPanel>
        <p-tabPanel header="Invited Users" class="line-height-3 m-0">
          @if (activeTabIndex === 1) {
            <app-invite-list />
          }
        </p-tabPanel>
      </p-tabView>
    </div>
  </div>
</div>
