<div class="grid">
  <div class="col-12">
    <div class="card">
      <div class="flex justify-content-between">
        <div>
          <h4>Billing</h4>
          <p>Manage your subscriptions and payment methods."</p>
        </div>
        <app-stripe-connect></app-stripe-connect>
      </div>

      <p-tabView orientation="left" (onChange)="onTabChange($event)">
        <p-tabPanel header="Subscriptions" class="line-height-3 m-0">
          @if (activeTabIndex === 0) {
            <app-subscriptions />
          }
        </p-tabPanel>
        <p-tabPanel header="Payment Methods" class="line-height-3 m-0">
          @if (activeTabIndex === 1) {
            <app-payment-methods />
          }
        </p-tabPanel>
      </p-tabView>
    </div>
  </div>
</div>
