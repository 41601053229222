import { Component, inject, signal } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { RouterLink } from '@angular/router';

import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { RippleModule } from 'primeng/ripple';
import { finalize } from 'rxjs';

import { InputComponent } from 'app/_shared/components/input/input.component';
import { AuthService } from 'app/_shared/services/auth.service';
import { ToastService } from 'app/_shared/services/toast.service';

@Component({
  selector: 'app-forgot-password',
  imports: [ButtonModule, RippleModule, RouterLink, InputTextModule, ReactiveFormsModule, InputComponent],
  templateUrl: './forgot-password.component.html',
  styleUrl: './forgot-password.component.scss'
})
export class ForgotPasswordComponent {
  private toastService = inject(ToastService);
  private authService = inject(AuthService);
  submitted = signal(false);
  isLoading = signal(false);

  forgotPasswordForm = new FormGroup({
    email: new FormControl(null, [Validators.required, Validators.email])
  });

  onFormSubmit() {
    if (!this.forgotPasswordForm.valid) {
      this.forgotPasswordForm.markAllAsTouched();
      this.submitted.set(true);
      this.toastService.error('Please fill email field correctly!');
      return;
    }
    const email: any = this.forgotPasswordForm.value.email;
    this.isLoading.set(true);
    this.authService
      .forgotEmail(email)
      .pipe(finalize(() => this.isLoading.set(false)))
      .subscribe({
        next: res => {
          this.toastService.success(res.message);
        },
        error: err => {
          console.error(err);
        }
      });
  }
}
