<div class="mt-5 menu-list">
  @if (showAddMenuItem) {
    <div class="create-new-item" [@slideDownAnimation]>
      <form [formGroup]="menuForm" (ngSubmit)="handleMenuCreate()">
        <div class="flex justify-content-end">
          <a href="#" (click)="$event.preventDefault(); showAddMenuItem = false">Close</a>
        </div>
        <app-input
          name="lagem"
          label="Menu item label"
          [control]="menuForm.controls.label"
          placeholder="Label"
          [fullWidth]="true"
          shape="round"
        ></app-input>
        <div class="mt-3 mb-3">
          <label for="">Select page</label>
          <p-dropdown
            appendTo="body"
            [options]="pages()"
            optionLabel="title"
            formControlName="page"
            placeholder="Select page"
            [style]="{ width: '100%', borderRadius: '25px' }"
          ></p-dropdown>
        </div>
        <app-input
          name="customUrl"
          label="Custom URL"
          [control]="menuForm.controls.customUrl"
          placeholder="Custom URL"
          [fullWidth]="true"
          shape="round"
        ></app-input>
        <div class="mt-3">
          <p-button label="Create Menu Item" type="submit" ariaLabel="Create menu item" [style]="{ width: '100%' }" />
        </div>
      </form>
    </div>
  } @else {
    <p-button
      [outlined]="true"
      (click)="showAddMenuItem = true"
      label="Add menu item"
      ariaLabel="Add menu item"
      class="w-full"
      [style]="{ flex: 1, width: '100%' }"
    />
  }
  <div class="mt-5">
    <p-orderList [value]="menuItems" [dragdrop]="true" (onReorder)="onMenuReorder()" [styleClass]="'menu-items-list'">
      <ng-template let-item pTemplate="item">
        <p-panel [toggleable]="false">
          <ng-template pTemplate="header">
            <div class="flex align-items-center gap-2">
              <span>{{ item.label }}</span>
            </div>
          </ng-template>
          <ng-template pTemplate="icons">
            <button class="p-panel-header-icon p-link mr-2" (click)="toggleMenuPopup($event, menu, item)">
              <span class="pi pi-cog"></span>
            </button>
            <p-menu appendTo="body" #menu id="config_menu" [model]="menuPopupItems" [popup]="true" />
          </ng-template>
        </p-panel>
      </ng-template>
    </p-orderList>
  </div>
  <p-toast />
  <p-confirmDialog />
</div>
