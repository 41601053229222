<p-tabView [activeIndex]="activeIndex" (onChange)="activeIndex = $event.index">
  <p-tabPanel header="Upload File">
    <form [formGroup]="uploadFileForm" (ngSubmit)="onUploadFile()">
      <div class="grid">
        <div class="col-12">
          <p>
            You can add more than one .png mockup file - for example dark and light versions of the same logo. You’ll be
            able to adjust that for each product color separately.
          </p>
        </div>
        <div class="col-12 custom-upload">
          <p-fileUpload
            #fileUploader
            [multiple]="false"
            [customUpload]="true"
            (onSelect)="onUpload($event)"
            accept=".png"
            maxFileSize="1000000"
            showUploadButton="false"
            customUpload="true"
            showUploadButton="false"
            styleClass="border-1 surface-border surface-card p-0 border-round mb-1"
          >
            <ng-template pTemplate="file"></ng-template>
            <ng-template pTemplate="content">
              <div class="h-10rem m-1 border-round">
                @if (!file?.objectURL) {
                  <div
                    class="flex flex-column w-full h-full justify-content-center align-items-center cursor-pointer"
                    (click)="fileUploader.advancedFileInput.nativeElement.click()"
                  >
                    <i class="pi pi-fw pi-file text-4xl text-primary"></i>
                    <span class="block font-semibold text-900 text-lg mt-3">Drop or select mockup file</span>
                  </div>
                }
                @if (this.file?.objectURL) {
                  <div class="flex justify-content-center align-items-center h-full w-full">
                    <img
                      [src]="this.file?.objectURL"
                      class="max-w-full max-h-full"
                      alt="Library thumbnail"
                      style="object-fit: contain"
                    />
                    <button
                      pButton
                      type="button"
                      aria-label="remove"
                      icon="pi pi-times"
                      class="p-button-rounded p-button-primary text-sm absolute justify-content-center align-items-center"
                      style="top: 10px; right: 10px"
                      (click)="$event.stopPropagation(); removeMockupVersion()"
                    ></button>
                  </div>
                }
              </div>
            </ng-template>
          </p-fileUpload>
          @if (submitted && !file?.objectURL) {
            <div>
              <small class="p-text-danger">File is required</small>
            </div>
          }
        </div>
        <div class="col-12 sm:col-6">
          <label for="design">Design folder</label>
          <p-dropdown
            [options]="storefrontLibraryFolders"
            optionValue="id"
            optionLabel="name"
            placeholder="Select a folder"
            [style]="{ width: '100%' }"
            formControlName="library_folder_id"
          />
          @if (uploadFileForm.get('library_folder_id')?.touched && uploadFileForm.get('library_folder_id')?.invalid) {
            <div>
              <small class="p-text-danger">Design folder is required</small>
            </div>
          }
        </div>
        <div class="col-12 sm:col-6">
          <label for="design_name">Design File Name</label>
          <input formControlName="name" type="text" pInputText class="w-full" />
          @if (uploadFileForm.get('name')?.touched && uploadFileForm.get('name')?.invalid) {
            <div>
              <small class="p-text-danger">File name is required</small>
            </div>
          }
        </div>
        <div class="col-12">
          <div class="flex justify-content-end">
            <p-button label="Save" type="submit" />
          </div>
        </div>
      </div>
    </form>
  </p-tabPanel>
  <p-tabPanel header="Select from Library">
    <div class="grid" [style]="{ maxHeight: '500px', overflow: 'auto' }">
      @for (file of allLibraries; let i = $index; track i) {
        <div class="col-4 md:col-3">
          <div
            class="border-2 border-transparent transition-colors transition-duration-150 border-round"
            [ngClass]="{ 'border-primary': file.id === selectedLibrary?.id }"
          >
            <p class="m-0 text-center">{{ file.name }}</p>
            <img
              [src]="file.library_thumbnail.full_path"
              class="w-full cursor-pointer"
              (click)="selectedLibrary = file"
              alt=""
            />
          </div>
        </div>
      }
    </div>
    <div class="mt-4">
      <div class="grid">
        <div class="col-6">
          <div>
            <label for="">Select decoration method</label>
          </div>
          <p-selectButton
            [options]="decorationMethods"
            [(ngModel)]="selectedDecoration"
            [multiple]="false"
            optionLabel="name"
          />
        </div>
        <div class="col-6">
          <div>
            <label for="">Select print position</label>
          </div>
          <p-dropdown
            [options]="printPositions"
            [(ngModel)]="selectedPrintPosition"
            optionLabel="label"
            placeholder="Select print position"
            appendTo="body"
          />
        </div>
      </div>
    </div>
    <div class="flex w-full justify-content-end mt-4 footer-buttons">
      <p-button
        [style]="{ width: '100%', display: 'flex', justifyContent: 'center' }"
        [outlined]="true"
        label="Cancel"
        type="button"
        (click)="dialogRef.close()"
      />
      <p-button
        [style]="{ width: '100%', display: 'flex', justifyContent: 'center' }"
        label="Save"
        type="button"
        (click)="onSaveAndClose()"
      />
    </div>
  </p-tabPanel>
</p-tabView>
