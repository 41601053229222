<div class="grid">
  <div class="col-12">
    <div class="card">
      <div class="flex justify-content-between">
        <h4>Store Settings</h4>
        <p-button
          (click)="openDeleteModal()"
          outlined
          severity="danger"
          icon="pi pi-trash"
          label="Remove Storefront "
        ></p-button>
      </div>
      <p-tabMenu [model]="menuItems" />

      <div class="mt-3">
        <router-outlet />
      </div>
    </div>
  </div>
</div>
<p-confirmDialog header="Confirmation" key="confirmDelete" [style]="{ width: '28rem' }"></p-confirmDialog>
