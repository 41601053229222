export enum StorefrontAction {
  Dashboard = 'storefronts.dashboard',
  Settings = 'storefronts.settings',
  Design = 'storefronts.design',
  Products = 'storefronts.products',
  AddProducts = 'storefronts.add-products',
  EditProducts = 'storefronts.edit-products',
  GiftCards = 'storefronts.gift-cards',
  PromoCodes = 'storefronts.promo-codes'
}

export enum CompanyAction {
  Update = 'companies.update',
  ManageUsers = 'companies.users.view',
  Orders = 'companies.orders',
  SingleOrders = 'companies.single-orders'
}
