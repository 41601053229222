import { Component, effect, inject } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { finalize, Subscription } from 'rxjs';

import { CustomValidators } from 'app/_shared/services/custom-validators.service';

import { AuthStore } from '../../../_store/auth.store';
import { FormGroupValue } from '../../models/FormGroup';
import { User } from '../../models/User';
import { AccountService } from '../../services/account.service';
import { ToastService } from '../../services/toast.service';

@Component({
    selector: 'app-user-account',
    imports: [InputTextModule, ReactiveFormsModule, ButtonModule],
    templateUrl: './user-account.component.html'
})
export class UserAccountComponent {
  authStore = inject(AuthStore);
  fb = inject(FormBuilder);
  toastService = inject(ToastService);
  accountService = inject(AccountService);
  router = inject(Router);

  account: User | null = null;
  accountForm: FormGroup;
  passwordForm: FormGroup;
  formReady = false;
  submitted = false;

  showPassword = false;
  showPasswordConfirmation = false;
  isLoading = false;

  private authUserEff = effect(() => {
    this.account = this.authStore.user();
    this.buildAccountForm();
    this.buildPasswordForm();
  });

  buildAccountForm() {
    this.accountForm = this.fb.group({
      first_name: [this.account?.first_name, [Validators.required]],
      last_name: [this.account?.last_name, [Validators.required]],
      phone: this.account?.phone,
      email: [this.account?.email, [Validators.required]]
    });
    this.formReady = true;
  }

  buildPasswordForm() {
    this.passwordForm = this.fb.group({
      password: [null, [Validators.required, CustomValidators.passwordPattern()]],
      password_confirmation: [null, [Validators.required, CustomValidators.matchValues('password')]]
    });
  }

  onSubmit() {
    if (this.accountForm.invalid) {
      this.submitted = true;
      this.toastService.error('Please fill all required fields correctly!');
      return;
    }
    const formData = this.accountForm.value;
    this.updateProfile(formData);
  }

  updateProfile(formData: FormGroupValue) {
    const originalEmail = this.account?.email;
    this.isLoading = true;
    this.accountService
      .updateAccount(formData)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe({
        next: response => {
          this.toastService.success('Successfully updated profile!');
          this.authStore.setAuthUser(response.data);
          if (response.data.email !== originalEmail) {
            this.router.navigateByUrl('/auth/email-verification');
          }
        },
        error: err => {
          console.error('Update failed:', err);
        }
      });
  }

  togglePasswordVisibility() {
    this.showPassword = !this.showPassword;
  }

  togglePasswordConfirmationVisibility() {
    this.showPasswordConfirmation = !this.showPasswordConfirmation;
  }

  onPasswordSubmit() {
    if (this.passwordForm.invalid) {
      this.submitted = true;
      this.toastService.error('Please fill all required fields correctly!');
      return;
    }

    const formData = this.passwordForm.value;
    this.updatePassword(formData);
  }

  updatePassword(formData: FormGroupValue) {
    this.isLoading = true;
    this.accountService
      .updatePassword(formData)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe({
        next: () => {
          this.toastService.success('Successfully updated password!');
        },
        error: err => {
          console.error('Update failed:', err);
          this.toastService.error(err.error.message);
        }
      });
  }
}
