import { Injectable } from '@angular/core';

import { TableLazyLoadEvent } from 'primeng/table';

import { ApiService } from './api.service';

@Injectable({ providedIn: 'root' })
export class TransactionService extends ApiService {
  routePrefix = this.apiUrl;

  public index(companyId?: number, params?: any) {
    return this.get(`${this.routePrefix}transactions/${companyId}`, { params });
  }

  public paginatedIndex(companyId?: number, $event?: TableLazyLoadEvent, params?: any) {
    return this.get(`${this.routePrefix}transactions/${companyId}`, {
      params: this.handleTableLazyLoadEvent($event, params)
    });
  }

  public transactionTypes() {
    return this.get(`${this.routePrefix}transaction-types`);
  }

  public companyTransactionsStats(companyId: string) {
    return this.get(`${this.routePrefix}transactions/${companyId}/stats`);
  }
}
