import { Component, input, output } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { ButtonModule } from 'primeng/button';
import { RatingModule } from 'primeng/rating';

import { Storefront, StorefrontStatus } from 'app/_shared/models/Storefront';

import { StatusBadgeComponent } from '../status-badge/status-badge.component';

@Component({
  selector: 'app-storefront-card',
  imports: [RatingModule, FormsModule, ButtonModule, StatusBadgeComponent],
  templateUrl: './storefront-card.component.html',
  styleUrl: './storefront-card.component.scss'
})
export class StorefrontCardComponent {
  storefront = input.required<Storefront>();
  userCompanyOrSuperAdmin = input.required<boolean>();
  openStorefrontSettings = output<Storefront>();
  editStorefront = output<Storefront>();
  openStorefront = output<Storefront>();
  reactiveStorefront = output<Storefront>();

  StorefrontStatus = StorefrontStatus;

  handleEditStorefront() {
    this.editStorefront.emit(this.storefront());
  }
  handleStorefrontSettings() {
    this.openStorefrontSettings.emit(this.storefront());
  }
  handleNavigateToStorefront() {
    this.openStorefront.emit(this.storefront());
  }
  handleReactiveStorefront() {
    this.reactiveStorefront.emit(this.storefront());
  }
}
