import { CommonModule } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { ConfirmationService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { InputTextModule } from 'primeng/inputtext';
import { TableModule } from 'primeng/table';
import { finalize } from 'rxjs';

import { AddPaymentMethodModalComponent } from 'app/_shared/components/add-card-modal/add-payment-method-modal.component';
import { PaymentMethod } from 'app/_shared/models/PaymentMethod';
import { CardMaskPipe } from 'app/_shared/pipes/card-mask.pipe';
import { PaymentService } from 'app/_shared/services/payment.service';
import { ToastService } from 'app/_shared/services/toast.service';

@Component({
    selector: 'app-payment-methods',
    imports: [
        ButtonModule,
        CardModule,
        TableModule,
        InputTextModule,
        DialogModule,
        CardMaskPipe,
        CommonModule,
        ConfirmDialogModule
    ],
    providers: [DialogService, ConfirmationService],
    templateUrl: './payment-methods.component.html'
})
export class PaymentMethodsComponent implements OnInit {
  dialogService = inject(DialogService);
  confirmationService = inject(ConfirmationService);
  toastService = inject(ToastService);
  paymentService = inject(PaymentService);
  route = inject(ActivatedRoute);

  paymentMethods: PaymentMethod[] = [];
  dialogRef: DynamicDialogRef | undefined;
  companyId: number;
  loading = false;

  ngOnInit(): void {
    const companyId = this.route.snapshot.paramMap.get('id');
    if (!companyId) {
      console.error('Company ID is null.');
      return;
    }
    this.companyId = +companyId;
    this.loadPaymentMethods();
  }

  loadPaymentMethods(): void {
    this.loading = true;
    this.paymentService
      .getCompanyPaymentMethods(this.companyId)
      .pipe(
        finalize(() => {
          this.loading = false;
        })
      )
      .subscribe({
        next: res => {
          this.paymentMethods = res.data;
        },
        error: error => {
          console.error(error);
        }
      });
  }

  openDefaultMethodModal(event: Event, paymentMethodId: string) {
    this.confirmationService.confirm({
      key: 'confirmDefault',
      target: event.target || new EventTarget(),
      message: 'Are you sure you want to set this method as default? This will be used for all future transactions.',
      accept: () => {
        this.setDefaultPaymentMethod(paymentMethodId);
      }
    });
  }

  setDefaultPaymentMethod(paymentMethodId: string) {
    this.paymentService.setDefaultPaymentMethod(this.companyId, paymentMethodId).subscribe({
      next: () => {
        this.toastService.success('Your default payment method has been updated successfully.');
        this.loadPaymentMethods();
      },
      error: err => {
        console.error('There was an issue while setting new default payment method', err);
      }
    });
  }

  openDeleteModal(event: Event, paymentMethodId: string) {
    this.confirmationService.confirm({
      key: 'confirmDelete',
      target: event.target || new EventTarget(),
      message: 'Are you sure that you want to delete this payment method?',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.deletePaymentMethod(paymentMethodId);
      }
    });
  }

  private deletePaymentMethod(paymentMethodId: string) {
    this.paymentService.deletePaymentMethod(this.companyId, paymentMethodId).subscribe({
      next: () => {
        this.toastService.success('Payment method successfully deleted!');
        this.loadPaymentMethods();
      },
      error: err => {
        console.error('Delete action failed:', err);
      }
    });
  }

  openAddMethodModal() {
    this.dialogRef = this.dialogService.open(AddPaymentMethodModalComponent, {
      header: 'Add new method',
      width: '800px'
    });
    this.dialogRef.onClose.subscribe((res: boolean) => {
      if (res) {
        this.toastService.success('Payment method added successfully!');
        if(this.paymentMethods.length === 0) {
          this.toastService.info('Before subscribing storefronts to plans, select a default payment method!');
        }
        this.loadPaymentMethods();
      }
    });
  }
}
