// Adjust the path as needed
import { inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

import { DesignerStore } from 'app/_store/designer.store';
import { StorefrontStore } from 'app/_store/storefront.store';
import { DesignerHelperService } from 'app/admin/storefront/designer/designer-helper.service';

export abstract class SectionDefaultImports {
  fb = inject(FormBuilder);
  form: FormGroup;
  dynamicConfig = inject(DynamicDialogConfig);
  ref = inject(DynamicDialogRef);
  designerHelperService = inject(DesignerHelperService);
  designerStore = inject(DesignerStore);
  storefrontStore = inject(StorefrontStore);
}
