import { Component, effect, inject, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

import { MenuItem } from 'primeng/api';
import { CalendarModule } from 'primeng/calendar';
import { DropdownModule } from 'primeng/dropdown';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { SkeletonModule } from 'primeng/skeleton';
import { SplitButtonModule } from 'primeng/splitbutton';
import { TableLazyLoadEvent, TableModule } from 'primeng/table';
import { TagModule } from 'primeng/tag';
import { BehaviorSubject, combineLatest, debounceTime, distinctUntilChanged } from 'rxjs';

import { AddTransactionServiceDialogComponent } from 'app/_shared/components/add-transaction-service-dialog/add-transaction-service-dialog.component';
import { DepositMoneyModalComponent } from 'app/_shared/components/deposit-money-modal/deposit-money-modal.component';
import { SuperadminCompanyTransferDialogComponent } from 'app/_shared/components/superadmin-company-transfer-dialog/superadmin-company-transfer-dialog.component';
import { TransactionDetailsComponent } from 'app/_shared/components/transaction-details/transaction-details.component';
import { CompanyTransactionsStatistics } from 'app/_shared/models/CompanyTransactionsStatistics';
import { Storefront } from 'app/_shared/models/Storefront';
import { DisplayDatePipe } from 'app/_shared/pipes/date.pipe';
import { PricePipe } from 'app/_shared/pipes/price.pipe';
import { UsdPipe } from 'app/_shared/pipes/usd-currency.pipe';
import { TransactionService } from 'app/_shared/services/transaction.service';
import { AuthStore } from 'app/_store/auth.store';
import { CompanyStore } from 'app/_store/company.store';

import { MoneyCardComponent } from '../../../_shared/components/money-card/money-card.component';

interface DropdownOption {
  label: string;
  value: string;
}
@Component({
  selector: 'app-transactions',
  imports: [
    TableModule,
    TagModule,
    FormsModule,
    CalendarModule,
    DropdownModule,
    SkeletonModule,
    DisplayDatePipe,
    UsdPipe,
    TransactionDetailsComponent,
    PricePipe,
    SplitButtonModule,
    MoneyCardComponent,
    ProgressSpinnerModule
  ],
  templateUrl: './transactions.component.html',
  styleUrl: './transactions.component.scss',
  providers: [DynamicDialogRef, DialogService]
})
export class TransactionsComponent implements OnInit {
  companyStore = inject(CompanyStore);
  transactionService = inject(TransactionService);
  dialogRef = inject(DynamicDialogRef);
  dialogService = inject(DialogService);
  transactions: any[] = [];
  route = inject(ActivatedRoute);
  authStore = inject(AuthStore);

  companyId: string;
  storefronts: Storefront[] | undefined = [];

  transactionTypes: DropdownOption[] = [];
  companyTransactionsStatistics: CompanyTransactionsStatistics;

  totalRecords: number;
  filtersSubject = new BehaviorSubject<any>(null);
  filtersChanged = false;
  lastLazyLoadEvent: TableLazyLoadEvent | undefined;

  expandedRows: { [key: number]: boolean } = {};
  isAllExpanded = false;

  globalFilter: string;
  selectedStorefront: string | null;
  selectedTransactionType: string | null;
  superAdminUser: boolean;
  actionButtonItems: MenuItem[];

  loadingRows = Array(10)
    .fill(0)
    .map((_, index) => index + 1);

  ngOnInit() {
    this.companyId = this.route.snapshot.paramMap.get('id') ?? '';
    this.superAdminUser = this.authStore.isSuperAdmin() ?? false;
    this.populatePosibleUserActions();
    this.fetchTransactionTypes();
    this.getCompanyTransactionStats();
    combineLatest([this.filtersSubject.pipe(debounceTime(300), distinctUntilChanged())]).subscribe(() => {
      if (this.filtersChanged) {
        this.loadTransactions(this.lastLazyLoadEvent);
      }
      this.filtersChanged = false;
    });
  }

  private storefrontEff = effect(() => {
    this.storefronts = this.companyStore.workingCompany()?.storefronts;
  });

  populatePosibleUserActions() {
    this.actionButtonItems = [
      {
        label: 'Deposit Money',
        icon: 'pi pi-money-bill',
        command: () => {
          this.openDepositMoneyDialog();
        }
      }
    ];

    if (this.superAdminUser) {
      this.actionButtonItems = [
        ...this.actionButtonItems,
        {
          label: 'Add Service',
          icon: 'pi pi-dollar',
          command: () => {
            this.openAddTransactionServiceDialog();
          }
        },
        {
          label: 'Transfer Funds to Company',
          icon: 'pi pi-arrow-right',
          command: () => {
            this.openCompanyTransferDialog();
          }
        }
      ];
    }
  }

  fetchTransactionTypes() {
    this.transactionService.transactionTypes().subscribe({
      next: res => {
        this.transactionTypes = Object.keys(res.data).map(key => ({
          label: key.charAt(0).toUpperCase() + key.slice(1),
          value: res.data[key]
        }));
      }
    });
  }
  getCompanyTransactionStats() {
    this.transactionService.companyTransactionsStats(this.companyId).subscribe({
      next: res => {
        this.companyTransactionsStatistics = res.data;
      }
    });
  }

  loadTransactions(event?: TableLazyLoadEvent) {
    if (event) {
      this.lastLazyLoadEvent = event;
    }

    const filters = this.filtersSubject.value || {};
    const params = {
      ...filters
    };

    this.transactionService.paginatedIndex(this.companyStore.workingCompany()?.id, event, params).subscribe({
      next: res => {
        this.transactions = res.data.data;
        this.totalRecords = res.data.total;
      },
      error: error => console.error(error)
    });
  }

  onFilterChange(filterKey: string, filterValue: any): void {
    const updatedFilters = { ...this.filtersSubject.value };

    if (filterValue !== null && filterValue !== undefined && filterValue !== '') {
      updatedFilters[filterKey] = filterValue;
    } else {
      delete updatedFilters[filterKey];
    }

    this.filtersSubject.next(updatedFilters);
    this.filtersChanged = true;
  }

  toggleExpandAll() {
    const allExpanded =
      Object.keys(this.expandedRows).length === this.transactions.length &&
      Object.values(this.expandedRows).every(Boolean);
    if (allExpanded) {
      this.expandedRows = {};
    } else {
      this.expandedRows = this.transactions.reduce((acc, transaction) => {
        acc[transaction.id] = true;
        return acc;
      }, {});
    }
    this.isAllExpanded = !allExpanded;
  }

  openDepositMoneyDialog() {
    this.dialogRef = this.dialogService.open(DepositMoneyModalComponent, {
      header: 'Deposit money',
      width: '700px',
      data: {
        companyId: this.companyId
      }
    });
    this.dialogRef.onClose.subscribe(res => {
      if (res) {
        this.getCompanyTransactionStats();
        this.loadTransactions(this.lastLazyLoadEvent);
      }
    });
  }

  openAddTransactionServiceDialog() {
    this.dialogRef = this.dialogService.open(AddTransactionServiceDialogComponent, {
      header: 'Create Service',
      width: '700px',
      data: {
        companyId: this.companyId
      }
    });
    this.dialogRef.onClose.subscribe(res => {
      if (res) {
        this.getCompanyTransactionStats();
        this.loadTransactions(this.lastLazyLoadEvent);
      }
    });
  }

  openCompanyTransferDialog() {
    this.dialogRef = this.dialogService.open(SuperadminCompanyTransferDialogComponent, {
      header: 'Create Service',
      width: '700px',
      data: {
        companyId: this.companyId
      }
    });
    this.dialogRef.onClose.subscribe(res => {
      if (res) {
        this.getCompanyTransactionStats();
        this.loadTransactions(this.lastLazyLoadEvent);
      }
    });
  }
}
