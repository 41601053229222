import { CommonModule } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';

import { ConfirmationService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { finalize } from 'rxjs';

import { SubscriptionPlan } from 'app/_shared/models/SubscriptionPlan';
import { SubscriptionService } from 'app/_shared/services/subscription.service';

import { StorefrontSubscripiton } from '../../models/StorefrontSubscription';
import { ToastService } from '../../services/toast.service';

@Component({
    selector: 'app-change-plan-modal',
    imports: [CommonModule, CardModule, ButtonModule, ConfirmDialogModule, ProgressSpinnerModule],
    styleUrl: './change-plan-modal.component.scss',
    templateUrl: './change-plan-modal.component.html'
})
export class ChangePlanModalComponent implements OnInit {
  subcriptionService = inject(SubscriptionService);
  confirmationService = inject(ConfirmationService);
  toastService = inject(ToastService);
  dialogConfig = inject(DynamicDialogConfig);
  dialogRef = inject(DynamicDialogRef);

  plans: SubscriptionPlan[] = [];
  currentStorefrontSubscription: StorefrontSubscripiton;

  userOperation: string | null = null;
  isLoading = false;

  ngOnInit() {
    this.currentStorefrontSubscription = this.dialogConfig.data.storefrontSubscription;
    this.fetchPlanOptions();
  }

  fetchPlanOptions() {
    this.isLoading = true;
    this.subcriptionService
      .getPlans()
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe({
        next: res => {
          this.plans = res.data;
        },
        error: error => console.error(error)
      });
  }

  selectPlan(event: Event, planId: number) {
    this.confirmationService.confirm({
      key: 'confirmPlanChange',
      target: event.target || new EventTarget(),
      message: this.getConfirmationModalMessage(),
      accept: () => {
        this.changeStorefrontPlan(planId);
      }
    });
  }

  changeStorefrontPlan(planId: number) {
    this.isLoading = true;
    if (this.userOperation === 'starting') {
      this.subcriptionService
        .startStorefrontSubscription({
          plan_id: planId,
          storefront_id: this.currentStorefrontSubscription.storefront_id
        })
        .pipe(finalize(() => (this.isLoading = false)))
        .subscribe({
          next: () => {
            this.toastService.success('You have successfully started the subscription plan!');
            this.dialogRef.close(this.currentStorefrontSubscription.plan?.id);
          },
          error: err => {
            console.error('Failed to start subscription plan. Please try again later.', err);
          }
        });
      return;
    }

    this.subcriptionService
      .updateStorefrontSubscription({
        plan_id: planId,
        storefront_id: this.currentStorefrontSubscription.storefront_id
      })
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe({
        next: () => {
          this.toastService.success('You have successfully switched to the new plan.');
          this.dialogRef.close(this.currentStorefrontSubscription.plan?.id);
        },
        error: err => {
          console.error('Failed to update subscription plan. Please try again later.', err);
        }
      });
  }

  getChangePlanButtonLabel(planLevel: number | undefined, currentPlanLevel: number | undefined): string {
    if (currentPlanLevel === null || planLevel === undefined || currentPlanLevel === undefined) {
      this.userOperation = 'starting';
      return 'Start';
    }

    if (planLevel > currentPlanLevel) {
      this.userOperation = 'upgrading';
      return 'Upgrade';
    } else if (planLevel < currentPlanLevel) {
      this.userOperation = 'downgrading';
      return 'Downgrade';
    } else {
      this.userOperation = 'starting';
      return 'Start';
    }
  }

  getConfirmationModalMessage(): string {
    if (this.userOperation === 'upgrading') {
      return 'Are you sure you want to upgrade to this subscription plan? Changes will take effect immediately';
    } else if (this.userOperation === 'downgrading') {
      return 'Are you sure you want to downgrade to this subscription plan? You will be able to use the current plan until the end of the billing cycle';
    }
    return 'Are you sure you want to start this subscription plan?';
  }
}
