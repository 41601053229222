import { Injectable } from '@angular/core';

import { ApiService } from 'app/_shared/services/api.service';

@Injectable({ providedIn: 'root' })
export class DepositMoneyService extends ApiService {
  routePrefix = `${this.apiUrl}payments/`;

  public createDepositPaymentLink(companyId: number, amount: number) {
    return this.post(`${this.routePrefix}create-payment-link`, {
      amount: amount * 100,
      company_id: companyId,
      transaction_type: 'deposit'
    });
  }

  public createDepositSuperAdmin(companyId: number, amount: number) {
    return this.post(`${this.routePrefix}create-deposit`, {
      amount: amount * 100,
      company_id: companyId
    });
  }

  public superAdminCreateService(companyId: number, data: any) {
    return this.post(`${this.routePrefix}create-service`, data);
  }
}
