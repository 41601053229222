import { NgClass } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';

import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { InputTextModule } from 'primeng/inputtext';
import { debounceTime } from 'rxjs';

import { SlugHelper } from 'app/_shared/helpers/slug-helper';
import { StorefrontProductCategory } from 'app/_shared/models/StorefrontProductCategory';
import { StorefrontProductCategoryService } from 'app/_shared/services/storefront-product-category.service';
import { ToastService } from 'app/_shared/services/toast.service';

@Component({
    selector: 'app-storefront-product-category-editor',
    imports: [ButtonModule, InputTextModule, DialogModule, ReactiveFormsModule, NgClass],
    templateUrl: './storefront-product-category-editor.component.html',
    styleUrl: './storefront-product-category-editor.component.scss'
})
export class StorefrontProductCategoryEditorComponent implements OnInit {
  fb = inject(FormBuilder);
  toastService = inject(ToastService);
  categoryService = inject(StorefrontProductCategoryService);
  private slugHelper = inject(SlugHelper);

  form: FormGroup;
  category: StorefrontProductCategory | null = null;
  storefrontId: number;
  storeCategory: boolean;
  dialogConfig = inject(DynamicDialogConfig);
  dialogRef = inject(DynamicDialogRef);
  submitted = false;
  isLoading = false;
  ngOnInit() {
    this.category = this.dialogConfig.data.category;
    this.storefrontId = this.dialogConfig.data.storefrontId;
    this.storeCategory = this.dialogConfig.data.storeCategory;
    this.buildForm();
  }

  buildForm() {
    this.form = this.fb.group({
      id: [this.category?.id || null],
      name: [this.category?.name, [Validators.required]],
      slug: [this.category?.slug, [Validators.required]]
    });

    if (this.category?.name) {
      this.generateAndSetUniqueSlug(this.category?.name);
    }

    this.subscribeToNameChange();
    this.subscribeToSlugChange();
  }

  generateAndSetUniqueSlug(categoryName: string) {
    this.slugHelper.generateUniqueCategorySlug(this.storefrontId, categoryName).subscribe(uniqueSlug => {
      this.form.get('slug')?.setValue(uniqueSlug, { emitEvent: false });
    });
  }

  subscribeToNameChange() {
    this.form
      .get('name')
      ?.valueChanges.pipe(debounceTime(500))
      .subscribe(categoryName => {
        this.slugHelper.generateUniqueCategorySlug(this.storefrontId, categoryName).subscribe(uniqueSlug => {
          this.form.get('slug')?.setValue(uniqueSlug, { emitEvent: false });
        });
      });
  }

  subscribeToSlugChange() {
    this.form
      .get('slug')
      ?.valueChanges.pipe(debounceTime(500))
      .subscribe(slug => {
        this.slugHelper.generateUniqueCategorySlug(this.storefrontId, slug).subscribe(uniqueSlug => {
          this.form.get('slug')?.setValue(uniqueSlug, { emitEvent: false });
        });
      });
  }

  closeDialog() {
    this.dialogRef.close();
  }

  onSubmit() {
    this.submitted = true;

    if (this.form.invalid) {
      this.toastService.error('Please fill all required fields correctly!');
      return;
    }
    if (this.storeCategory) {
      this.category ? this.updateCategory() : this.createCategory();
    } else {
      this.dialogRef.close(this.form.value);
    }
  }

  createCategory() {
    this.isLoading = true;

    this.categoryService.createCategory(this.storefrontId, this.form.value).subscribe({
      next: () => {
        this.dialogRef.close(true);
      },
      error: err => {
        console.error('Operation failed:', err);
        this.isLoading = false;
      },
      complete: () => {
        this.isLoading = false;
      }
    });
  }

  updateCategory() {
    this.isLoading = true;

    this.categoryService.updateCategory(this.storefrontId, this.form.value, this.category?.id).subscribe({
      next: () => {
        this.dialogRef.close(true);
      },
      error: err => {
        console.error('Operation failed:', err);
        this.isLoading = false;
      },
      complete: () => {
        this.isLoading = false;
      }
    });
  }
}
