<div class="card p-6">
  <h5 class="mb-5">Company orders</h5>
  <p-table
    #dt
    [value]="orders"
    [lazy]="true"
    (onLazyLoad)="loadOrders($event)"
    dataKey="id"
    [paginator]="true"
    [rows]="10"
    [totalRecords]="totalRecords"
    [showCurrentPageReport]="true"
    [loading]="isLoading"
    [showLoader]="false"
    responsiveLayout="scroll"
    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
    [rowsPerPageOptions]="[5, 10, 25, 50]"
    [globalFilterFields]="['status']"
    [expandedRowKeys]="expandedRows"
  >
    <ng-template pTemplate="caption">
      <div class="grid">
        <div class="col-12 sm:col-6 md:col-4 xl:col-2">
          <span class="p-input-icon-left w-full">
            <i class="pi pi-search"></i>
            <input
              pInputText
              type="text"
              #filter
              [(ngModel)]="global_filter"
              (input)="onFilterChange('global_filter', filter?.value)"
              placeholder="Search Keyword"
              class="w-full"
            />
          </span>
        </div>
        <div class="col-12 sm:col-6 md:col-4 xl:col-2">
          <p-calendar
            [showIcon]="true"
            dateFormat="yy-mm-dd"
            inputId="start"
            placeholder="Start Date"
            [(ngModel)]="date_from"
            (onSelect)="onFilterChange('date_from', $event)"
            (onClear)="onFilterChange('date_from', null)"
            [showClear]="true"
            styleClass="w-full"
          ></p-calendar>
        </div>
        <div class="col-12 sm:col-6 md:col-4 xl:col-2">
          <p-calendar
            [showIcon]="true"
            dateFormat="yy-mm-dd"
            [showTime]="false"
            inputId="end"
            placeholder="Due Date"
            [(ngModel)]="date_to"
            (onSelect)="onFilterChange('date_to', $event)"
            (onClear)="onFilterChange('date_to', null)"
            [showClear]="true"
            styleClass="w-full"
          ></p-calendar>
        </div>
        <div class="col-12 sm:col-6 md:col-4 xl:col-2">
          <p-dropdown
            [options]="statusOptions"
            placeholder="Filter by Status"
            optionLabel="label"
            optionValue="value"
            [(ngModel)]="status"
            (onChange)="onFilterChange('status', $event.value)"
            [showClear]="true"
            styleClass="w-full"
          >
            <ng-template let-option>
              <div class="p-multiselect-representative-option">
                <span class="ml-2">{{ option.name }}</span>
              </div>
            </ng-template>
          </p-dropdown>
        </div>
        <div class="col-12 sm:col-6 md:col-4 xl:col-2">
          <p-dropdown
            [options]="storefronts"
            [(ngModel)]="storefront_id"
            (onLazyLoad)="loadMoreStorefronts($event)"
            (onChange)="onFilterChange('storefront_id', $event.value)"
            [virtualScroll]="true"
            [virtualScrollItemSize]="30"
            [lazy]="true"
            [loading]="isLoading"
            placeholder="Select a storefront"
            optionLabel="name"
            optionValue="id"
            styleClass="w-full"
            [filter]="true"
            filterBy="name"
          >
            <ng-template let-option>
              <div class="p-multiselect-representative-option">
                <span class="ml-2">{{ option.name }}</span>
              </div>
            </ng-template>
          </p-dropdown>
        </div>
        <div class="col-12 sm:col-6 md:col-4 xl:col-2">
          <button
            pButton
            pRipple
            type="button"
            label="Clear"
            class="p-button-text mb-2"
            (click)="clearFilters()"
          ></button>
        </div>
      </div>
    </ng-template>
    <ng-template pTemplate="header">
      <tr>
        <th style="width: 5rem">
          <p-button
            type="button"
            [icon]="isAllExpanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"
            (click)="toggleExpandAll()"
            tooltip="Expand/Collapse All"
            [plain]="true"
            [text]="true"
          ></p-button>
        </th>
        <th style="min-width: 6rem">
          <div class="flex justify-content-between align-items-center">Order number</div>
        </th>
        <th pSortableColumn="status" style="min-width: 12rem">
          <div class="flex justify-content-between align-items-center">Status</div>
        </th>
        <th style="min-width: 14rem">
          <div class="flex justify-content-between align-items-center">Buyer</div>
        </th>
        <th style="min-width: 12rem">
          <div class="flex justify-content-between align-items-center">Store</div>
        </th>
        <th style="min-width: 10rem">
          <div class="flex justify-content-between align-items-center">Created at</div>
        </th>
        <th style="min-width: 10rem">
          <div class="flex justify-content-between align-items-center">Paid at</div>
        </th>
        <th style="min-width: 6rem">
          <div class="flex justify-content-between align-items-center">Order total</div>
        </th>
        <th style="min-width: 10rem">
          <div class="flex justify-content-between align-items-center">Actions</div>
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-order let-expanded="expanded">
      <tr class="cursor-pointer">
        <td>
          <p-button
            type="button"
            pRipple
            [pRowToggler]="order"
            [text]="true"
            [rounded]="true"
            [plain]="true"
            [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"
          />
        </td>
        <td>
          {{ order.uuid }}
        </td>
        <td>
          <app-status-badge [status]="order.status" />
        </td>
        <td>
          {{ order.contact_info.first_name }}
          {{ order.contact_info.last_name }}
        </td>
        <td>
          {{ order.storefront?.name }}
        </td>
        <td>
          {{ order.created_at | usdDate }}
        </td>
        <td>
          {{ order.paid_at ? (order.paid_at | usdDate) : 'Not Paid' }}
        </td>
        <td>
          {{ order.total | price | usdPipe }}
        </td>
        <td>
          <div class="flex gap-2">
            <p-button [routerLink]="[order.id]" icon="pi pi-eye" severity="secondary" outlined></p-button>
            <p-button icon="pi pi-bolt" (click)="quickPreview(order)" outlined></p-button>
            @if(order.status !== OrderStatus.REFUND)
            {
              <p-button icon="pi pi-undo" (click)="openRefundModal($event, order.id)" severity="danger" outlined />
            }
            <p-button icon="pi pi-trash" (click)="openDeleteModal($event, order.id)" severity="danger" outlined />
          </div>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="rowexpansion" let-order>
      <tr>
        <td colspan="12">
          <app-order-details-collapse-item [order]="order"></app-order-details-collapse-item>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
      <tr>
        <td colspan="7" class="text-center">There are no orders</td>
      </tr>
    </ng-template>
    <ng-template pTemplate="loadingbody">
      @for (row of loadingRows; track row) {
        <tr>
          @for (col of ['id', 'status', 'buyer', 'store', 'created', 'order_total', 'actions']; track col) {
            <td>
              <p-skeleton />
            </td>
          }
        </tr>
      }
    </ng-template>
  </p-table>
  <p-confirmDialog header="Confirmation" key="confirmDelete" [style]="{ width: '28rem' }"></p-confirmDialog>
  <p-confirmDialog header="Confirmation" key="confirmRefund" [style]="{ width: '28rem' }"></p-confirmDialog>
</div>

<app-order-quick-preview
  [order]="orderForQuickPreview"
  [visible]="quickOrderPreviewDialog"
  (cancelEdit)="hideDialog()"
></app-order-quick-preview>
