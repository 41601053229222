import { Component, inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { Button } from 'primeng/button';
import { PaginatorModule } from 'primeng/paginator';
import { finalize } from 'rxjs';

import { InputComponent } from 'app/_shared/components/input/input.component';
import { Supplier } from 'app/_shared/interfaces/supplier';
import { SupplierService } from 'app/_shared/services/supplier.service';
import { ToastService } from 'app/_shared/services/toast.service';

@Component({
  selector: 'app-supplier-settings',
  imports: [Button, InputComponent, PaginatorModule, ReactiveFormsModule],
  templateUrl: './supplier-settings.component.html',
  styleUrl: './supplier-settings.component.scss'
})
export class SupplierSettingsComponent implements OnInit {
  private supplierService = inject(SupplierService);
  private activatedRoute = inject(ActivatedRoute);
  private fb = inject(FormBuilder);
  private router = inject(Router);
  private toastService = inject(ToastService);

  supplier: Supplier;
  form: FormGroup;

  isLoading = false;

  ngOnInit() {
    const slug = this.activatedRoute.snapshot.paramMap.get('slug') ?? '';
    if (!slug) {
      this.toastService.error('Something went wrong!');
      return;
    }
    this.isLoading = true;
    this.supplierService
      .getSupplierBySlug(slug)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe(next => {
        this.supplier = next.data;
        this.form = this.fb.group({
          margin: [this.supplier?.price_margin ?? '']
        });
      });
  }

  handleSubmit() {
    this.isLoading = true;

    this.supplierService
      .updateSupplier(this.supplier.id, { price_margin: this.form.value.margin })
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe(() => {
        this.toastService.success('Supplier updated!');
        this.router.navigate(['../'], { relativeTo: this.activatedRoute });
      });
  }
}
