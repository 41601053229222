import { DatePipe, NgStyle } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  effect,
  inject,
  OnInit,
  QueryList,
  signal,
  ViewChild,
  ViewChildren
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { ImageConfig } from 'konva/lib/shapes/Image';
import { CoreShapeComponent, StageComponent } from 'ng2-konva';
import { ShapeConfigTypes } from 'ng2-konva/lib/utils/configTypes';
import { ButtonGroupModule } from 'primeng/buttongroup';
import { DividerModule } from 'primeng/divider';
import { DropdownModule } from 'primeng/dropdown';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { SelectButtonModule } from 'primeng/selectbutton';
import { SkeletonModule } from 'primeng/skeleton';
import { TooltipModule } from 'primeng/tooltip';
import { finalize, map } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';

import {
  CatalogProduct,
  CatalogProductVariant,
  Print,
  PrintArea,
  SpecificColorConfig
} from 'app/_shared/interfaces/catalog';
import { StorefrontProduct, StorefrontProductData } from 'app/_shared/interfaces/storefront';
import { DecorationMethod, Library, LibraryFile_NEW } from 'app/_shared/models/LibraryFile';
import { CatalogService } from 'app/_shared/services/catalog.service';
import { StorefrontService } from 'app/_shared/services/storefront.service';
import { ToastService } from 'app/_shared/services/toast.service';
import { LibraryUploadComponent } from 'app/admin/storefront/products/create-product/library-upload/library-upload.component';
import { ProductDetailsComponent } from 'app/admin/storefront/products/create-product/product-details/product-details.component';

export interface CatalogProductVariantWithChecked extends CatalogProductVariant {
  checked: boolean;
}

@Component({
  selector: 'app-create-product',
  imports: [
    DividerModule,
    DropdownModule,
    FormsModule,
    ButtonGroupModule,
    NgStyle,
    SkeletonModule,
    StageComponent,
    CoreShapeComponent,
    SelectButtonModule,
    ProductDetailsComponent,
    TooltipModule
  ],
  providers: [DatePipe, DynamicDialogConfig, DynamicDialogRef, DialogService],
  templateUrl: './create-product.component.html',
  styleUrl: './create-product.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CreateProductComponent implements OnInit {
  @ViewChild('transformer') printImageTransformerRef: CoreShapeComponent;
  @ViewChildren('printImages') printImagesRef: QueryList<CoreShapeComponent>;

  private dialogRefImage = inject(DynamicDialogRef);
  private dialogService = inject(DialogService);
  private activatedRoute = inject(ActivatedRoute);
  private catalogService = inject(CatalogService);
  private storefrontService = inject(StorefrontService);
  toastService = inject(ToastService);
  datePipe = inject(DatePipe);
  private router = inject(Router);

  productId: string;
  editMode: boolean;
  isSubmitting: boolean;
  storefrontId: string;
  showMoreSelectedColors = false;
  preselectedVariants: number[] = [];
  productsQue: number[] = [];
  productsInQue: CatalogProduct[] | undefined = undefined;

  product = signal<StorefrontProduct | undefined>(undefined);
  printAreas = signal<PrintArea[]>([]);
  productVariants = signal<CatalogProductVariantWithChecked[]>([]);
  selectedProductVariants = computed<{ name: string; value: number; hex: string }[]>(() => {
    const value = this.productVariants();
    return value
      .filter(variant => variant.checked)
      .map(v => ({ name: v.color, value: v.id!, hex: v.supplier_product_variant?.primary_hex_color ?? '' }));
  });
  selectedPrintArea = computed<PrintArea>(() => {
    return this.printAreas().find(area => area.selected)!;
  });
  numberOfPrints = computed<number>(() => {
    return this.printAreas().flatMap(area => area.prints).length;
  });

  selectedPrint = computed<Print | undefined>(() => {
    return this.selectedPrintArea()?.prints?.find(print => print.selected);
  });
  baseData = { x: 0, y: 0, width: 0, height: 0, scaleX: 1, scaleY: 1, rotation: 0, skewX: 0, skewY: 0 };
  configStage = {
    width: 650,
    height: 650
  };
  printAreaBackgroundImage = signal<ImageConfig>({ ...this.baseData, image: undefined });
  printImageTransformerConfig: ShapeConfigTypes = {};

  selectedColor = signal<{ name: string; value: number } | undefined>(undefined);
  selectedLogoVersion: { file: LibraryFile_NEW; buttonName: number } | undefined;
  deletedPrints: number[] = [];
  // libraryFile = computed<LibraryFile | undefined>(() => this.selectedPrint()?.library);
  logoVersions = computed(() => {
    const library = this.selectedPrint()?.library;
    if (!library) return [];

    return library.library_files
      .map((file, index) => ({
        buttonName: index + 1,
        file: file
      }))
      .filter(item => item.file);
  });
  selectedColorConfig = computed(() => {
    return this.selectedPrint()?.specificColorConfig?.find(
      conf => conf.colorId === this.selectedColor()?.value && this.selectedPrintArea()?.id === conf.printAreaId
    )?.data as ShapeConfigTypes;
  });

  printAreaChangeEffect = effect(
    () => {
      const value = this.selectedPrintArea();

      // Background image change
      const printImage = new Image();
      printImage.src = this.selectedPrintArea()?.backgroundImage ?? '';
      printImage.onload = () => {
        this.configStage = {
          width: printImage.width,
          height: printImage.height
        };
        this.printAreaBackgroundImage.set({
          x: 0,
          y: 0,
          width: printImage.width,
          height: printImage.height,
          image: printImage
        });
      };
    },
    { allowSignalWrites: true }
  );
  selectedPrintChangeEffect = effect(() => {
    const value = this.selectedPrint();
    const currentColorConfig = value?.specificColorConfig?.find(
      conf => conf.colorId === this.selectedColor()?.value && this.selectedPrintArea().id === conf.printAreaId
    );
    const file = this.selectedColor() && currentColorConfig ? currentColorConfig.libraryFileId : value?.libraryFileId;

    this.selectedLogoVersion = this.logoVersions().find(version => {
      return version.file.id === file;
    });
  });

  log = effect(() => {
    // console.log('printAreas', this.printAreas());
    // console.log('updateSelectedArea', this.selectedPrintArea());
    // console.log('selectedPrint', this.selectedPrint());
  });

  ngOnInit(): void {
    // this.preselectedVariants = this.dialogConfig.data.preselectedVariants;
    // this.multipleCreate = this.dialogConfig.data.multiple;
    this.editMode = this.activatedRoute.snapshot.data.edit;
    // this.companyUser = this.route.snapshot.data.companyUser;
    this.activatedRoute.queryParams.subscribe(params => {
      if (params.productsQue) {
        this.productsQue = JSON.parse(params.productsQue);
      }
    });
    this.activatedRoute.paramMap.subscribe(params => {
      this.storefrontId = params.get('storefrontId') ?? '';
      this.productId = params.get('catalogProductId') ?? params.get('productId') ?? '';
      this.storefrontId = params.get('storefrontId') ?? '';
    });

    this.fetchProduct();
    if (this.productsQue.length) {
      this.catalogService.getProductsByIds(this.productsQue).subscribe(products => {
        this.productsInQue = products.data;
      });
    }
  }

  // ngAfterViewInit() {
  //   this.printImagesRef.changes.subscribe(next => {
  //     this.attachTransformerToImage(next?.last, next.length - 1);
  //   });
  // }

  fetchProduct(productId?: string) {
    const id = productId ?? this.productId;
    const loadImage = (src: string | undefined) => {
      return new Promise<HTMLImageElement>((resolve, reject) => {
        const img = new Image();
        img.src = src ?? '';
        img.onload = () => resolve(img);
        img.onerror = reject;
      });
    };

    this.handleFetchProduct(id).subscribe(product => {
      this.product.set(product);
      // Prepare areas
      const variants = !this.editMode
        ? product.catalog_product.catalog_product_variants[0].catalog_product_medias
        : product.product_variants?.[0]?.catalog_product_variant.catalog_product_medias;
      const allSpecificPrints = (product.product_variants ?? [])?.flatMap(variant =>
        variant.product_variant_prints.map(prints => ({
          ...prints,
          colorId: variant.catalog_product_variant_id
        }))
      ) as any[];
      const areas: PrintArea[] = (variants ?? []).map((media, index) => {
        const prints = (product.product_prints ?? [])
          ?.filter(print => print.order === media.order)
          .map(p => {
            return {
              id: p.id,
              printId: p.uuid,
              library_id: p.library.id,
              position: p?.position,
              libraryFileId: p.library_file.id,
              library: p.library,
              printImage:
                p.library_file.thumbnails?.find(t => t.decoration_method_id === p.decoration_method_id)?.full_path ??
                p.library.library_thumbnail.full_path,
              decorationMethod: p.decoration_method,
              order: p.order,
              specificColorConfig: (allSpecificPrints ?? [])
                .filter(spec => spec.uuid === p.uuid)
                .map(
                  item =>
                    ({
                      id: item.id,
                      colorId: item.colorId,
                      printAreaId: item.order,
                      position: item.position,
                      printId: item.uuid,
                      libraryFileId: item.library_file.id,
                      library: item.library,
                      libraryId: item.library_id,
                      order: item.order,
                      printImage:
                        item.library_file.thumbnails?.find(t => t.decoration_method_id === item.decoration_method_id)
                          ?.full_path ?? p.library.library_thumbnail.full_path,
                      decorationMethod: item.decoration_method,
                      data: JSON.parse(item.data as string)
                    }) as SpecificColorConfig
                ),
              data: JSON.parse(p.data as string)
            } as Print;
          }) as Print[];

        return {
          id: index,
          index: index,
          selected: index === 0,
          // @ts-ignore
          backgroundImage: media.supplier_media?.full_path || media.full_path,
          order: media.order,
          prints: prints
        } as PrintArea;
      });
      // Create promises to update data needed for ko-image
      //  We need to load new image for each print area
      const promises = areas.flatMap(area =>
        area.prints.map(async print => {
          try {
            const printImage = await loadImage(print.printImage);
            const updatedPrint = { ...print, data: { ...print.data, image: printImage } };

            // Process the specificColorConfig array, if it exists
            if (print.specificColorConfig && Array.isArray(print.specificColorConfig)) {
              updatedPrint.specificColorConfig = await Promise.all(
                print.specificColorConfig.map(async config => {
                  try {
                    const configImage = await loadImage(config.printImage);
                    return { ...config, data: { ...config.data, image: configImage } };
                  } catch (error) {
                    return config;
                  }
                })
              );
            }
            // Find and update the corresponding area and print
            const areaToUpdate = areas.find(a => a.order === area.order);
            if (areaToUpdate) {
              const printIndex = areaToUpdate.prints.findIndex(p => p.id === print.id);
              if (printIndex !== -1) {
                areaToUpdate.prints[printIndex] = updatedPrint;
              }
            }
          } catch (error) {
            console.error('Image load error:', error);
          }
        })
      );

      Promise.all(promises).then(() => {
        //  Set print areas with new updated data
        this.printAreas.set(areas);
      });
      this.buildVariants();
    });
  }

  handleFetchProduct(productId: string) {
    if (!this.editMode) {
      return this.catalogService.getProduct(productId).pipe(
        map(res => {
          // Map CatalogProduct to StorefrontProduct
          const catalogPrintPrice = res.data.catalog_product_configuration?.product_price ?? 0;
          const { name, description, print_areas, featured_image, catalog_product_variants, admin_categories } =
            res.data;
          return {
            name,
            description,
            price: +(catalogPrintPrice / 100).toFixed(2),
            catalog_product: {
              print_areas,
              catalog_product_variants,
              admin_categories
            },
            catalog_product_configuration: res.data.catalog_product_configuration,
            starting_price: res.data.starting_price,
            product_thumbnail: featured_image
          } as StorefrontProduct;
        })
      );
    } else {
      return this.storefrontService.getStorefrontProduct(this.storefrontId, productId).pipe(
        map(data => {
          const price = data.data.price;
          const formatPrice = price ? +(price / 100).toFixed(2) : 0;
          return { ...data.data, price: formatPrice };
        })
      );
    }
  }

  buildVariants() {
    const colors: CatalogProductVariantWithChecked[] = (
      this.product()?.catalog_product.catalog_product_variants ?? []
    ).map((variant, index) => {
      return { ...variant, checked: (this.preselectedVariants ?? []).includes(variant.id as number) || index === 0 };
    });

    if (this.editMode) {
      colors.forEach(item => {
        item.checked = (this.product()?.product_variants ?? []).some(
          variant => variant.catalog_product_variant_id === item.id
        );
      });
      this.productVariants.set(colors);
    } else {
      this.productVariants.set(colors);
    }
  }

  attachTransformerToImage(image: CoreShapeComponent, printId: number) {
    if (!image) return;
    const printImageStage = image.getStage();
    this.printImageTransformerConfig = {
      nodes: [printImageStage],
      keepRatio: true,
      enabledAnchors: ['top-left', 'top-right', 'bottom-left', 'bottom-right'],
      borderStroke: 'rgb(79,75,224)',
      anchorFill: 'rgb(79,75,224)',
      anchorStroke: 'transparent'
    };
    printImageStage.on('transformend', $event => this.updatePrintImageAttributes($event, printId));
  }

  updatePrintImageAttributes($event: any, index: number) {
    const data = { ...$event.target.attrs };
    if (this.selectedColor()) {
      //  Check if this color is already in array
      if (this.selectedColorConfig()) {
        const selectedColor = this.selectedColor()?.value;
        const selectedAreaId = this.selectedPrintArea()?.id;

        const prints = this.selectedPrintArea().prints.map((print, i) => {
          if (i === index) {
            const updatedConfig =
              print.specificColorConfig?.map(config =>
                config.colorId === selectedColor && config.printAreaId === selectedAreaId
                  ? { ...config, data: { ...data } }
                  : config
              ) ?? [];

            // Return updated print object, adding specificColorConfig only if updatedConfig exists
            return updatedConfig.length > 0 ? { ...print, specificColorConfig: updatedConfig } : { ...print };
          }
          return print;
        });

        // Apply the updated prints to the selected area
        this.updateSelectedArea({ prints });
        // this.specificColorPrintConfig.set(updatedConfig);
      } else {
        const prints = this.selectedPrintArea().prints.map((print, i) => {
          if (i === index) {
            const libFile = this.selectedLogoVersion?.file;
            return {
              ...print,
              specificColorConfig: [
                ...(print.specificColorConfig ?? []),
                {
                  printId: print.printId,
                  position: print.position,
                  printAreaId: this.selectedPrintArea()?.id,
                  order: this.selectedPrintArea()?.order,
                  colorId: this.selectedColor()!.value,
                  library: print.library,
                  libraryId: print.library?.id,
                  libraryFileId: libFile?.id,
                  data,
                  printImage: print.printImage
                } as SpecificColorConfig
              ] as SpecificColorConfig[]
            };
          }
          return { ...print };
        });
        this.updateSelectedArea({ prints });
      }
    } else {
      const prints = this.selectedPrintArea().prints.map((print, i) => {
        return {
          ...print,
          selected: index === i,
          data: index === i ? { ...data } : { ...print.data }
        };
      });
      this.updateSelectedArea({ prints });
    }
  }

  handlePrintAreaChange(area: PrintArea, $event?: MouseEvent) {
    $event?.preventDefault();
    this.printAreas.update(areas => {
      return areas.map(item => {
        return { ...item, selected: item.id === area.id };
      });
    });

    this.printImageTransformerConfig = {};
  }

  openMediaLibraryModal() {
    this.dialogRefImage = this.dialogService.open(LibraryUploadComponent, {
      header: 'Add your design',
      width: '1000px',
      height: 'auto',
      data: {
        storefrontId: this.storefrontId,
        printPositions: this.product()?.catalog_product?.admin_categories?.[0]?.print_positions
      }
    });
    this.dialogRefImage.onClose.subscribe(data => {
      const { library, decorationMethod, printPosition } = data;
      if (library && decorationMethod && printPosition) {
        this.handlePrintAreaImageUpload(library, decorationMethod, printPosition);
      }
    });
  }

  handlePrintAreaImageUpload(library: Library, decorationMethod?: DecorationMethod, printPosition?: string) {
    const file = library.library_files[0];
    const image = file.thumbnails?.find(thumbnail => thumbnail.decoration_method_id === decorationMethod?.id);
    const printImage = new Image();
    const src = image?.full_path || library.library_thumbnail.full_path;
    printImage.src = src ?? '';
    printImage.onload = () => {
      const scale = this.getImageToAreaScale(printImage);
      const data = {
        draggable: true,
        image: printImage,
        width: printImage.width,
        height: printImage.height,
        scaleX: scale,
        scaleY: scale,
        rotation: 0,
        x: this.selectedPrintArea().prints.length * 10,
        y: this.selectedPrintArea().prints.length * 10
      };
      this.updateSelectedArea({
        prints: [
          ...this.selectedPrintArea().prints.map(p => ({ ...p, selected: false })),
          {
            id: undefined,
            printId: uuidv4(),
            selected: true,
            library: library,
            decorationMethod: decorationMethod,
            libraryFileId: file.id,
            position: printPosition as string,
            printImage: src ?? '',
            data,
            order: this.selectedPrintArea().order
          }
        ]
      });
    };
  }

  updateSelectedArea(areaData: Partial<PrintArea>) {
    const areas = this.printAreas().map(area => {
      if (area.id === this.selectedPrintArea()!.id) {
        return {
          ...area,
          ...areaData,
          prints: areaData.prints ? [...areaData.prints] : [...area.prints]
        } as PrintArea;
      }
      return { ...area, prints: [...area.prints] };
    });

    this.printAreas.set(areas);
  }

  onSubmit(formData) {
    this.isSubmitting = true;
    const submitData = this.prepareSubmitData(formData);
    this.handleSubmit(submitData)
      .pipe(finalize(() => (this.isSubmitting = false)))
      .subscribe({
        next: () => {
          this.toastService.success(this.editMode ? 'Product Updated' : 'Product Added');
          if (!this.editMode && this.productsQue.length) {
            this.productsQue = this.productsQue.slice(1);
            this.productsInQue = this.productsInQue?.slice(1);
            this.productId = (this.productsInQue?.[0]?.id ?? '').toString();
            if (this.productsQue.length > 0) {
              this.fetchProduct(this.productsQue[0].toString());
            } else {
              this.router.navigate(['../../'], { relativeTo: this.activatedRoute });
            }
          } else {
            this.router.navigate(['../../'], { relativeTo: this.activatedRoute });
          }
        },
        error: () => (this.isSubmitting = false)
      });
  }

  handleSubmit(submitData: StorefrontProductData) {
    if (this.editMode) {
      return this.storefrontService.updateStorefrontProduct(this.storefrontId, submitData, this.product()?.id);
    }
    return this.storefrontService.createStorefrontProduct(this.storefrontId, submitData);
  }

  prepareSubmitData(formData): StorefrontProductData {
    const { start_publish_date, product_categories, end_publish_date, additional_photos, deleted_additional_photos } =
      formData;
    const allPrints = this.printAreas().flatMap(printArea => printArea.prints);
    const printAreasData = allPrints.map(print => {
      return {
        ...(print.id ? { id: print.id } : {}),
        uuid: print.printId,
        library_id: print.library?.id,
        position: print.position,
        library_file_id: print.libraryFileId,
        decoration_method_id: print.decorationMethod?.id,
        order: print.order,
        data: JSON.stringify(print.data)
      };
    });

    const variants = this.productVariants()
      .filter(item => item.checked)
      .map(item => {
        const specificColorPrintConfig: SpecificColorConfig[] = this.printAreas()
          .flatMap(printArea => printArea.prints)
          .flatMap(print => print.specificColorConfig || []);
        const versionConfig = specificColorPrintConfig.filter(conf => conf.colorId === item.id);
        const prints = versionConfig.map(item => {
          return {
            ...(item.id ? { id: item.id } : {}),
            uuid: item.printId,
            library_id: item.library?.id,
            position: item.position,
            library_file_id: item.libraryFileId,
            decoration_method_id: item.decorationMethod?.id,
            order: item.order,
            data: JSON.stringify(item.data)
          };
        });
        // const prints = versionConfig{ print_area_id: versionConfig?.id, library_id: 1, library_version: 1, data: 1 };
        return { id: item.id, prints };
      });

    const startPublishDate = start_publish_date
      ? this.datePipe.transform(start_publish_date, 'yyyy-MM-dd HH:mm')
      : null;

    const endPublishDate = end_publish_date ? this.datePipe.transform(end_publish_date, 'yyyy-MM-dd HH:mm') : null;

    const formattedCategories = product_categories || [];
    const mappedCategories = formattedCategories.map(category =>
      category.id ? { id: category.id } : { name: category.name, slug: category.slug }
    );

    const mappedAdditionalPhotos = additional_photos.filter(photo => photo.file).map(photo => photo.base64);
    let catalogProductId;

    if (!this.editMode) {
      catalogProductId = this.productId;
    } else {
      catalogProductId = this.product()?.catalog_product_id;
    }
    const prepared = {
      ...formData,
      catalog_product_id: catalogProductId,
      prints: printAreasData,
      catalog_product_variants: variants,
      additional_photos: mappedAdditionalPhotos,
      start_publish_date: startPublishDate,
      end_publish_date: endPublishDate,
      product_categories: mappedCategories,
      deleted_prints: this.deletedPrints,
      deleted_additional_photos: deleted_additional_photos
    };
    Object.keys(prepared).forEach(key => !prepared[key] && delete prepared[key]);
    return prepared;
  }

  handleVariantSelect(data: { checked: boolean; variantId: number }) {
    const { checked, variantId } = data;

    if (this.selectedColor()?.value === variantId) {
      this.selectedColor.set(undefined);
    }
    this.productVariants.update(value => {
      return value.map(v => {
        if (v.id === variantId) {
          return { ...v, checked: checked } as CatalogProductVariantWithChecked;
        }
        return { ...v } as CatalogProductVariantWithChecked;
      });
    });
  }

  handlePrintVariantSelect(variant: { name: string; value: number; hex: string }) {
    this.selectedColor.update(selected => {
      return selected?.value === variant.value ? undefined : variant;
    });
    this.printAreas.update(areas => {
      return areas.map((area, index) => {
        const selectedVariant = this.productVariants().find(
          prod =>
            prod.id ===
            (this.selectedColor()?.value ? this.selectedColor()?.value : this.selectedProductVariants()[0].value)
        );
        const image =
          selectedVariant?.catalog_product_medias[index]?.supplier_media?.full_path ||
          selectedVariant?.catalog_product_medias[index]?.full_path;
        return { ...area, backgroundImage: image } as PrintArea;
      });
    });
  }

  selectAllVariants(select: boolean) {
    this.productVariants.update(value => {
      return value.map(v => {
        return { ...v, checked: select } as CatalogProductVariantWithChecked;
      });
    });
  }

  handleLogoVersionChange(file: LibraryFile_NEW) {
    // const removedPrintId = this.selectedPrintArea()?.prints[0].id;
    // if (removedPrintId && !this.deletedPrints.includes(removedPrintId) && !this.selectedColor()) {
    //   this.deletedPrints.push(removedPrintId);
    // }
    const image = file.thumbnails?.find(
      thumbnail => thumbnail.decoration_method_id === this.selectedPrint()?.decorationMethod?.id
    );
    const printImage = new Image();
    printImage.src = image?.full_path || this.selectedPrint()?.library?.library_thumbnail.full_path || '';
    printImage.onload = () => {
      const scale = this.getImageToAreaScale(printImage);
      const data = {
        draggable: true,
        image: printImage,
        width: printImage.width,
        height: printImage.height,
        scaleX: scale,
        scaleY: scale,
        rotation: 0
      };
      if (!this.selectedColor()) {
        const prints = this.selectedPrintArea().prints.map(p => {
          if (p.printId === this.selectedPrint()?.printId) {
            return {
              ...p,
              libraryFileId: file.id,
              printImage: image?.full_path ?? '',
              data: { ...data, x: p.data.x, y: p.data.y }
            };
          }
          return p;
        });
        this.updateSelectedArea({ prints });
      } else {
        const config = this.selectedColorConfig();
        if (config) {
          const prints = this.selectedPrintArea().prints.map(print => {
            if (print.printId === this.selectedPrint()?.printId) {
              const updatedConfig = print.specificColorConfig?.map(conf => {
                if (conf.colorId === this.selectedColor()?.value) {
                  return {
                    ...conf,
                    libraryFileId: file.id,
                    printImage: image?.full_path ?? '',
                    data: { ...data, x: conf.data.x, y: conf.data.y }
                  };
                }
                return conf;
              });
              return { ...print, specificColorConfig: updatedConfig };
            }
            return print;
          });
          this.updateSelectedArea({ prints });
        } else {
          const prints = this.selectedPrintArea().prints.map(print => {
            if (print.printId === this.selectedPrint()?.printId) {
              return {
                ...print,
                specificColorConfig: [
                  ...(print.specificColorConfig ?? []),
                  {
                    id: undefined,
                    printId: print.printId,
                    printAreaId: this.selectedPrintArea()?.id,
                    colorId: this.selectedColor()!.value,
                    library: this.selectedPrint()?.library,
                    decorationMethod: this.selectedPrint()?.decorationMethod,
                    libraryId: file.library_id,
                    position: print.position,
                    libraryFileId: file.id,
                    printImage: image?.full_path ?? '',
                    order: this.selectedPrintArea()?.order,
                    data: { ...data, x: print.data.x, y: print.data.y }
                  } as SpecificColorConfig
                ] as SpecificColorConfig[]
              };
            }
            return { ...print };
          });
          this.updateSelectedArea({ prints });
        }
      }
    };
  }

  getImageToAreaScale(printImage: HTMLImageElement) {
    const sizeToScaleTo = { width: 100, height: 100 };
    if (printImage.height > printImage.width) {
      if (printImage.height > sizeToScaleTo.height) {
        return sizeToScaleTo.height / printImage.height;
      }
    } else {
      if (printImage.width > sizeToScaleTo.width) {
        return sizeToScaleTo.width / printImage.width;
      }
    }
    return 1;
  }

  handlePrintClick(printImages: CoreShapeComponent, index: number) {
    this.attachTransformerToImage(printImages, index);
    const prints = this.selectedPrintArea().prints.map((print, i) => {
      return { ...print, selected: index === i };
    });
    this.updateSelectedArea({ prints });
  }

  printConfig(print: Print) {
    let data: ShapeConfigTypes = print.data;
    if (this.selectedColor() && print.specificColorConfig?.length) {
      print.specificColorConfig.forEach(conf => {
        if (conf.colorId === this.selectedColor()?.value && this.selectedPrintArea()?.id === conf.printAreaId) {
          data = conf.data;
        }
      });
    }
    return data;
  }

  attachTransformer(id: number, printImages: CoreShapeComponent) {
    this.attachTransformerToImage(printImages, id);
  }

  handleRemovePrint($event: string) {
    const print = this.selectedPrintArea()?.prints.find(print => print.printId === $event);
    if (print?.id) {
      this.deletedPrints.push(print.id);
    }
    this.updateSelectedArea({
      prints: this.selectedPrintArea().prints.filter(print => print.printId !== $event)
    });
  }
}
