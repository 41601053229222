import { Component, computed, inject, OnInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';

import { Button } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { DropdownModule } from 'primeng/dropdown';
import { MultiSelectModule } from 'primeng/multiselect';

import { InputComponent } from 'app/_shared/components/input/input.component';
import { CatalogProductCategory } from 'app/_shared/models/CatalogProductCategory';
import { StorefrontProductCategoryService } from 'app/_shared/services/storefront-product-category.service';
import { fontOptions } from 'app/admin/storefront/designer/page-builder/page-builder.component';
import { SectionDefaultImports } from 'app/admin/storefront/designer/page-builder/page-sections/section-default-imports';

@Component({
  selector: 'app-product-list',
  imports: [ReactiveFormsModule, Button, MultiSelectModule, DropdownModule, CheckboxModule, FormsModule],
  templateUrl: './product-list.component.html',
  styleUrl: './product-list.component.scss'
})
export class ProductListComponent extends SectionDefaultImports implements OnInit {
  private categoryService = inject(StorefrontProductCategoryService);
  productCategories: CatalogProductCategory[];

  storefrontId = computed(() => this.storefrontStore.selectedStorefront?.()?.id);
  ngOnInit() {
    this.getStorefrontProductCategories();
    const value = this.dynamicConfig.data.section.content?.productsPerRow;
    const categories = this.dynamicConfig.data.section.content?.categories;
    const listAllProducts = this.dynamicConfig.data.section.content?.listAllProducts;
    console.log(this.dynamicConfig.data.section.content);
    this.form = this.fb.group({
      productsPerRow: [value ?? '4', Validators.required],
      categories: [categories ?? []],
      listAllProducts: [listAllProducts ?? false]
    });
  }

  getStorefrontProductCategories() {
    this.categoryService.index(+this.storefrontId()!).subscribe({
      next: res => {
        this.productCategories = res.data;
      },
      error: error => console.error(error)
    });
  }

  onSubmit() {
    const selectedPage = this.dynamicConfig.data.selectedPage;
    const selectedSection = this.dynamicConfig.data.section;
    const { productsPerRow, categories, listAllProducts } = this.form.value;
    const pages = this.designerHelperService.saveSectionData(selectedPage, selectedSection, {
      productsPerRow,
      categories,
      listAllProducts
    });

    this.designerStore.saveConfig(this.storefrontId()!, { pages }, true).subscribe(next => {
      this.designerHelperService.sendToPreview(this.storefrontId()!, next, () => this.ref.close());
    });
  }
}
