<div>
  @if (!submitted) {
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
      <div class="grid grid-nogutter justify-content-end p-fluid">
        <div class="col-12">
          <div class="field">
            <label for="amount">Amount to deposit</label>
            <input
              type="number"
              pInputText
              formControlName="amount"
              id="amount"
              [class.ng-dirty]="submitted || form.get('amount')?.touched"
            />
            @if (submitted || form.get('amount')?.touched) {
              @if (form.get('amount')?.errors?.['required']) {
                <div>
                  <small class="p-text-danger">Amount is required</small>
                </div>
              } @else if (form.get('amount')?.errors?.['amount']) {
                <div>
                  <small class="p-text-danger">Entered amount is not valid</small>
                </div>
              } @else if (form.get('amount')?.errors?.['min']) {
                <div>
                  <small class="p-text-danger">
                    Entered amount must be at least {{ form.get('amount')?.errors?.['min'].min | currency: 'USD' }}
                  </small>
                </div>
              }
            }
          </div>
        </div>
        @if (!isSuperAdmin) {
          <div class="col-auto" style="margin-top: 28px">
            <p-button
              [loading]="loading"
              label="Get payment link"
              icon="pi pi-link"
              class="p-button-text"
              type="submit"
            ></p-button>
          </div>
        } @else {
          <div class="col-auto" style="margin-top: 28px">
            <p-button
              label="Add deposit"
              icon="pi pi-plus-circle"
              class="p-button-text"
              (onClick)="addDeposit()"
            ></p-button>
          </div>
        }
      </div>
    </form>
  } @else {
    <h4 class="text-800 text-primary">Close this modal to reload transactions</h4>
    <p-button (onClick)="closeModal(false)" label="Close" />
  }
</div>
