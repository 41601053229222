import { NgStyle } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { PromoCode, PromoCodeType, PromoCodeValueType } from 'app/_shared/models/Coupon';
import { DisplayDatePipe } from 'app/_shared/pipes/date.pipe';
import { PricePipe } from 'app/_shared/pipes/price.pipe';
import { PromoCodeTypeFormatPipe } from 'app/_shared/pipes/promo-code-type-format.pipe';
import { UsdPipe } from 'app/_shared/pipes/usd-currency.pipe';
import { PromoCodeService } from 'app/_shared/services/promo-code.service';

@Component({
    selector: 'app-promo-code-details',
    imports: [UsdPipe, DisplayDatePipe, PromoCodeTypeFormatPipe, NgStyle, PricePipe],
    templateUrl: './promo-code-details.component.html',
    styleUrl: './promo-code-details.component.scss'
})
export class PromoCodeDetailsComponent implements OnInit {
  private promoCodeService: PromoCodeService = inject(PromoCodeService);
  private activatedRoute: ActivatedRoute = inject(ActivatedRoute);

  storefrontId: string;
  promoCodeId: string;
  promoCode: PromoCode;
  promoCodeType = PromoCodeType;
  promoCodeValueType = PromoCodeValueType;

  ngOnInit() {
    this.activatedRoute.paramMap.subscribe(params => {
      this.storefrontId = params.get('storefrontId') ?? '';
      this.promoCodeId = params.get('promoCodeId') ?? '';
      if (this.promoCodeId) {
        this.fetchPromoCode();
      }
    });
  }

  fetchPromoCode() {
    this.promoCodeService.getById(+this.storefrontId, this.promoCodeId).subscribe({
      next: res => {
        this.promoCode = res.data;
      },
      error: err => {
        console.error('Operation failed:', err);
      }
    });
  }
}
