import { Component } from '@angular/core';

import { PanelModule } from 'primeng/panel';
import { TabMenuModule } from 'primeng/tabmenu';
import { TabViewModule } from 'primeng/tabview';

import { StripeConnectComponent } from '../stripe-connect/stripe-connect.component';
import { PaymentMethodsComponent } from './payment-methods/payment-methods.component';
import { SubscriptionsComponent } from './subscriptions/subscriptions.component';

@Component({
    selector: 'app-billing',
    imports: [
        TabMenuModule,
        PanelModule,
        TabViewModule,
        SubscriptionsComponent,
        PaymentMethodsComponent,
        StripeConnectComponent
    ],
    templateUrl: './billing.component.html'
})
export class BillingComponent {
  activeTabIndex = 0;
  onTabChange(event: any) {
    this.activeTabIndex = event.index;
  }
}
