<div class="grid">
  <div class="col-12">
    <div class="card">
      <h5>Categories</h5>

      <div class="flex my-2 justify-content-end">
        <p-button icon="pi pi-fw pi-plus" label="Create category" outlined (click)="openCategoryEditor()" />
      </div>

      <p-treeTable
        [value]="isLoading || isLoadingDelete ? [] : productCategories"
        [loading]="isLoading || isLoadingDelete"
        [showLoader]="false"
      >
        <ng-template pTemplate="header">
          <tr>
            <th>Name</th>
            <th class="text-center">Image</th>
            <th class="text-center">Tax Code</th>
            <th class="text-center">Actions</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowNode let-category="rowData">
          <tr [ttRow]="rowNode">
            <td>
              <p-treeTableToggler [rowNode]="rowNode" />
              {{ category?.name }}
            </td>
            <td class="text-center">
              @if (category.category_image) {
                <img
                  [ngSrc]="category.category_image.full_path"
                  [alt]="category.category_image.name"
                  width="40"
                  height="60"
                  class="shadow-2"
                  style="object-fit: contain"
                  priority
                />
              }
            </td>
            <td class="text-center">
              {{ category.tax_code }}
            </td>
            <td>
              <div class="flex gap-2 justify-content-center">
                <p-button
                  type="button"
                  icon="pi pi-plus"
                  outlined
                  pTooltip="Add subcategory"
                  tooltipPosition="top"
                  [disabled]="category.depth >= 2"
                  (click)="openCategoryEditor(undefined, category.id)"
                />
                <p-button type="button" icon="pi pi-pen-to-square" outlined (click)="openCategoryEditor(category)" />
                <p-button
                  type="button"
                  icon="pi pi-trash"
                  severity="danger"
                  outlined
                  (click)="openDeleteModal($event, category.id)"
                />
              </div>
            </td>
          </tr>
        </ng-template>
      </p-treeTable>
    </div>
  </div>
</div>
<p-confirmDialog
  header="Confirmation"
  key="confirmDeleteCategory"
  icon="pi pi-exclamation-triangle"
  [style]="{ width: '28rem' }"
></p-confirmDialog>
