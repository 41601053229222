import { Company } from './Company';
import { GiftCardCustomMail } from './Coupon';
import { Permission } from './Permission';
import { User } from './User';

export enum StorefrontStatus {
  DRAFT = 'draft',
  DEMO = 'demo',
  LIVE = 'live',
  INACTIVE = 'inactive',
  ARCHIVED = 'archived'
}
export class Storefront {
  id: number;

  active: boolean;
  status: StorefrontStatus;
  user_id: number;
  company_id: number;
  state_id: number;
  country_id: number;

  name: string;
  url: string;
  subdomain: string;
  domain: string;
  price_rounding: string;
  api_key: string;
  logo: { full_path: string; name: string };
  data: any;
  price_margin: string;
  hash: string;

  company?: Company;
  users?: User[];
  permissions: Permission[];
  granted?: boolean;
  gift_card_custom_mails: GiftCardCustomMail[];
}

export const storefrontStatusDropdownOptions = Object.keys(StorefrontStatus).map(key => ({
  label: key.charAt(0) + key.slice(1).toLowerCase(),
  value: StorefrontStatus[key as keyof typeof StorefrontStatus]
}));
