import { Component, inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';

import { AuthService } from '../../_shared/services/auth.service';
import { ToastService } from '../../_shared/services/toast.service';
import { AuthStore } from '../../_store/auth.store';

@Component({
    selector: 'app-email-verification',
    imports: [ButtonModule, InputTextModule],
    templateUrl: './email-verification.component.html'
})
export class EmailVerificationComponent implements OnInit {
  route = inject(ActivatedRoute);
  authService = inject(AuthService);
  toastService = inject(ToastService);
  authStore = inject(AuthStore);
  router = inject(Router);

  token: string;

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.token = params.token;
      if (this.token) {
        this.resendVerificationEmail();
      }
    });
  }

  resendVerificationEmail() {
    this.authService.verifyEmail(this.token).subscribe({
      next: res => {
        this.authStore.setToken(res.data.access_token);
        this.authStore.setAuthUser(res.data.user);
        this.router.navigateByUrl('/');
      },
      error: err => {
        if (err.status === 404) {
          this.toastService.error('Invalid token. Please try again.');
          return;
        }
        console.error('Action failed:', err);
      }
    });
  }

  resendEmail() {
    this.authService.resendVerificationEmail().subscribe({
      next: () => {
        this.toastService.success('Verification email is sent again');
      },
      error: err => {
        console.error('Action failed:', err);
      }
    });
  }
}
