import { AfterViewChecked, ChangeDetectorRef, Component, inject, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { PanelModule } from 'primeng/panel';
import { Table, TableLazyLoadEvent } from 'primeng/table';
import { TabMenuModule } from 'primeng/tabmenu';
import { TabViewModule } from 'primeng/tabview';
import { finalize } from 'rxjs';

import { UserTableListComponent } from '../../../_shared/components/user-table-list/user-table-list.component';
import { Company } from '../../../_shared/models/Company';
import { FormGroupValue } from '../../../_shared/models/FormGroup';
import { User } from '../../../_shared/models/User';
import { CompanyService } from '../../../_shared/services/company.service';
import { ToastService } from '../../../_shared/services/toast.service';
import { AuthStore } from '../../../_store/auth.store';
import { InviteListComponent } from '../invite-list/invite-list.component';

@Component({
    selector: 'app-user-list',
    imports: [UserTableListComponent, TabMenuModule, PanelModule, TabViewModule, InviteListComponent],
    templateUrl: './user-list.component.html'
})
export class UserListComponent implements OnInit, AfterViewChecked {
  companyService = inject(CompanyService);
  authStore = inject(AuthStore);
  toastService = inject(ToastService);
  route = inject(ActivatedRoute);
  cdRef = inject(ChangeDetectorRef);

  public superAdminMode = false;
  companyId?: number;
  authUserCompany: Company;
  isLoading = false;
  isLoadingEdit = false;
  isLoadingDelete = false;

  users: User[] = [];
  totalRecords: number = 0;

  activeTabIndex = 0;
  tabContents: string[] = [
    'Here you can view and manage the list of users who are currently part of your company.',
    'This section displays users who have been invited to join your company.'
  ];

  onTabChange(event: any) {
    this.activeTabIndex = event.index;
  }

  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }

  ngOnInit() {
    const companyId = this.route.snapshot.paramMap.get('id');
    if (!companyId) {
      console.error('Company ID is null.');
      return;
    }
    this.companyId = +companyId;
  }

  onGlobalFilter(table: Table, event: Event) {
    table.filterGlobal((event.target as HTMLInputElement).value, 'contains');
  }

  loadCompanyUsers($event?: TableLazyLoadEvent) {
    this.isLoading = true;
    this.companyService
      .getCompanyUsers($event, this.companyId)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe({
        next: res => {
          this.users = res.data.data;
          this.totalRecords = res.data.total;
        },
        error: error => console.error(error)
      });
  }

  deleteCompanyUser(userId: number) {
    this.isLoadingDelete = true;
    this.companyService
      .deleteCompanyUser(userId, this.companyId)
      .pipe(finalize(() => (this.isLoadingDelete = false)))
      .subscribe({
        next: () => {
          this.toastService.success('User successfully deleted!');
          this.loadCompanyUsers();
        },
        error: err => {
          console.error('Delete action failed:', err);
        }
      });
  }

  editCompanyUser(editData: { formData: FormGroupValue; id: number }) {
    this.isLoadingEdit = true;
    this.companyService
      .updateCompanyUser(editData.id, editData.formData, this.companyId)
      .pipe(
        finalize(() => {
          this.isLoadingEdit = false;
        })
      )
      .subscribe({
        next: () => {
          this.toastService.success('Successfully updated user!');
          this.loadCompanyUsers();
        },
        error: err => {
          console.error('Update failed:', err);
        }
      });
  }
}
