import { ComponentType } from '@angular/cdk/overlay';
import { ChangeDetectionStrategy, Component, computed, effect, inject, input, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { MenuItem } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { DialogService } from 'primeng/dynamicdialog';
import { EditorModule } from 'primeng/editor';
import { MenuModule } from 'primeng/menu';
import { Menu as PMenu } from 'primeng/menu/menu';
import { OrderListModule } from 'primeng/orderlist';
import { PanelModule } from 'primeng/panel';
import { RadioButtonModule } from 'primeng/radiobutton';
import { v4 as uuidv4 } from 'uuid';

import { ComponentEnum, Page, Section } from 'app/_shared/interfaces/designer/page';
import { DesignerStore } from 'app/_store/designer.store';
import { StorefrontStore } from 'app/_store/storefront.store';
import { DesignerHelperService } from 'app/admin/storefront/designer/designer-helper.service';
import { BannerComponent } from 'app/admin/storefront/designer/page-builder/page-sections/banner/banner.component';
import { ProductListComponent } from 'app/admin/storefront/designer/page-builder/page-sections/product-list/product-list.component';
import { TextEditorComponent } from 'app/admin/storefront/designer/page-builder/page-sections/text-editor/text-editor.component';
import { TextImageComponent } from 'app/admin/storefront/designer/page-builder/page-sections/text-image/text-image.component';
import { TitleComponent } from 'app/admin/storefront/designer/page-builder/page-sections/title/title.component';

interface Element {
  id: string;
  name: string;
  label: string;
  content: any;
  image: string;
}

export const fontOptions = [
  { name: 'Roboto', code: 'Roboto' },
  { name: 'Open Sans', code: 'Open Sans' },
  { name: 'Poppins', code: 'Poppins' }
];

export const fontSizeOptions = ['12', '14', '16', '18', '20', '25', '30', '35', '40'];

@Component({
  selector: 'app-page-builder',
  imports: [
    OrderListModule,
    ButtonModule,
    PanelModule,
    MenuModule,
    DialogModule,
    RadioButtonModule,
    EditorModule,
    FormsModule
  ],
  providers: [DialogService],
  templateUrl: './page-builder.component.html',
  styleUrl: './page-builder.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PageBuilderComponent implements OnInit {
  designerStore = inject(DesignerStore);
  storefrontStore = inject(StorefrontStore);
  designerHelperService = inject(DesignerHelperService);
  dialogService = inject(DialogService);

  page = input.required<Page>();

  pages = computed(() => this.designerStore.config().pages ?? []);
  storefrontId = computed(() => this.storefrontStore.selectedStorefront?.()?.id);
  sectionsChangeEffect = effect(() => {
    this.sections = this.page().sections.slice();
  });

  sections: Section[] = [];
  sectionPopupItems: MenuItem[] = [
    {
      label: 'Edit',
      icon: 'pi pi-file-edit',
      command: event => {
        const modalWidth = event.item?.section?.component === ComponentEnum.Title ? '30vh' : undefined;
        this.generateModal(event?.item?.section, modalWidth);
      }
    },
    {
      separator: true
    },
    {
      label: 'Delete',
      icon: 'pi pi-times',
      command: event => {
        this.handleDeleteSection(event);
      }
    }
  ];
  showAddNewContentSectionDialog: boolean = false;
  elements: Element[] = [
    {
      id: '1',
      name: ComponentEnum.TextArea,
      label: 'Text area',
      content: null,
      image: 'assets/images/icons/firstline.svg'
    },
    {
      id: '2',
      name: ComponentEnum.TextWithImage,
      label: 'Text image area',
      content: null,
      image: 'assets/images/icons/firstline.svg'
    },
    {
      id: '3',
      name: ComponentEnum.BannerImage,
      label: 'Banner image',
      content: null,
      image: 'assets/images/icons/video-square.svg'
    },
    {
      id: '4',
      name: ComponentEnum.ProductList,
      label: 'Product list',
      content: null,
      image: 'assets/images/icons/slider-horizontal.svg'
    },
    {
      id: '5',
      name: ComponentEnum.Title,
      label: 'Title',
      content: null,
      image: 'assets/images/icons/firstline.svg'
    }
  ];
  selectedElement: Element;

  ngOnInit() {
    this.sections = this.page().sections;
  }

  toggleSectionPopup($event: Event, menu: PMenu, section: any) {
    this.sectionPopupItems.forEach(menuItem => {
      menuItem.section = section as Section;
    });
    menu.toggle($event);
  }

  handleDeleteSection(event) {
    const pages = this.pages().map(page => {
      if (page.id === this.page().id) {
        const pageSection = page.sections.filter(section => section.id !== event.item.section.id);
        return { ...page, sections: pageSection };
      }
      return page;
    });
    this.designerStore.saveConfig(this.storefrontId()!, { pages }, true).subscribe(next => {
      this.designerHelperService.sendToPreview(this.storefrontId()!, next);
    });
  }

  handleAddSection() {
    const newSection = {
      id: uuidv4(),
      label: this.selectedElement.label,
      component: this.selectedElement.name,
      content: this.selectedElement.content
    } as Section;

    this.showAddNewContentSectionDialog = false;
    this.generateModal(newSection, this.selectedElement.name === ComponentEnum.Title ? '30vh' : undefined);
  }

  generateModal(item: Section, modalWidth?: string) {
    let slot: ComponentType<any>;

    switch (item.component) {
      case ComponentEnum.TextArea:
        slot = TextEditorComponent;
        break;

      case ComponentEnum.TextWithImage:
        slot = TextImageComponent;
        break;

      case ComponentEnum.BannerImage:
        slot = BannerComponent;
        break;

      case ComponentEnum.ProductList:
        slot = ProductListComponent;
        break;

      case ComponentEnum.Title:
        slot = TitleComponent;
        break;

      default:
        slot = TextEditorComponent;
    }
    this.dialogService.open(slot, {
      header: item.label,
      width: modalWidth ?? '60vh',
      breakpoints: { '960px': '75vw' },
      modal: true,
      data: { section: item, selectedPage: this.page() }
    });
  }

  onSectionReorder() {
    const pages = this.pages().map(page =>
      page.id === this.page().id
        ? {
            ...this.page(),
            sections: [...this.sections]
          }
        : page
    );

    this.designerStore.saveConfig(this.storefrontId()!, { pages }, true).subscribe(next => {
      this.showAddNewContentSectionDialog = false;
      this.designerHelperService.sendToPreview(this.storefrontId()!, next);
    });
  }
}
