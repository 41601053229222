import { Component, computed, model, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';

import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { Editor, EditorModule } from 'primeng/editor';

import { SectionDefaultImports } from 'app/admin/storefront/designer/page-builder/page-sections/section-default-imports';

@Component({
  selector: 'app-text-editor-section-modal',
  imports: [DialogModule, ReactiveFormsModule, EditorModule, ButtonModule, FormsModule],
  templateUrl: './text-editor.component.html',
  styleUrl: './text-editor.component.scss'
})
export class TextEditorComponent extends SectionDefaultImports implements OnInit {
  @ViewChild('editor') editor: Editor;
  visible = model<boolean>(false);
  pages = computed(() => this.designerStore.config().pages ?? []);
  storefrontId = computed(() => this.storefrontStore.selectedStorefront?.()?.id);
  formGroup: FormGroup;

  ngOnInit() {
    const value = this.dynamicConfig.data.section.content;
    this.formGroup = this.fb.group({
      text: new FormControl(value ?? '')
    });
  }

  onSubmit() {
    const selectedPage = this.dynamicConfig.data.selectedPage;
    const selectedSection = this.dynamicConfig.data.section;

    const pages = this.designerHelperService.saveSectionData(selectedPage, selectedSection, {
      text: this.formGroup.value.text
    });
    console.log({ pages });

    this.designerStore.saveConfig(this.storefrontId()!, { pages }, true).subscribe(next => {
      this.designerHelperService.sendToPreview(this.storefrontId()!, next, () => this.ref.close());
    });
  }
  setDefaultValue() {
    const value = this.dynamicConfig.data.section.content?.text;
    this.editor.quill.clipboard.dangerouslyPasteHTML(value);
  }
}
