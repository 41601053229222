<div class="mt-5">
  @if (showCreatePage) {
    <div class="create-new-page" [@slideDownAnimation]>
      <form [formGroup]="newPageForm" (ngSubmit)="handleCreatePage()">
        <div class="flex justify-content-end">
          <a href="#" (click)="$event.preventDefault(); showCreatePage = false">Close</a>
        </div>
        <app-input
          name="name"
          label="Page name"
          [control]="newPageForm.controls.title"
          placeholder="Page name"
          [fullWidth]="true"
          shape="round"
        ></app-input>
        <!--        <input id="name" class="round-input mb-3" pInputText formControlName="title" placeholder="Page name" />-->
        <app-input
          name="url"
          label="Page url"
          [control]="newPageForm.controls.url"
          placeholder="Page url"
          [fullWidth]="true"
          shape="round"
        ></app-input>
        <div class="mt-3">
          <p-checkbox
            [binary]="true"
            id="all"
            name="addToNavigation"
            formControlName="addToNavigation"
            [value]="true"
          />
          <label class="ml-1" for="all">Add to menu</label>
        </div>
        <div class="mt-3">
          <p-button label="Create page" type="submit" ariaLabel="Create page" [style]="{ width: '100%' }" />
        </div>
      </form>
    </div>
  }
  @if (!showCreatePage) {
    <p-button
      [outlined]="true"
      (click)="showCreatePage = true"
      label="Add page"
      ariaLabel="Add page"
      class="w-full"
      [style]="{ flex: 1, width: '100%' }"
    />
  }
  <p-accordion [multiple]="false">
    @for (page of pages(); track page.id) {
      <p-accordionTab
        header="{{ page.title }}"
        [selected]="false"
        class="line-height-3 m-0"
        (selectedChange)="handlePageExpanded($event, page.url)"
      >
        @if (page.url !== '') {
          <ng-template pTemplate="header">
            <div class="flex justify-content-end flex-1">
              <a href="#" (click)="$event.preventDefault(); $event.stopPropagation(); handleDeletePage(page.id)">
                <img src="assets/images/icons/trash-dark.svg" alt="Remove page" />
              </a>
            </div>
          </ng-template>
        }
        <app-page-builder [page]="page" />
      </p-accordionTab>
    }
  </p-accordion>
</div>
<p-confirmDialog />
