import { Component, Input } from '@angular/core';
import { RouterLink } from '@angular/router';

import { Order } from 'app/_shared/models/Order';
import { TransactionDetails } from 'app/_shared/models/Transaction';
import { PricePipe } from 'app/_shared/pipes/price.pipe';
import { UsdPipe } from 'app/_shared/pipes/usd-currency.pipe';

@Component({
  selector: 'app-transaction-details',
  imports: [UsdPipe, PricePipe, RouterLink],
  templateUrl: './transaction-details.component.html',
  styleUrl: './transaction-details.component.scss'
})
export class TransactionDetailsComponent {
  @Input() transactionDetails: TransactionDetails;
  @Input() transactionBalance: number;
  @Input() order: Order;
}
