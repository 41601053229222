import { Component } from '@angular/core';

import { MenuItem } from 'primeng/api';
import { TabMenuModule } from 'primeng/tabmenu';

@Component({
    selector: 'app-coupons',
    imports: [TabMenuModule],
    templateUrl: './coupons.component.html'
})
export class CouponsComponent {
  tabMenuItems: MenuItem[] = [
    { label: 'Gift Cards', routerLink: './gift-cards' },
    { label: 'Promo Codes', routerLink: './promo-codes' }
  ];
}
