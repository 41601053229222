{
  "name": "admin",
  "version": "0.1.84",
  "scripts": {
    "ng": "ng",
    "start": "ng serve --host 0.0.0.0 --disable-host-check",
    "build": "ng build",
    "build:local": "ng build --configuration local",
    "build:dev": "ng build --configuration development",
    "build:stage": "ng build --configuration staging",
    "build:production": "ng build --configuration production",
    "watch": "ng build --watch --configuration development",
    "test": "ng test",
    "lint": "ng lint --fix"
  },
  "private": true,
  "dependencies": {
    "@angular-architects/ngrx-toolkit": "^19.0.1",
    "@angular/animations": "^19.0.6",
    "@angular/cdk": "^19.0.5",
    "@angular/common": "^19.0.6",
    "@angular/compiler": "^19.0.6",
    "@angular/core": "^19.0.6",
    "@angular/fire": "^19.0.0-rc.5",
    "@angular/forms": "^19.0.6",
    "@angular/platform-browser": "^19.0.6",
    "@angular/platform-browser-dynamic": "^19.0.6",
    "@angular/router": "^19.0.6",
    "@iplab/ngx-color-picker": "^19.0.0",
    "@ngrx/signals": "^19.0.0",
    "@ngrx/store-devtools": "^19.0.0",
    "@stripe/stripe-js": "^5.5.0",
    "chart.js": "^4.4.7",
    "ng2-konva": "^9.0.0",
    "ngx-stripe": "^19.0.0",
    "primeflex": "^3.3.1",
    "primeicons": "^7.0.0",
    "primeng": "^17.18.9",
    "quill": "^2.0.2",
    "rxjs": "~7.8.1",
    "tslib": "^2.3.0",
    "uuid": "^10.0.0",
    "zone.js": "~0.15.0",
    "laravel-echo": "^1.16.1",
    "pusher-js": "^8.4.0-rc2"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^19.0.7",
    "@angular-eslint/builder": "^19.0.2",
    "@angular-eslint/eslint-plugin": "^19.0.2",
    "@angular-eslint/eslint-plugin-template": "^19.0.2",
    "@angular-eslint/schematics": "^19.0.2",
    "@angular-eslint/template-parser": "^19.0.2",
    "@angular/cli": "^19.0.7",
    "@angular/compiler-cli": "^19.0.6",
    "@ngrx/eslint-plugin": "^19.0.0",
    "@trivago/prettier-plugin-sort-imports": "^4.3.0",
    "@types/jasmine": "~5.1.0",
    "@types/uuid": "^9.0.8",
    "@typescript-eslint/eslint-plugin": "7.2.0",
    "@typescript-eslint/parser": "7.2.0",
    "eslint": "^8.57.0",
    "eslint-config-prettier": "^9.1.0",
    "eslint-plugin-import": "^2.29.1",
    "eslint-plugin-jsx-a11y": "^6.8.0",
    "eslint-plugin-prettier": "^5.1.3",
    "eslint-plugin-react": "^7.34.1",
    "eslint-plugin-react-hooks": "^4.6.0",
    "eslint-plugin-unused-imports": "^3.1.0",
    "jasmine-core": "~5.1.0",
    "karma": "~6.4.0",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.0",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.1.0",
    "prettier": "^3.2.5",
    "prettier-eslint": "^16.3.0",
    "typescript": "^5.6.3"
  },
  "overrides": {
    "ng2-konva": {
      "@angular/common": "^19.0.6",
      "@angular/core": "^19.0.6"
    },
    "primeng": {
      "@angular/core": "^19.0.6",
      "@angular/common": "^19.0.6",
      "@angular/forms": "^19.0.6",
      "zone.js": "^0.15.0"
    }
  }
}
