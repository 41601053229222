<div>
  <div class="font-bold text-lg">
    Stripe connect status:
    @if (connectStatus === 'complete') {
      <label class="p-text-success">Connected</label>
    } @else {
      <label class="p-text-danger">Not connected</label>
    }
  </div>
  <div class="flex justify-content-end">
    <p-button
      [label]="connectStatus === 'complete' ? 'Update Stripe account details' : 'Connect Stripe account'"
      [loading]="loading"
      styleClass="mb-2 mt-2 p-button-sm"
      (click)="getConnectLink()"
    ></p-button>
  </div>
</div>
