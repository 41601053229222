import { NgClass } from '@angular/common';
import { Component, Input } from '@angular/core';

import { Order } from 'app/_shared/models/Order';
import { CardMaskPipe } from 'app/_shared/pipes/card-mask.pipe';
import { PricePipe } from 'app/_shared/pipes/price.pipe';
import { UsdPipe } from 'app/_shared/pipes/usd-currency.pipe';

@Component({
  selector: 'app-order-details-collapse-item',
  imports: [NgClass, PricePipe, UsdPipe, CardMaskPipe],
  templateUrl: './order-details-collapse-item.component.html',
  styleUrl: './order-details-collapse-item.component.scss'
})
export class OrderDetailsCollapseItemComponent {
  @Input() order: Order;
}
