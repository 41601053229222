import { Component, inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';

import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { DialogModule } from 'primeng/dialog';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';

import { InputComponent } from 'app/_shared/components/input/input.component';
import { DecorationMethod } from 'app/_shared/interfaces/catalog';
import { GiftCardCode } from 'app/_shared/models/Coupon';
import { GiftCardCodeService } from 'app/_shared/services/gift-card-codes.service';
import { markAllAsTouched } from 'app/_shared/services/helper.service';

@Component({
  selector: 'app-gift-card-code-editor-dialog',
  imports: [ButtonModule, DialogModule, ReactiveFormsModule, InputComponent, CheckboxModule],
  templateUrl: './gift-card-code-editor-dialog.component.html',
  styleUrl: './gift-card-code-editor-dialog.component.scss'
})
export class GiftCardCodeEditorDialogComponent implements OnInit {
  dialogRef = inject(DynamicDialogRef);
  dialogConfig = inject(DynamicDialogConfig);
  fb = inject(FormBuilder);
  giftCardCodeService = inject(GiftCardCodeService);

  code: GiftCardCode;
  storefrontId: string;

  form: FormGroup;
  decorationMethod: DecorationMethod;
  isLoading = false;

  ngOnInit(): void {
    this.code = this.dialogConfig?.data?.code;
    this.storefrontId = this.dialogConfig?.data?.storefrontId;
    this.buildForm();
  }

  buildForm() {
    this.form = this.fb.group({
      name: [this.code?.name ?? null, Validators.required],
      email: [this.code?.email ?? null, Validators.required],
      amount: [this.code?.amount ?? null, Validators.required],
      notify: [!!this.code?.notify]
    });
  }

  closeDialog() {
    this.dialogRef.close();
  }

  onSubmit() {
    if (this.form.invalid) {
      markAllAsTouched(this.form);
      return;
    }
    this.updateGiftCardCode();
  }

  updateGiftCardCode() {
    this.isLoading = true;

    this.giftCardCodeService.update(+this.storefrontId, this.form.value, this.code?.id).subscribe({
      next: () => {
        this.dialogRef.close(true);
      },
      error: err => {
        console.error('Operation failed:', err);
      },
      complete: () => {
        this.isLoading = false;
      }
    });
  }
}
