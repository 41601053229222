import { NgClass } from '@angular/common';
import { Component, inject } from '@angular/core';
import { RouterOutlet } from '@angular/router';

import { LayoutService } from 'app/_shared/services/layout.service';
import { RightSidebarComponent } from 'app/admin/layout/right-sidebar/right-sidebar.component';
import { SidebarComponent } from 'app/admin/layout/sidebar/sidebar.component';
import { TopbarComponent } from 'app/admin/layout/topbar/topbar.component';

@Component({
    selector: 'app-layout',
    imports: [SidebarComponent, TopbarComponent, RouterOutlet, RightSidebarComponent, NgClass],
    styleUrl: './layout.component.scss',
    templateUrl: './layout.component.html'
})
export class LayoutComponent {
  private layoutService = inject(LayoutService);
  get containerClass() {
    return {
      'layout-light': this.layoutService.config().colorScheme === 'light',
      'layout-dim': this.layoutService.config().colorScheme === 'dim',
      'layout-dark': this.layoutService.config().colorScheme === 'dark',
      'layout-colorscheme-menu': this.layoutService.config().menuTheme === 'colorScheme',
      'layout-primarycolor-menu': this.layoutService.config().menuTheme === 'primaryColor',
      'layout-transparent-menu': this.layoutService.config().menuTheme === 'transparent',
      'layout-overlay': this.layoutService.config().menuMode === 'overlay',
      'layout-static': this.layoutService.config().menuMode === 'static',
      'layout-slim': this.layoutService.config().menuMode === 'slim',
      'layout-slim-plus': this.layoutService.config().menuMode === 'slim-plus',
      'layout-horizontal': this.layoutService.config().menuMode === 'horizontal',
      'layout-reveal': this.layoutService.config().menuMode === 'reveal',
      'layout-drawer': this.layoutService.config().menuMode === 'drawer',
      'layout-static-inactive':
        this.layoutService.state.staticMenuDesktopInactive && this.layoutService.config().menuMode === 'static',
      'layout-overlay-active': this.layoutService.state.overlayMenuActive,
      'layout-mobile-active': this.layoutService.state.staticMenuMobileActive,
      'p-input-filled': this.layoutService.config().inputStyle === 'filled',
      'p-ripple-disabled': !this.layoutService.config().ripple,
      'layout-sidebar-active': this.layoutService.state.sidebarActive,
      'layout-sidebar-anchored': this.layoutService.state.anchored
    };
  }
}
