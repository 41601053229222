import { AfterViewChecked, ChangeDetectorRef, Component, inject, OnInit } from '@angular/core';

import { PanelModule } from 'primeng/panel';
import { TableLazyLoadEvent } from 'primeng/table';
import { TabMenuModule } from 'primeng/tabmenu';
import { TabViewModule } from 'primeng/tabview';
import { finalize } from 'rxjs';

import { UserTableListComponent } from '../../_shared/components/user-table-list/user-table-list.component';
import { FormGroupValue } from '../../_shared/models/FormGroup';
import { User } from '../../_shared/models/User';
import { ToastService } from '../../_shared/services/toast.service';
import { UserService } from '../../_shared/services/user.service';
import { InviteCompanyListComponent } from '../invite-company-list/invite-company-list.component';

@Component({
    selector: 'app-user-list',
    imports: [UserTableListComponent, TabMenuModule, PanelModule, TabViewModule, InviteCompanyListComponent],
    templateUrl: './user-list.component.html'
})
export class UserListComponent implements OnInit, AfterViewChecked {
  userService = inject(UserService);
  toastService = inject(ToastService);
  cdRef = inject(ChangeDetectorRef);

  users: User[] = [];
  totalRecords: number = 0;
  isLoading = false;
  isLoadingEdit = false;
  isLoadingDelete = false;

  editUserDialog = false;

  activeTabIndex = 0;
  tabContents: string[] = [
    'This section shows users across all companies in the system.',
    'Here you can view all pending invitations sent to companies for joining SSM. '
  ];

  onTabChange(event: any) {
    this.activeTabIndex = event.index;
  }

  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }

  ngOnInit() {
    this.isLoading = true;
  }

  loadUsers($event?: TableLazyLoadEvent) {
    this.isLoading = true;
    this.userService
      .index($event)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe({
        next: res => {
          this.users = res.data.data;
          this.totalRecords = res.data.total;
        },
        error: error => console.error(error)
      });
  }

  deleteCompanyUser(userId: number) {
    this.isLoadingDelete = true;
    this.userService
      .deleteUser(userId)
      .pipe(
        finalize(() => {
          this.isLoadingDelete = false;
        })
      )
      .subscribe({
        next: () => {
          this.toastService.success('User successfully deleted!');
          this.loadUsers();
        },
        error: err => {
          console.error('Delete action failed:', err);
        }
      });
  }

  editCompanyUser(editData: { formData: FormGroupValue; id: number }) {
    this.isLoadingEdit = true;
    this.userService
      .updateUser(editData.id, editData.formData)
      .pipe(
        finalize(() => {
          this.isLoadingEdit = false;
        })
      )
      .subscribe({
        next: () => {
          this.toastService.success('Successfully updated user!');
          this.loadUsers();
        },
        error: err => {
          console.error('Update failed:', err);
        }
      });
  }

  hideDialog() {
    this.editUserDialog = false;
  }
}
