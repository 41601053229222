<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <div class="grid grid-nogutter justify-content-end">
    <div class="col-12">
      <div class="field">
        <label for="name">Category Name</label>
        <input
          type="text"
          pInputText
          class="w-full"
          formControlName="name"
          id="name"
          [ngClass]="{
            'ng-dirty': form.get('name')?.invalid && (form.get('name')?.dirty || form.get('name')?.touched)
          }"
        />
        @if ((submitted || form.get('name')?.touched) && form.get('name')?.errors?.['required']) {
          <div>
            <small class="p-text-danger">Name is required</small>
          </div>
        }
      </div>
    </div>
    <div class="col-12">
      <div class="mb-3">
        <div class="custom-upload">
          <p-fileUpload
            #fileUploader
            name="catefory-image-uploader"
            [multiple]="false"
            [customUpload]="true"
            (onSelect)="onImageUpload($event)"
            accept=".jpg,.jpeg,.png"
            maxFileSize="1000000"
            styleClass="border-1 surface-border surface-card p-0 border-round mb-1"
          >
            <ng-template pTemplate="content">
              <div class="min-h-10 m-1 border-round py-4">
                @if (!categoryImage?.objectURL) {
                  <div
                    class="flex flex-column w-full h-full justify-content-center align-items-center cursor-pointer"
                    (click)="fileUploader.advancedFileInput.nativeElement.click()"
                  >
                    <i class="pi pi-fw pi-file text-4xl text-primary"></i>
                    <span class="block font-semibold text-900 text-lg mt-3">Drop or select Category image</span>
                  </div>
                }
                @if (categoryImage?.objectURL) {
                  <div class="flex justify-content-center align-items-center h-full w-full">
                    <img
                      [src]="categoryImage?.objectURL"
                      height="80"
                      alt="Category image"
                      style="object-fit: contain"
                    />
                    <button
                      pButton
                      type="button"
                      aria-label="remove"
                      icon="pi pi-times"
                      class="p-button-rounded p-button-primary text-sm absolute justify-content-center align-items-center"
                      style="top: 10px; right: 10px"
                      (click)="$event.stopPropagation(); onImageRemove()"
                    ></button>
                  </div>
                }
              </div>
            </ng-template>
            <ng-template pTemplate="file"></ng-template>
          </p-fileUpload>
        </div>
        @if (submitted && !categoryImage.objectURL) {
          <div>
            <small class="p-text-danger">Image is required</small>
          </div>
        }
      </div>
    </div>

    <div class="col-12">
      <div class="field">
        <label for="name">Category Tax Code</label>
        <div>
          <p-dropdown
            styleClass="w-full"
            formControlName="tax_code"
            id="tax_code"
            [ngClass]="{
              'ng-dirty':
                form.get('tax_code')?.invalid && (form.get('tax_code')?.dirty || form.get('tax_code')?.touched)
            }"
            [filter]="true"
            filterBy="name"
            optionValue="id"
            optionLabel="name"
            [options]="taxCodes"
            appendTo="body"
          >
            <ng-template let-item pTemplate="item">
              <div [pTooltip]="item.name" tooltipPosition="top" class="custom-dropdown-item">
                {{ item.name }}
              </div>
            </ng-template>
          </p-dropdown>
        </div>

        @if ((submitted || form.get('tax_code')?.touched) && form.get('tax_code')?.errors?.['required']) {
          <div>
            <small class="p-text-danger">Tax Code is required</small>
          </div>
        }
      </div>
    </div>

    <div class="col-12 py-3">
      <h6>Print Positions</h6>
      <div formArrayName="print_positions">
        @for (position of printPositions.controls; track $index; let i = $index) {
          <div>
            <div [formGroupName]="i" class="flex gap-4 mb-3">
              <div class="flex flex-column w-full">
                <input type="text" pInputText formControlName="position" placeholder="Print Position" class="w-full" />
                @if (
                  (submitted || position.get('position')?.touched) && position.get('position')?.errors?.['required']
                ) {
                  <div>
                    <small class="p-text-danger">Position is required</small>
                  </div>
                }
              </div>
              <p-button
                type="button"
                icon="pi pi-trash"
                (click)="removePrintPosition(i)"
                outlined
                severity="danger"
              ></p-button>
            </div>
          </div>
        }
      </div>
      <p-button
        type="button"
        label="Add Print Position"
        icon="pi pi-plus"
        (click)="addPrintPosition()"
        outlined
      ></p-button>
    </div>
    <div class="col-auto">
      <div class="flex gap-2 justify-content-end">
        <p-button
          icon="pi pi-times"
          label="Cancel"
          type="button"
          outlined
          (onClick)="closeDialog()"
          [disabled]="isLoading"
        />
        <p-button icon="pi pi-check" label="Save" type="submit" [disabled]="isLoading" />
      </div>
    </div>
  </div>
</form>
