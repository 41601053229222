import { Component, inject, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';

import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { TreeModule } from 'primeng/tree';
import { finalize } from 'rxjs';

import { Permission } from 'app/_shared/models/Permission';
import { CompanyService } from 'app/_shared/services/company.service';

@Component({
  selector: 'app-user-storefront-permissions-modal',
  imports: [TreeModule, ButtonModule, ReactiveFormsModule, CheckboxModule],
  templateUrl: './user-storefront-permissions-modal.component.html',
  styleUrl: './user-storefront-permissions-modal.component.scss'
})
export class UserStorefrontPermissionsModalComponent implements OnInit {
  dialogRef = inject(DynamicDialogRef);
  dialogConfig = inject(DynamicDialogConfig);
  fb = inject(FormBuilder);
  companyService = inject(CompanyService);

  form: FormGroup;

  userId: number;
  companyId: number;
  storefrontId: number;
  allStorefrontPermissions: Permission[] = [];
  isLoading = false;

  ngOnInit() {
    this.userId = this.dialogConfig?.data.userId;
    this.storefrontId = this.dialogConfig?.data.storefrontId;
    this.companyId = this.dialogConfig?.data.companyId;
    this.allStorefrontPermissions = this.dialogConfig?.data.allStorefrontPermissions;
    this.form = this.fb.group({
      permissions: this.fb.array(this.allStorefrontPermissions.map(p => this.createPermission(p)))
    });
  }

  createPermission(permission: Permission): FormGroup {
    return this.fb.group({
      id: [permission.id],
      name: [permission.full_name],
      granted: [permission.granted]
    });
  }

  get permissionsArray(): FormArray {
    return this.form.get('permissions') as FormArray;
  }

  onSubmit() {
    const grantedPermissionsIds = this.form.value.permissions
      .filter((permission: { granted: boolean }) => permission.granted)
      .map((permission: { id: number }) => permission.id);

    this.updateUserPermissions(grantedPermissionsIds);
  }

  updateUserPermissions(permissions: number[]) {
    this.isLoading = true;
    this.companyService
      .updateStorefrontUserPersmissions(this.userId, this.storefrontId, this.companyId, permissions)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe({
        next: () => {
          this.dialogRef.close(true);
        },
        error: err => {
          console.error('Update failed:', err);
        }
      });
  }
}
