import { Component, computed, inject, OnInit, signal, ViewChild } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { SharedModule } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import { Editor, EditorModule } from 'primeng/editor';
import { FileUploadModule } from 'primeng/fileupload';
import { InputTextModule } from 'primeng/inputtext';
import { RadioButtonModule } from 'primeng/radiobutton';

import { Page, Section } from 'app/_shared/interfaces/designer/page';
import { DesignerService } from 'app/_shared/services/designer.service';
import { fontOptions, fontSizeOptions } from 'app/admin/storefront/designer/page-builder/page-builder.component';
import { SectionDefaultImports } from 'app/admin/storefront/designer/page-builder/page-sections/section-default-imports';

@Component({
  selector: 'app-title',
  imports: [
    ButtonModule,
    FileUploadModule,
    SharedModule,
    RadioButtonModule,
    FormsModule,
    EditorModule,
    ReactiveFormsModule,
    DropdownModule,
    InputTextModule
  ],
  templateUrl: './title.component.html',
  styleUrl: './title.component.scss'
})
export class TitleComponent extends SectionDefaultImports implements OnInit {
  @ViewChild('editor') editor: Editor;
  protected readonly fontOptions = fontOptions;
  protected readonly fontSizeOptions = fontSizeOptions;
  private designerService: DesignerService = inject(DesignerService);

  storefrontId = computed(() => this.storefrontStore.selectedStorefront?.()?.id);

  font = 'Poppins';
  fontSize = '12';
  title: string;
  selectedPage: Page;
  selectedSection: Section;

  ngOnInit() {
    this.selectedPage = this.dynamicConfig.data.selectedPage;
    this.selectedSection = this.dynamicConfig.data.section;
    this.font = this.dynamicConfig.data.section.content?.font;
    this.title = this.dynamicConfig.data.section.content?.title;
    this.fontSize = this.dynamicConfig.data.section.content?.fontSize;
  }

  setDefaultValue() {
    const value = this.dynamicConfig.data.section.content?.title;
    this.editor.quill.clipboard.dangerouslyPasteHTML(value);
  }

  onSubmit() {
    const selectedPage = this.dynamicConfig.data.selectedPage;
    const selectedSection = this.dynamicConfig.data.section;

    const pages = this.designerHelperService.saveSectionData(selectedPage, selectedSection, {
      title: this.title,
      font: this.font,
      fontSize: this.fontSize
    });

    this.designerStore.saveConfig(this.storefrontId()!, { pages }, true).subscribe(next => {
      this.designerHelperService.sendToPreview(this.storefrontId()!, next, () => this.ref.close());
    });
  }
}
