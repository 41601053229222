import { Component, inject, input, OnInit, output, signal } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { AccordionModule } from 'primeng/accordion';
import { PrimeTemplate, TreeNode } from 'primeng/api';
import { Button } from 'primeng/button';
import { DataViewLazyLoadEvent, DataViewModule } from 'primeng/dataview';
import { DividerModule } from 'primeng/divider';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { TreeModule } from 'primeng/tree';

import { ProductPaginatorOptions } from 'app/_shared/components/list-products/list-products.component';
import { CatalogProduct } from 'app/_shared/interfaces/catalog';
import { PricePipe } from 'app/_shared/pipes/price.pipe';
import { UsdPipe } from 'app/_shared/pipes/usd-currency.pipe';
import { CatalogProductCategoryService } from 'app/_shared/services/catalog-product-category.service';
import { CatalogService } from 'app/_shared/services/catalog.service';
import { CompanyService } from 'app/_shared/services/company.service';
import { ToastService } from 'app/_shared/services/toast.service';

interface CatalogProductWithSelected extends CatalogProduct {
  selected: boolean;
}

interface Filters {
  'category_ids[]': number[];
  global_filter: string;
  sort_field: string;
  sort_order: string;
  'brands[]': string[];
  'colors[]': string[];
}

@Component({
  selector: 'app-product-selection',
  imports: [
    Button,
    DataViewModule,
    PrimeTemplate,
    DividerModule,
    PricePipe,
    UsdPipe,
    TreeModule,
    AccordionModule,
    InputTextModule,
    DropdownModule,
    FormsModule
  ],
  templateUrl: './product-selection.component.html',
  styleUrl: './product-selection.component.scss'
})
export class ProductSelectionComponent implements OnInit {
  type = input<'collection' | 'selection'>('selection');
  isSubmitting = input<boolean>();
  disabled = input<boolean>();
  defaultSelection = input<CatalogProduct[]>();
  onSave = output<CatalogProduct[]>();
  selectedCategories!: TreeNode[];
  categories!: TreeNode[];

  private catalogService = inject(CatalogService);
  private companyService = inject(CompanyService);
  private activatedRoute = inject(ActivatedRoute);
  private toastService = inject(ToastService);
  private categoryService = inject(CatalogProductCategoryService);

  logo = signal<string>('');
  products = signal<CatalogProductWithSelected[]>([]);
  selectedProducts = signal<CatalogProductWithSelected[]>([]);
  search = '';
  companyId: string;
  collectionId: string;
  token: string;
  brands: TreeNode[];
  colors: TreeNode[];
  selectedBrands: TreeNode[];
  selectedColors: TreeNode[];
  paginatorOptions: ProductPaginatorOptions = {
    total: 0,
    rows: 9,
    first: 0
  };
  sortingOptions = [
    { label: 'Price High to Low', value: { id: 1, name: 'starting_price', code: 'desc' } },
    { label: 'Price Low to High', value: { id: 2, name: 'starting_price', code: 'asc' } }
  ];
  selectedSort: { id: number; name: string; code: string } | null = null;
  isLoading = true;

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {
      this.companyId = params.id;
      this.collectionId = params.collectionId;
      this.token = params.token;
    });
    this.getProducts(this.paginatorOptions);
    this.fetchCatalogCategories();
    this.getBrandsAndColors();
  }

  getBrandsAndColors() {
    this.companyService.getBrandsAndColors().subscribe(next => {
      this.brands = next.data.brands.map(brand => ({
        label: brand,
        key: brand,
        data: {
          name: brand
        },
        children: []
      }));
      this.colors = Object.keys(next.data.colors).map(key => ({
        label: key.charAt(0).toUpperCase() + key.slice(1),
        data: {
          name: next.data.colors[key][0]
        }
      }));
    });
  }

  fetchCatalogCategories() {
    this.categoryService.index().subscribe({
      next: res => {
        const mapToTreeNodes = (categories: any[]): any[] => {
          return categories.map(category => ({
            label: category.name,
            key: category.id.toString(), // Unique key for the node
            data: {
              id: category.id,
              adminCategoryId: category.admin_category_id,
              categoryImage: category.category_image
            },
            children: mapToTreeNodes(category.sub_categories || []) // Recursive mapping for subcategories
          }));
        };
        this.categories = mapToTreeNodes(res.data);
      },
      error: error => console.error(error)
    });
  }

  getProducts(paginatorOptions: ProductPaginatorOptions | DataViewLazyLoadEvent) {
    const { rows, first } = paginatorOptions;
    const filters: Filters = {
      'category_ids[]': (this.selectedCategories ?? []).map(cat => cat.data.id),
      global_filter: this.search,
      sort_field: this.selectedSort?.name ?? '',
      sort_order: this.selectedSort?.code ?? '',
      'brands[]': (this.selectedBrands ?? []).map(brand => brand.data.name),
      'colors[]': (this.selectedColors ?? []).map(color => color.data.name)
    };
    const params = {
      rows,
      page: first && rows ? Math.floor(first / rows) + 1 : 1,
      ...filters,
      'relations[]': ['catalogProductConfiguration', 'featuredImage']
    };
    this.catalogService.getProducts(params).subscribe({
      next: products => {
        if (this.defaultSelection()?.length) {
          this.preselectItems(this.defaultSelection() ?? []);
        }

        const withSelected = products.data.data.map(product => {
          const selected = this.selectedProducts().some(prod => product.id === prod.id);
          return { ...product, selected };
        });
        this.products.set(withSelected);
        this.paginatorOptions = {
          total: products.data.total,
          rows: products.data.per_page,
          first: products.data.from - 1
        };
      },
      error: err => {
        console.error(err);
      }
    });
  }

  preselectItems(items: CatalogProduct[]) {
    this.selectedProducts.update(() => {
      return items.map(prod => {
        return { ...prod, selected: true };
      });
    });
    this.selectedProducts.set(this.selectedProducts());
  }

  removeFromSelectedProducts(product: CatalogProductWithSelected) {
    this.selectedProducts.update(products => products.filter(item => item.id !== product.id));
  }

  handleAddRemoveProduct(product: CatalogProductWithSelected) {
    if (product?.selected && this.selectedProducts().length === 1) {
      return this.toastService.error('You must have at least one product selected!');
    }
    this.products.update(products => {
      return products.map(item => {
        if (item.id === product.id) {
          return { ...item, selected: !product.selected };
        }
        return item;
      });
    });
    const item = this.products().find(item => item.id === product.id);
    if (item?.selected) {
      this.selectedProducts.update(prev => [...prev, item]);
    } else {
      this.removeFromSelectedProducts(item!);
    }
    this.handleSave();
  }

  handleSave() {
    this.onSave.emit(this.selectedProducts());
  }
}
