<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <div class="col-12 py-3">
    <div formArrayName="emails">
      @for (recipient of emails.controls; track $index; let i = $index) {
        <div [formGroupName]="i" class="flex gap-4 mb-3">
          <div class="flex flex-column w-full">
            <input type="email" pInputText formControlName="email" placeholder="example@gmail.com" class="w-full" />
            @if (recipient.get('email')?.touched && recipient.get('email')?.errors?.['required']) {
              <div>
                <small class="p-text-danger">Email is required</small>
              </div>
            }
            @if (recipient.get('email')?.touched && recipient.get('email')?.errors?.['email']) {
              <div>
                <small class="p-text-danger">Must be a valid email address</small>
              </div>
            }
          </div>
          <p-button type="button" icon="pi pi-trash" (click)="removeRecepient(i)" outlined severity="danger"></p-button>
        </div>
      }
    </div>
    <div class="flex justify-content-between">
      <p-button type="button" label="Add Recipient" icon="pi pi-plus" (click)="addRecepient()" outlined></p-button>
      <p-button type="submit" label="Send emails" />
    </div>
  </div>
</form>
