import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Router, RouterLink } from '@angular/router';

import { ButtonModule } from 'primeng/button';
import { ChartModule } from 'primeng/chart';
import { SkeletonModule } from 'primeng/skeleton';
import { TableLazyLoadEvent, TableModule } from 'primeng/table';
import { finalize } from 'rxjs';

import { Company } from 'app/_shared/models/Company';
import { CompanyService } from 'app/_shared/services/company.service';
import { CompanyStore } from 'app/_store/company.store';

@Component({
  selector: 'app-company-select-dashboard',
  imports: [CommonModule, FormsModule, ChartModule, TableModule, RouterLink, ButtonModule, SkeletonModule],
  templateUrl: './company-select-dashboard.component.html',
  styleUrl: './company-select-dashboard.component.scss'
})
export class CompanySelectDashboardComponent {
  companyStore = inject(CompanyStore);
  companyService = inject(CompanyService);
  router = inject(Router);

  companies: Company[] = [];
  totalRecords: number = 0;
  isLoading = true;
  loadingRows = Array(10)
    .fill(0)
    .map((_, index) => index + 1);

  loadCompanies($event?: TableLazyLoadEvent) {
    this.isLoading = true;
    this.companyService
      .paginatedIndex($event)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe({
        next: res => {
          this.companies = res.data.data;
          this.totalRecords = res.data.total;
          this.handleSingleCompany(res.data.total);
        },
        error: error => console.error(error)
      });
  }

  handleSingleCompany(total: number) {
    if (total === 1) {
      this.router.navigateByUrl(`companies/${this.companies[0].id}/general`);
    }
  }
}
