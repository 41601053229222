<div class="variant-item">
  <div class="flex justify-content-between">
    <div class="mb-3">
      <small>
        <strong>Supplier Color:</strong>
        {{ variant().supplier_product_variant?.color }}
      </small>
      @if (!editMode) {
        <div>
          <strong>Color:</strong>
          {{ variant().color }}
          <p-button icon="pi pi-pencil" link size="small" styleClass="p-0" (click)="editMode = true" />
        </div>
      }
    </div>
    <div>
      <p-button icon="pi pi-trash" severity="danger" outlined (click)="openDeleteVariantModal($event)" />
    </div>
  </div>

  @if (editMode) {
    <div class="mt-2 mb-3">
      <p-inputGroup>
        <p-button type="button" icon="pi pi-check" styleClass="border-noround-right" (onClick)="editMode = false" />

        <input
          [id]="'Color' + variantIndex"
          class="m-0"
          [(ngModel)]="variant().color"
          type="text"
          pInputText
          placeholder="Color"
        />

        <p-button
          type="button"
          label="Add to dictinary"
          styleClass="border-noround-left"
          (onClick)="addToDictionary()"
        />
      </p-inputGroup>
    </div>
  }
  <div class="flex gap-5 flex-wrap mb-3">
    @for (image of variantImages(); track image; let i = $index) {
      <div
        class="flex flex-column align-items-center justify-content-center flex-grow-1 cursor-move"
        [attr.draggable]="true"
        (dragstart)="onImageDragStart($event, i)"
        (dragover)="onImageDragOver($event)"
        (drop)="onImageDrop($event, i)"
      >
        @if (!image.id) {
          <img [src]="image.full_path" alt="Variant image" style="object-fit: contain" width="100" height="125" />
        } @else {
          <img [ngSrc]="image.full_path" alt="Variant image" style="object-fit: contain" width="100" height="125" />
        }
        <div class="flex justify-content-center w-full mt-2 gap-1">
          <p-button icon="pi pi-pencil" outlined (click)="fileInputEdit.click()"></p-button>
          <p-button icon="pi pi-trash" outlined severity="danger" (click)="openDeleteMediaModal(image)"></p-button>
        </div>
        <input
          #fileInputEdit
          type="file"
          (change)="onFileChange($event, image)"
          accept="image/*"
          style="display: none"
        />
      </div>
    }
  </div>
  <div class="flex justify-content-between">
    <p-button icon="pi pi-plus" label="Add Image" outlined (click)="openMediaUploaderModal()"></p-button>
    <p-button icon="pi pi-cog" label="Edit sizes" outlined (click)="handleEditSizes()"></p-button>
  </div>
</div>
