import { v4 as uuidv4 } from 'uuid';

import { ComponentEnum } from 'app/_shared/interfaces/designer/page';
import { Config } from 'app/_store/designer.store';

export const storefrontDefaultData: Partial<Config> = {
  pages: [
    {
      title: 'Homepage',
      url: '',
      id: uuidv4(),
      sections: [
        {
          id: uuidv4(),
          label: 'Banner image',
          component: ComponentEnum.BannerImage,
          content: {
            img: {
              url: 'assets/images/default-banner.png'
            }
          }
        },
        {
          id: uuidv4(),
          label: 'Title',
          component: ComponentEnum.Title,
          content: {
            title: 'Latest products'
          }
        },
        {
          id: uuidv4(),
          label: 'Product list',
          component: ComponentEnum.ProductList,
          content: {
            productsPerRow: 4,
            categories: [],
            listAllProducts: false
          }
        }
      ]
    }
  ],
  menu: [{ id: uuidv4(), label: 'Products', customUrl: '/products' }]
};
