import { Component, input } from '@angular/core';

import { PricePipe } from 'app/_shared/pipes/price.pipe';
import { UsdPipe } from 'app/_shared/pipes/usd-currency.pipe';

@Component({
  selector: 'app-money-card',
  imports: [UsdPipe, PricePipe],
  templateUrl: './money-card.component.html',
  styleUrl: './money-card.component.scss'
})
export class MoneyCardComponent {
  amount = input.required<number>();
  label = input.required<string>();
}
