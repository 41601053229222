import { Component, inject } from '@angular/core';
import { Router, RouterLink } from '@angular/router';

import { BadgeModule } from 'primeng/badge';
import { SidebarModule } from 'primeng/sidebar';

import { AuthService } from 'app/_shared/services/auth.service';
import { LayoutService } from 'app/_shared/services/layout.service';
import { AuthStore } from 'app/_store/auth.store';
import { CompanyStore } from 'app/_store/company.store';
import { StorefrontStore } from 'app/_store/storefront.store';

@Component({
    selector: 'app-right-sidebar',
    imports: [BadgeModule, SidebarModule, RouterLink],
    templateUrl: './right-sidebar.component.html',
    styles: ''
})
export class RightSidebarComponent {
  layoutService = inject(LayoutService);
  authStore = inject(AuthStore);
  companyStore = inject(CompanyStore);
  storefrontStore = inject(StorefrontStore);
  authService = inject(AuthService);
  router = inject(Router);

  get visible(): boolean {
    return this.layoutService.state.rightSidebarVisible;
  }

  set visible(_val: boolean) {
    this.layoutService.state.rightSidebarVisible = _val;
  }

  logout() {
    this.visible = false;
    this.authService.logout().subscribe(() => {});
    this.authStore.clearAuthUser();
    this.companyStore.unsetWorkingCompany();
    this.storefrontStore.unsetSelectedStorefront();
    this.router.navigate(['/auth/login']);
  }

  hideMenu() {
    this.layoutService.state.rightSidebarVisible = false;
  }
}
