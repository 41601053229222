import { Component, inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

import { ButtonModule } from 'primeng/button';
import { ChipModule } from 'primeng/chip';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { FileSelectEvent, FileUploadEvent, FileUploadModule } from 'primeng/fileupload';
import { SelectButtonModule } from 'primeng/selectbutton';
import { StepsModule } from 'primeng/steps';
import { finalize } from 'rxjs';

import { InputComponent } from 'app/_shared/components/input/input.component';
import { LibraryService } from 'app/_shared/services/library.service';
import { ToastService } from 'app/_shared/services/toast.service';

interface FilePrepFile {
  file: File | null;
  name: string | null;
  objectURL?: string | null;
}

@Component({
  selector: 'app-request-file-prep-dialog',
  imports: [
    StepsModule,
    ButtonModule,
    ReactiveFormsModule,
    InputComponent,
    FileUploadModule,
    SelectButtonModule,
    ChipModule
  ],
  templateUrl: './request-file-prep-dialog.component.html',
  styleUrl: './request-file-prep-dialog.component.scss'
})
export class RequestFilePrepDialogComponent implements OnInit {
  private fb = inject(FormBuilder);
  private dialogRef = inject(DynamicDialogRef);
  private libraryService = inject(LibraryService);
  private toastService = inject(ToastService);
  private route = inject(ActivatedRoute);

  form: FormGroup;
  activeIndex: number = 0;
  steps = [{ label: 'Information' }, { label: 'Summary' }];
  libraryThumbnail: FilePrepFile = {
    file: null,
    objectURL: null,
    name: null
  };

  additionaFiles: FilePrepFile[] = [];
  storefrontId: string;
  isLoading = false;

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.storefrontId = params.storefrontId;
      this.buildForm();
    });
  }

  buildForm() {
    this.form = this.fb.group({
      name: [null, Validators.required],
      additional_message: [null]
    });
  }

  onThumbnailUpload(event: FileUploadEvent | FileSelectEvent) {
    const file = event.files[0];
    const fileName = event.files[0].name;
    const objectURL = URL.createObjectURL(file);
    this.libraryThumbnail.file = file;
    this.libraryThumbnail.objectURL = objectURL;
    this.libraryThumbnail.name = fileName;
  }

  removeThumbnail(): void {
    this.libraryThumbnail.file = null;
    this.libraryThumbnail.objectURL = null;
  }

  onAdditionalFileRemove(index: number) {
    this.additionaFiles.splice(index, 1);
  }

  onAdditionalFilesUpload(event: FileUploadEvent | FileSelectEvent) {
    for (const file of event.files) {
      this.additionaFiles.push({
        file,
        name: file.name
      });
    }
  }

  nextStep() {
    this.activeIndex++;
  }

  previousStep() {
    this.activeIndex--;
  }

  closeDialog() {
    this.dialogRef.close();
  }

  onSubmit() {
    if (this.form.invalid) {
      this.toastService.error('Please fill all required fields correctly!');
      this.form.markAllAsTouched();
      return;
    }
    if (!this.libraryThumbnail.objectURL) {
      this.toastService.error('Please upload thumbnail file!');
      return;
    }
    const formData = this.prepareFormData();
    this.createPrepFileReq(formData);
  }

  prepareFormData() {
    const formData = new FormData();
    const formValue = this.form.value;
    formData.append('name', formValue.name);

    if (this.libraryThumbnail.file) {
      formData.append('library_thumbnail', this.libraryThumbnail.file);
    }
    formData.append('additional_message', formValue.additional_message);
    this.additionaFiles.forEach((addFile, index) => {
      if (addFile.file) {
        formData.append(`additional_files[${index}]`, addFile.file);
      }
    });
    return formData;
  }

  createPrepFileReq(preparedData: FormData) {
    this.isLoading = true;
    this.libraryService
      .createPrepFileRequestWithLibrabry(+this.storefrontId, preparedData)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe({
        next: () => {
          this.dialogRef.close(true);
        },
        error: err => {
          console.error('Operation failed:', err);
        }
      });
  }
}
