import { CommonModule, DatePipe } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { CardModule } from 'primeng/card';
import { DividerModule } from 'primeng/divider';
import { TagModule } from 'primeng/tag';
import { finalize } from 'rxjs';

import { Order } from 'app/_shared/models/Order';
import { DisplayDatePipe } from 'app/_shared/pipes/date.pipe';
import { PricePipe } from 'app/_shared/pipes/price.pipe';
import { UsdPipe } from 'app/_shared/pipes/usd-currency.pipe';
import { CompanyOrderService } from 'app/_shared/services/company-order.service';
import { SuperadminOrderService } from 'app/_shared/services/superadmin-order.service';
import { ToastService } from 'app/_shared/services/toast.service';

@Component({
    selector: 'app-order-details',
    imports: [CardModule, DividerModule, TagModule, CommonModule, DisplayDatePipe, UsdPipe, PricePipe],
    providers: [DatePipe],
    templateUrl: './order-details.component.html'
})
export class OrderDetailsComponent implements OnInit {
  route = inject(ActivatedRoute);
  router = inject(Router);
  toastService = inject(ToastService);
  companyOrderService = inject(CompanyOrderService);
  superadminOrderService = inject(SuperadminOrderService);
  isLoading = false;
  order: Order;

  ngOnInit(): void {
    this.checkUrlForOrderId();
  }

  checkUrlForOrderId(): void {
    const companyId = this.route.snapshot.paramMap.get('id');
    const orderId = this.route.snapshot.paramMap.get('orderId');

    const superadminMode = this.route.snapshot.data.superadminMode;

    if (orderId && (superadminMode || companyId)) {
      if (superadminMode) {
        this.fetchSuperadminOrder(+orderId);
      } else if (companyId) {
        this.fetchCompanyOrder(+companyId, +orderId);
      }
    } else {
      this.router.navigate(['../'], { relativeTo: this.route });
    }
  }

  fetchCompanyOrder(companyId: number, orderId: number) {
    const params = {
      'relations[]': [
        'contactInfo',
        'storefront',
        'orderShippingInformation',
        'billingInfo',
        'items.product.productThumbnail',
        'items.productSize'
      ]
    };
    this.isLoading = true;
    this.companyOrderService
      .getById(companyId, orderId, params)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe({
        next: response => {
          this.order = response.data;
        },
        error: error => {
          console.error(error);
          this.toastService.error('Error occurred on user fetch. Please try again!');
          this.router.navigate(['../'], { relativeTo: this.route });
        }
      });
  }

  fetchSuperadminOrder(id: number) {
    const params = {
      'relations[]': [
        'contactInfo',
        'storefront',
        'orderShippingInformation',
        'billingInfo',
        'items.product.productThumbnail',
        'items.productSize'
      ]
    };
    this.isLoading = true;
    this.superadminOrderService
      .getById(id, params)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe({
        next: response => {
          this.order = response.data;
        },
        error: error => {
          console.error(error);
          this.toastService.error('Error occurred on user fetch. Please try again!');
          this.router.navigate(['../'], { relativeTo: this.route });
        }
      });
  }
}
