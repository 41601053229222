[
  {
    "id": "txcd_00000000",
    "type": "physical",
    "description": "Any nontaxable good or service which can be used to ensure no tax is applied, even for jurisdictions that impose a tax.",
    "name": "Nontaxable"
  },
  {
    "id": "txcd_10000000",
    "type": "digital",
    "description": "A digital service provided mainly through the internet with minimal human involvement, relying on information technology. Consider more specific categories like software, digital goods, cloud services, or website services for your product (especially if you sell in the US). If you stay with this category, taxes will be similar to those for a generic digital item like downloaded music.",
    "name": "General - Electronically Supplied Services"
  },
  {
    "id": "txcd_10401000",
    "type": "digital",
    "description": "Works that result from the fixation of a series of musical, spoken, or other sounds that are transferred electronically.  These goods are streamed to a device with access that expires after a stated period of time.  These goods include prerecorded or live music, prerecorded or live readings of books or other written materials, prerecorded or live speeches, ringtones, or other sound recordings, but not including audio greeting cards.",
    "name": "Digital Audio Works - streamed - non subscription - with limited rights"
  },
  {
    "id": "txcd_10401001",
    "type": "digital",
    "description": "Works that result from the fixation of a series of musical, spoken, or other sounds that are transferred electronically.  These goods are downloaded to a device with access that expires after a stated period of time.  These goods include prerecorded or live music, prerecorded or live readings of books or other written materials, prerecorded or live speeches, ringtones, or other sound recordings, but not including audio greeting cards.  Note the presence of PTC 10301000 (Audiobook), a more granular option for downloaded audiobooks.",
    "name": "Digital Audio Works - downloaded - non subscription - with limited rights"
  },
  {
    "id": "txcd_10401100",
    "type": "digital",
    "description": "Works that result from the fixation of a series of musical, spoken, or other sounds that are transferred electronically. These goods are downloaded to a device with permanent access granted. These goods include prerecorded or live music, prerecorded or live readings of books or other written materials, prerecorded or live speeches, ringtones, or other sound recordings, but not including audio greeting cards.  Note the presence of PTC 10301000 (Audiobook), a more granular option for downloaded audiobooks.",
    "name": "Digital Audio Works - downloaded - non subscription - with permanent rights"
  },
  {
    "id": "txcd_10401200",
    "type": "digital",
    "description": "Works that result from the fixation of a series of musical, spoken, or other sounds that are transferred electronically. These goods are streamed to a device with access that is conditioned upon continued subscription payment. These goods include prerecorded or live music, prerecorded or live readings of books or other written materials, prerecorded or live speeches, ringtones, or other sound recordings, but not including audio greeting cards.",
    "name": "Digital Audio Works - streamed - subscription - with conditional rights"
  },
  {
    "id": "txcd_10402300",
    "type": "digital",
    "description": "An audio visual work of a live performance streamed over the internet that you can only access for a limited time during the performance (e.g., a live concert stream).",
    "name": "Digital Video Streaming - live events - limited use"
  },
  {
    "id": "txcd_10501000",
    "type": "digital",
    "description": "Digital images that are downloaded to a device with permanent access granted.",
    "name": "Digital Photographs\/Images - downloaded - non subscription - with permanent rights"
  },
  {
    "id": "txcd_10502000",
    "type": "digital",
    "description": "Gift card or gift certificate that you purchase and receive electronically and assumed to be multi-purpose.",
    "name": "Gift Card"
  },
  {
    "id": "txcd_10503000",
    "type": "digital",
    "description": "Individual digital news articles, newsletters, and other stand-alone documents.  These goods are downloaded to a device with permanent access granted.  These publications are accessed without a subscription.",
    "name": "Digital other news or documents - downloadable - non subscription - with permanent rights"
  },
  {
    "id": "txcd_10503001",
    "type": "digital",
    "description": "Individual digital news articles, newsletters, and other stand-alone documents. These goods are downloaded to a device with access that expires after a stated period of time.",
    "name": "Digital other news or documents - downloadable - non subscription - with limited rights"
  },
  {
    "id": "txcd_10503002",
    "type": "digital",
    "description": "Individual digital news articles, newsletters, and other stand-alone documents. These goods are downloaded to a device with access that is conditioned upon continued subscription payment.",
    "name": "Digital other news or documents - downloadable - subscription - with conditional rights"
  },
  {
    "id": "txcd_10503003",
    "type": "digital",
    "description": "Individual digital news articles, newsletters, and other stand-alone documents. These goods are viewable (but not downloadable) on a device with access that expires after a stated period of time.",
    "name": "Digital other news or documents - viewable only - non subscription - with limited rights"
  },
  {
    "id": "txcd_10503004",
    "type": "digital",
    "description": "Individual digital news articles, newsletters, and other stand-alone documents. These goods are viewable (but not downloadable) on a device with permanent access granted.",
    "name": "Digital other news or documents - viewable only - non subscription - with permanent rights"
  },
  {
    "id": "txcd_10503005",
    "type": "digital",
    "description": "Individual digital news articles, newsletters, and other stand-alone documents. These goods are viewable (but not downloadable) on a device with access that is conditioned upon continued subscription payment.",
    "name": "Digital other news or documents - viewable only - subscription - with conditional rights"
  },
  {
    "id": "txcd_10504000",
    "type": "digital",
    "description": "Electronic software documentation or user manuals - For custom software & delivered electronically.",
    "name": "Electronic software documentation or user manuals - Custom, electronic delivery"
  },
  {
    "id": "txcd_10504003",
    "type": "digital",
    "description": "Electronic software documentation or user manuals - For prewritten software & delivered electronically.",
    "name": "Electronic software documentation or user manuals - Prewritten, electronic delivery"
  },
  {
    "id": "txcd_10505000",
    "type": "digital",
    "description": "The final art used for actual reproduction by photomechanical or other processes or for display purposes, but does not include website or home page design, and that is transferred electronically. These goods are downloaded to a device with access that expires after a stated period of time. These goods include drawings, paintings, designs, photographs, lettering, paste-ups, mechanicals, assemblies, charts, graphs, illustrative materials, etc.",
    "name": "Digital Finished Artwork - downloaded - non subscription - with limited rights"
  },
  {
    "id": "txcd_10505001",
    "type": "digital",
    "description": "The final art used for actual reproduction by photomechanical or other processes or for display purposes, but does not include website or home page design, and that is transferred electronically. These goods are downloaded to a device with permanent access granted. These goods include drawings, paintings, designs, photographs, lettering, paste-ups, mechanicals, assemblies, charts, graphs, illustrative materials, etc.",
    "name": "Digital Finished Artwork - downloaded - non subscription - with permanent rights"
  },
  {
    "id": "txcd_10505002",
    "type": "digital",
    "description": "The final art used for actual reproduction by photomechanical or other processes or for display purposes, but does not include website or home page design, and that is transferred electronically. These goods are downloaded to a device with access that is conditioned upon continued subscription payment. These goods include drawings, paintings, designs, photographs, lettering, paste-ups, mechanicals, assemblies, charts, graphs, illustrative materials, etc.",
    "name": "Digital Finished Artwork - downloaded - subscription - with conditional rights"
  },
  {
    "id": "txcd_10506000",
    "type": "digital",
    "description": "An electronic greeting \"card\" typically sent via email that contains an audio only message.",
    "name": "Digital Greeting Cards - Audio Only"
  },
  {
    "id": "txcd_10506001",
    "type": "digital",
    "description": "An electronic greeting \"card\" typically sent via email that contains a series of related images which, when shown in succession, impart an impression of motion, together with accompanying sounds, if any.",
    "name": "Digital Greeting Cards - Audio Visual"
  },
  {
    "id": "txcd_10506002",
    "type": "digital",
    "description": "An electronic greeting \"card\" typically sent via email that contains only static images or text, rather than an audio visual or audio only experience.",
    "name": "Digital Greeting Cards - Static text and\/or images only"
  },
  {
    "id": "txcd_10701000",
    "type": "digital",
    "description": "Online advertising services such as creating and uploading advertisements on the internet. This is a standalone service that doesn't involve the sale of tangible personal property.",
    "name": "Website Advertising"
  },
  {
    "id": "txcd_10701100",
    "type": "digital",
    "description": "A service to enable a customer's website to be accessible on the internet.",
    "name": "Website Hosting"
  },
  {
    "id": "txcd_10701200",
    "type": "services",
    "description": "A service to design a website or webpage.",
    "name": "Website Design"
  },
  {
    "id": "txcd_10701300",
    "type": "digital",
    "description": "An online service that allows a customer to create, transform, process or access data electronically.",
    "name": "Website Data Processing"
  },
  {
    "id": "txcd_10701400",
    "type": "digital",
    "description": "An online service furnishing information to customers, including online search and data comparison.",
    "name": "Website Information Services"
  },
  {
    "id": "txcd_10701401",
    "type": "digital",
    "description": "An online service furnishing information to customers, including online search and data comparison. This PTC involves the customer utilizing a SaaS program to access the information content.\u00a0 This offering is intended for personal use, rather than for use by a commercial enterprise.\u00a0 Note: The distinction between business use and personal use for this product tax category is relevant only if you have sales in the U.S.",
    "name": "Website Information Services -  Personal Use"
  },
  {
    "id": "txcd_10701410",
    "type": "digital",
    "description": "The furnishing of information of any kind by electronic means. This PTC does not involve the customer utilizing a SaaS program to access the information content. This offering is intended for use by a commercial enterprise. Note: The distinction between business use and personal use for this PTC is relevant only if you have sales in the US.",
    "name": "Electronically Delivered Information Services - Business Use"
  },
  {
    "id": "txcd_10701411",
    "type": "digital",
    "description": "The furnishing of information of any kind by electronic means. This PTC does not involve the customer utilizing a SaaS program to access the information content.\u00a0 This offering is intended for personal use, rather than for use by a commercial enterprise. Note: The distinction between business use and personal use for this product tax category is relevant only if you have sales in the US.",
    "name": "Electronically Delivered Information Services - Personal Use"
  },
  {
    "id": "txcd_10702000",
    "type": "digital",
    "description": "A subscription service for membership to an online dating platform.",
    "name": "Online Dating Services"
  },
  {
    "id": "txcd_10804001",
    "type": "digital",
    "description": "A series of related images which, when shown in succession, impart an impression of motion, together with accompanying sounds, if any. These goods are streamed and\/or downloaded to a device with access that is conditioned upon continued subscription payment.  Any downloads received while under subscription remain the permanent property of the subscriber.  These goods include motion pictures, music videos, animations, news and entertainment programs, and live events, but do not include video greeting cards or video or electronic games.  These goods further include self-study web based training services that impart content via audio visual goods described here.",
    "name": "Digital Audio Visual Works - bundle - downloaded with permanent rights and streamed - subscription - with conditional rights"
  },
  {
    "id": "txcd_10804002",
    "type": "digital",
    "description": "A series of related images which, when shown in succession, impart an impression of motion, together with accompanying sounds, if any. These goods can be streamed and\/or downloaded to a device with access that expires after a stated period of time. These goods include motion pictures, music videos, animations, news and entertainment programs, and live events, but do not include video greeting cards or video or electronic games.",
    "name": "Digital Audio Visual Works - bundle - downloaded with limited rights and streamed - non subscription"
  },
  {
    "id": "txcd_10804003",
    "type": "digital",
    "description": "A series of related images which, when shown in succession, impart an impression of motion, together with accompanying sounds, if any. These goods can be streamed and\/or downloaded to a device with permanent access granted. These goods include motion pictures, music videos, animations, news and entertainment programs, and live events, but do not include video greeting cards or video or electronic games.",
    "name": "Digital Audio Visual Works - bundle - downloaded with permanent rights and streamed - non subscription"
  },
  {
    "id": "txcd_10804010",
    "type": "digital",
    "description": "Works that result from the fixation of a series of musical, spoken, or other sounds that are transferred electronically. These goods are streamed and\/or downloaded to a device with access that is conditioned upon continued subscription payment.  Any downloads received while under subscription remain the permanent property of the subscriber.  These goods include prerecorded or live music, prerecorded or live readings of books or other written materials, prerecorded or live speeches, ringtones, or other sound recordings, but not including audio greeting cards.   These goods further include self-study web based training services that impart content via audio goods described here.  Note the presence of PTC 10301000 (Audiobook), a more granular option for downloaded audiobooks.",
    "name": "Digital Audio Works - bundle - downloaded with permanent rights and streamed - subscription - with conditional rights"
  },
  {
    "id": "txcd_20010001",
    "type": "services",
    "description": "A charge to clean, wash or wax a motor vehicle, other than a self-service coin (or credit card) operated washing station. This a labor charge, with any non-separately stated property transferred in performing the service considered inconsequential.",
    "name": "Car Washing"
  },
  {
    "id": "txcd_20010003",
    "type": "services",
    "description": "A charge for the cleaning of tangible personal property, other than motor vehicles or clothing.",
    "name": "Cleaning of Tangible Personal Property"
  },
  {
    "id": "txcd_20030000",
    "type": "services",
    "description": "General category for services. It should be used only when there is no more specific services category. In the European Union, the default rule for business-to-consumer sales (B2C) is the location of the seller, whereas for business-to-business sales (B2B) - the location of the buyer.",
    "name": "General - Services"
  },
  {
    "id": "txcd_20060044",
    "type": "services",
    "description": "A charge for training sessions which provide instruction to the buyer. This includes education sessions or workshops but excludes physical exercise or workouts.",
    "name": "Training"
  },
  {
    "id": "txcd_20060045",
    "type": "services",
    "description": "Live web based training. This does not include video replays of the instruction or course.  This service allows for a level of participation that is substantially similar to an in-person training on the same subject matter.",
    "name": "Training Services - Live Virtual"
  },
  {
    "id": "txcd_30011000",
    "type": "physical",
    "description": "Apparel and footwear for people made for general use.",
    "name": "Clothing & Footwear"
  },
  {
    "id": "txcd_30011015",
    "type": "physical",
    "description": "A closed tube of fabric worn about the neck for warmth. It can be pulled over mouth and nose to protect against wind, dust, or other particles.",
    "name": "Neck Gaiter"
  },
  {
    "id": "txcd_30011020",
    "type": "physical",
    "description": "A set of standardized clothes worn primarily during attendnce at an educational institution.",
    "name": "School Uniforms"
  },
  {
    "id": "txcd_30011028",
    "type": "physical",
    "description": "Clothing or footwear, such as coats, hats, ear muffs, gloves, etc. worn on the human body made of imitation fur materials.",
    "name": "Fur Clothing - Synthetic"
  },
  {
    "id": "txcd_30011034",
    "type": "physical",
    "description": "A broad strip of cloth that covers the shoulders, neck, or head for warmth or appearance.",
    "name": "Scarves"
  },
  {
    "id": "txcd_30011045",
    "type": "physical",
    "description": "A necktie, or simply a tie, is a piece of cloth worn by men and women for decorative purposes around the neck, resting under the shirt collar and knotted at the throat, and often draped down the chest.",
    "name": "Neckties"
  },
  {
    "id": "txcd_30011100",
    "type": "physical",
    "description": "Clothing designed for, and not normally worn except while attending, a formal function, including tuxedos, bridal gowns, and evening gowns.",
    "name": "Formal or Special Occasion Wear"
  },
  {
    "id": "txcd_30011200",
    "type": "physical",
    "description": "Children's clothing and footwear including general purpose articles intended to be worn by a person based on certain size or age requirements.",
    "name": "Children's clothing and footwear"
  },
  {
    "id": "txcd_30011201",
    "type": "physical",
    "description": "Clothing or footwear, such as coats, hats, ear muffs, gloves, etc. worn on the human body made of the real pelt of an animal.",
    "name": "Fur Clothing"
  },
  {
    "id": "txcd_30021000",
    "type": "physical",
    "description": "Clothing, footwear, and accessories worn on a person's body while participating in recreational or sporting activities, and which are not typical for everyday usage.  Please select a more granular product tax category where appropriate.",
    "name": "Athletic Activity Clothing"
  },
  {
    "id": "txcd_30021001",
    "type": "physical",
    "description": "A band of absorbent material worn around the head or wrist to soak up sweat, especially by participants in physical activity.",
    "name": "Sweat Bands"
  },
  {
    "id": "txcd_30030001",
    "type": "physical",
    "description": "Clothing worn while performing an employment role, such as paramedic uniforms, firefighter uniforms, military uniforms, medical attire, law enforcement attire, etc.",
    "name": "Occupational Uniforms"
  },
  {
    "id": "txcd_30031004",
    "type": "physical",
    "description": "Clothing and related covering material that is worn within cleanrooms, which require controlled low levels of environmental contaminants such as dusts, microbes, vapors, and aerosol particles.",
    "name": "Cleanroom Apparel"
  },
  {
    "id": "txcd_30031041",
    "type": "physical",
    "description": "High-visibility warning clothing to signal the presence of the user in places and situations which may cause potential threats to their health and safety.",
    "name": "Reflective Apparel"
  },
  {
    "id": "txcd_30031404",
    "type": "physical",
    "description": "Clothing for human wear and designed as protection of the wearer against injury or disease or as protections against damage or injury of other persons or property but not suitable for general use.",
    "name": "Protective\/Safety Clothing"
  },
  {
    "id": "txcd_30031405",
    "type": "physical",
    "description": "Clothing worn by an employee during the course of employment for protection against injury or disease or as protection against damage or injury of other persons or property.  Such clothing items are not suitable for general use.",
    "name": "Protective\/Safety Clothing - Occupational Use"
  },
  {
    "id": "txcd_30060001",
    "type": "physical",
    "description": "Bags including handbags, purses, coin purses, fanny packs \/ bum bags, and diaper \/ nappy bags.",
    "name": "Purses and Handbags"
  },
  {
    "id": "txcd_30060004",
    "type": "physical",
    "description": "A small usually square piece of cloth used for usually personal purposes (such as blowing the nose) or as a clothing accessory.",
    "name": "Handkerchiefs"
  },
  {
    "id": "txcd_30060006",
    "type": "physical",
    "description": "A shaped covering for the head worn for warmth, as a fashion item, or as part of a uniform.",
    "name": "Hats"
  },
  {
    "id": "txcd_30060010",
    "type": "physical",
    "description": "Articles, although worn on the body, which are not considered clothing or footwear.  Examples include armbands, pocket protectors, shoulder boards, sleep masks, etc.  Please select a more granular product tax category where appropriate.",
    "name": "Non-Clothing Accessories"
  },
  {
    "id": "txcd_30060013",
    "type": "physical",
    "description": "Umbrellas.",
    "name": "Umbrellas"
  },
  {
    "id": "txcd_30060015",
    "type": "physical",
    "description": "Suitcases and baggage typically used for transporting travellers' belongings.",
    "name": "Luggage"
  },
  {
    "id": "txcd_30060017",
    "type": "physical",
    "description": "Sunglasses with a lens containing no lens powers to correct vision problems.",
    "name": "Sunglasses - Non-prescription"
  },
  {
    "id": "txcd_30060018",
    "type": "physical",
    "description": "A digital wristwatch that provides many other features besides timekeeping. Like a smartphone, a smartwatch has a touchscreen display, which allows you to perform actions by tapping or swiping on the screen. Smartwatches include allow access to apps, similar to apps for smartphones and tablets.",
    "name": "Watches - Smart"
  },
  {
    "id": "txcd_30060020",
    "type": "physical",
    "description": "A piece of clothing, such as a kitchen apron, that you wear over the front of other clothes to keep the clothes clean.",
    "name": "Aprons"
  },
  {
    "id": "txcd_30060021",
    "type": "physical",
    "description": "A triangular or square piece of cloth tied around the head, face or neck for protective or decorative purposes.",
    "name": "Bandannas"
  },
  {
    "id": "txcd_30060101",
    "type": "physical",
    "description": "A small folding case for carrying paper money, credit cards and other flat objects.",
    "name": "Wallets"
  },
  {
    "id": "txcd_30070004",
    "type": "physical",
    "description": "Gloves worn by baseball\/softball participants while hitting.",
    "name": "Baseball Batting Gloves"
  },
  {
    "id": "txcd_30070014",
    "type": "physical",
    "description": "Clothing apparel\/uniforms that are specific to the training and competition of various martial arts.",
    "name": "Martial Arts Attire"
  },
  {
    "id": "txcd_30070015",
    "type": "physical",
    "description": "A helmet designed for used when opertating a motorcycle, atv, snowmobile, etc.",
    "name": "Motorcycle Helmets"
  },
  {
    "id": "txcd_30070022",
    "type": "physical",
    "description": "Athletic uniforms not containing protective padding or gear.",
    "name": "Sport Uniforms"
  },
  {
    "id": "txcd_30070025",
    "type": "physical",
    "description": "A close-fitting garment  covering most of the body worn for warmth in water sports or diving.",
    "name": "Wetsuit"
  },
  {
    "id": "txcd_30070026",
    "type": "physical",
    "description": "An elastic support for the male genitals, worn especially in athletic or other strenuous activity.",
    "name": "Athletic Supporter"
  },
  {
    "id": "txcd_30070028",
    "type": "physical",
    "description": "Vests designed primarily for wear while hunting, but which are also adaptable to everyday use.",
    "name": "Hunting Vests"
  },
  {
    "id": "txcd_30070029",
    "type": "physical",
    "description": "Clothing, footwear, and accessories for wear while hunting, but that are not adaptable to everyday use.",
    "name": "Hunting Activity Clothing"
  },
  {
    "id": "txcd_30090010",
    "type": "physical",
    "description": "Shoelaces.",
    "name": "Shoelaces"
  },
  {
    "id": "txcd_31020001",
    "type": "physical",
    "description": "A piece of cloth or plastic fastened around a baby's neck to keep their clothes clean while eating.",
    "name": "Bibs"
  },
  {
    "id": "txcd_31020003",
    "type": "physical",
    "description": "A small lightweight blanket used to wrap an infant, eg. after bathing.",
    "name": "Infant Receiving Blankets"
  },
  {
    "id": "txcd_33080303",
    "type": "physical",
    "description": "Towels used for individual drying of persons, including bath towels, beach towels, wash cloths, hand towels, facetowels, sport towels, etc.",
    "name": "Bath Towels"
  },
  {
    "id": "txcd_33080306",
    "type": "physical",
    "description": "Dish towels used for kitchenware drying.",
    "name": "Dish Towels"
  },
  {
    "id": "txcd_34020002",
    "type": "physical",
    "description": "A framed display designed to display preloaded digital images (jpeg or any digital image format). Has slots for flash memory cards and\/or an interface for digital photo camera connection.",
    "name": "Digital Picture Frames"
  },
  {
    "id": "txcd_34020003",
    "type": "physical",
    "description": "A portable electronic device for reading digital books and periodicals.",
    "name": "E-Book Readers"
  },
  {
    "id": "txcd_34020004",
    "type": "physical",
    "description": "Wired and bluetooth headphones and earbuds for audio listening.",
    "name": "Headphones\/Earbuds"
  },
  {
    "id": "txcd_34020005",
    "type": "physical",
    "description": "Consumer electronics peripherals and accessories.  Please select a more granular product tax category where appropriate.",
    "name": "Consumer Electronics Peripherals\/Accessories"
  },
  {
    "id": "txcd_34020006",
    "type": "physical",
    "description": "Televisions.",
    "name": "Televisions"
  },
  {
    "id": "txcd_34020027",
    "type": "physical",
    "description": "Electronic devices bought for personal rather than commercial use.  Please select a more granular product tax category where appropriate.",
    "name": "Consumer Electronics"
  },
  {
    "id": "txcd_34021000",
    "type": "physical",
    "description": "A portable device for connecting to a telecommunications network in order to transmit and receive voice, video, or other data.",
    "name": "Mobile Phones"
  },
  {
    "id": "txcd_34021001",
    "type": "physical",
    "description": "A device for recharging the battery of a mobile phone, especially one consisting of a cable connected to an adaptor which plugs into an electrical socket or usb port.",
    "name": "Mobile Phone Charging Device\/cord"
  },
  {
    "id": "txcd_34022000",
    "type": "physical",
    "description": "A specialized computer system designed for interactive video gameplay that typically connects to a television or other monitor.",
    "name": "Video Gaming Console - Fixed"
  },
  {
    "id": "txcd_34022001",
    "type": "physical",
    "description": "A handheld portable electronic device used for playing video games that has its own screen, speakers and controls in one unit.",
    "name": "Video Gaming Console - Portable"
  },
  {
    "id": "txcd_34040001",
    "type": "physical",
    "description": "Typically a lithium-ion remote power source for laptop computers.",
    "name": "Computer Batteries"
  },
  {
    "id": "txcd_34040002",
    "type": "physical",
    "description": "Cables with industry standard connection and termination configurations used to connect various peripherals and equipment to computers.",
    "name": "Computer Cables"
  },
  {
    "id": "txcd_34040003",
    "type": "physical",
    "description": "Storage drives, hard drives, Zip drives, etc.",
    "name": "Computer Drives"
  },
  {
    "id": "txcd_34040009",
    "type": "physical",
    "description": "Computer Speakers.",
    "name": "Computer Speakers"
  },
  {
    "id": "txcd_37071001",
    "type": "digital",
    "description": "A charge, apart from the charge for the software, for an agreement that is not required to be purchased in order to obtain the software.  The agreement entitles the software user to obtain periodic canned software updates, upgrades, and error corrections in electronic form.",
    "name": "Software Maintenance Agreement - Optional, Prewritten, Electronic Delivery, Updates Only"
  },
  {
    "id": "txcd_37071002",
    "type": "digital",
    "description": "A charge, apart from the charge for the software, for an agreement that is not required to be purchased in order to obtain the software.  The agreement entitles the software user to obtain periodic canned software updates, upgrades, and error corrections in electronic form, as well as non-separately stated support sevices, such as consulation or phone support.",
    "name": "Software Maintenance Agreement - Optional, Prewritten, Electronic Delivery, Updates and Services"
  },
  {
    "id": "txcd_37071006",
    "type": "physical",
    "description": "A charge, apart from the charge for the software, for an agreement that is not required to be purchased in order to obtain the software.  The agreement entitles the software user to obtain periodic canned software updates, upgrades, and error corrections in tangible form.",
    "name": "Software Maintenance Agreement - Optional, Prewritten, Tangible Delivery, Updates Only"
  }
]
