<div class="grid">
  <div class="col-12">
    <div class="card">
      <h5>Product Categories</h5>
      <p-table
        class="category-table"
        [value]="isLoading || isLoadingDelete ? [] : productCategories"
        dataKey="name"
        [loading]="isLoading || isLoadingDelete"
        [showLoader]="false"
        responsiveLayout="scroll"
      >
        <ng-template pTemplate="caption">
          <div class="flex table-header justify-content-end pb-3">
            <p-button icon="pi pi-fw pi-plus" label="Create category" outlined (click)="openCategoryEditor()" />
          </div>
        </ng-template>
        <ng-template pTemplate="header">
          <tr>
            <th class="white-space-nowrap" style="width: 15%">ID</th>
            <th class="white-space-nowrap" style="width: 30%">Name</th>
            <th class="white-space-nowrap" style="width: 30%">Slug</th>
            <th class="white-space-nowrap" style="width: 15%">Actions</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-category let-expanded="expanded">
          <tr class="p-14 row-hover">
            <td>{{ category.id }}</td>
            <td>
              {{ category.name }}
            </td>
            <td>
              {{ category.slug }}
            </td>
            <td>
              <div class="flex gap-2">
                <p-button
                  type="button"
                  icon="pi pi-pen-to-square"
                  outlined
                  (click)="openCategoryEditor(category)"
                ></p-button>
                <p-button
                  type="button"
                  icon="pi pi-trash"
                  severity="danger"
                  outlined
                  (click)="openDeleteModal($event, category.id)"
                ></p-button>
              </div>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr>
            <td colspan="4" class="text-center">There are no categories</td>
          </tr>
        </ng-template>
        <ng-template pTemplate="loadingbody">
          @for (row of loadingRows; track $index) {
            <tr>
              <td><p-skeleton width="2rem" /></td>
              <td><p-skeleton width="10rem" /></td>
              <td><p-skeleton width="10rem" /></td>
              <td>
                <div class="flex">
                  <p-skeleton width="3rem" />
                  <p-skeleton width="3rem" />
                </div>
              </td>
            </tr>
          }
        </ng-template>
      </p-table>
    </div>
  </div>
</div>
<p-confirmDialog
  header="Confirmation"
  key="confirmDeleteCategory"
  icon="pi pi-exclamation-triangle"
  [style]="{ width: '28rem' }"
></p-confirmDialog>
