<div class="card">
  <h5>Gift Card Email Templates</h5>
  <p-tabView [(activeIndex)]="activeIndex" (onChange)="onTabChange($event)">
    @for (mail of giftCardCustomMails; track $index) {
      <p-tabPanel [header]="mail.data.template_name">
        <form [formGroup]="form" (ngSubmit)="formSubmit()">
          <div class="grid">
            <div class="col-12 md:col-6">
              <label for="template_name">Template Name</label>
              <input type="text" pInputText class="w-full" formControlName="template_name" />
            </div>
            <div class="col-12 md:col-6">
              <label for="email_subject">Email Subject</label>
              <input type="text" pInputText class="w-full" formControlName="email_subject" />
            </div>
            <div class="col-12 md:col-6">
              <label for="greeting">Greeting</label>
              <input type="text" pInputText class="w-full" formControlName="greeting" />
            </div>
            <div class="col-12 md:col-6">
              <label for="line_1">Line 1</label>
              <input type="text" pInputText class="w-full" formControlName="line_1" />
            </div>
            <div class="col-12 md:col-6">
              <label for="line_2">Line 2</label>
              <input type="text" pInputText class="w-full" formControlName="line_2" />
            </div>
            <div class="col-12 md:col-6">
              <label for="line_3">Line 3</label>
              <input type="text" pInputText class="w-full" formControlName="line_3" />
            </div>
            <div class="col-12 md:col-6">
              <label for="button_text">Button Text</label>
              <input type="text" pInputText class="w-full" formControlName="button_text" />
            </div>
            <div class="col-12 md:col-6">
              <label for="goodbye_message">Goodbye Message</label>
              <input type="text" pInputText class="w-full" formControlName="goodbye_message" />
            </div>
            <div class="col-12 md:col-6">
              <label for="sender">Sender</label>
              <input type="text" pInputText class="w-full" formControlName="sender" />
            </div>
            <div class="col-12 mb-3">
              <p-button [disabled]="isLoading" type="submit" label="Submit" />
            </div>
          </div>
        </form>
        <p-panel
          [collapseIcon]="'pi pi-arrow-down'"
          [expandIcon]="'pi pi-arrow-up'"
          [collapsed]="false"
          [toggleable]="true"
          header="Preview Gift Card Email"
        >
          <div>
            <div class="flex gap-2">
              <h5>
                {{ form.value.email_subject }}
              </h5>
              <i class="pi pi-angle-double-right text-yellow-500 mt-1"></i>
            </div>

            <div class="flex justify-content-between">
              <div class="flex gap-2">
                <i class="pi pi-user text-gray-500 mt-1" style="font-size: 1.5rem"></i>
                <div>
                  <p class="font-bold mb-0">
                    Phil
                    <span>
                      <small class="text-gray-500">{{ '>phil@gmail.com' }}</small>
                    </span>
                  </p>
                  <small class="text-gray-500">
                    to me
                    <i class="pi pi-chevron-down text-gray-500 mt-1" style="font-size: 0.5rem"></i>
                  </small>
                </div>
              </div>
              <div class="flex gap-3">
                <p>15:50 (4 minutes ago)</p>
                <i class="pi pi-star text-gray-500 mt-1"></i>
                <i class="pi pi-undo text-gray-500 mt-1"></i>
                <i class="pi pi-ellipsis-v text-gray-500 mt-1"></i>
              </div>
            </div>

            <div class="flex flex-column gap-3 justify-content-center align-items-center mt-5">
              <img
                [src]="storefront?.logo?.full_path || 'assets/images/img-placeholder.png'"
                [alt]="storefront.name"
                style="object-fit: cover; width: auto; max-height: 120px"
              />
              <h3>{{ form.value.greeting }}, Mark!</h3>
              <p>
                {{ form.value.line_1 }} on
                <strong>{{ storefront.name }}.</strong>
              </p>
              <p>
                {{ form.value.line_2 }}
                <strong>{{ 50 | usdPipe }}.</strong>
              </p>
              <p>{{ form.value.line_3 }}</p>

              <div class="flex justify-content-center">
                <a [href]="storefront.url" class="p-button">{{ form.value.button_text }}</a>
              </div>

              <hr class="w-full" />
              <div>
                <h5>{{ form.value.goodbye_message }}</h5>
                <p class="text-center">
                  <strong>{{ form.value.sender }}</strong>
                </p>
              </div>
            </div>
          </div>
        </p-panel>
      </p-tabPanel>
    }
  </p-tabView>
</div>
