import { CommonModule } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { ConfirmationService, MenuItem } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { MenubarModule } from 'primeng/menubar';
import { TabMenuModule } from 'primeng/tabmenu';

import { StorefrontService } from 'app/_shared/services/storefront.service';
import { ToastService } from 'app/_shared/services/toast.service';

@Component({
  selector: 'app-company',
  imports: [MenubarModule, TabMenuModule, CommonModule, ButtonModule, ConfirmDialogModule],
  providers: [ConfirmationService],
  templateUrl: './settings.component.html'
})
export class SettingsComponent implements OnInit {
  confirmationService = inject(ConfirmationService);
  storefrontService = inject(StorefrontService);
  toastService = inject(ToastService);
  activatedRoute = inject(ActivatedRoute);
  router = inject(Router);
  storefrontId: string;

  menuItems: MenuItem[] = [
    {
      label: 'General',
      routerLink: './general'
    },
    {
      label: 'Communications',
      routerLink: './communications'
    }
  ];

  ngOnInit() {
    this.activatedRoute.params.subscribe(param => {
      this.storefrontId = param.storefrontId ?? '';
    });
  }

  openDeleteModal() {
    this.confirmationService.confirm({
      key: 'confirmDelete',
      message: 'Are you sure you want to delete this store? This item is scheduled for permanent deletion in 10 days.',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.deleteStorefront();
      },
      acceptButtonStyleClass: 'p-button-filled p-button-danger',
      rejectButtonStyleClass: 'p-button-outlined'
    });
  }

  private deleteStorefront() {
    this.storefrontService.deleteStorefront(+this.storefrontId).subscribe({
      next: () => {
        this.toastService.success(
          'This store has been marked for deletion and will be permanently removed in 10 days.'
        );
        this.router.navigate(['../../'], { relativeTo: this.activatedRoute });
      },
      error: err => {
        console.error('Delete action failed:', err);
      }
    });
  }
}
