import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable({ providedIn: 'root' })
export class StripeConnectService extends ApiService {
  routePrefix = `${this.apiUrl}connect/`;

  public getAccountStatus(companyId: number) {
    return this.get(`${this.routePrefix + companyId}/get-connect-status`);
  }

  public createStripeConnectLink(companyId: number) {
    return this.post(`${this.routePrefix + companyId}/generate-connect-link`);
  }

}
