<div class="card m-3 border-1 surface-border" (click)="handleNavigateToStorefront()">
  <div class="flex flex-wrap gap-2 align-items-center justify-content-between mb-2">
    <div class="flex align-items-center">
      <i class="pi pi-tag mr-2"></i>
      <span class="store-type">Type of Store</span>
    </div>
    <app-status-badge [status]="storefront().status" />
  </div>
  <div class="flex flex-column align-items-center text-center mb-3">
    <div class="image-container">
      <img
        [src]="storefront()?.logo?.full_path || 'assets/images/img-placeholder.png'"
        [alt]="storefront().name"
        class="h-full w-full"
        style="object-fit: contain"
      />
    </div>
    <div class="storefront-title">{{ storefront().name }}</div>
    <div class="storefront-location">Los Angeles</div>
    <p-rating [ngModel]="5" [readonly]="true" [cancel]="false"></p-rating>
  </div>
  <div class="card-buttons">
    @if (userCompanyOrSuperAdmin() && storefront().status === StorefrontStatus.INACTIVE) {
      <p-button
        outlined
        class="custom-storefront-button"
        label="Reactive"
        icon="pi pi-file"
        [style]="{ width: '100%', display: 'flex', justifyContent: 'center' }"
        (click)="$event.stopPropagation(); handleReactiveStorefront()"
      />
    }
    <p-button
      outlined
      class="custom-storefront-button"
      label="Edit"
      icon="pi pi-pencil"
      [style]="{ width: '100%', display: 'flex', justifyContent: 'center' }"
      (click)="$event.stopPropagation(); handleEditStorefront()"
    />
    <p-button
      outlined
      class="custom-storefront-button"
      label="Settings"
      icon="pi pi-cog"
      [style]="{ width: '100%', display: 'flex', justifyContent: 'center' }"
      (click)="$event.stopPropagation(); handleStorefrontSettings()"
    />
  </div>
</div>
