import { Component, inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';

import { ButtonModule } from 'primeng/button';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { InputTextModule } from 'primeng/inputtext';

import { ToastService } from 'app/_shared/services/toast.service';

@Component({
    selector: 'app-contact-email-dialog',
    imports: [InputTextModule, ButtonModule, ReactiveFormsModule],
    templateUrl: './contact-email-dialog.component.html'
})
export class ContactEmailDialogComponent implements OnInit {
  dialogConfig = inject(DynamicDialogConfig);
  dialogRef = inject(DynamicDialogRef);
  toastService = inject(ToastService);
  fb = inject(FormBuilder);

  form: FormGroup;
  submitted = false;
  contactEmail: { id: number; email: string };
  editMode: boolean;

  ngOnInit() {
    this.contactEmail = this.dialogConfig.data.contactEmail;
    this.editMode = this.dialogConfig.data.editMode;
    this.buildForm();
  }

  buildForm() {
    this.form = this.fb.group({
      id: [this.contactEmail?.id],
      email: [this.contactEmail?.email, [Validators.email, Validators.required]]
    });
  }

  onSubmit() {
    if (this.form.invalid) {
      this.submitted = true;
      this.toastService.error('Please fill all required fields correctly!');
      return;
    }

    this.updateContactEmails();
  }

  updateContactEmails() {
    this.dialogRef.close(this.form.value);
  }
}
