import { Component, inject, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';

import { CardModule } from 'primeng/card';
import { TableModule } from 'primeng/table';
import { map } from 'rxjs';

import { handleSupplierMapping } from 'app/_shared/helpers/suppliers-helper';
import { SupplierProduct } from 'app/_shared/models/supplier-product.model';
import { PricePipe } from 'app/_shared/pipes/price.pipe';
import { UsdPipe } from 'app/_shared/pipes/usd-currency.pipe';
import { SupplierService } from 'app/_shared/services/supplier.service';

@Component({
  selector: 'app-supplier-product-view',
  standalone: true,
  imports: [CardModule, TableModule],
  templateUrl: './supplier-product-view.component.html',
  styleUrl: './supplier-product-view.component.scss'
})
export class SupplierProductViewComponent implements OnInit {
  supplierService = inject(SupplierService);
  activatedRoute = inject(ActivatedRoute);
  sanitizer = inject(DomSanitizer);
  product: SupplierProduct;
  slug: string;
  id: string;
  sanitizedHtml: SafeHtml;
  details: { label: string; value: string | number | null }[] = [];

  ngOnInit(): void {
    this.slug = this.activatedRoute.snapshot.paramMap.get('slug') ?? '';
    this.id = this.activatedRoute.snapshot.paramMap.get('id') ?? '';

    this.fetchProduct();
  }

  fetchProduct() {
    this.supplierService
      .getSupplierProduct(this.slug, this.id)
      .pipe(
        map(product => {
          return handleSupplierMapping(this.slug, product.data);
        })
      )
      .subscribe(product => {
        this.product = product;
        this.sanitizedHtml = this.sanitizer.bypassSecurityTrustHtml(product.description);
        this.buildDetails();
      });
  }

  guessImagePath(full_path: string) {
    return full_path.replace('/variant', '').replace('_colored', '');
  }

  private buildDetails() {
    const price = new PricePipe().transform(this.product.price);
    const usdPrice = new UsdPipe().transform(price);

    console.log(this.product);

    this.details = [
      { label: 'Brand', value: this.product.brand },
      { label: 'Style Name', value: this.product.style_name },
      { label: 'External ID', value: this.product.external_id },
      { label: 'Price', value: usdPrice },
      { label: 'Price Margin', value: this.product.price_margin + '%' },
      { label: 'Import Status', value: this.product.import_status },
    ];
  }
}
