import { Injectable } from '@angular/core';

import { Company } from '../models/Company';
import { CompanyAction, StorefrontAction } from '../models/access-control-actions';

@Injectable({
  providedIn: 'root'
})
export class AccessControlService {
  private companyPermissionsMap: Record<string, Record<string, boolean>> = {};
  private storefrontPermissionsMap: Record<string, Record<string, boolean>> = {};

  populatePermissionsMap(companies: Company[]): void {
    companies.forEach(company => {
      if (!company) return;

      // Populate company permissions map using CompanyAction enum
      this.companyPermissionsMap[company.id] = {};
      company.permissions.forEach(permission => {
        if (permission.granted) {
          this.companyPermissionsMap[company.id][permission.name] = true;
        }
      });

      // Populate storefront permissions map using StorefrontAction enum
      this.storefrontPermissionsMap[company.id] = {};
      company.storefronts.forEach(storefront => {
        storefront.permissions.forEach(permission => {
          if (permission.granted) {
            this.storefrontPermissionsMap[company.id][permission.name] = true;
          }
        });
      });
    });
  }

  getCompanyPermissionsMap(companyId: string): Record<string, boolean> {
    return this.companyPermissionsMap[companyId] || {};
  }

  getStorefrontPermissionsMap(companyId: string): Record<string, boolean> {
    return this.storefrontPermissionsMap[companyId] || {};
  }

  canPerformCompanyAction(companyId: string, action: CompanyAction): boolean {
    return !!this.companyPermissionsMap[companyId]?.[`${action}.${companyId}`];
  }

  canPerformStorefrontAction(companyId: string, storefrontId: string, action: StorefrontAction): boolean {
    return !!this.storefrontPermissionsMap[companyId]?.[`${action}.${storefrontId}`];
  }
}
