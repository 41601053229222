import { ChangeDetectionStrategy, Component, computed, effect, inject, signal } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

import { MenuItem } from 'primeng/api';
import { Button } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { TabMenuModule } from 'primeng/tabmenu';

import { IframeComponent } from 'app/_shared/components/iframe/iframe.component';
import { DesignerStore } from 'app/_store/designer.store';
import { StorefrontStore } from 'app/_store/storefront.store';
import { MenuListComponent } from 'app/admin/storefront/designer/menu-list/menu-list.component';
import { PagesListComponent } from 'app/admin/storefront/designer/pages-list/pages-list.component';
import { SettingsComponent } from 'app/admin/storefront/designer/settings/settings.component';

@Component({
  selector: 'app-designer',
  imports: [
    ProgressSpinnerModule,
    TabMenuModule,
    CardModule,
    IframeComponent,
    PagesListComponent,
    SettingsComponent,
    MenuListComponent,
    Button
  ],
  templateUrl: './designer.component.html',
  styleUrl: './designer.component.scss',
  providers: [],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DesignerComponent {
  storefrontStore = inject(StorefrontStore);
  designerStore = inject(DesignerStore);
  sanitizer = inject(DomSanitizer);

  selectedStorefront = computed(() => this.storefrontStore.selectedStorefront()!);
  iframeUrl = signal('');
  iframeUrlFormatted: SafeUrl;
  items: MenuItem[] = [{ label: 'Pages' }, { label: 'Menu' }, { label: 'Settings' }];
  activeItem: MenuItem = this.items[0];

  iframeEffect = effect(() => {
    // This effect is on iframeUrl change where we format iframe url to safeUrl and append hash
    const value = this.iframeUrl();
    const isLiveOrDemo = ['live', 'demo'].includes(this.selectedStorefront().status);
    const hash = isLiveOrDemo ? '' : `&hash=${this.selectedStorefront().hash}`;
    if (value) {
      this.iframeUrlFormatted = this.sanitizer.bypassSecurityTrustResourceUrl(`${value}?preview=true${hash}`);
    } else {
      this.iframeUrlFormatted = this.sanitizer.bypassSecurityTrustResourceUrl(
        `${this.selectedStorefront()?.url}/?preview=true${hash}`
      );
    }
  });

  handleTabChange($event: MenuItem) {
    // Set iframe to homepage when tab change
    this.iframeUrl.set(`${this.selectedStorefront()?.url}`);
  }

  applyChanges() {
    const config = this.designerStore.config();
    this.designerStore.saveConfig(this.selectedStorefront().id!, config).subscribe();
  }
}
