import { Injectable } from '@angular/core';

import { ApiService } from 'app/_shared/services/api.service';

@Injectable({ providedIn: 'root' })
export class TransferService extends ApiService {
  routePrefix = `${this.apiUrl}transfers/`;

  public transferMoneyToCompany(data: any) {
    return this.post(`${this.routePrefix}execute`, data);
  }
}
