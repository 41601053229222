import { CommonModule } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { ConfirmationService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DataViewModule } from 'primeng/dataview';
import { DropdownModule } from 'primeng/dropdown';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { InputTextModule } from 'primeng/inputtext';
import { OrderListModule } from 'primeng/orderlist';
import { PickListModule } from 'primeng/picklist';

import { StripeConnectService } from 'app/_shared/services/stripe-connect.service';
import { ToastService } from 'app/_shared/services/toast.service';

interface SelectItem<T = any> {
  label?: string;
  value: T;
  styleClass?: string;
  icon?: string;
  title?: string;
  disabled?: boolean;
}

@Component({
    selector: 'app-stripe-connect',
    imports: [
        CommonModule,
        DataViewModule,
        PickListModule,
        OrderListModule,
        InputTextModule,
        DropdownModule,
        ButtonModule,
        ConfirmDialogModule
    ],
    providers: [ConfirmationService, DialogService],
    templateUrl: './stripe-connect.component.html'
})
export class StripeConnectComponent implements OnInit {
  route = inject(ActivatedRoute);
  stripeConnectService = inject(StripeConnectService);
  confirmationService = inject(ConfirmationService);
  toastService = inject(ToastService);
  dialogService = inject(DialogService);

  dialogRef: DynamicDialogRef | undefined;
  loading = false;

  companyId: number;

  connectStatus: string;

  ngOnInit() {
    const companyId = this.route.snapshot.paramMap.get('id');
    if (!companyId) {
      console.error('Company ID is null.');
      return;
    }
    this.companyId = +companyId;
    this.loadStripeConnectStatus();
  }

  loadStripeConnectStatus(): void {
    this.stripeConnectService.getAccountStatus(this.companyId).subscribe({
      next: res => {
        this.connectStatus = res.data.status;
      },
      error: error => console.error(error)
    });
  }

  getConnectLink(): void {
    this.loading = true;
    this.stripeConnectService.createStripeConnectLink(this.companyId).subscribe({
      next: res => {
        window.location.href = res.data.url;
      },
      error: err => {
        this.loading = false;
        console.error(err);
      }
    });
  }
}
