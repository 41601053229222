import { NgStyle } from '@angular/common';
import { Component, computed, input, model, output } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { environment } from 'environments/environment';
import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { SkeletonModule } from 'primeng/skeleton';
import { Table, TableLazyLoadEvent, TableModule, TableSelectAllChangeEvent } from 'primeng/table';
import { TagModule } from 'primeng/tag';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { TooltipModule } from 'primeng/tooltip';

import { PricePipe } from 'app/_shared/pipes/price.pipe';

export interface ProductPaginatorOptions {
  total: number;
  rows: number;
  first: number;
  globalFilter?: string | string[] | null;
}

export interface HeaderActions {
  icon: string;
  label: string;
  onClick: (event: MouseEvent) => void;
}

export interface ColumnActions {
  icon: string;
  label: string;
  disabled?: (data?: any) => boolean;
  severity?:
    | 'success'
    | 'info'
    | 'warning'
    | 'danger'
    | 'help'
    | 'primary'
    | 'secondary'
    | 'contrast'
    | null
    | undefined;
  onClick: (event: MouseEvent, data?: any) => void;
}

export interface DropdownFilter {
  placeholder: string;
  optionValue: string;
  optionLabel: string;
  options: any[];
  onChange: (event: number) => void;
}

export interface Columns {
  element?: string;
  field: string;
  header: string;
  width?: string;
  cellAction?: (event: MouseEvent, data?: any) => void;
  value?: (data?: any) => any;
  actions?: ColumnActions[];
  dropdownFilters?: DropdownFilter[];
}

@Component({
  selector: 'app-list-products',
  imports: [
    TableModule,
    InputTextModule,
    ButtonModule,
    NgStyle,
    SkeletonModule,
    TooltipModule,
    TagModule,
    DropdownModule,
    PricePipe,
    ToggleButtonModule,
    FormsModule
  ],
  templateUrl: './list-products.component.html',
  styleUrl: './list-products.component.scss',
  providers: []
})
export class ListProductsComponent<T> {
  protected storageUrl = environment.STORAGE_URL;
  products = input.required<T[]>();
  paginatorOptions = input.required<ProductPaginatorOptions>();
  totalProducts = computed(() => this.paginatorOptions().total);
  columns = input.required<Columns[]>();
  loading = input.required<boolean>();
  headerActions = input<HeaderActions[]>();
  dropdownFilters = input<DropdownFilter[]>();
  multiSelect = input<boolean>();
  emptyMessage = input<string>();
  selectedItems = model<T[] | ['allSelected']>();
  allSelected: boolean;
  pageSelected: boolean;
  onSelectionChange = output<T[] | 'allSelected'>();
  lazyLoadProducts = output<TableLazyLoadEvent>();
  loadingRows = Array(3)
    .fill(0)
    .map((_, index) => {
      return index + 1;
    });

  onGlobalFilter(table: Table, event: Event) {
    table.filterGlobal((event.target as HTMLInputElement).value, 'contains');
  }

  loadProducts($event: TableLazyLoadEvent) {
    this.lazyLoadProducts.emit($event);
  }

  handleSelectionChange($event: any) {
    this.onSelectionChange.emit($event);
    this.allSelected = false;
    this.pageSelected = false;
  }

  handleSelectAllChange($event: TableSelectAllChangeEvent) {
    this.pageSelected = $event.checked;
  }

  handleAllProductsSelect() {
    this.allSelected = true;
    this.onSelectionChange.emit('allSelected');
  }
}
