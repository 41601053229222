<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <div class="flex flex-column gap-3 pt-3">
    <app-input
      name="Side"
      label="Side"
      [control]="form.controls.side"
      placeholder="Side name"
      [fullWidth]="true"
    ></app-input>
    <div class="custom-upload">
      <p-fileUpload
        #fileUploader
        name="logo-uploader"
        [multiple]="false"
        [customUpload]="true"
        (onSelect)="onImageUpload($event)"
        accept="image/*"
        maxFileSize="1000000"
        showUploadButton="false"
        styleClass="border-1 surface-border surface-card p-0 border-round"
      >
        <ng-template pTemplate="content">
          <div class="min-h-10 m-1 border-round py-4">
            @if (!form.get('full_path')?.value) {
              <div
                class="flex flex-column w-full h-full justify-content-center align-items-center cursor-pointer"
                (click)="fileUploader.advancedFileInput.nativeElement.click()"
              >
                <i class="pi pi-fw pi-file text-4xl text-primary"></i>
                <span class="block font-semibold text-900 text-lg mt-3">Drop or select new Variant image</span>
              </div>
            } @else {
              <div class="flex justify-content-center align-items-center h-full w-full">
                <img
                  [src]="form.get('full_path')?.value"
                  height="80"
                  alt="Storefront logo"
                  style="object-fit: contain"
                />
                <button
                  pButton
                  type="button"
                  aria-label="remove"
                  icon="pi pi-times"
                  class="p-button-rounded p-button-primary text-sm absolute justify-content-center align-items-center"
                  style="top: 10px; right: 10px"
                  (click)="$event.stopPropagation(); removeImage()"
                ></button>
              </div>
            }
          </div>
        </ng-template>
      </p-fileUpload>
      @if (form.get('full_path')?.invalid && (form.get('full_path')?.dirty || form.get('full_path')?.touched)) {
        <div>
          <small class="p-text-danger">Image is required</small>
        </div>
      }
    </div>
    <div class="flex justify-content-end gap-1 pt-3">
      <p-button outlined type="button" label="Cancel" (onClick)="closeDialog()"></p-button>
      <p-button type="submit" label="Submit"></p-button>
    </div>
  </div>
</form>
