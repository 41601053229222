import { Component, inject, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { ConfirmationService, TreeNode } from 'primeng/api';
import { Button } from 'primeng/button';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { TreeModule } from 'primeng/tree';

import { StorefrontService } from 'app/_shared/services/storefront.service';
import { ToastService } from 'app/_shared/services/toast.service';

interface TreeNodeWithId extends TreeNode {
  id?: string;
  locked?: 0 | 1;
}

@Component({
  selector: 'app-storage-files-list',
  imports: [Button, TreeModule, ConfirmDialogModule],
  templateUrl: './storage-files-list.component.html',
  providers: [ConfirmationService, ToastService],
  styleUrl: './storage-files-list.component.scss'
})
export class StorageFilesListComponent implements OnInit {
  private storefrontService = inject(StorefrontService);
  private activatedRoute = inject(ActivatedRoute);
  private confirmationService = inject(ConfirmationService);
  private toastService = inject(ToastService);

  files: TreeNode[] = [];
  storefrontId: string;
  selectedFiles!: TreeNodeWithId[];

  ngOnInit() {
    this.activatedRoute.params.subscribe(param => {
      this.storefrontId = param.storefrontId;
    });
    this.getFiles();
  }

  getFiles() {
    this.storefrontService.getStorefrontStorageFiles(this.storefrontId).subscribe(next => {
      this.files = this.transformToTree(next.data);
    });
  }

  expandAll() {
    this.files.forEach(node => {
      this.expandRecursive(node, true);
    });
  }

  collapseAll() {
    this.files.forEach(node => {
      this.expandRecursive(node, false);
    });
  }

  private expandRecursive(node: TreeNode, isExpand: boolean) {
    node.expanded = isExpand;
    if (node.children) {
      node.children.forEach(childNode => {
        this.expandRecursive(childNode, isExpand);
      });
    }
  }

  transformToTree(data: any, keyPrefix: string = '0'): TreeNodeWithId[] {
    const tree: TreeNodeWithId[] = [];

    data.forEach((item, index) => {
      const currentKey = `${keyPrefix}-${index}`;

      // If the item is a file, add it to the tree
      if (item.file) {
        tree.push({
          key: currentKey,
          label: item.file.name,
          data: item.file.full_path,
          id: item.file.id,
          locked: item.file.locked,
          expanded: true,
          icon: 'pi pi-fw pi-file'
        });
      }

      // If the item has a name and children, process it as a folder
      if (item.name && item.child) {
        tree.push({
          key: currentKey,
          label: item.name,
          data: `Folder containing ${item.child.length} items`,
          icon: 'pi pi-fw pi-folder',
          expanded: true,
          children: this.transformToTree(item.child, currentKey) // Recursive call for children
        });
      }
    });

    return tree;
  }

  private removeItemsFromTree(nodes: TreeNodeWithId[], itemsToDelete: TreeNodeWithId[]): TreeNodeWithId[] {
    return nodes
      .map(node => {
        if (itemsToDelete.includes(node)) {
          return null; // Remove this node
        }
        if (node.children) {
          node.children = this.removeItemsFromTree(node.children, itemsToDelete);
        }
        return node;
      })
      .filter(node => node !== null) as TreeNodeWithId[];
  }

  deleteFiles(batchDelete = true, file?: TreeNodeWithId) {
    this.confirmationService.confirm({
      key: 'confirmDelete',
      message: 'Are you sure you want to delete this files',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        const files = this.selectedFiles.filter(file => file.id);
        const ids = batchDelete ? files.map(item => item.id!).filter(Boolean) : [file!.id as string];
        const filesToDelete = batchDelete ? this.selectedFiles : ([file] as TreeNodeWithId[]);
        this.storefrontService.removeFiles(this.storefrontId, ids).subscribe(next => {
          this.files = this.removeItemsFromTree(this.files, filesToDelete);

          this.selectedFiles = this.selectedFiles.filter(item => !filesToDelete.includes(item));
        });
      }
    });
  }

  lockUnlockFiles(batchLock: boolean, lock: 0 | 1, file?: TreeNodeWithId) {
    const files = this.selectedFiles.filter(file => file.id);
    const ids = batchLock ? files.map(item => item.id!).filter(Boolean) : [file!.id as string];
    this.storefrontService.updateFiles(this.storefrontId, ids, lock).subscribe(next => {
      this.toastService.success('Filles ' + (lock === 0 ? 'unlocked' : 'locked') + ' succesfully');
    });
  }
}
