<form [formGroup]="uploadFileForm" (ngSubmit)="onSubmit()">
  <div class="grid">
    @if (this.library()) {
      <div class="col-12 flex justify-content-end">
        <p-button outlined icon="pi pi-download" label="Download Files" (click)="downloadLibraryFiles()" />
      </div>
    }
    <div class="col-12">
      <p>You need to upload one .png file which will be used as product thumbnail</p>
    </div>
    <div class="col-12 custom-upload">
      <p-fileUpload
        #thumbnailUploader
        [multiple]="false"
        [customUpload]="true"
        (onSelect)="onThumbnailUpload($event)"
        accept=".png"
        maxFileSize="1000000"
        showUploadButton="false"
        customUpload="true"
        showUploadButton="false"
        styleClass="border-1 surface-border surface-card p-0 border-round"
      >
        <ng-template pTemplate="file"></ng-template>
        <ng-template pTemplate="content">
          <div class="min-h-10 m-1 border-round py-4">
            @if (!libraryThumbnail?.objectURL) {
              <div
                class="flex flex-column w-full h-full justify-content-center align-items-center cursor-pointer"
                (click)="thumbnailUploader.advancedFileInput.nativeElement.click()"
              >
                <i class="pi pi-fw pi-file text-4xl text-primary"></i>
                <span class="block font-semibold text-900 text-lg mt-3">Drop or select Thumbnail</span>
              </div>
            }
            @if (libraryThumbnail?.objectURL) {
              <div class="flex justify-content-center align-items-center h-full w-full">
                <img
                  [src]="this.libraryThumbnail?.objectURL"
                  height="80"
                  alt="Library thumbnail"
                  style="object-fit: contain"
                />
                <button
                  pButton
                  type="button"
                  aria-label="remove"
                  icon="pi pi-times"
                  class="p-button-rounded p-button-primary text-sm absolute justify-content-center align-items-center"
                  style="top: 10px; right: 10px"
                  (click)="$event.stopPropagation(); removeThumbnail()"
                ></button>
              </div>
            }
          </div>
        </ng-template>
      </p-fileUpload>
    </div>
    <div class="col-12 sm:col-6">
      <label for="country">Design folder</label>
      <p-dropdown
        [options]="storefrontLibraryFolders()"
        optionValue="id"
        optionLabel="name"
        placeholder="Select a folder"
        [style]="{ width: '100%' }"
        formControlName="library_folder_id"
      />
      @if (uploadFileForm.get('library_folder_id')?.touched && uploadFileForm.get('library_folder_id')?.invalid) {
        <div>
          <small class="p-text-danger">Design folder is required</small>
        </div>
      }
    </div>
    <div class="col-12 sm:col-6">
      <label for="design_name">Design File Name</label>
      <input formControlName="name" type="text" pInputText class="w-full" />
      @if (uploadFileForm.get('name')?.touched && uploadFileForm.get('name')?.invalid) {
        <div>
          <small class="p-text-danger">File name is required</small>
        </div>
      }
    </div>
    @if (showRequestPrepButton()) {
      <div class="col-12">
        <p-button
          outlined
          label="Create File Prep Request"
          type="button"
          icon="pi pi-file"
          (click)="openLibraryFilePrepRequestDialog()"
        />
      </div>
    }

    <div class="col-12 custom-upload">
      <p>
        Provide all supporting files to the main mockup file in order to print the design in every decoration method
        available. Embroidery (.dst + sequence sheet .pdf (optional) + .emb (optional))
      </p>
      <p-fileUpload
        #additionalFileUploader
        [multiple]="true"
        [customUpload]="true"
        (onSelect)="onAdditionalFilesUpload($event)"
        maxFileSize="1000000"
        showUploadButton="false"
        customUpload="true"
        showUploadButton="false"
        accept=".dst,.emb,.pdf,.eps,.ai"
        styleClass="border-1 surface-border surface-card p-0 border-round"
      >
        <ng-template pTemplate="file"></ng-template>
        <ng-template pTemplate="content">
          <div class="min-h-10 m-1 border-round py-4">
            @if (!additionaFiles.length) {
              <div
                class="flex flex-column w-full h-full justify-content-center align-items-center cursor-pointer"
                (click)="additionalFileUploader.advancedFileInput.nativeElement.click()"
              >
                <i class="pi pi-fw pi-file text-4xl text-primary"></i>
                <span class="block font-semibold text-900 text-lg mt-3">Drop or select files</span>
              </div>
            }
            @if (additionaFiles.length) {
              <div class="flex flex-wrap gap-5">
                @for (additionalItem of additionaFiles; track $index) {
                  <p-chip
                    styleClass="mr-2 py-2 px-3 text-900 font-bold surface-card border-1 surface-border"
                    [style]="{ 'border-radius': '20px' }"
                    onclick=""
                  >
                    <span class="mr-3">{{ additionalItem.name }}</span>
                    <span
                      class="chip-remove-icon flex align-items-center justify-content-center border-1 surface-border bg-gray-100 border-circle cursor-pointer"
                      (click)="onAdditionalFileRemove($index)"
                    >
                      <i class="pi pi-fw pi-times text-black-alpha-60"></i>
                    </span>
                  </p-chip>
                }
              </div>
            }
          </div>
        </ng-template>
      </p-fileUpload>
    </div>

    <div class="col-12">
      <div class="flex justify-content-end">
        <p-button [disabled]="isLoading()" label="Save & Close" type="submit" />
      </div>
    </div>
  </div>
</form>
