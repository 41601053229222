import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { IApiPaginatedData } from 'app/_shared/interfaces/IApiPaginatedData';
import { IApiResponse } from 'app/_shared/interfaces/IApiResponse';
import { Supplier } from 'app/_shared/interfaces/supplier';

import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class SupplierService extends ApiService {
  routePrefix = `${this.apiUrl}suppliers`;

  public getSuppliers() {
    return this.get<Supplier[]>(`${this.routePrefix}`);
  }

  public getSupplierColors() {
    return this.get<Supplier[]>(`${this.routePrefix}`, {
      params: { 'relations[]': 'supplierColors' }
    });
  }

  public importSupplierProduct(supplierSlug: string, productId: string) {
    return this.get(`${this.routePrefix}/${supplierSlug}/import/${productId}`);
  }

  public fetchProductDetails(supplierSlug: string, productId: string) {
    return this.get(`${this.routePrefix}/${supplierSlug}/get-product/${productId}`);
  }

  public getSupplierProducts(supplierSlug: string, params: any): Observable<IApiResponse<IApiPaginatedData>> {
    return this.get(`${this.routePrefix}/${supplierSlug}/products`, { params });
  }

  public getSupplierProduct(supplierSlug: string, productId: string): Observable<any> {
    return this.get(`${this.routePrefix}/${supplierSlug}/products/${productId}`, {
      params: {
        'relations[]': [
          'featuredImage',
          'supplier',
          'supplierCategories',
          'supplierProductVariants.supplierVariantSizes',
          'supplierProductVariants.frontImage',
          'supplierProductVariants.rearImage',
          'supplierProductVariants.sideImage',
          'supplierProductVariants.media'
        ]
      }
    });
  }

  public getSupplierBySlug(slug: string) {
    return this.get<Supplier>(`${this.routePrefix}/get-by-slug`, {
      params: { slug }
    });
  }

  public updateSupplier(id: number, data: any) {
    return this.patch<Supplier>(`${this.routePrefix}/${id}`, data);
  }
}
