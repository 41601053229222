<div class="grid">
  <div class="col-12">
    <div class="card">
      <div class="flex justify-content-between">
        <p class="custom-page-title">Storefronts</p>
        <div>
          <p-button (click)="handleEditStorefront()" label="Create new Storefront" outlined icon="pi pi-plus" />
        </div>
      </div>

      <p-dataView
        #dv
        [value]="storefronts()"
        [paginator]="true"
        [rows]="paginatorOptions().rows"
        [totalRecords]="paginatorOptions().total"
        [first]="paginatorOptions().first"
        [lazy]="true"
        filterBy="name"
        layout="grid"
        (onLazyLoad)="loadStorefronts($event)"
      >
        <ng-template pTemplate="header">
          <div class="flex flex-column md:flex-row md:justify-content-between gap-2">
            <div class="flex gap-3">
              <span class="p-input-icon-left mb-2">
                <i class="pi pi-search"></i>
                <input
                  pInputText
                  type="text"
                  #filter
                  [ngModel]="globalFilter()"
                  placeholder="Search"
                  class="lg:w-full"
                  (input)="onFilterChange('global_filter', filter.value)"
                />
              </span>
              <p-dropdown
                [options]="storefrontStatusOptions"
                placeholder="Status"
                optionLabel="label"
                optionValue="value"
                [showClear]="true"
                (onChange)="onFilterChange('status', $event.value)"
                [ngModel]="selectedStatus()"
              >
                <ng-template let-option>
                  <div class="p-multiselect-representative-option">
                    <span class="ml-2">{{ option.name }}</span>
                  </div>
                </ng-template>
              </p-dropdown>
            </div>
            <p-dataViewLayoutOptions></p-dataViewLayoutOptions>
          </div>
        </ng-template>
        <ng-template let-products pTemplate="gridItem">
          <div class="grid grid-nogutter">
            @for (storefront of storefronts(); track storefront) {
              <div class="col-12 sm:col-6 md:col-4 lg:col-3">
                <app-storefront-card
                  [storefront]="storefront"
                  [userCompanyOrSuperAdmin]="userCompanyOrSuperAdmin()"
                  (editStorefront)="handleEditStorefront($event)"
                  (openStorefrontSettings)="handleStorefrontSettings($event)"
                  (openStorefront)="openStorefrontInNewTab($event)"
                  (reactiveStorefront)="handleReactiveStorefront($event)"
                />
              </div>
            }
          </div>
        </ng-template>
        <ng-template let-products pTemplate="listItem">
          <p-table [value]="storefronts()">
            <ng-template pTemplate="header">
              <tr>
                <th>Image</th>
                <th>Name</th>
                <th>URL</th>
                <th>Status</th>
                <th>Created</th>
                <th>Actions</th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-storefront>
              <tr>
                <td>
                  <img
                    [src]="storefront?.logo?.full_path"
                    [alt]="storefront.name"
                    style="width: 50px; height: 50px; object-fit: cover"
                  />
                </td>
                <td>{{ storefront.name }}</td>
                <td>{{ storefront.url }}</td>
                <td><app-status-badge [status]="storefront.status" /></td>
                <td>{{ storefront.created_at | usdDate }}</td>
                <td>
                  <p-splitButton
                    outlined
                    label="View"
                    [model]="splitButtonItemsMap()[storefront.id]"
                    (onClick)="openStorefrontInNewTab(storefront)"
                  />
                </td>
              </tr>
            </ng-template>
            <ng-template pTemplate="loadingbody">
              @for (row of loadingRows; track row) {
                <tr>
                  @for (col of ['image', 'name', 'url', 'status', 'created', 'actions']; track col) {
                    <td>
                      <p-skeleton />
                    </td>
                  }
                </tr>
              }
            </ng-template>
          </p-table>
        </ng-template>
      </p-dataView>
    </div>
  </div>
</div>
