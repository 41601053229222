import { Component, inject, input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { MessageService, TreeNode } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { Editor, EditorModule } from 'primeng/editor';
import { FileUploadModule } from 'primeng/fileupload';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { MultiSelectModule } from 'primeng/multiselect';
import { SelectButtonModule } from 'primeng/selectbutton';
import { TabMenuModule } from 'primeng/tabmenu';
import { TreeSelectModule } from 'primeng/treeselect';
import { map } from 'rxjs';

import { VariantsListComponent } from 'app/_shared/components/catalog-product-form/variants-list/variants-list.component';
import { InputComponent } from 'app/_shared/components/input/input.component';
import { handleSupplierMapping } from 'app/_shared/helpers/suppliers-helper';
import {
  CatalogProduct,
  CatalogProductConfig,
  CatalogProductVariant,
  CatalogVariantSize,
  DecorationMethod
} from 'app/_shared/interfaces/catalog';
import { CatalogProductCategory } from 'app/_shared/models/CatalogProductCategory';
import { PricePipe } from 'app/_shared/pipes/price.pipe';
import { CatalogProductCategoryService } from 'app/_shared/services/catalog-product-category.service';
import { CatalogService } from 'app/_shared/services/catalog.service';
import { ColorDictionaryService } from 'app/_shared/services/color-dictionary.service';
import { DecorationMethodService } from 'app/_shared/services/decoration-method.service';
import { SupplierService } from 'app/_shared/services/supplier.service';

enum Tabs {
  INFO = 'Info',
  VARIANTS = 'Variants'
}

@Component({
  selector: 'app-catalog-product-form',
  imports: [
    ButtonModule,
    CardModule,
    InputComponent,
    ReactiveFormsModule,
    TabMenuModule,
    VariantsListComponent,
    DropdownModule,
    InputTextareaModule,
    FileUploadModule,
    EditorModule,
    DialogModule,
    PricePipe,
    SelectButtonModule,
    MultiSelectModule,
    TreeSelectModule,
    FormsModule
  ],
  providers: [DynamicDialogConfig, ColorDictionaryService],
  templateUrl: './catalog-product-form.component.html',
  styleUrl: './catalog-product-form.component.scss'
})
export class CatalogProductFormComponent implements OnInit {
  @ViewChild('editor') editor: Editor;
  type = input.required<'create' | 'edit'>();
  redirectTo = input.required<string>();
  private router = inject(Router);
  private activatedRoute = inject(ActivatedRoute);
  private supplierService = inject(SupplierService);
  private catalogService = inject(CatalogService);
  private categoryService = inject(CatalogProductCategoryService);
  private decorationMethodService = inject(DecorationMethodService);
  private fb = inject(FormBuilder);
  private messageService = inject(MessageService);
  private dynamicConfig = inject(DynamicDialogConfig);

  protected readonly Tabs = Tabs;
  isSubmitting: boolean;
  slug: string;
  id: string;
  supplierPrintPrice: number;
  supplierPriceMargin: string;
  form: FormGroup;
  catalogProduct: CatalogProduct;
  catalogProducts: CatalogProduct[];
  productVariants: CatalogProductVariant[];
  deletedVariants: number[] = [];
  deletedCatalogProductMedias: number[] = [];
  items = [
    { label: Tabs.INFO, icon: 'pi pi-fw pi-pencil' },
    { label: Tabs.VARIANTS, icon: 'pi pi-fw pi-calendar' }
  ];
  decorationMethodOptions: DecorationMethod[] = [];
  activeItem = this.items[0];
  productCategories;
  selectedCategories;
  selectedNodes;

  nodes = {
    data: [
      {
        key: '0',
        label: 'Documents',
        data: 'Documents Folder',
        icon: 'pi pi-fw pi-inbox',
        children: [
          {
            key: '0-0',
            label: 'Work',
            data: 'Work Folder',
            icon: 'pi pi-fw pi-cog',
            children: [
              { key: '0-0-0', label: 'Expenses.doc', icon: 'pi pi-fw pi-file', data: 'Expenses Document' },
              { key: '0-0-1', label: 'Resume.doc', icon: 'pi pi-fw pi-file', data: 'Resume Document' }
            ]
          },
          {
            key: '0-1',
            label: 'Home',
            data: 'Home Folder',
            icon: 'pi pi-fw pi-home',
            children: [
              { key: '0-1-0', label: 'Invoices.txt', icon: 'pi pi-fw pi-file', data: 'Invoices for this month' }
            ]
          }
        ]
      },
      {
        key: '1',
        label: 'Events',
        data: 'Events Folder',
        icon: 'pi pi-fw pi-calendar',
        children: [
          { key: '1-0', label: 'Meeting', icon: 'pi pi-fw pi-calendar-plus', data: 'Meeting' },
          { key: '1-1', label: 'Product Launch', icon: 'pi pi-fw pi-calendar-plus', data: 'Product Launch' },
          { key: '1-2', label: 'Report Review', icon: 'pi pi-fw pi-calendar-plus', data: 'Report Review' }
        ]
      },
      {
        key: '2',
        label: 'Movies',
        data: 'Movies Folder',
        icon: 'pi pi-fw pi-star',
        children: [
          {
            key: '2-0',
            icon: 'pi pi-fw pi-star',
            label: 'Al Pacino',
            data: 'Pacino Movies',
            children: [
              { key: '2-0-0', label: 'Scarface', icon: 'pi pi-fw pi-video', data: 'Scarface Movie' },
              { key: '2-0-1', label: 'Serpico', icon: 'pi pi-fw pi-video', data: 'Serpico Movie' }
            ]
          },
          {
            key: '2-1',
            label: 'Robert De Niro',
            icon: 'pi pi-fw pi-star',
            data: 'De Niro Movies',
            children: [
              { key: '2-1-0', label: 'Goodfellas', icon: 'pi pi-fw pi-video', data: 'Goodfellas Movie' },
              { key: '2-1-1', label: 'Untouchables', icon: 'pi pi-fw pi-video', data: 'Untouchables Movie' }
            ]
          }
        ]
      }
    ]
  };

  ngOnInit(): void {
    this.slug = this.activatedRoute.snapshot.paramMap.get('slug') ?? '';
    this.id = this.activatedRoute.snapshot.paramMap.get('id') ?? '';
    this.fetchCatalogCategories();
    this.fetchDecorationMethods();
    this.fetchCatalogProducts();
    this.fetchProduct();
  }

  buildForm(product: CatalogProduct) {
    const {
      name,
      brand,
      description,
      similar_product_id,
      companion_product_id,
      decoration_methods,
      style_name,
      catalog_product_configuration: {
        short_name,
        product_price,
        print_price,
        print_price_second,
        weight,
        moq
      } = {} as CatalogProductConfig
    } = product;

    const decorationMethodIds = decoration_methods?.map(method => method.id) || [this.decorationMethodOptions[0]?.id];
    const hasStyleNumberInName = name?.includes(style_name as string);
    const hasBrandInName = name?.includes(brand as string);
    let fullName;
    let shortName;

    if (this.type() !== 'edit') {
      if (hasStyleNumberInName && hasBrandInName) {
        fullName = name;
        shortName = name?.replace(style_name as string, '').trim();
      } else if (hasStyleNumberInName && !hasBrandInName) {
        fullName = `${brand} ${name}`;
        shortName = `${brand} ${name?.replace(style_name as string, '').trim()}`;
      } else if (!hasStyleNumberInName && hasBrandInName) {
        fullName = `${name} ${style_name}`;
        shortName = name;
      } else {
        fullName = `${brand} ${name} ${style_name}`;
        shortName = `${brand} ${name}`;
      }
    } else {
      fullName = name;
      shortName = short_name;
    }
    this.form = this.fb.group({
      name: new FormControl(fullName ?? '', [Validators.required]),
      brand: new FormControl(brand ?? ''),
      style_name: new FormControl(style_name ?? ''),
      description: new FormControl(description ?? ''),
      similar_product_id: new FormControl(similar_product_id ?? ''),
      companion_product_id: new FormControl(companion_product_id ?? ''),
      decoration_methods: new FormControl(decorationMethodIds ?? [], [Validators.required]),
      // catalog_category_ids: new FormControl(categoryIds ?? ''),
      catalog_product_configuration: new FormGroup({
        short_name: new FormControl(shortName ?? ''),
        product_price: new FormControl((product_price / 100).toFixed(2) ?? 0, [Validators.required]),
        print_price: new FormControl((print_price ? print_price / 100 : 9.5).toFixed(2)),
        print_price_second: new FormControl((print_price_second ? print_price_second / 100 : 8.5).toFixed(2)),
        moq: new FormControl(moq ?? 1)
      })
    });
  }

  handleBack() {
    this.router.navigate([this.redirectTo()]);
  }

  preselectCategories(tree: any[], ids: number[]): any[] {
    const selectedNodes: any[] = [];

    const traverse = (nodes: any[]): void => {
      for (const node of nodes) {
        if (ids.includes(node.data)) {
          selectedNodes.push(node); // Add node to selected
        }
        if (node.children) {
          traverse(node.children); // Recursively check children
        }
      }
    };

    traverse(tree);
    return selectedNodes;
  }

  fetchCatalogCategories() {}

  transformToTreeSelectFormat(categories: CatalogProductCategory[], parentKey: string = '0'): TreeNode[] {
    return categories.map((category, index) => {
      const currentKey = `${parentKey}-${index}`;
      const hasSubCategories = category.sub_categories && category.sub_categories.length > 0;

      const transformed: TreeNode = {
        key: currentKey,
        label: category.name,
        data: category.id,
        icon: hasSubCategories ? 'pi pi-fw pi-folder' : 'pi pi-fw pi-file'
      };

      if (hasSubCategories) {
        transformed.children = this.transformToTreeSelectFormat(category.sub_categories, currentKey);
      }

      return transformed;
    });
  }

  fetchDecorationMethods() {
    this.decorationMethodService.index().subscribe({
      next: res => {
        this.decorationMethodOptions = res.data;
      },
      error: error => error(error)
    });
  }

  fetchCatalogProducts() {
    const params = {
      rows: 10,
      page: 1,
      'relations[]': ['catalogProductConfiguration', 'featuredImage']
    };
    this.catalogService.getProducts(params).subscribe({
      next: res => {
        this.catalogProducts = res.data.data;
      },
      error: error => console.error(error)
    });
  }

  fetchProduct() {
    this.handleFetchProduct().subscribe(product => {
      // Get categories
      this.categoryService.index().subscribe({
        next: res => {
          this.productCategories = this.transformToTreeSelectFormat(res.data);
          const categoryIds = product.admin_categories?.map(category => category.id) ?? [];
          this.selectedCategories = this.preselectCategories(this.productCategories, categoryIds);
        },
        error: error => console.error(error)
      });
      this.catalogProduct = product;
      this.productVariants = product.catalog_product_variants.map(variant => {
        return {
          ...variant,
          catalog_variant_sizes: variant.catalog_variant_sizes.map(size => {
            return { ...size, total: (+size.total / 100).toString() } as CatalogVariantSize;
          })
        } as CatalogProductVariant;
      });
      this.buildForm(product);
    });
  }

  get supplierCategoriesNames(): string {
    return this.catalogProduct?.catalogable?.supplier_categories?.map(asd => asd.name).join(', ') || 'none';
  }

  handleFetchProduct() {
    if (this.type() === 'create') {
      return this.supplierService.getSupplierProduct(this.slug, this.id).pipe(
        map(product => {
          // Map product to SupplierProduct type
          return handleSupplierMapping(this.slug, product.data);
        }),
        map(supplierProduct => {
          // Map SupplierProduct to CatalogProduct
          const {
            name,
            price,
            supplierId,
            id,
            image,
            product_variants,
            description,
            price_margin,
            brand,
            external_id,
            supplier_categories,
            style_name
          } = supplierProduct;
          const marginPrice = price_margin ? price * (1 + +price_margin / 100) : price;
          this.supplierPrintPrice = +price;
          this.supplierPriceMargin = price_margin;
          // TODO: implement function that handles mapping supplier product to catalog product
          // @ts-ignore
          return {
            name,
            brand,
            style_name: style_name ?? external_id,
            description,
            supplier_id: supplierId,
            supplier_product_id: id,
            featured_image: image,
            catalogable: {
              supplier_categories
            },
            catalog_product_variants: product_variants?.map(variant => ({
              id: undefined,
              color: variant.color,
              supplier_product_variant_id: variant.id,
              catalog_product_medias: variant.media.map(media => {
                return {
                  color: media.color,
                  full_path: media.full_path,
                  id: media.id,
                  name: media.name,
                  side: media.side,
                  supplier_media_id: media.id,
                  supplier_media: {
                    full_path: media.full_path,
                    id: media.id,
                    side: media.side,
                    name: media.name
                  }
                };
              }),
              catalog_variant_sizes: variant.supplier_variant_sizes.map(
                size =>
                  ({
                    id: undefined,
                    quantity: size.quantity,
                    size: size.size,
                    weight: size.weight,
                    total: size.total,
                    supplier_variant_size_id: size.id
                  }) as CatalogVariantSize
              ),
              supplier_product_variant: {
                color: variant.color
              }
            })),
            catalog_product_configuration: {
              product_price: +marginPrice.toFixed(2)
            }
          } as CatalogProduct;
        })
      );
    } else {
      return this.catalogService.getProductForCatalogProductCreator(this.id).pipe(map(data => data.data));
    }
  }

  submit() {
    if (this.form.valid) {
      this.isSubmitting = true;
      this.handleSaveProduct().subscribe({
        next: () => {
          this.messageService.add({
            severity: 'success',
            summary: 'Successful',
            detail: `Product ${this.type() === 'create' ? 'created' : 'updated'}`,
            life: 3000
          });
          this.router.navigate([this.redirectTo()]);
        },
        error: () => (this.isSubmitting = false),
        complete: () => (this.isSubmitting = false)
      });
    }
  }

  handleSaveProduct() {
    const product: CatalogProduct = {
      ...this.form.value,
      catalog_category_ids: this.selectedCategories.map(selected => selected.data),
      catalog_product_configuration: {
        ...this.form.value.catalog_product_configuration,
        product_price: this.form.value.catalog_product_configuration.product_price * 100,
        print_price: this.form.value.catalog_product_configuration.print_price * 100,
        print_price_second: this.form.value.catalog_product_configuration.print_price_second * 100
      },
      supplier_id: this.catalogProduct.supplier_id as number,
      supplier_product_id: this.catalogProduct.supplier_product_id as number,
      print_areas: [],
      ...(this.productVariants.length > 0
        ? { catalog_product_variants: this.prepareMediaData(this.productVariants) }
        : {})
    };

    if (!product.size_guide) {
      delete product.size_guide;
    }

    if (!product.size_guide_timeline) {
      delete product.size_guide_timeline;
    }

    if (this.type() === 'edit') {
      product.deleted_variants = this.deletedVariants;
      product.deleted_catalog_product_medias = this.deletedCatalogProductMedias;
      return this.catalogService.editProduct(product, this.catalogProduct.id);
    }
    return this.catalogService.createProduct(product);
  }

  prepareMediaData(productVariants: CatalogProductVariant[]) {
    return productVariants.map(variant => {
      const mediasArray: any[] = [];
      variant.catalog_product_medias.forEach(image => {
        if (!image.id) {
          mediasArray.push({ image: image.full_path, color: image.color, side: image.side });
          return;
        }
        if (image.id && this.type() === 'edit') {
          mediasArray.push({ id: image.id });
          return;
        } else if (this.type() === 'create') {
          mediasArray.push({ supplier_media_id: image.id });
        }
      });

      return {
        ...variant,
        catalog_variant_sizes: variant.catalog_variant_sizes.map(size => ({ ...size, total: +size.total * 100 })),
        catalog_product_medias: mediasArray
      };
    });
  }

  onVariantChange($event: CatalogProductVariant[]) {
    this.productVariants = $event;
  }

  onDeleteVariant($event: number[]) {
    this.deletedVariants = $event;
  }

  onDeleteVariantMedia($event: number[]) {
    this.deletedCatalogProductMedias = $event;
  }

  setDefaultDescriptionValue() {
    const value = this.dynamicConfig.data?.section?.content?.text;
    this.editor.quill.clipboard.dangerouslyPasteHTML(value);
  }
}
