import { Route } from '@angular/router';

import { storefrontPermission } from 'app/_shared/guards/storefront-permission.guard';
import { StorefrontAction } from 'app/_shared/models/access-control-actions';
import { CouponsComponent } from 'app/admin/storefront/coupons/coupons.component';
import { GiftCardDetailsComponent } from 'app/admin/storefront/coupons/gift-cards/gift-card-details/gift-card-details.component';
import { GiftCardEditorComponent } from 'app/admin/storefront/coupons/gift-cards/gift-card-editor/gift-card-editor.component';
import { GiftCardEmailTemplateEditorComponent } from 'app/admin/storefront/coupons/gift-cards/gift-card-email-template-editor/gift-card-email-template-editor.component';
import { GiftCardListComponent } from 'app/admin/storefront/coupons/gift-cards/gift-card-list/gift-card-list.component';
import { PromoCodeDetailsComponent } from 'app/admin/storefront/coupons/promo-codes/promo-code-details/promo-code-details.component';
import { PromoCodeEditorComponent } from 'app/admin/storefront/coupons/promo-codes/promo-code-editor/promo-code-editor.component';
import { PromoCodeListComponent } from 'app/admin/storefront/coupons/promo-codes/promo-code-list/promo-code-list.component';
import { DesignerComponent } from 'app/admin/storefront/designer/designer.component';
import { LibrariesListComponent } from 'app/admin/storefront/files/libraries-list/libraries-list.component';
import { LibraryEditorComponent } from 'app/admin/storefront/files/library-editor/library-editor.component';
import { LibraryFoldersListComponent } from 'app/admin/storefront/files/library-folders-list.component';
import { StorageFilesListComponent } from 'app/admin/storefront/files/storage-files-list/storage-files-list.component';
import { CategoryManagmentComponent } from 'app/admin/storefront/products/category-managment/category-managment.component';
import { CreateProductComponent } from 'app/admin/storefront/products/create-product/create-product.component';
import { CustomProductEditorComponent } from 'app/admin/storefront/products/custom-product-editor/custom-product-editor.component';
import { ListCatalogProductsComponent } from 'app/admin/storefront/products/list-catalog-products/list-catalog-products.component';
import { ProductsComponent } from 'app/admin/storefront/products/products.component';
import { SingleProductComponent } from 'app/admin/storefront/products/single-product/single-product.component';
import { CommunicationsComponent } from 'app/admin/storefront/settings/communications/communications.component';
import { GeneralSettingsComponent } from 'app/admin/storefront/settings/general-settings/general-settings.component';
import { SettingsComponent as StorefrontSettings } from 'app/admin/storefront/settings/settings.component';

// Prefix: "companies/:id/storefronts/:storefrontId"
export const StorefrontRoutes: Route[] = [
  {
    path: 'products',
    canActivate: [storefrontPermission],
    data: {
      breadcrumb: 'Products',
      action: StorefrontAction.Products
    },
    children: [
      {
        path: '',
        component: ProductsComponent
      },
      {
        path: 'create',
        canActivate: [storefrontPermission],
        data: {
          breadcrumb: 'Create',
          action: StorefrontAction.AddProducts
        },
        children: [
          {
            path: '',
            component: ListCatalogProductsComponent
          },

          {
            path: ':catalogProductId',
            component: CreateProductComponent
          }
        ]
      },
      {
        path: 'category-management',
        component: CategoryManagmentComponent,
        data: { breadcrumb: 'Categories' }
      },
      {
        path: ':productId',
        children: [
          {
            path: 'edit',
            data: { edit: true },
            component: CreateProductComponent
          }
        ]
      },
      {
        path: 'custom',
        children: [
          {
            path: 'create',
            component: CustomProductEditorComponent,
            canActivate: [storefrontPermission],
            data: {
              action: StorefrontAction.AddProducts
            }
          },

          {
            path: ':customProductId',
            component: CustomProductEditorComponent,
            canActivate: [storefrontPermission],
            data: {
              action: StorefrontAction.EditProducts
            }
          }
        ]
      },
      {
        path: ':productId',
        component: SingleProductComponent
      }
    ]
  },
  {
    path: 'designer',
    component: DesignerComponent,
    canActivate: [storefrontPermission],
    data: {
      breadcrumb: 'Designer',
      action: StorefrontAction.Design
    }
    // children: [
    //   {
    //     path: '',
    //     redirectTo: 'homepage',
    //     pathMatch: 'full'
    //   },
    //   {
    //     path: 'homepage',
    //     component: HomepageComponent
    //   },
    //   {
    //     path: 'menu-and-pages',
    //     component: MenuAndPagesComponent
    //   },
    //   {
    //     path: 'settings',
    //     component: SettingsComponent
    //   }
    // ]
  },
  {
    path: 'files',
    data: { breadcrumb: 'Files' },
    children: [
      {
        path: 'folders',
        data: { breadcrumb: 'Libraries' },
        children: [
          { path: '', component: LibraryFoldersListComponent },
          {
            path: ':libraryFolderId',

            children: [
              { path: '', component: LibrariesListComponent },
              {
                path: 'libraries/create',
                component: LibraryEditorComponent
              },
              {
                path: 'libraries/:libraryId',
                component: LibraryEditorComponent,
                data: { breadcrumb: 'Libraries' }
              }
            ]
          }
        ]
      },
      { path: 'storage', component: StorageFilesListComponent }
    ]
  },
  {
    path: 'settings',
    component: StorefrontSettings,
    canActivate: [storefrontPermission],
    data: {
      breadcrumb: 'Settings',
      action: StorefrontAction.Settings
    },
    children: [
      {
        path: '',
        redirectTo: 'general',
        pathMatch: 'full'
      },
      {
        path: 'general',
        component: GeneralSettingsComponent
      },
      {
        path: 'communications',
        component: CommunicationsComponent
      }
    ]
  },
  {
    path: 'coupons',
    data: { breadcrumb: 'Gift Cards & Promo Codes' },
    component: CouponsComponent,
    children: [
      { path: '', redirectTo: 'gift-cards', pathMatch: 'full' },
      {
        path: 'gift-cards',
        canActivate: [storefrontPermission],
        data: {
          action: StorefrontAction.GiftCards
        },
        children: [
          {
            path: '',
            component: GiftCardListComponent
          },
          {
            path: 'create',
            component: GiftCardEditorComponent
          },
          {
            path: ':giftCardId/edit',
            component: GiftCardEditorComponent
          },
          {
            path: 'email-templates',
            component: GiftCardEmailTemplateEditorComponent
          },
          {
            path: ':giftCardId',
            component: GiftCardDetailsComponent
          }
        ]
      },

      {
        path: 'promo-codes',
        canActivate: [storefrontPermission],
        data: {
          action: StorefrontAction.PromoCodes
        },
        children: [
          {
            path: '',
            component: PromoCodeListComponent
          },
          {
            path: 'create',
            component: PromoCodeEditorComponent
          },
          {
            path: ':promoCodeId/edit',
            component: PromoCodeEditorComponent
          },
          {
            path: ':promoCodeId',
            component: PromoCodeDetailsComponent
          }
        ]
      }
    ]
  }
];
