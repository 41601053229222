import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FileDownloadService {
  downloadFile(blob: Blob, fileName: string): void {
    const link = document.createElement('a');
    const url = window.URL.createObjectURL(blob);

    link.href = url;

    link.download = fileName;

    link.click();

    window.URL.revokeObjectURL(url);
  }
}
