<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <p-steps [model]="steps" [(activeIndex)]="activeIndex"></p-steps>
  @if (activeIndex === 0) {
    <div class="flex flex-column gap-3 pt-3">
      <p class="text-400">
        File preparation is required prior to launching a store. Single Serve will create all of the necessary versions
        of your logo/artwork provided below for all decoration methods including embroidery digitizing if needed.
        Supplied embroidery files with proper thread information can be used if you prefer.
      </p>
      <app-input
        name="name"
        label="Name"
        [control]="form.controls.name"
        placeholder="Design name"
        [fullWidth]="true"
      ></app-input>
      <div class="custom-upload">
        <p-fileUpload
          #fileUploader
          [multiple]="false"
          [customUpload]="true"
          (onSelect)="onThumbnailUpload($event)"
          accept=".png"
          maxFileSize="1000000"
          showUploadButton="false"
          customUpload="true"
          styleClass="border-1 surface-border surface-card p-0 border-round mb-1"
        >
          <ng-template pTemplate="header" let-chooseCallback="chooseCallback" let-clearCallback="clearCallback">
            <div class="flex flex-wrap justify-content-between align-items-center flex-1 gap-2">
              <div class="w-full flex justify-content-between gap-2">
                <p-button (onClick)="chooseCallback()" icon="pi pi-images" outlined label="Upload Image" />
              </div>
            </div>
          </ng-template>
          <ng-template pTemplate="file"></ng-template>
          <ng-template pTemplate="content">
            <div class="min-h-10 m-1 border-round py-4">
              @if (!libraryThumbnail?.objectURL) {
                <div
                  class="flex flex-column w-full h-full justify-content-center align-items-center cursor-pointer"
                  (click)="fileUploader.advancedFileInput.nativeElement.click()"
                >
                  <i class="pi pi-fw pi-file text-4xl text-primary"></i>
                  <span class="block font-semibold text-900 text-lg mt-3">Drop or select file(.png)</span>
                </div>
              }
              @if (libraryThumbnail?.objectURL) {
                <div class="flex justify-content-center align-items-center h-full w-full">
                  <img
                    [src]="libraryThumbnail?.objectURL"
                    height="80"
                    alt="Library Thumbnail"
                    style="object-fit: contain"
                  />
                  <button
                    pButton
                    type="button"
                    aria-label="remove"
                    icon="pi pi-times"
                    class="p-button-rounded p-button-primary text-sm absolute justify-content-center align-items-center"
                    style="top: 10px; right: 10px"
                    (click)="$event.stopPropagation(); removeThumbnail()"
                  ></button>
                </div>
              }
            </div>
          </ng-template>
        </p-fileUpload>
      </div>
      <div class="custom-upload">
        <p class="text-400">
          Please add any additional files below such as eps, embroidery, etc that you would like us to use and assist us
          with the creation of these production files.
        </p>
        <p-fileUpload
          #additionalFileUploader
          [multiple]="true"
          [customUpload]="true"
          (onSelect)="onAdditionalFilesUpload($event)"
          accept=".pdf"
          maxFileSize="1000000"
          showUploadButton="false"
          customUpload="true"
          showUploadButton="false"
          styleClass="border-1 surface-border surface-card p-0 border-round mb-1"
        >
          <ng-template pTemplate="file"></ng-template>
          <ng-template pTemplate="content">
            <div class="min-h-10 m-1 border-round py-4">
              @if (additionaFiles.length) {
                <div class="flex flex-wrap gap-5">
                  @for (additionalItem of additionaFiles; track $index) {
                    <p-chip
                      styleClass="mr-2 py-2 px-3 text-900 font-bold surface-card border-1 surface-border"
                      [style]="{ 'border-radius': '20px' }"
                      onclick=""
                    >
                      <span class="mr-3">{{ additionalItem.name }}</span>
                      <span
                        class="chip-remove-icon flex align-items-center justify-content-center border-1 surface-border bg-gray-100 border-circle cursor-pointer"
                        (click)="onAdditionalFileRemove($index)"
                      >
                        <i class="pi pi-fw pi-times text-black-alpha-60"></i>
                      </span>
                    </p-chip>
                  }
                </div>
              } @else {
                <div
                  class="flex flex-column w-full h-full justify-content-center align-items-center cursor-pointer"
                  (click)="additionalFileUploader.advancedFileInput.nativeElement.click()"
                >
                  <i class="pi pi-fw pi-file text-4xl text-primary"></i>
                  <span class="block font-semibold text-900 text-lg mt-3">Drop or select files</span>
                </div>
              }
            </div>
          </ng-template>
        </p-fileUpload>
      </div>
      <div>
        <p class="text-400">
          Please provide all information related to color versions needed as well as any additional information to
          deliver the best results to you. Supplied embroidery files must include all thread colors and sequences.
        </p>
        <app-input
          name="additional_message"
          label="Additional message"
          [control]="form.controls.additional_message"
          placeholder="Write some additional information"
          [fullWidth]="true"
        ></app-input>
      </div>
    </div>
  } @else {
    <div class="flex flex-column gap-3 pt-3">
      <img [src]="libraryThumbnail?.objectURL" alt="Uploaded Image" style="width: 150px" />
      <p>We will begin to prepare your files so that you may start to build your products shortly.</p>
      <p>We will email you as soon as your files are prepared and ready to be used for product creation.</p>
    </div>
  }
  <div class="flex justify-content-end gap-1 pt-3">
    <p-button outlined type="button" label="Cancel" (onClick)="closeDialog()"></p-button>
    @if (activeIndex === 0) {
      <p-button outlined type="button" label="Go to summary" (click)="nextStep()"></p-button>
    }
    @if (activeIndex === 1) {
      <p-button outlined type="button" label="Back" (click)="previousStep()"></p-button>
    }
    <p-button [disabled]="isLoading" type="submit" label="Submit"></p-button>
  </div>
</form>
