import { Component, inject, OnInit } from '@angular/core';

import { ButtonModule } from 'primeng/button';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { TableLazyLoadEvent } from 'primeng/table';
import { finalize } from 'rxjs';

import {
  Columns,
  ListProductsComponent,
  ProductPaginatorOptions
} from 'app/_shared/components/list-products/list-products.component';
import { StorefrontProduct } from 'app/_shared/interfaces/storefront';
import { StorefrontProductCategory } from 'app/_shared/models/StorefrontProductCategory';
import { StorefrontService } from 'app/_shared/services/storefront.service';

@Component({
    selector: 'app-promo-code-product-list-selection',
    imports: [ListProductsComponent, ButtonModule],
    templateUrl: './promo-code-product-list-selection.component.html',
    styleUrl: './promo-code-product-list-selection.component.scss'
})
export class PromoCodeProductListSelectionComponent implements OnInit {
  dialogConfig = inject(DynamicDialogConfig);
  dialogRef = inject(DynamicDialogRef);
  storefrontService = inject(StorefrontService);

  storefrontId: string;
  productCategories: StorefrontProductCategory;
  storefrontProducts: StorefrontProduct[];
  isLoading: boolean;

  paginatorOptions: ProductPaginatorOptions = {
    total: 0,
    rows: 10,
    first: 0
  };

  cols: Columns[] = [
    { element: 'image', field: 'product_thumbnail', header: 'Image', width: '20%' },
    { field: 'name', header: 'Name', width: '65%' }
  ];

  selectedProducts: StorefrontProduct[];

  ngOnInit() {
    this.storefrontId = this.dialogConfig.data.storefrontId;
    this.productCategories = this.dialogConfig.data.productCategories;
  }

  fetchProducts(paginatorOptions: ProductPaginatorOptions | TableLazyLoadEvent) {
    this.isLoading = true;
    const { rows, first, globalFilter } = paginatorOptions;
    const params = {
      rows,
      ...(globalFilter && { global_filter: globalFilter }),
      page: first && rows ? Math.floor(first / rows) + 1 : 1
    };
    this.storefrontService
      .getStorefrontProducts(this.storefrontId, params)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe(products => {
        this.storefrontProducts = products.data.data;
        this.paginatorOptions = {
          total: products.data.total,
          rows: products.data.per_page,
          first: products.data.from - 1
        };
      });
  }

  handleLazyLoadProducts($event: TableLazyLoadEvent) {
    this.fetchProducts($event);
  }

  handleSelectionChange($event: any) {
    this.selectedProducts = $event;
  }

  onFinishSelection() {
    this.dialogRef.close(this.selectedProducts);
  }

  closeDialog() {
    this.dialogRef.close(null);
  }
}
