import { CommonModule, DatePipe } from '@angular/common';
import { Component, effect, inject, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ActivatedRoute, RouterLink } from '@angular/router';

import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { ProgressBarModule } from 'primeng/progressbar';
import { SkeletonModule } from 'primeng/skeleton';
import { TableLazyLoadEvent, TableModule } from 'primeng/table';
import { BehaviorSubject, combineLatest, debounceTime, distinctUntilChanged, finalize } from 'rxjs';

import { OrderDetailsCollapseItemComponent } from 'app/_shared/components/order-details-collapse-item/order-details-collapse-item.component';
import { OrderQuickPreviewComponent } from 'app/_shared/components/order-quick-preview/order-quick-preview.component';
import { StatusBadgeComponent } from 'app/_shared/components/status-badge/status-badge.component';
import { statusOptions } from 'app/_shared/constants/order-status-options';
import { Order } from 'app/_shared/models/Order';
import { Storefront } from 'app/_shared/models/Storefront';
import { DisplayDatePipe } from 'app/_shared/pipes/date.pipe';
import { PricePipe } from 'app/_shared/pipes/price.pipe';
import { UsdPipe } from 'app/_shared/pipes/usd-currency.pipe';
import { CompanyOrderService } from 'app/_shared/services/company-order.service';
import { CompanyStore } from 'app/_store/company.store';

@Component({
  selector: 'app-orders',
  imports: [
    TableModule,
    ButtonModule,
    FormsModule,
    DropdownModule,
    ProgressBarModule,
    CommonModule,
    InputTextModule,
    CalendarModule,
    RouterLink,
    DisplayDatePipe,
    UsdPipe,
    StatusBadgeComponent,
    PricePipe,
    OrderQuickPreviewComponent,
    OrderDetailsCollapseItemComponent,
    SkeletonModule
  ],
  providers: [DatePipe],
  templateUrl: './orders.component.html'
})
export class OrdersComponent implements OnInit {
  orderService = inject(CompanyOrderService);
  companyStore = inject(CompanyStore);
  datePipe = inject(DatePipe);
  route = inject(ActivatedRoute);

  isLoading = false;
  orders: Order[] = [];
  totalRecords: number;
  loadingRows = Array(10)
    .fill(0)
    .map((_, index) => index + 1);
  public storefronts: Storefront[] | undefined = [];

  statusOptions = statusOptions;

  private storefrontEff = effect(() => {
    this.storefronts = this.companyStore.workingCompany()?.storefronts;
  });

  filtersSubject = new BehaviorSubject<any>(null);

  filtersChanged = false;
  lastLazyLoadEvent: TableLazyLoadEvent | undefined;

  globalFilter: string;
  startDate: Date | null;
  endDate: Date | null;
  selectedStatus: string | null;
  selectedStorefront: string | null;

  companyId: number;
  orderForQuickPreview: Order;
  quickOrderPreviewDialog = false;

  expandedRows: { [key: number]: boolean } = {};
  isAllExpanded = false;

  ngOnInit() {
    const companyId = this.route.snapshot.paramMap.get('id');
    if (!companyId) {
      console.error('Company ID is null.');
      return;
    }
    this.companyId = +companyId;

    combineLatest([this.filtersSubject.pipe(debounceTime(300), distinctUntilChanged())]).subscribe(() => {
      if (this.filtersChanged) {
        this.loadOrders(this.lastLazyLoadEvent);
      }
      this.filtersChanged = false;
    });
  }

  loadOrders(event?: TableLazyLoadEvent) {
    if (event) {
      this.lastLazyLoadEvent = event;
    }
    const filters = this.filtersSubject.value || {};

    if (filters?.date_from) {
      filters.date_from = this.datePipe.transform(filters.date_from, 'dd.MM.yyyy');
    }
    if (filters?.date_to) {
      filters.date_to = this.datePipe.transform(filters.date_to, 'dd.MM.yyyy');
    }

    const params = {
      'relations[]': [
        'contactInfo',
        'storefront',
        'items.product',
        'items.productSize',
        'items.productVariant',
        'items.product.productThumbnail'
      ],
      ...filters
    };

    this.isLoading = true;

    this.orderService
      .paginatedIndex(this.companyId, event, params)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe({
        next: res => {
          this.orders = res.data.data;
          this.totalRecords = res.data.total;
        },
        error: error => console.error(error)
      });
  }

  onFilterChange(filterKey: string, filterValue: any): void {
    const updatedFilters = { ...this.filtersSubject.value };

    if (filterValue !== null && filterValue !== undefined && filterValue !== '') {
      updatedFilters[filterKey] = filterValue;
    } else {
      delete updatedFilters[filterKey];
    }

    this.filtersSubject.next(updatedFilters);
    this.filtersChanged = true;
  }

  clearFilters(): void {
    this.globalFilter = '';
    this.startDate = null;
    this.endDate = null;
    this.selectedStatus = null;
    this.selectedStorefront = null;

    this.filtersSubject.next({});
    this.filtersChanged = true;
  }

  quickPreview(order: Order) {
    this.orderForQuickPreview = { ...order };
    this.quickOrderPreviewDialog = true;
  }

  hideDialog() {
    this.quickOrderPreviewDialog = false;
  }

  toggleExpandAll() {
    const allExpanded =
      Object.keys(this.expandedRows).length === this.orders.length && Object.values(this.expandedRows).every(Boolean);
    if (allExpanded) {
      this.expandedRows = {};
    } else {
      this.expandedRows = this.orders.reduce((acc, order) => {
        acc[order.id] = true;
        return acc;
      }, {});
    }
    this.isAllExpanded = !allExpanded;
  }
}
