import { Component, inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';

import { environment } from 'environments/environment';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { FileSelectEvent, FileUploadEvent, FileUploadModule } from 'primeng/fileupload';
import { InputGroupModule } from 'primeng/inputgroup';
import { InputGroupAddonModule } from 'primeng/inputgroupaddon';
import { InputTextModule } from 'primeng/inputtext';
import { debounceTime } from 'rxjs';

import { SlugHelper } from 'app/_shared/helpers/slug-helper';
import { Company } from 'app/_shared/models/Company';
import { CustomUploadedFile } from 'app/_shared/models/CustomUplaodedFile';
import { Storefront } from 'app/_shared/models/Storefront';
import { CustomValidators } from 'app/_shared/services/custom-validators.service';
import { storefrontDefaultData } from 'app/admin/storefront/designer/storefront-themes';

import { StorefrontService } from '../../services/storefront.service';
import { ToastService } from '../../services/toast.service';

@Component({
  selector: 'app-storefront-editor-dialog',
  imports: [
    ButtonModule,
    InputTextModule,
    DialogModule,
    ReactiveFormsModule,
    InputGroupAddonModule,
    InputGroupModule,
    FileUploadModule,
    DropdownModule
  ],
  templateUrl: './storefront-editor-dialog.component.html',
  styleUrl: './storefront-editor-dialog.component.scss'
})
export class StorefrontEditorDialogComponent implements OnInit {
  protected prefix = environment.PREFIX;
  protected suffix = environment.SUFFIX;
  storefrontService = inject(StorefrontService);
  fb = inject(FormBuilder);
  toastService = inject(ToastService);
  dialogConfig = inject(DynamicDialogConfig);
  dialogRef = inject(DynamicDialogRef);
  slugHelper = inject(SlugHelper);

  companyId: string;
  storefront: Storefront;

  storefrontForm: FormGroup;
  submitted = false;
  isLoading = false;
  formReady = false;

  logo: CustomUploadedFile = {
    file: null,
    objectURL: null,
    name: null
  };

  superAdminMode: boolean;
  companyOptions: Company[];

  ngOnInit(): void {
    this.storefront = this.dialogConfig.data.storefront;
    this.companyId = this.dialogConfig.data.companyId;
    this.companyOptions = this.dialogConfig.data.companyOptions ?? [];
    this.superAdminMode = this.dialogConfig.data.superAdminMode ?? false;
    this.buildForm();
  }

  buildForm() {
    this.storefrontForm = this.fb.group({
      id: this.storefront?.id,
      name: [this.storefront?.name, [Validators.required]],
      subdomain: [this.storefront?.subdomain, [Validators.required, CustomValidators.validateSubdomain()]],
      company_id: [this.storefront?.company_id ?? null]
    });

    if (this.superAdminMode) {
      this.storefrontForm.get('company_id')?.setValidators([Validators.required]);
    }
    this.subscribeToNameChange();
    this.initLogo();
  }

  subscribeToNameChange() {
    this.storefrontForm
      .get('name')
      ?.valueChanges.pipe(debounceTime(500))
      .subscribe(name => {
        const baseUrl = this.slugHelper.generateBaseSlug(name);
        this.storefrontForm.get('subdomain')?.setValue(baseUrl, { emitEvent: false });
      });
  }

  initLogo() {
    if (this.storefront?.logo) {
      const { name, full_path } = this.storefront.logo;
      this.logo = { name, file: null, objectURL: full_path };
    }
  }

  onLogoUpload(event: FileUploadEvent | FileSelectEvent) {
    const file = event.files[0];
    const fileName = event.files[0].name;
    const objectURL = URL.createObjectURL(file);
    this.logo.file = file;
    this.logo.objectURL = objectURL;
    this.logo.name = fileName;
  }

  removeLogo(): void {
    this.logo.file = null;
    this.logo.objectURL = null;
    this.logo.name = null;
  }

  onSubmit() {
    if (this.storefrontForm.invalid) {
      this.submitted = true;
      this.toastService.error('Please fill all required fields correctly!');
      return;
    }

    const submitData = this.prepareSubmitData();
    if (this.storefront) {
      this.dialogRef.close({ id: this.storefront.id, data: submitData });
    } else {
      this.dialogRef.close({ data: submitData });
    }
  }

  prepareSubmitData() {
    const formData = new FormData();
    const formValue = this.storefrontForm.value;
    const storefrontForCompanyId = this.companyId || formValue.company_id;
    const defaultStorefrontData = storefrontDefaultData;

    formData.append('name', formValue.name);
    formData.append('subdomain', formValue.subdomain);
    formData.append('company_id', storefrontForCompanyId);

    if (this.logo.file) {
      formData.append('logo', this.logo.file);
    }
    if (this.storefront?.logo && !this.logo.objectURL) {
      formData.append('delete_logo', 'true');
    }

    if (!this.storefront) {
      formData.append('data', JSON.stringify(defaultStorefrontData));
      formData.append('status', 'draft');
    }

    if (this.storefront) {
      formData.append('_method', 'put');
    }

    return formData;
  }
}
