import { Component, inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';

import { ButtonModule } from 'primeng/button';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { InputTextModule } from 'primeng/inputtext';
import { finalize } from 'rxjs';

import { InviteService } from '../../services/invite.service';
import { ToastService } from '../../services/toast.service';

@Component({
    selector: 'app-invite-company-modal',
    imports: [InputTextModule, ButtonModule, ReactiveFormsModule],
    templateUrl: './invite-company-modal.component.html'
})
export class InviteCompanyModalComponent implements OnInit {
  dialogConfig = inject(DynamicDialogConfig);
  dialogRef = inject(DynamicDialogRef);
  toastService = inject(ToastService);
  inviteService = inject(InviteService);
  fb = inject(FormBuilder);

  form: FormGroup;

  submitted = false;
  isLoading = false;

  ngOnInit() {
    this.buildForm();
  }

  buildForm() {
    this.form = this.fb.group({
      email: ['', [Validators.email, Validators.required]]
    });
  }

  onSubmit() {
    if (this.form.invalid) {
      this.submitted = true;
      this.toastService.error('Please fill all required fields correctly!');
      return;
    }

    this.sendInviteToCompany();
  }

  sendInviteToCompany() {
    this.isLoading = true;
    this.inviteService
      .createInvite(this.form.value)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe({
        next: () => {
          this.dialogRef.close(true);
        },
        error: err => {
          this.isLoading = false;
          console.error('Operation failed:', err);
        }
      });
  }
}
