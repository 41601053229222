<app-storefronts-table
  [storefronts]="storefronts"
  [loading]="isLoading"
  [paginatorOptions]="paginatorOptions"
  [splitButtonItemsMap]="splitButtonItemsMap"
  [globalFilter]="globalFilter"
  [selectedStatus]="selectedStatus"
  [userCompanyOrSuperAdmin]="userCompanyOrSuperAdmin"
  (lazyLoadStorefronts)="loadStorefronts($event)"
  (filterChanged)="onFilterChange($event)"
  (openStorefrontEditor)="handleOpenStorefrontEditor($event)"
  (openStorefrontSettings)="navigateToStorefrontSettings($event)"
  (openStorefront)="openInNewTab($event)"
  (reactiveStorefront)="activateStore($event)"
/>
