<div class="card">
  <h5>Details</h5>
  <div class="grid">
    <div class="col-12 md:col-6">
      <ul class="list-none p-0 m-0 text-600 mb-4 text-600">
        <li class="mb-3 flex justify-content-between">
          <span>Promo Name:</span>
          <span class="font-semibold">{{ promoCode.name }}</span>
        </li>
        <li class="mb-3 flex justify-content-between">
          <span>Code:</span>
          <span class="font-semibold">{{ promoCode.code }}</span>
        </li>
        <li class="mb-3 flex justify-content-between">
          <span>Type:</span>
          <span class="font-semibold">{{ promoCode.type | promoCodeFormat }}</span>
        </li>
        <li class="mb-3 flex justify-content-between">
          <span>Date created:</span>
          <span class="font-semibold">{{ (promoCode.created_at | usdDate) || 'not set' }}</span>
        </li>
        @if (promoCode.type !== promoCodeType.FREE_SHIPPING) {
          <li class="mb-3 flex justify-content-between">
            <span>Value:</span>
            <span class="font-semibold">
              {{
                promoCode.promo_code_value === null
                  ? ''
                  : promoCode.promo_code_value_type === promoCodeValueType.FIXED
                    ? (promoCode.promo_code_value | price | usdPipe)
                    : (promoCode.promo_code_value | price) + '%'
              }}
            </span>
          </li>
        }
        <li class="mb-3 flex justify-content-between">
          <span>Exclusive to Customer:</span>
          <span class="font-semibold">{{ promoCode.per_customer ? 'Yes' : 'No' }}</span>
        </li>
        @if (promoCode.minimum_order_amount) {
          <li class="mb-3 flex justify-content-between">
            <span>Minimum Order Amount:</span>
            <span class="font-semibold">{{ promoCode.minimum_order_amount | price | usdPipe }}</span>
          </li>
        }
        @if (promoCode.start_date) {
          <li class="mb-3 flex justify-content-between">
            <span>Promotion Starts On:</span>
            <span class="font-semibold">{{ promoCode.start_date | usdDate }}</span>
          </li>
        }
        @if (promoCode.end_date) {
          <li class="mb-3 flex justify-content-between">
            <span>Promotion Ends On:</span>
            <span class="font-semibold">{{ promoCode.end_date | usdDate }}</span>
          </li>
        }
      </ul>
    </div>
  </div>
</div>

@if (promoCode.type === promoCodeType.CATEGORY) {
  <div class="card">
    <h5>Categories</h5>
    <ul class="list-none p-0 m-0 text-600 mb-4 text-600 flex flex-column gap-3">
      @for (category of promoCode.product_categories; track $index) {
        <span>{{ category.name }}</span>
      }
    </ul>
  </div>
}

@if (promoCode.type === promoCodeType.PRODUCT) {
  <div class="card">
    <h5>Products</h5>
    <div class="flex flex-column gap-2">
      @for (product of promoCode.products; track $index) {
        <div class="flex gap-5 align-items-center">
          <img
            [src]="product.product_thumbnail?.full_path"
            [alt]="product.product_thumbnail?.name"
            style="object-fit: contain"
            [ngStyle]="{ maxWidth: '80px' }"
            class="shadow-4"
            priority
          />
          <p>{{ product.name }}</p>
        </div>
      }
    </div>
  </div>
}
