import { Component, inject, input, model, OnInit, output } from '@angular/core';

import { ConfirmationService } from 'primeng/api';
import { Button } from 'primeng/button';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';
import { InputTextModule } from 'primeng/inputtext';
import { PaginatorModule } from 'primeng/paginator';

import { VariantsItemComponent } from 'app/_shared/components/catalog-product-form/variants-list/variants-item/variants-item.component';
import { IApiResponse } from 'app/_shared/interfaces/IApiResponse';
import { CatalogProductVariant } from 'app/_shared/interfaces/catalog';
import { ColorDictionaryService } from 'app/_shared/services/color-dictionary.service';

@Component({
  selector: 'app-variants-list',
  imports: [VariantsItemComponent, DialogModule, PaginatorModule, InputTextModule, Button, ConfirmDialogModule],
  providers: [ConfirmationService],
  templateUrl: './variants-list.component.html',
  styleUrl: './variants-list.component.scss'
})
export class VariantsListComponent implements OnInit {
  variants = model.required<CatalogProductVariant[]>();
  type = input.required<string>();
  supplierId = input.required<number>();
  showSizesDialog = false;
  sizesDialogVariant: CatalogProductVariant;
  sizesDialogVariantIndex: number;
  onVariantChange = output<CatalogProductVariant[]>();
  onDeleteVariant = output<number[]>();
  onDeleteVariantMedia = output<number[]>();

  private colorDictionaryService = inject(ColorDictionaryService);

  deletedVariants: number[] = [];
  deletedCatalogProductMedias: number[] = [];

  ngOnInit() {
    this.fetchColorDictionary();
  }

  deleteCatalogProductVariant(index: number) {
    const variantId = this.variants()[index].id;

    if (variantId) {
      this.deletedVariants.push(variantId);
      this.onDeleteVariant.emit(this.deletedVariants);
    }

    this.variants.update(variants => {
      return variants.filter((item, i) => i !== index);
    });
  }

  deleteVariantMedia(imageId: number) {
    if (imageId && this.type() === 'edit') {
      this.deletedCatalogProductMedias.push(imageId);
      this.onDeleteVariantMedia.emit(this.deletedCatalogProductMedias);
    }
  }

  private fetchColorDictionary() {
    const supplierId = this.supplierId();
    this.colorDictionaryService.getDictionary(supplierId).subscribe({
      next: res => this.handleDictionaryResponse(res),
      error: err => console.error(err)
    });
  }

  private handleDictionaryResponse(res: IApiResponse) {
    const colorDictionary: { [key: string]: string } = {};
    res.data.forEach((record: { key: string; value: string }) => (colorDictionary[record.key] = record.value));

    this.variants.update(variants => {
      return variants.map(variant => {
        const key = variant.color;

        if (colorDictionary[key]) {
          return { ...variant, color: colorDictionary[key] };
        }
        return variant;
      });
    });
  }

  handleSizeDialogOpen(index: number) {
    this.sizesDialogVariantIndex = index;
    this.sizesDialogVariant = this.variants()[index];
    this.showSizesDialog = true;
  }

  handleSizeChange() {
    this.showSizesDialog = false;
    this.variants.update(variants => {
      return variants.map((item, index) => {
        if (index === this.sizesDialogVariantIndex) {
          return {
            ...item,
            catalog_variant_sizes: this.sizesDialogVariant.catalog_variant_sizes
          } as CatalogProductVariant;
        }
        return item;
      });
    });
  }

  doNotAskAgain() {
    const date = new Date();
    date.setMinutes(date.getMinutes() + 5);
    localStorage.setItem('doNotAskAgain', date.toISOString());
  }
}
