import { DragDropModule } from '@angular/cdk/drag-drop';
import { NgOptimizedImage } from '@angular/common';
import { Component, computed, inject, input, model, OnInit, output } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { ConfirmationService } from 'primeng/api';
import { Button } from 'primeng/button';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { InputGroupModule } from 'primeng/inputgroup';
import { InputTextModule } from 'primeng/inputtext';

import { CatalogProductMedias, CatalogProductVariant } from 'app/_shared/interfaces/catalog';
import { ColorDictionaryService } from 'app/_shared/services/color-dictionary.service';
import { ToastService } from 'app/_shared/services/toast.service';

import { NewVariantMediaModalComponent } from './new-variant-media-modal/new-variant-media-modal.component';

@Component({
  selector: 'app-variants-item',
  imports: [Button, NgOptimizedImage, InputTextModule, InputGroupModule, FormsModule, DragDropModule],
  providers: [DialogService],
  templateUrl: './variants-item.component.html',
  styleUrl: './variants-item.component.scss'
})
export class VariantsItemComponent {
  variant = model.required<CatalogProductVariant>();
  variantIndex = input.required<number>();
  supplierId = input.required<number>();
  editMode = false;
  variantDeleted = output<number>();
  mediaDeleted = output<number>();
  sizesChangeDialog = output<number>();
  private colorDictionaryService = inject(ColorDictionaryService);
  private toastService = inject(ToastService);
  private confirmationService = inject(ConfirmationService);
  private dialogService = inject(DialogService);

  dragMediaIndex?: number;
  dialogRef: DynamicDialogRef | undefined;

  variantImages = computed(() => {
    return this.variant().catalog_product_medias.map(image => {
      return {
        id: image.id,
        full_path: image?.full_path || image.supplier_media?.full_path || '',
        color: image.color,
        side: image.side
      } as CatalogProductMedias;
    });
  });

  openDeleteVariantModal(event: Event) {
    this.confirmationService.confirm({
      key: 'confirmDelete',
      target: event.target || new EventTarget(),
      message: 'Are you sure you want to delete this variant?',
      icon: 'pi pi-exclamation-triangle',
      accept: () => this.variantDeleted.emit(this.variantIndex())
    });
  }

  shouldSkipConfirmationDialog(): boolean {
    const doNotAskAgain = localStorage.getItem('doNotAskAgain');
    if (!doNotAskAgain) {
      return false;
    }

    const expirationDate = new Date(doNotAskAgain);
    const currentDate = new Date();

    return currentDate < expirationDate;
  }

  openDeleteMediaModal(image: CatalogProductMedias) {
    if (this.shouldSkipConfirmationDialog()) {
      this.handleDeleteMedia(image);
      return;
    }

    this.confirmationService.confirm({
      key: 'confirmDeleteMedia',
      message: `Are you sure you want to delete this image?`,
      icon: 'pi pi-exclamation-triangle',
      accept: () => this.handleDeleteMedia(image)
    });
  }

  handleDeleteMedia(image: CatalogProductMedias) {
    this.variant.update(variant => {
      return {
        ...variant,
        catalog_product_medias: variant.catalog_product_medias.filter(media => media.id !== image.id)
      };
    });
    if (image.id) {
      this.mediaDeleted.emit(image.id);
    }
  }

  onFileChange(event: Event, image: CatalogProductMedias) {
    const fileInput = event.target as HTMLInputElement;
    if (fileInput.files && fileInput.files.length > 0) {
      const file = fileInput.files[0];
      const reader = new FileReader();
      reader.onload = e => {
        const imageUrl = e.target?.result as string;
        this.variant.update(variant => {
          return {
            ...variant,
            catalog_product_medias: variant.catalog_product_medias.map(media =>
              media.id === image.id
                ? ({ full_path: imageUrl, color: image.color, side: image.side } as CatalogProductMedias)
                : media
            )
          };
        });
      };
      reader.readAsDataURL(file);
    }
  }

  addToDictionary() {
    const value = this.variant().color;
    const key = this.variant().supplier_product_variant?.color;

    if (key && key != value) {
      return this.colorDictionaryService.addToDictionary(this.supplierId(), key, value).subscribe({
        next: () => this.toastService.success('Saved!'),
        error: err => {
          this.toastService.error('An error occurred. Please try again');
          console.error(err);
        },
        complete: () => (this.editMode = false)
      });
    }

    this.editMode = false;
    return this.toastService.warning('Name is same as supplier name, skipping!');
  }

  handleEditSizes() {
    this.sizesChangeDialog.emit(this.variantIndex());
  }

  onImageDragStart(event: DragEvent, index: number) {
    this.dragMediaIndex = index;
  }

  onImageDragOver(event: DragEvent) {
    event.preventDefault();
  }

  onImageDrop(event: DragEvent, dropIndex: number) {
    event.preventDefault();

    if (this.dragMediaIndex === undefined || this.dragMediaIndex === dropIndex) return;

    const images = this.variant().catalog_product_medias;
    const draggedImage = images[this.dragMediaIndex];

    images.splice(this.dragMediaIndex, 1);
    images.splice(dropIndex, 0, draggedImage);

    this.variant.update(variant => ({
      ...variant,
      catalog_product_medias: [...images]
    }));
  }

  openMediaUploaderModal() {
    this.dialogRef = this.dialogService.open(NewVariantMediaModalComponent, {
      header: 'Uplaod New Variant image',
      width: '34rem'
    });

    this.dialogRef.onClose.subscribe({
      next: res => {
        if (res) {
          this.addNewVariantImage(res);
        }
      },
      error: err => {
        this.toastService.error('Error closing the dialog');
        console.error('Error closing the dialog:', err);
      }
    });
  }

  addNewVariantImage(variantImageData: { full_path: string; side: string }) {
    const currentImages = this.variant().catalog_product_medias;

    const newImage: any = {
      full_path: variantImageData.full_path,
      color: this.variant().color,
      side: variantImageData.side
    };

    this.variant.update(variant => ({
      ...variant,
      catalog_product_medias: [...currentImages, newImage]
    }));
  }
}
