import { animate, state, style, transition, trigger } from '@angular/animations';
import { ChangeDetectionStrategy, Component, computed, inject, model, OnInit, Signal } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

import { AccordionModule } from 'primeng/accordion';
import { ConfirmationService, MessageService, SharedModule } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { CheckboxModule } from 'primeng/checkbox';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';
import { FileUploadModule } from 'primeng/fileupload';
import { InputTextModule } from 'primeng/inputtext';
import { MenuModule } from 'primeng/menu';
import { OrderListModule } from 'primeng/orderlist';
import { PanelModule } from 'primeng/panel';
import { RadioButtonModule } from 'primeng/radiobutton';
import { v4 as uuidv4 } from 'uuid';

import { InputComponent } from 'app/_shared/components/input/input.component';
import { Menu } from 'app/_shared/interfaces/designer/menu';
import { Page } from 'app/_shared/interfaces/designer/page';
import { DesignerStore } from 'app/_store/designer.store';
import { StorefrontStore } from 'app/_store/storefront.store';
import { DesignerHelperService } from 'app/admin/storefront/designer/designer-helper.service';
import { PageBuilderComponent } from 'app/admin/storefront/designer/page-builder/page-builder.component';

@Component({
  selector: 'app-pages-list',
  imports: [
    ButtonModule,
    CardModule,
    DialogModule,
    FormsModule,
    MenuModule,
    OrderListModule,
    PanelModule,
    SharedModule,
    ReactiveFormsModule,
    ConfirmDialogModule,
    InputTextModule,
    AccordionModule,
    FileUploadModule,
    RadioButtonModule,
    InputComponent,
    PageBuilderComponent,
    CheckboxModule
  ],
  providers: [ConfirmationService],
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './pages-list.component.html',
  styleUrl: './pages-list.component.scss',
  animations: [
    trigger('slideDownAnimation', [
      state(
        'void',
        style({
          height: '0',
          opacity: 0,
          overflow: 'hidden'
        })
      ),
      state(
        '*',
        style({
          height: '*',
          opacity: 1
        })
      ),
      transition('void <=> *', [animate('300ms ease-in-out')])
    ])
  ]
})
export class PagesListComponent implements OnInit {
  iframeUrl = model<SafeUrl>();

  confirmationService = inject(ConfirmationService);
  messageService = inject(MessageService);
  fb = inject(FormBuilder);
  designerStore = inject(DesignerStore);
  storefrontStore = inject(StorefrontStore);
  sanitizer = inject(DomSanitizer);
  designerHelperService = inject(DesignerHelperService);

  selectedStorefront = computed(() => this.storefrontStore.selectedStorefront()!);
  storefrontId = computed(() => this.storefrontStore.selectedStorefront?.()?.id);
  pages: Signal<Page[]> = computed(() => this.designerStore.config().pages ?? ([] as Page[]));
  menu = computed(() => this.designerStore.config().menu ?? ([] as Menu[]));

  showCreatePage: boolean = false;
  newPageForm: FormGroup;

  ngOnInit() {
    this.newPageForm = this.fb.group({
      title: this.fb.control('', [Validators.required]),
      url: this.fb.control('', [Validators.required]),
      addToNavigation: this.fb.control([false])
    });
  }

  get title(): FormControl {
    return this.newPageForm.get('title') as FormControl;
  }
  get url(): FormControl {
    return this.newPageForm.get('url') as FormControl;
  }

  handleDeletePage(pageId: string) {
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete this page?',
      header: 'Delete Page',
      acceptButtonStyleClass: 'p-button-danger p-button-text',
      rejectButtonStyleClass: 'p-button-text p-button-text',
      acceptIcon: 'none',
      rejectIcon: 'none',

      accept: () => {
        const data = {
          pages: this.pages().filter(page => page.id !== pageId),
          menu: this.menu().filter(item => item.page?.id !== pageId)
        };
        this.designerStore.saveConfig(this.storefrontId()!, data).subscribe(next => {
          this.designerHelperService.sendToPreview(this.storefrontId()!, next);
          // Set iframe to homepage
          this.handlePageExpanded(true, '');
          this.messageService.add({
            severity: 'success',
            summary: 'Success',
            detail: 'Page deleted successfully.'
          });
        });
      },
      reject: () => {}
    });
  }

  handleCreatePage() {
    if (!this.newPageForm.valid) {
      return;
    }
    const newPage = { ...this.newPageForm.value, sections: [], id: uuidv4() } as Page;
    const pages = [...this.pages(), newPage];
    const menu = [...this.menu()];
    if (this.newPageForm.value.addToNavigation) {
      menu.push({ id: uuidv4(), label: this.newPageForm.value.title, page: newPage });
    }

    this.newPageForm.reset();
    this.showCreatePage = false;
    this.designerStore.saveConfig(this.storefrontId()!, { pages, menu }).subscribe(next => {
      this.designerHelperService.sendToPreview(this.storefrontId()!, next);
      this.messageService.add({
        severity: 'success',
        summary: 'Success',
        detail: 'Page created successfully.'
      });
    });
  }

  handleEditPage(page: Page) {}

  handlePageExpanded($event: boolean, pageUrl: string) {
    if ($event) {
      this.iframeUrl.set(`${this.storefrontStore?.selectedStorefront()?.url}/${pageUrl}`);
    }
  }
}
