import { Component, inject, OnInit, signal } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ActivatedRoute, RouterLink } from '@angular/router';

import { Button } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { InputTextareaModule } from 'primeng/inputtextarea';

import { CatalogProduct } from 'app/_shared/interfaces/catalog';
import { ClientSelectionList } from 'app/_shared/interfaces/clientSelection';
import { PricePipe } from 'app/_shared/pipes/price.pipe';
import { UsdPipe } from 'app/_shared/pipes/usd-currency.pipe';
import { CompanyService } from 'app/_shared/services/company.service';
import { ToastService } from 'app/_shared/services/toast.service';

export interface CatalogProductWithColors extends CatalogProduct {
  colors: any[];
  visibleColorsNumber: number;
  showAll: boolean;
  comment: string | null;
}

@Component({
    selector: 'app-client-selection-details',
    imports: [Button, RouterLink, PricePipe, UsdPipe, DialogModule, FormsModule, InputTextareaModule],
    templateUrl: './client-selection-details.component.html',
    styleUrl: './client-selection-details.component.scss'
})
export class ClientSelectionDetailsComponent implements OnInit {
  private activatedRoute = inject(ActivatedRoute);
  private companyService = inject(CompanyService);
  private toastService = inject(ToastService);
  logo = signal<string>('');
  token: string;
  selection: ClientSelectionList;
  products: CatalogProductWithColors[];
  selectedProduct: CatalogProductWithColors | undefined;
  defaultVisibleLimit = 10;
  commentModal = false;
  commentValue = '';

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {
      this.token = params.token;
    });

    this.companyService
      .getSelectionByToken({
        token: this.token,
        'relations[]': [
          'catalogSelections.catalogProduct',
          'logo',
          'catalogProductCollections.catalogProducts.featuredImage',
          'catalogProductCollections.catalogProducts.catalogProductVariants.supplierProductVariant'
        ]
      })
      .subscribe(next => {
        const userSelectedItems = next.data.catalog_selections ?? [];
        this.logo.set(next.data.logo.full_path);
        const defaultSelection: CatalogProductWithColors[] =
          userSelectedItems.length > 0
            ? (userSelectedItems as CatalogProductWithColors[])
            : (next.data.catalog_product_collections?.flatMap(
                collection => collection.catalog_products
              ) as CatalogProductWithColors[]);

        this.products = defaultSelection?.map(catalogProduct => {
          const colors = catalogProduct.catalog_product_variants.map(catalogProductVariant => {
            return {
              ...catalogProductVariant,
              hex: catalogProductVariant.supplier_product_variant?.primary_hex_color,
              selected: catalogProductVariant.selected
            };
          });
          return {
            ...catalogProduct,
            colors,
            visibleColorsNumber: this.defaultVisibleLimit,
            showAll: false,
            comment: catalogProduct.comment
          };
        });
      });
  }

  removeProduct(product: CatalogProduct) {
    this.products = this.products.filter(item => item.id !== product.id);
  }

  getVisibleColors(product: CatalogProductWithColors) {
    return product.colors.slice(0, product.visibleColorsNumber);
  }

  getSelectedColors(colors: any[]) {
    const selected = colors.filter(color => color.selected).map(color => color.color);
    return selected.length > 0 ? selected : ['All'];
  }

  showLessColors($event: MouseEvent, product: CatalogProductWithColors) {
    $event.preventDefault();
    this.products = this.products.map(item => {
      if (item.id === product.id) {
        return { ...item, showAll: false, visibleColorsNumber: this.defaultVisibleLimit };
      }
      return item;
    });
  }

  showAllColors($event: MouseEvent, product: CatalogProductWithColors) {
    $event.preventDefault();
    this.products = this.products.map(item => {
      if (item.id === product.id) {
        return { ...item, showAll: true, visibleColorsNumber: product.colors.length };
      }
      return item;
    });
  }

  selectColor(product: CatalogProductWithColors, color: any) {
    this.products = this.products.map(item => {
      if (item.id === product.id) {
        return {
          ...item,
          colors: item.colors.map(col => {
            if (color.id === col.id) {
              return { ...col, selected: !col.selected };
            }
            return col;
          })
        };
      }
      return item;
    });
  }

  addComment(product: CatalogProductWithColors) {
    this.commentModal = true;
    this.selectedProduct = product;
    this.commentValue = product.comment ?? '';
  }

  closeCommentModal() {
    this.commentModal = false;
    this.commentValue = '';
    this.selectedProduct = undefined;
  }

  saveComment() {
    this.products = this.products.map(prod => {
      if (prod.id === this.selectedProduct?.id) {
        return { ...prod, comment: this.commentValue as string };
      }
      return prod;
    });

    this.closeCommentModal();
  }

  saveSelection() {
    const colors = this.products.map(prod => {
      return prod.colors.filter(color => color.selected);
    });
    const data = {
      token: this.token,
      catalog_products: this.products.map(product => {
        const colors = product.colors.filter(col => col.selected).map(col => col.id);
        return { id: product.id, comment: product.comment ?? '', catalog_product_variants: colors };
      })
    };
    this.companyService.editClientSelection(data).subscribe(next => {
      this.toastService.success('You selection is saved successfully!');
    });
  }
}
