import { Component, inject, OnInit, signal } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';

import { PrimeTemplate } from 'primeng/api';
import { Button } from 'primeng/button';
import { DataViewModule } from 'primeng/dataview';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { finalize } from 'rxjs';

import { ProductPaginatorOptions } from 'app/_shared/components/list-products/list-products.component';
import { Library } from 'app/_shared/models/Library';
import { User } from 'app/_shared/models/User';
import { RoleName } from 'app/_shared/models/UserRole';
import { LibraryService } from 'app/_shared/services/library.service';
import { ToastService } from 'app/_shared/services/toast.service';
import { AuthStore } from 'app/_store/auth.store';

@Component({
    selector: 'app-libraries-list',
    imports: [Button, DataViewModule, PrimeTemplate, RouterLink, ToggleButtonModule, FormsModule],
    templateUrl: './libraries-list.component.html',
    styleUrl: './libraries-list.component.scss'
})
export class LibrariesListComponent implements OnInit {
  private libraryService = inject(LibraryService);
  private activatedRoute = inject(ActivatedRoute);
  private authStore = inject(AuthStore);
  private toastService = inject(ToastService);
  private router = inject(Router);

  storefrontId: string;
  libraryFolderId: string;
  isLoading: false;
  libraries = signal<Library[]>([]);
  paginatorOptions: ProductPaginatorOptions = {
    total: 0,
    rows: 9,
    first: 0
  };
  account: User | null = null;
  UserRoleNames = RoleName;

  ngOnInit() {
    this.activatedRoute.params.subscribe(next => {
      this.account = this.authStore.user();
      this.storefrontId = next.storefrontId;
      this.libraryFolderId = next.libraryFolderId;
    });
  }

  getLibraries() {
    this.libraryService
      .getLibraryFolderLibraries(+this.storefrontId, +this.libraryFolderId)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe({
        next: data => {
          this.libraries.set(data.data);
        },
        error: err => {
          console.error(err);
        }
      });
  }

  navigateToLibrary(libraryId: number) {
    this.router.navigate(['libraries', libraryId], { relativeTo: this.activatedRoute });
  }

  changeLibraryApproveStatus(event: any, libraryId: number) {
    event?.originalEvent.preventDefault();
    event?.originalEvent.stopPropagation();

    this.libraryService.changeApproveStatus(+this.storefrontId, libraryId).subscribe({
      next: () => {
        this.toastService.success('The library status successfully chenged.');
        this.getLibraries();
      },
      error: err => {
        console.error(err);
      }
    });
  }
}
