import { Component, inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Button } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { ChipModule } from 'primeng/chip';
import { DockModule } from 'primeng/dock';
import { TableModule } from 'primeng/table';

import { Supplier } from 'app/_shared/interfaces/supplier';

import { StatusBadgeComponent } from '../../_shared/components/status-badge/status-badge.component';

@Component({
    selector: 'app-suppliers',
    imports: [TableModule, CardModule, ChipModule, StatusBadgeComponent, Button, DockModule],
    templateUrl: './suppliers.component.html',
    styleUrl: './suppliers.component.scss'
})
export class SuppliersComponent implements OnInit {
  private activatedRoute = inject(ActivatedRoute);
  private router = inject(Router);
  suppliers: Supplier[];

  ngOnInit() {
    this.activatedRoute.data.subscribe(next => {
      this.suppliers = next.suppliers.data;
    });
  }

  openSupplier(slug: string) {
    this.router.navigate([`suppliers/${slug}/products`]);
  }

  openSupplierDetails(slug: any) {
    this.router.navigate(['/suppliers', slug]);
  }
}
