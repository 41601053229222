import { DatePipe, NgClass } from '@angular/common';
import { Component, inject, OnInit, signal } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray, ReactiveFormsModule, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { ConfirmationService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CheckboxModule } from 'primeng/checkbox';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { EditorModule } from 'primeng/editor';
import { FileUploadEvent, FileSelectEvent, FileUploadModule } from 'primeng/fileupload';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { PanelModule } from 'primeng/panel';
import { RadioButtonModule } from 'primeng/radiobutton';
import { SelectButtonModule } from 'primeng/selectbutton';
import { StepsModule } from 'primeng/steps';
import { TableModule } from 'primeng/table';
import { debounceTime, finalize } from 'rxjs';

import { DateHelperService } from 'app/_shared/helpers/date-helper.service';
import { SlugHelper } from 'app/_shared/helpers/slug-helper';
import { AdditionalPhoto } from 'app/_shared/interfaces/storefront';
import { CustomProductVariant, CustomStorefrontProduct } from 'app/_shared/models/CustomProduct';
import { FormGroupValue } from 'app/_shared/models/FormGroup';
import { StorefrontProductCategory } from 'app/_shared/models/StorefrontProductCategory';
import { markAllAsTouched } from 'app/_shared/services/helper.service';
import { StorefrontProductCategoryService } from 'app/_shared/services/storefront-product-category.service';
import { StorefrontService } from 'app/_shared/services/storefront.service';
import { ToastService } from 'app/_shared/services/toast.service';

import { CustomProductVariantEditorComponent } from '../custom-product-variant-editor/custom-product-variant-editor.component';
import { StorefrontProductCategoryEditorComponent } from '../storefront-product-category-editor/storefront-product-category-editor.component';

@Component({
  selector: 'app-custom-product-editor',
  imports: [
    ReactiveFormsModule,
    StepsModule,
    InputTextModule,
    ButtonModule,
    CheckboxModule,
    PanelModule,
    EditorModule,
    FileUploadModule,
    CustomProductVariantEditorComponent,
    ConfirmDialogModule,
    InputSwitchModule,
    CalendarModule,
    RadioButtonModule,
    TableModule,
    SelectButtonModule,
    NgClass
  ],
  providers: [ConfirmationService, DialogService, DatePipe],
  templateUrl: './custom-product-editor.component.html',
  styleUrl: './custom-product-editor.component.scss'
})
export class CustomProductEditorComponent implements OnInit {
  private toastService = inject(ToastService);
  private confirmationService = inject(ConfirmationService);
  private dateHelperService = inject(DateHelperService);
  private slugHelper = inject(SlugHelper);
  private categoryService = inject(StorefrontProductCategoryService);
  private activatedRoute = inject(ActivatedRoute);
  private dialogService = inject(DialogService);
  private fb = inject(FormBuilder);
  private storefrontService = inject(StorefrontService);
  private datePipe = inject(DatePipe);
  private router = inject(Router);

  productForm: FormGroup;
  activeIndex: number = 0;
  editVariantDialog = false;
  steps = [{ label: 'Basic Info' }, { label: 'Product Variants' }, { label: 'Pricing' }, { label: 'Description' }];

  productStatusOptions = [
    { name: 'Draft', value: 'draft' },
    { name: 'Published', value: 'published' },
    { name: 'Scheduled publish', value: 'scheduled' }
  ];

  additionalPhotos: AdditionalPhoto[] = [];
  deletedAdditionalPhotosIds: number[] = [];
  variantForEdit: CustomProductVariant | null;
  variantForEditIndex: number | undefined;
  displayDateFormat: string;
  visibleSlugInput = false;
  productCategoryOptions = signal<StorefrontProductCategory[]>([]);
  storefrontId: string;
  customProductId: string;
  customProduct: CustomStorefrontProduct;

  expandedRows = {};
  lowestVariantPrice: number = 0;
  deletedVariants: number[] = [];
  deletedProductSizes: number[] = [];
  deletedVariantImages: number[] = [];
  ref: DynamicDialogRef | undefined;
  submitted = false;
  isLoading = false;

  ngOnInit() {
    this.displayDateFormat = this.dateHelperService.getDateFormat();
    this.activatedRoute.paramMap.subscribe(params => {
      this.storefrontId = params.get('storefrontId') ?? '';
      this.customProductId = params.get('customProductId') ?? '';
      this.fetchStorefrontProductCategories();
      if (this.customProductId) {
        this.fetchCustomProductById();
        return;
      }
    });
    this.buildForm();
    this.setNewMinimalPrice();
  }

  fetchCustomProductById() {
    this.storefrontService.getCustomProduct(this.storefrontId, this.customProductId).subscribe({
      next: res => {
        this.customProduct = res.data;
        this.buildForm();
        this.initializeAdditionalPhotos();
        this.setNewMinimalPrice();
      }
    });
  }

  fetchStorefrontProductCategories() {
    this.categoryService.index(+this.storefrontId).subscribe({
      next: res => {
        this.productCategoryOptions.set(res.data);
      },
      error: error => console.error(error)
    });
  }

  buildForm() {
    const product = this.customProduct;

    const preparedProductVariants = (product?.product_variants ?? []).map(variant => {
      const adjustedSizes = variant.product_variant_sizes?.map(size => ({
        ...size,
        net_price: size.net_price / 100
      }));

      return {
        ...variant,
        product_variant_sizes: adjustedSizes
      };
    });

    this.productForm = this.fb.group({
      name: [product?.name ?? '', Validators.required],
      slug: [product?.slug ?? '', Validators.required],
      product_categories: [product?.product_categories ?? []],
      description: [product?.description ?? '', Validators.required],
      short_description: [product?.short_description ?? ''],
      copy_underneath_description: [product?.copy_underneath_description ?? ''],
      line_name: [product?.line_name ?? 'line-name'],
      price: [product?.price_decimal ?? null, Validators.required],
      currency: [product?.currency ?? 'usd'],
      product_variants: this.fb.array(
        preparedProductVariants.map(variant => this.createVariantItemForm(variant)),
        Validators.required
      ),
      margin: [product?.margin_decimal ?? null, Validators.required],
      start_publish_date: [
        product?.start_publish_date ? this.dateHelperService.parseDateString(product?.start_publish_date) : null
      ],
      end_publish_date: [
        product?.end_publish_date ? this.dateHelperService.parseDateString(product?.end_publish_date) : null
      ],
      storefront_id: [product?.storefront_id ?? this.storefrontId],
      active: [product?.active ?? 1],
      status: [product?.status ?? 'published']
    });
    this.subscribeToFormChanges();
  }

  initializeAdditionalPhotos() {
    (this.customProduct.additional_photos ?? []).forEach(photo =>
      this.additionalPhotos.push({
        id: photo.id,
        objectURL: photo?.full_path,
        fileName: photo.name
      })
    );
  }

  subscribeToFormChanges() {
    const statusControl = this.productForm.get('status');
    const nameControl = this.productForm.get('name');
    const slugControl = this.productForm.get('slug');
    const marginControl = this.productForm.get('margin');
    const priceControl = this.productForm.get('price');
    const startPublishDate = this.productForm.get('start_publish_date') as FormControl;
    const endPublishDate = this.productForm.get('end_publish_date') as FormControl;

    statusControl?.valueChanges.subscribe(status => {
      startPublishDate.clearValidators();
      if (status === 'scheduled') {
        startPublishDate.addValidators([Validators.required]);
      } else {
        startPublishDate.reset();
        endPublishDate.reset();
        startPublishDate.clearValidators();
        endPublishDate.clearValidators();
        startPublishDate.markAsUntouched();
        endPublishDate.markAsUntouched();
      }
      startPublishDate.updateValueAndValidity();
      endPublishDate.updateValueAndValidity();
    });

    nameControl?.valueChanges.pipe(debounceTime(500)).subscribe(productName => {
      if (productName) {
        this.slugHelper.generateUniqueProductSlug(+this.storefrontId, productName).subscribe(uniqueSlug => {
          slugControl?.setValue(uniqueSlug, { emitEvent: false });
        });
      }
    });

    slugControl?.valueChanges.pipe(debounceTime(500)).subscribe(slug => {
      if (slug) {
        this.slugHelper.generateUniqueProductSlug(+this.storefrontId, slug).subscribe(uniqueSlug => {
          slugControl?.setValue(uniqueSlug, { emitEvent: false });
        });
      }
    });

    marginControl?.valueChanges.pipe(debounceTime(500)).subscribe(margin => {
      priceControl?.setValue(+margin + +this.lowestVariantPrice);
    });
  }

  createVariantFormGroup(variant: CustomProductVariant) {
    return this.fb.group({
      id: [variant.id],
      color: [variant.color, [Validators.required]],
      variant_images: [variant.variant_images],
      product_variant_sizes: this.fb.array(
        variant.product_variant_sizes?.map(size =>
          this.fb.group({
            id: [size.id],
            quantity: [size.quantity, [Validators.required]],
            size: [size.size, [Validators.required]],
            weight: [size.weight, [Validators.required]],
            net_price: [size.net_price, [Validators.required]]
          })
        ) ?? []
      )
    });
  }

  createVariantItemForm(variant: CustomProductVariant) {
    return this.createVariantFormGroup(variant);
  }

  get variants() {
    return this.productForm.get('product_variants') as FormArray;
  }

  nextStep() {
    if (this.activeIndex < this.steps.length - 1) {
      switch (this.activeIndex) {
        case 0:
          if (this.basicInfoValid()) {
            this.activeIndex++;
          } else {
            this.toastService.error('Please fill all required fields correctly!');
          }
          break;
        case 1:
          if (this.variantsValid()) {
            this.activeIndex++;
          } else {
            this.toastService.error('Variants are reqired');
          }
          break;
        case 2:
          if (this.pricingValid()) {
            this.activeIndex++;
          }
          break;
      }
    }
  }

  previousStep() {
    if (this.activeIndex > 0) {
      this.activeIndex--;
    }
  }

  basicInfoValid() {
    this.productForm.get('name')?.markAsTouched();
    this.productForm.get('slug')?.markAsTouched();
    this.productForm.get('description')?.markAsTouched();
    return (
      this.productForm.get('name')?.valid &&
      this.productForm.get('slug')?.valid &&
      this.productForm.get('description')?.valid
    );
  }

  variantsValid() {
    return this.variants.valid;
  }

  pricingValid() {
    return this.productForm.get('margin')?.valid;
  }

  onAdditionalPhotosUpload(event: FileUploadEvent | FileSelectEvent) {
    for (const file of event.files) {
      const objectURL = URL.createObjectURL(file);

      const reader = new FileReader();
      reader.onload = (e: any) => {
        const base64String = e.target.result;
        this.additionalPhotos.push({
          file,
          objectURL,
          fileName: file.name,
          base64: base64String
        });
      };

      reader.readAsDataURL(file);
    }
  }

  removeAdditionalPhoto(index: number): void {
    const imageToDelete = this.additionalPhotos[index];
    if (imageToDelete.id) {
      this.deletedAdditionalPhotosIds.push(imageToDelete.id);
    }

    this.additionalPhotos = [...this.additionalPhotos.slice(0, index)];
  }

  openEditVariantModal(variant?: any, index?: number) {
    this.variantForEditIndex = index;
    this.variantForEdit = { ...variant };
    this.editVariantDialog = true;
  }

  addOrUpdateVariant(variant: CustomProductVariant) {
    if (this.variantForEditIndex !== null && this.variantForEditIndex !== undefined) {
      const variantFormGroup = this.variants.at(this.variantForEditIndex);

      variantFormGroup.patchValue({
        id: variant.id,
        color: variant.color,
        variant_images: variant.variant_images
      });

      const sizesArray = variantFormGroup.get('product_variant_sizes') as FormArray;
      sizesArray.clear();

      variant.product_variant_sizes?.forEach(size => {
        sizesArray.push(
          this.fb.group({
            id: [size.id],
            quantity: [size.quantity, [Validators.required]],
            size: [size.size, [Validators.required]],
            weight: [size.weight, [Validators.required]],
            net_price: [size.net_price, [Validators.required]]
          })
        );
      });

      this.variantForEditIndex = undefined;
    } else {
      const variantGroup = this.createVariantFormGroup(variant);
      this.variants.push(variantGroup);
    }

    this.variantForEditIndex = undefined;
    this.variantForEdit = null;

    this.hideDialog();
    this.setNewMinimalPrice();
  }

  hideDialog() {
    this.editVariantDialog = false;
  }

  handleDeletedProductSizes(deletedSizes: number[]) {
    this.deletedProductSizes = deletedSizes;
  }

  handleDeletedVariantImages(deletedImagesIds: number[]) {
    this.deletedVariantImages = deletedImagesIds;
  }

  openDeleteVariantModal(event: Event, index: number) {
    this.confirmationService.confirm({
      key: 'confirmDelete',
      target: event.target || new EventTarget(),
      message: 'Are you sure that you want to delete variant',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.deleteCatalogProductVariant(index);
      }
    });
  }

  deleteCatalogProductVariant(index: number) {
    const variantId = this.variants.at(index)?.value.id;
    if (variantId) {
      this.deletedVariants.push(variantId);
    }
    this.variants.removeAt(index);
    this.setNewMinimalPrice();
  }

  openCategoryEditor(category?: StorefrontProductCategory) {
    const dialogRef = this.dialogService.open(StorefrontProductCategoryEditorComponent, {
      header: category ? 'Edit Category' : 'Add New Category',
      width: '38rem',
      height: 'auto',
      data: {
        category,
        storefrontId: +this.storefrontId,
        storeCategory: false
      }
    });

    dialogRef.onClose.subscribe((newCategory: StorefrontProductCategory | null) => {
      if (newCategory) {
        this.productCategoryOptions.set([...this.productCategoryOptions(), newCategory]);
        const currentCategories = this.productForm.get('product_categories')?.value || [];
        this.productForm.get('product_categories')?.setValue([...currentCategories, newCategory]);
      }
    });
  }

  setNewMinimalPrice() {
    let minPrice = Infinity;

    this.variants?.value.forEach(variant => {
      if (variant.product_variant_sizes && variant.product_variant_sizes.length > 0) {
        variant.product_variant_sizes.forEach(size => {
          if (+size.net_price < minPrice) {
            minPrice = +size.net_price;
          }
        });
      }
    });

    this.lowestVariantPrice = minPrice === Infinity ? 0 : minPrice;
  }

  onSubmit() {
    if (this.productForm.invalid) {
      this.submitted = true;
      markAllAsTouched(this.productForm);
      this.toastService.error('Please fill all required fields correctly!');
      return;
    }
    const preparedData = this.prepareSubmitData(this.productForm);
    if (!this.customProduct) {
      this.createCustomProduct(preparedData);
      return;
    }
    this.updateCustomProduct(preparedData);
  }

  prepareSubmitData(formData: FormGroupValue) {
    const { start_publish_date, product_categories, end_publish_date, price, margin } = formData.value;

    const startPublishDate = start_publish_date
      ? this.datePipe.transform(start_publish_date, 'yyyy-MM-dd HH:mm')
      : null;

    const endPublishDate = end_publish_date ? this.datePipe.transform(end_publish_date, 'yyyy-MM-dd HH:mm') : null;

    const formattedCategories = product_categories || [];
    const mappedCategories = formattedCategories.map(category =>
      category.id ? { id: category.id } : { name: category.name, slug: category.slug }
    );

    const mappedAdditionalPhotos = this.additionalPhotos.filter(photo => photo.file).map(photo => photo.base64);

    const prepared = {
      ...formData.value,
      start_publish_date: startPublishDate,
      end_publish_date: endPublishDate,
      product_categories: mappedCategories,
      deleted_additional_photos: this.deletedAdditionalPhotosIds,
      additional_photos: mappedAdditionalPhotos,
      deleted_product_variants: this.deletedVariants,
      deleted_product_sizes: this.deletedProductSizes,
      deleted_variant_images: this.deletedVariantImages,
      price: price,
      margin: margin * 100,
      ...(this.variants.length > 0 ? { product_variants: this.prepareVariants() } : {})
    };

    Object.keys(prepared).forEach(key => {
      if (!prepared[key]) {
        delete prepared[key];
      }
    });

    return prepared;
  }

  prepareVariants() {
    return this.variants.value.map(variant => {
      const preparedImages = variant.variant_images.filter(image => !image.id).map(image => image.url);

      const formattedVariantSizes = variant.product_variant_sizes?.map(size => ({
        ...size,
        net_price: size.net_price * 100
      }));

      return {
        sizes: formattedVariantSizes,
        color: variant.color,
        ...(variant.id && { id: variant.id }),
        ...(preparedImages.length > 0 && { images: preparedImages })
      };
    });
  }

  createCustomProduct(data: FormGroupValue) {
    this.isLoading = true;
    this.storefrontService
      .createCustomStorefrontProduct(this.storefrontId, data)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe(() => {
        this.toastService.success('You have successfully created the product!');
        this.router.navigate(['../../'], { relativeTo: this.activatedRoute });
      });
  }

  updateCustomProduct(data: FormGroupValue) {
    this.isLoading = true;
    this.storefrontService
      .updateCustomStorefrontProduct(this.storefrontId, data, this.customProductId)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe(() => {
        this.toastService.success('Custom product updated successfully!');
        this.router.navigate(['../../'], { relativeTo: this.activatedRoute });
      });
  }
}
