import { CurrencyPipe, DatePipe } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { Button } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CheckboxModule } from 'primeng/checkbox';
import { DialogModule } from 'primeng/dialog';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { InputGroupModule } from 'primeng/inputgroup';
import { InputGroupAddonModule } from 'primeng/inputgroupaddon';
import { InputTextModule } from 'primeng/inputtext';
import { PaginatorModule } from 'primeng/paginator';
import { RadioButtonModule } from 'primeng/radiobutton';

import { InputComponent } from 'app/_shared/components/input/input.component';
import { DateHelperService } from 'app/_shared/helpers/date-helper.service';
import { GiftCard, GiftCardCode } from 'app/_shared/models/Coupon';
import { GiftCardService } from 'app/_shared/services/gift-card.service';
import { markAllAsTouched } from 'app/_shared/services/helper.service';
import { ToastService } from 'app/_shared/services/toast.service';
import { PositiveIntegerValidator } from 'app/_shared/validators/positive-integer.validator';
import { RecipientsManagementComponent } from 'app/admin/storefront/coupons/gift-cards/recipients-management/recipients-management.component';

@Component({
  selector: 'app-gift-card-editor',
  imports: [
    InputTextModule,
    PaginatorModule,
    ReactiveFormsModule,
    InputComponent,
    RadioButtonModule,
    Button,
    DialogModule,
    CurrencyPipe,
    InputGroupAddonModule,
    InputGroupModule,
    CheckboxModule,
    CalendarModule
  ],
  providers: [DialogService, DatePipe],
  templateUrl: './gift-card-editor.component.html',
  styleUrl: './gift-card-editor.component.scss'
})
export class GiftCardEditorComponent implements OnInit {
  private fb: FormBuilder = inject(FormBuilder);
  private toastService: ToastService = inject(ToastService);
  private dialogService: DialogService = inject(DialogService);
  private giftCardService: GiftCardService = inject(GiftCardService);
  private activatedRoute: ActivatedRoute = inject(ActivatedRoute);
  private router: Router = inject(Router);
  private dateHelperService: DateHelperService = inject(DateHelperService);
  private datePipe: DatePipe = inject(DatePipe);

  form: FormGroup;
  ref: DynamicDialogRef | undefined;
  todayDate = new Date();
  isLoading = false;
  storefrontId: string;
  giftCardId: string;
  giftCardForEdit: GiftCard;
  displayDateFormat: string;

  ngOnInit() {
    this.displayDateFormat = this.dateHelperService.getDateFormat();
    this.activatedRoute.paramMap.subscribe(params => {
      this.storefrontId = params.get('storefrontId') ?? '';
      this.giftCardId = params.get('giftCardId') ?? '';
      if (this.giftCardId) {
        this.fetchGiftCard();
        return;
      }
      this.buildForm();
    });
  }

  fetchGiftCard() {
    const params = {
      'relations[]': ['codes']
    };
    this.giftCardService.find(this.storefrontId, this.giftCardId, params).subscribe({
      next: res => {
        this.giftCardForEdit = res.data;
        this.buildForm();
      },
      error: err => {
        console.error('Operation failed:', err);
      }
    });
  }

  buildForm() {
    this.form = this.fb.group({
      name: [this.giftCardForEdit?.name ?? null, Validators.required],
      specified_recipients: [this.giftCardForEdit?.specified_recipients ?? true, Validators.required],
      codes: this.fb.array(this.buildCodesFormArray()),
      tiers: this.fb.array(this.buildTieresFormArray()),
      cover_sales_tax: [!!this.giftCardForEdit?.cover_sales_tax],
      cover_shipping: [!!this.giftCardForEdit?.cover_shipping],
      single_use: [!!this.giftCardForEdit?.single_use],
      expiration_date: [
        this.giftCardForEdit?.expiration_date
          ? this.dateHelperService.parseDateString(this.giftCardForEdit?.expiration_date)
          : null
      ]
    });

    if (!this.giftCardForEdit) {
      this.addNewTier();
    }
  }

  buildCodesFormArray() {
    if (this.giftCardForEdit?.codes && !!this.giftCardForEdit.specified_recipients) {
      return this.giftCardForEdit.codes.map(code => this.createRecepientsCodeItemForm(code));
    }

    return [];
  }

  createRecepientsCodeItemForm(code: GiftCardCode) {
    const codeAmount = code.id ? code.amount / 100 : code.amount;
    return this.fb.group({
      name: [code.name, [Validators.required]],
      email: [code.email, [Validators.required, Validators.email]],
      amount: [codeAmount, [Validators.required]],
      notify: [code.notify ?? false]
    });
  }

  buildTieresFormArray() {
    if (this.giftCardForEdit?.codes && !this.giftCardForEdit.specified_recipients) {
      const groupedByAmount = this.groupCodesByAmount(this.giftCardForEdit.codes);
      return Object.entries(groupedByAmount).map(([amount, quantity]) =>
        this.fb.group({
          quantity: [quantity, [Validators.required, PositiveIntegerValidator()]],
          amount: [+amount / 100, [Validators.required, Validators.min(0)]]
        })
      );
    }
    return [];
  }

  groupCodesByAmount(codes: GiftCardCode[]): { [amount: number]: number } {
    const grouped: { [amount: number]: number } = {};

    codes.forEach(code => {
      if (grouped[code.amount]) {
        grouped[code.amount] += 1;
      } else {
        grouped[code.amount] = 1;
      }
    });

    return grouped;
  }

  openRecipientsModal() {
    this.ref = this.dialogService.open(RecipientsManagementComponent, {
      header: 'Manage Gift Card Recipients',
      maximizable: true,
      width: '95%',
      data: {
        recipients: this.form.value.codes
      }
    });

    this.ref.onClose.subscribe({
      next: res => {
        if (res) {
          const { recipients } = res;
          const codesArray = this.form.get('codes') as FormArray;
          codesArray.clear();

          recipients.forEach(element => {
            codesArray.push(this.createRecepientsCodeItemForm(element));
          });
        }
      },
      error: err => {
        this.toastService.error('Error closing the dialog');
        console.error('Error closing the dialog:', err);
      }
    });
  }

  newTierRow() {
    return this.fb.group({
      quantity: [null, [Validators.required, PositiveIntegerValidator()]],
      amount: [null, [Validators.required, PositiveIntegerValidator()]]
    });
  }

  get tiers() {
    return this.form.get('tiers') as FormArray;
  }

  get totalAmount() {
    return this.tiers.controls.reduce((acc, tier) => acc + tier.value.quantity * tier.value.amount, 0);
  }

  addNewTier() {
    this.tiers.push(this.newTierRow());
  }

  removeTier(i: number) {
    this.tiers.removeAt(i);
  }

  prepareDataForSubmit() {
    const formValue = { ...this.form.value };

    if (!formValue.expiration_date) {
      delete formValue.expiration_date;
    }

    if (formValue.specified_recipients) {
      this.tiers.clear();
      formValue.tiers = [];
    } else {
      formValue.codes = [];
    }

    const { expiration_date } = formValue;

    const expirationDate = expiration_date ? this.datePipe.transform(expiration_date, 'yyyy-MM-dd') : null;

    if (formValue.tiers && formValue.tiers.length > 0) {
      formValue.tiers = formValue.tiers.map(tier => ({
        ...tier,
        amount: tier.amount * 100
      }));
    }

    if (formValue.codes && formValue.codes.length > 0) {
      formValue.codes = formValue.codes.map(code => ({
        ...code,
        amount: code.amount * 100
      }));
    }

    return {
      ...formValue,
      ...(expirationDate && { expiration_date: expirationDate })
    };
  }

  createGiftCards(submitData: GiftCard) {
    this.isLoading = true;
    this.giftCardService.store(+this.storefrontId, submitData).subscribe({
      next: () => {
        this.toastService.success('Successfully created gift card!');
        this.router.navigate([`../`], { relativeTo: this.activatedRoute });
      },
      error: err => {
        console.error('Operation failed:', err);
      },
      complete: () => {
        this.isLoading = false;
      }
    });
  }

  updateGiftCards(submitData: GiftCard) {
    this.isLoading = true;
    this.giftCardService.update(this.storefrontId, this.giftCardId, submitData).subscribe({
      next: () => {
        this.toastService.success('Successfully updated gift card!');
        this.router.navigate([`../../`], { relativeTo: this.activatedRoute });
      },
      error: err => {
        console.error('Operation failed:', err);
      },
      complete: () => {
        this.isLoading = false;
      }
    });
  }

  formSubmit() {
    const formValue = this.prepareDataForSubmit();

    if (this.form.valid) {
      if (!this.giftCardId) {
        this.createGiftCards(formValue);
      } else {
        this.updateGiftCards(formValue);
      }
    } else {
      this.toastService.error('Please fill all required fields correctly!');
      markAllAsTouched(this.form);
    }
  }
}
