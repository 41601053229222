import { Injectable } from '@angular/core';

import { PromoCode } from 'app/_shared/models/Coupon';
import { ApiService } from 'app/_shared/services/api.service';

@Injectable({ providedIn: 'root' })
export class PromoCodeService extends ApiService {
  routePrefix = `${this.apiUrl}storefronts/`;

  public paginatedIndex(storefrontId: number, $event?: any) {
    return this.get(`${this.routePrefix}${storefrontId}/promo-codes`, {
      params: this.handleTableLazyLoadEvent($event)
    });
  }

  store(storefrontId: number, promoCode: PromoCode) {
    return this.post<PromoCode>(`${this.routePrefix}${storefrontId}/promo-codes`, promoCode);
  }

  update(storefrontId: number, promoCodeId: string, promoCode: PromoCode) {
    return this.put<PromoCode>(`${this.routePrefix}${storefrontId}/promo-codes/${promoCodeId}`, promoCode);
  }

  remove(storefrontId: number, promoCodeId: number) {
    return this.delete(`${this.routePrefix}${storefrontId}/promo-codes/${promoCodeId}`);
  }

  getById(storefrontId: number, promoCodeId: string) {
    return this.get<PromoCode>(`${this.routePrefix}${storefrontId}/promo-codes/${promoCodeId}`, {
      params: {
        'relations[]': ['products', 'productCategories', 'products.productThumbnail']
      }
    });
  }
}
