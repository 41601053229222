<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <div formArrayName="permissions">
    @for (permissionControl of permissionsArray.controls; let i = $index; track $index) {
      <div [formGroupName]="i" class="mb-3">
        <p-checkbox
          [label]="permissionsArray.at(i).get('name')?.value"
          [binary]="true"
          formControlName="granted"
        ></p-checkbox>
      </div>
    }
  </div>
  <div class="flex pt-4 justify-content-between">
    <p-button label="Close" severity="secondary" />
    <p-button [disabled]="isLoading" type="submit" label="Update Permissions" />
  </div>
</form>
