import { inject } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, CanActivateFn, Router } from '@angular/router';

import { AuthStore } from 'app/_store/auth.store';

import { AccessControlService } from '../helpers/access-control.service';

export const storefrontPermission: CanActivateFn = (route: ActivatedRouteSnapshot) => {
  const router = inject(Router);
  const activatedRoute = inject(ActivatedRoute);
  const accessControlService = inject(AccessControlService);
  const authStore = inject(AuthStore);

  if (authStore.user() && authStore.isSuperAdmin()) {
    return true;
  }

  const action = route.data['action'];
  const companyId = route.paramMap.get('id');
  const storefrontId = route.paramMap.get('storefrontId');

  if (!companyId || !storefrontId) {
    router.navigate(['../'], { relativeTo: activatedRoute });
    return false;
  }

  const hasPermission = accessControlService.canPerformStorefrontAction(companyId, storefrontId, action);
  if (!hasPermission) {
    router.navigateByUrl(`companies/${companyId}/storefronts`);
    return false;
  }

  return true;
};
