import { NgClass } from '@angular/common';
import { ChangeDetectorRef, Component, effect, inject, input, OnInit, output, signal } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';

import { Button } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { ChipModule } from 'primeng/chip';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { EditorModule } from 'primeng/editor';
import { FileSelectEvent, FileUploadModule } from 'primeng/fileupload';
import { PanelModule } from 'primeng/panel';
import { RadioButtonModule } from 'primeng/radiobutton';
import { SelectButtonModule } from 'primeng/selectbutton';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { TooltipModule } from 'primeng/tooltip';
import { debounceTime } from 'rxjs';

import { InputComponent } from 'app/_shared/components/input/input.component';
import { DateHelperService } from 'app/_shared/helpers/date-helper.service';
import { SlugHelper } from 'app/_shared/helpers/slug-helper';
import { PrintArea } from 'app/_shared/interfaces/catalog';
import { AdditionalPhoto, StorefrontProduct } from 'app/_shared/interfaces/storefront';
import { Library, LibraryFile } from 'app/_shared/models/LibraryFile';
import { StorefrontProductCategory } from 'app/_shared/models/StorefrontProductCategory';
import { LibraryService } from 'app/_shared/services/library.service';
import { StorefrontProductCategoryService } from 'app/_shared/services/storefront-product-category.service';
import { ToastService } from 'app/_shared/services/toast.service';
import { StorefrontStore } from 'app/_store/storefront.store';
import { CatalogProductVariantWithChecked } from 'app/admin/storefront/products/create-product/create-product.component';
import { StorefrontProductCategoryEditorComponent } from 'app/admin/storefront/products/storefront-product-category-editor/storefront-product-category-editor.component';

@Component({
  selector: 'app-product-details',
  imports: [
    Button,
    ReactiveFormsModule,
    SelectButtonModule,
    PanelModule,
    NgClass,
    InputComponent,
    EditorModule,
    RadioButtonModule,
    CalendarModule,
    ToggleButtonModule,
    FormsModule,
    FileUploadModule,
    TooltipModule,
    ChipModule
  ],
  templateUrl: './product-details.component.html',
  styleUrl: './product-details.component.scss'
})
export class ProductDetailsComponent implements OnInit {
  product = input.required<StorefrontProduct>();
  editMode = input.required<boolean>();
  isSubmitting = input<boolean>();
  selectedPrintArea = input.required<PrintArea>();
  numberOfPrints = input.required<number>();
  storefrontId = input.required<string>();
  productsInQue = input<number[]>();
  addedPrints = input<number>();
  productVariants = input.required<CatalogProductVariantWithChecked[]>();
  handleFormSubmit = output();
  // setProductImage = output<string>();
  handlePrintAreaImageUpload = output<Library>();
  handleOpenMediaLibraryModal = output();
  selectAllVariants = output<boolean>();
  removePrint = output<string>();
  handleVariantSelect = output<{ checked: boolean; variantId: number }>();

  private fb = inject(FormBuilder);
  toastService = inject(ToastService);
  storefrontStore = inject(StorefrontStore);
  private dateHelperService = inject(DateHelperService);
  private slugHelper = inject(SlugHelper);
  private libraryService = inject(LibraryService);
  private categoryService = inject(StorefrontProductCategoryService);
  private dialogRefCategory = inject(DynamicDialogRef);
  private dialogService = inject(DialogService);
  private cdr = inject(ChangeDetectorRef);
  productCategoryOptions = signal<StorefrontProductCategory[]>([]);
  displayDateFormat: string;
  visibleSlugInput = false;
  latestUsedMedias: LibraryFile[];
  additionalPhotos: AdditionalPhoto[] = [];
  deletedAdditionalPhotosIds: number[] = [];
  decorationMethodOptions = [
    { name: 'DTG', value: 'dtg' },
    { name: 'EMBROIDERY', value: 'embroidery' }
  ];
  productStatusOptions = [
    { name: 'Draft', value: 'draft' },
    { name: 'Published', value: 'published' },
    { name: 'Scheduled publish', value: 'scheduled' }
  ];
  form: FormGroup;

  calculatePriceEffect = effect(() => {
    // This effect is recalculating price when prints are added/removed from the product
    if (this.form) {
      const print_price_decimal = this.product().catalog_product.catalog_product_configuration
        ? this.product().catalog_product.catalog_product_configuration?.print_price_decimal
        : this.product().catalog_product_configuration.print_price_decimal;
      const print_price_second_decimal = this.product().catalog_product.catalog_product_configuration
        ? this.product().catalog_product.catalog_product_configuration?.print_price_second_decimal
        : this.product().catalog_product_configuration.print_price_second_decimal;
      const startingPrice =
        (this.product()?.catalog_product?.starting_price ?? this.product()?.starting_price ?? 0) / 100;
      const calculatePrintPrice = () => {
        if (this.numberOfPrints() === 0) {
          return 0;
        } else if (this.numberOfPrints() === 1) {
          return print_price_decimal ?? 0;
        } else {
          return (print_price_decimal ?? 0) + (this.numberOfPrints() - 1) * (print_price_second_decimal ?? 0);
        }
      };
      const printsPrice = calculatePrintPrice();
      const price = startingPrice + printsPrice;
      this.form.patchValue({ total_cost: price.toFixed(2) });
      this.form.updateValueAndValidity();
    }
  });
  queEffect = effect(() => {
    // This effect is to update form when there are multiple products in queue
    // When we are creating products from client-selection
    const product = this.product();
    if (!this.editMode() && this.product()?.name) {
      this.generateAndSetUniqueSlug(this.product()?.name ?? '');
    }
    if (this.form && !this.editMode()) {
      const margin = parseInt(this.storefrontStore?.selectedStorefront()?.price_margin ?? '0');
      const startingPrice = (product?.starting_price ?? 0) / 100;
      const round = this.storefrontStore.selectedStorefront()?.price_rounding;
      const finalPrice = startingPrice / ((100 - +margin) / 100);
      const roundedPrice = round ? Math.floor(finalPrice) + +round : finalPrice;
      this.form.patchValue(
        {
          name: product.name ?? '',
          slug: product.slug ?? '',
          price: product.price ?? 10,
          description: product.description ?? '',
          short_description: product.short_description ?? '',
          copy_underneath_description: product.copy_underneath_description ?? '',
          line_name: product.line_name ?? 'line-name',
          currency: product.currency ?? 'usd',
          active: product.active ?? 1,
          status: product.status ?? 'published',
          start_publish_date: product.start_publish_date
            ? this.dateHelperService.parseDateString(product.start_publish_date)
            : null,
          end_publish_date: product.end_publish_date
            ? this.dateHelperService.parseDateString(product.end_publish_date)
            : null,
          margin: margin,
          final_price: roundedPrice.toFixed(2),
          total_cost: startingPrice
        },
        { emitEvent: false }
      );
      this.form.updateValueAndValidity();
    }

    this.initializeAdditionalPhotos();
  });

  ngOnInit() {
    this.displayDateFormat = this.dateHelperService.getDateFormat();
    this.fetchLatestUsedFiles();
    this.fetchStorefrontProductCategories();
    this.buildForm(this.product());
    this.initializeAdditionalPhotos();
  }

  initializeAdditionalPhotos() {
    this.additionalPhotos = [];
    (this.product().additional_photos ?? []).forEach(photo =>
      this.additionalPhotos.push({
        id: photo.id,
        objectURL: photo?.full_path,
        fileName: photo.name
      })
    );
  }

  fetchStorefrontProductCategories() {
    this.categoryService.index(+this.storefrontId()).subscribe({
      next: res => {
        this.productCategoryOptions.set(res.data);
      },
      error: error => console.error(error)
    });
  }

  fetchLatestUsedFiles() {
    this.libraryService.getLatestUsedFiles(+this.storefrontId()).subscribe({
      next: res => {
        this.latestUsedMedias = res.data;
        console.log(this.latestUsedMedias);
      },
      error: error => console.error(error)
    });
  }

  buildForm(product: StorefrontProduct) {
    const margin = product?.margin_decimal ?? parseInt(this.storefrontStore?.selectedStorefront()?.price_margin ?? '0');
    const startingPrice = (product?.starting_price ?? 0) / 100;
    const finalPrice = this.editMode() ? product.final_price! / 100 : startingPrice / ((100 - +margin) / 100);
    const round = this.storefrontStore.selectedStorefront()?.price_rounding;
    const roundedPrice = round ? Math.floor(finalPrice) + +round : finalPrice;

    this.form = this.fb.group({
      name: [product.name ?? '', Validators.required],
      slug: [product.slug ?? '', Validators.required],
      price: [product.price ?? 10, Validators.required],
      description: [product.description, Validators.required],
      short_description: [product.short_description ?? ''],
      copy_underneath_description: [product.copy_underneath_description ?? ''],
      line_name: [product.line_name ?? 'line-name'],
      currency: [product.currency ?? 'usd'],
      active: [product.active ?? 1],
      status: [product.status ?? 'published'],
      start_publish_date: new FormControl(
        product.end_publish_date ? this.dateHelperService.parseDateString(product.start_publish_date) : null
      ),
      end_publish_date: new FormControl(
        product.end_publish_date ? this.dateHelperService.parseDateString(product.end_publish_date) : null
      ),
      margin: [margin, Validators.max(99)],
      final_price: roundedPrice.toFixed(2),
      total_cost: this.editMode() ? product.total_cost! / 100 : startingPrice
    });

    this.subscribeToFormChanges();
    if (!this.editMode() && this.product()?.name) {
      this.generateAndSetUniqueSlug(this.product()?.name ?? '');
    }
  }

  subscribeToFormChanges() {
    const statusControl = this.form.get('status');
    const nameControl = this.form.get('name');
    const slugControl = this.form.get('slug');
    const totalCost = this.form.get('total_cost');
    const finalPrice = this.form.get('final_price');
    const margin = this.form.get('margin');
    const startPublishDate = this.form.get('start_publish_date') as FormControl;
    const endPublishDate = this.form.get('end_publish_date') as FormControl;

    statusControl?.valueChanges.subscribe(status => {
      startPublishDate.clearValidators();
      if (status === 'scheduled') {
        startPublishDate.addValidators([Validators.required]);
      } else {
        startPublishDate.reset();
        endPublishDate.reset();
      }
      startPublishDate.updateValueAndValidity();
      endPublishDate.updateValueAndValidity();
    });

    nameControl?.valueChanges.pipe(debounceTime(500)).subscribe(productName => {
      this.slugHelper.generateUniqueProductSlug(+this.storefrontId(), productName).subscribe(uniqueSlug => {
        slugControl?.setValue(uniqueSlug, { emitEvent: false });
      });
    });

    slugControl?.valueChanges.pipe(debounceTime(500)).subscribe(slug => {
      this.slugHelper.generateUniqueProductSlug(+this.storefrontId(), slug).subscribe(uniqueSlug => {
        slugControl?.setValue(uniqueSlug, { emitEvent: false });
      });
    });
    margin?.valueChanges.subscribe(margin => {
      const remainingPercentage = (100 - margin) / 100;
      const totalPrice = this.form.value.total_cost / remainingPercentage; // Reverse margin calculation
      const round = this.storefrontStore.selectedStorefront()?.price_rounding;
      const roundedPrice = round ? Math.floor(totalPrice) + +round : totalPrice;

      finalPrice?.setValue(roundedPrice.toFixed(2));
    });
    totalCost?.valueChanges.subscribe(price => {
      const remainingPercentage = (100 - this.form.value.margin) / 100;
      const totalPrice = price / remainingPercentage; // Reverse margin calculation
      const round = this.storefrontStore.selectedStorefront()?.price_rounding;
      const roundedPrice = round ? Math.floor(totalPrice) + +round : totalPrice;

      finalPrice?.setValue(roundedPrice.toFixed(2));
    });
  }

  generateAndSetUniqueSlug(productName: string) {
    this.slugHelper.generateUniqueProductSlug(+this.storefrontId(), productName).subscribe(uniqueSlug => {
      this.form.get('slug')?.setValue(uniqueSlug, { emitEvent: false });
    });
  }

  variantSelectChange(variant: CatalogProductVariantWithChecked) {
    this.handleVariantSelect.emit({ checked: !variant.checked, variantId: variant.id! });
  }
  toBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = reject;
    });
  }

  async onAdditionalPhotosUpload(event: FileSelectEvent) {
    for (const file of event.files) {
      const base64 = (await this.toBase64(file)) as string;
      const objectURL = URL.createObjectURL(file);
      this.additionalPhotos.push({
        objectURL,
        file,
        fileName: file.name,
        base64
      });
      this.cdr.detectChanges();
    }
  }

  removeAdditionalPhoto(index: number): void {
    const imageToDelete = this.additionalPhotos[index];
    if (imageToDelete.id) {
      this.deletedAdditionalPhotosIds.push(imageToDelete.id);
    }

    this.additionalPhotos = this.additionalPhotos.filter((_, i) => i !== index);
    this.toastService.success('Photo removed.');
  }

  openCategoryEditor(category?: StorefrontProductCategory) {
    this.dialogRefCategory = this.dialogService.open(StorefrontProductCategoryEditorComponent, {
      header: category ? 'Edit Category' : 'Add New Category',
      width: '38rem',
      height: 'auto',
      data: {
        category,
        storefrontId: +this.storefrontId(),
        storeCategory: false
      }
    });
    this.dialogRefCategory.onClose.subscribe((newCategory: StorefrontProductCategory | null) => {
      if (newCategory) {
        this.productCategoryOptions.set([...this.productCategoryOptions(), newCategory]);
        const currentCategories = this.form.get('product_categories')?.value || [];
        this.form.get('product_categories')?.setValue([...currentCategories, newCategory]);
      }
    });
  }

  onSubmit(status: string = 'published') {
    if (this.form.value.description === '<p></p>') {
      this.form.get('description')?.setErrors({ invalid: true });
      this.form.updateValueAndValidity();
    }
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      this.toastService.error('Please fill all required fields correctly!');
      return;
    }
    this.handleFormSubmit.emit({
      ...this.form.value,
      status,
      product_categories: this.productCategoryOptions(),
      additional_photos: this.additionalPhotos,
      deleted_additional_photos: this.deletedAdditionalPhotosIds,
      margin: this.form.value.margin * 100,
      final_price: this.form.value.final_price * 100,
      total_cost: this.form.value.total_cost * 100
    });
  }

  categoryRemove(index: number) {
    this.productCategoryOptions.update(cat => {
      return cat.filter((_, i) => index !== i);
    });
  }
}
