@if (productsInQue?.length) {
  <div class="card">
    <h5 [style]="{ fontSize: '14px' }">Products in queue</h5>
    <div class="product-que">
      @for (product of productsInQue; track product.id) {
        <div class="que-item">
          <div class="que-item-image">
            <img [src]="product.featured_image?.full_path" alt="Product" />
          </div>
          <p>{{ product.name }}</p>
        </div>
      }
    </div>
  </div>
}
<div class="product-creator">
  <div class="grid content">
    <div class="col-12 md:col-6 xl:col-8">
      <div class="card">
        <div class="print-area">
          <div class="col-12 overflow-x-auto">
            <div class="flex flex-column relative" style="min-height: 650px">
              <div
                class="mx-auto col-12"
                [ngStyle]="{ display: printAreaBackgroundImage().image ? 'none' : 'block', width: '650px' }"
              >
                <p-skeleton width="650px" height="650px" [ngStyle]="{ position: 'absolute' }"></p-skeleton>
              </div>
              <div class="flex justify-content-between absolute z-1">
                <div class="selected-colors">
                  <div
                    class="color-list"
                    [style.height]="showMoreSelectedColors ? 'auto' : '24px'"
                    [style.overflow]="showMoreSelectedColors ? 'auto' : 'hidden'"
                  >
                    @for (variant of selectedProductVariants(); track variant.value) {
                      <div
                        [pTooltip]="variant.name"
                        tooltipPosition="top"
                        class="color-circle"
                        [style.background-color]="variant.hex"
                        (click)="handlePrintVariantSelect(variant)"
                      >
                        @if (this.selectedColor()?.value === variant.value) {
                          <i class="pi pi-check"></i>
                        }
                      </div>
                    }
                  </div>
                  <div
                    class="show-more-colors"
                    [style.display]="selectedProductVariants().length > 5 ? 'flex' : 'none'"
                    (click)="showMoreSelectedColors = !showMoreSelectedColors"
                  >
                    <img
                      src="assets/images/icons/arrow-down-dark.svg"
                      alt="Show more"
                      [style.transform]="showMoreSelectedColors ? 'rotate(180deg)' : 'none'"
                    />
                  </div>
                </div>
                <div class="print-area-size"></div>
              </div>
              <ko-stage #stage [config]="configStage" class="my-canvas mx-auto col-fixed">
                <ko-layer #layer>
                  <ko-image [config]="printAreaBackgroundImage()"></ko-image>
                  <ko-transformer #transformer [config]="printImageTransformerConfig"></ko-transformer>
                  @for (print of selectedPrintArea()?.prints; let i = $index; track i) {
                    <ko-image
                      #printImages
                      [config]="printConfig(print)"
                      (mousedown)="handlePrintClick(printImages, i)"
                      (dragend)="updatePrintImageAttributes($event.event, i)"
                      (dragstart)="attachTransformer(i, printImages)"
                    ></ko-image>
                  }
                </ko-layer>
              </ko-stage>
            </div>
          </div>
          <div class="col-12">
            @if (selectedPrint()?.library) {
              <p class="mb-0 text-center">Select logo version</p>
            }
            <div class="flex justify-content-center mb-3">
              <p-selectButton
                [(ngModel)]="selectedLogoVersion"
                [options]="logoVersions()"
                (onChange)="handleLogoVersionChange($event.value.file)"
                optionLabel="buttonName"
                [styleClass]="'selected-variants'"
                [allowEmpty]="false"
              ></p-selectButton>
            </div>
            <!--            <div class="flex justify-content-center mt-3">-->
            <!--              @if (!selectedColor()) {-->
            <!--                <p>-->
            <!--                  Changing print version and position will be apply to-->
            <!--                  <b>ALL</b>-->
            <!--                  product variants-->
            <!--                </p>-->
            <!--              } @else {-->
            <!--                <p>-->
            <!--                  Changing print version and position will be applied only to-->
            <!--                  <b>THIS</b>-->
            <!--                  variant:-->
            <!--                  <b>{{ selectedColor()?.name }}</b>-->
            <!--                </p>-->
            <!--              }-->
            <!--            </div>-->
          </div>
        </div>
      </div>
      <div class="print-images">
        @for (area of printAreas(); track area.index) {
          <div class="card mb-0">
            <a href="#" (click)="handlePrintAreaChange(area, $event)">
              <img src="{{ area.backgroundImage }}" alt="" />
            </a>
          </div>
        }
      </div>
    </div>
    <div class="col-12 md:col-6 xl:col-4">
      @if (product() && storefrontId && selectedPrintArea() && productVariants()) {
        <app-product-details
          [product]="product()!"
          (handleFormSubmit)="onSubmit($event)"
          [editMode]="editMode"
          [selectedPrintArea]="selectedPrintArea()!"
          [numberOfPrints]="numberOfPrints()"
          [storefrontId]="storefrontId"
          [productVariants]="productVariants()"
          [isSubmitting]="isSubmitting"
          (handlePrintAreaImageUpload)="handlePrintAreaImageUpload($event)"
          (handleOpenMediaLibraryModal)="openMediaLibraryModal()"
          (selectAllVariants)="selectAllVariants($event)"
          (handleVariantSelect)="handleVariantSelect($event)"
          [productsInQue]="productsQue"
          (removePrint)="handleRemovePrint($event)"
        />
      }
    </div>
  </div>
</div>
