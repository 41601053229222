<p-confirmDialog
  header="Confirmation"
  key="confirmDelete"
  icon="pi pi-exclamation-triangle"
  message="Are you sure you want to proceed?"
  [style]="{ width: '450px' }"
  acceptButtonStyleClass="p-button-danger p-button-text"
  rejectButtonStyleClass="p-button-text"
/>
<div class="list-actions ga-2">
  <p-splitButton outlined label="Actions" [model]="actionButtonItems" />
  <a class="p-button p-button-outlined" routerLink="email-templates">
    Update Email Templates
    <i class="ml-2 pi pi-envelope"></i>
  </a>
  <a class="p-button" routerLink="create">
    Create
    <i class="ml-2 pi pi-plus"></i>
  </a>
</div>

<p-table
  [value]="coupons"
  dataKey="id"
  [lazy]="true"
  [paginator]="true"
  [rows]="10"
  [totalRecords]="totalRecords"
  [showCurrentPageReport]="true"
  [loading]="isLoading"
  responsiveLayout="scroll"
  currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
  [rowsPerPageOptions]="[5, 10, 25, 50]"
  [globalFilterFields]="['name']"
  (onLazyLoad)="loadGiftCards($event)"
>
  <ng-template pTemplate="header">
    <tr>
      <th style="width: 4rem">
        <p-tableHeaderCheckbox name="Select all" />
      </th>
      <th pSortableColumn="created_at">
        Created
        <p-sortIcon field="created_at" />
      </th>
      <th pSortableColumn="name">
        Name
        <p-sortIcon field="name" />
      </th>
      <th>Number of codes</th>
      <th>Total Value</th>
      <th pSortableColumn="expires">
        Exp. Date
        <p-sortIcon field="expires" />
      </th>
      <th></th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-giftCard>
    <tr>
      <td>
        <p-tableCheckbox [value]="giftCard" [name]="giftCard.name" />
      </td>
      <td>{{ giftCard.created_at | usdDate }}</td>
      <td>{{ giftCard.name }}</td>
      <td>{{ giftCard.number_of_codes.total }}</td>
      <td>{{ giftCard.total | price | usdPipe }}</td>
      <td>{{ (giftCard.expiration_date | usdDate) || 'Not set' }}</td>
      <td>
        <p-splitButton
          [disabled]="isLoading"
          label="Details"
          appendTo="body"
          [model]="splitButtonItemsMap[giftCard.id]"
          (onClick)="splitClick(giftCard)"
        />
      </td>
    </tr>
  </ng-template>
</p-table>
