import { NgClass } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';

import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { FileSelectEvent, FileUploadEvent, FileUploadModule } from 'primeng/fileupload';
import { InputTextModule } from 'primeng/inputtext';
import { TooltipModule } from 'primeng/tooltip';

import ProductTaxCodes from 'app/_shared/data/product_tax_codes.json';
import { CatalogProductCategory, CategoryPrintPosition } from 'app/_shared/models/CatalogProductCategory';
import { CustomUploadedFile } from 'app/_shared/models/CustomUplaodedFile';
import { CatalogProductCategoryService } from 'app/_shared/services/catalog-product-category.service';
import { ToastService } from 'app/_shared/services/toast.service';

@Component({
  selector: 'app-category-editor',
  imports: [
    ButtonModule,
    InputTextModule,
    DialogModule,
    ReactiveFormsModule,
    FileUploadModule,
    NgClass,
    DropdownModule,
    TooltipModule
  ],
  templateUrl: './category-editor.component.html',
  styleUrl: './category-editor.component.scss'
})
export class CategoryEditorComponent implements OnInit {
  fb = inject(FormBuilder);
  toastService = inject(ToastService);
  categoryService = inject(CatalogProductCategoryService);

  form: FormGroup;
  category: CatalogProductCategory | null = null;
  parentCategoryId: number;
  dialogConfig = inject(DynamicDialogConfig);
  dialogRef = inject(DynamicDialogRef);

  categoryImage: CustomUploadedFile;

  submitted = false;
  isLoading = false;
  taxCodes = ProductTaxCodes;

  deletedPrintPositions: number[] = [];

  ngOnInit() {
    this.category = this.dialogConfig.data.category;
    this.parentCategoryId = this.dialogConfig.data.parentCategoryId;
    this.initCategoryImage();
    this.buildForm();
  }

  initCategoryImage() {
    this.categoryImage = this.category?.category_image
      ? {
          name: this.category.category_image.name,
          file: null,
          objectURL: this.category.category_image.full_path
        }
      : { name: null, file: null, objectURL: null };
  }

  buildForm() {
    this.form = this.fb.group({
      name: [this.category?.name, [Validators.required]],
      tax_code: [this.category?.tax_code, [Validators.required]],
      print_positions: this.fb.array([])
    });

    if (this.category && this.category?.print_positions?.length > 0) {
      this.category?.print_positions.forEach(position => this.addPrintPosition(position));
    }
  }

  onImageUpload(event: FileUploadEvent | FileSelectEvent) {
    const file = event.files[0];
    const fileName = event.files[0].name;
    const objectURL = URL.createObjectURL(file);
    this.categoryImage.file = file;
    this.categoryImage.objectURL = objectURL;
    this.categoryImage.name = fileName;
  }

  onImageRemove() {
    this.categoryImage = {
      file: null,
      objectURL: null
    };
  }

  closeDialog() {
    this.dialogRef.close();
  }

  get printPositions() {
    return this.form.get('print_positions') as FormArray;
  }

  addPrintPosition(categoryPosition?: CategoryPrintPosition) {
    const positionGroup = this.fb.group({
      id: [categoryPosition?.id || null],
      position: [categoryPosition?.position || null, [Validators.required]]
    });
    this.printPositions.push(positionGroup);
  }

  removePrintPosition(index: number) {
    const deletedPrint = this.category?.print_positions[index];
    if (deletedPrint?.id) {
      this.deletedPrintPositions.push(deletedPrint.id);
    }
    this.printPositions.removeAt(index);
  }

  onSubmit() {
    this.submitted = true;
    if (this.form.invalid) {
      this.toastService.error('Please fill all required fields correctly!');
      return;
    }
    this.category ? this.updateCategory() : this.createCategory();
  }

  createCategory() {
    if (!this.categoryImage) {
      this.toastService.error('Please fill all required fields correctly!');
      return;
    }

    const submitData = this.prepareSubmitData();
    this.isLoading = true;
    this.categoryService.createCategory(submitData).subscribe({
      next: () => {
        this.dialogRef.close(true);
      },
      error: err => {
        console.error('Operation failed:', err);
      },
      complete: () => {
        this.isLoading = false;
      }
    });
  }

  updateCategory() {
    if (!this.categoryImage.file || !this.categoryImage.objectURL) {
      this.toastService.error('Please fill all required fields correctly!');
      return;
    }

    const submitData = this.prepareSubmitData();
    this.isLoading = true;
    this.categoryService.updateCategory(submitData, this.category?.id).subscribe({
      next: () => {
        this.dialogRef.close(true);
      },
      error: err => {
        console.error('Operation failed:', err);
      },
      complete: () => {
        this.isLoading = false;
      }
    });
  }

  prepareSubmitData() {
    const formValue = this.form.value;
    const formData = new FormData();
    formData.append('name', formValue.name);
    formData.append('tax_code', formValue.tax_code);

    const printPositions = formValue.print_positions as CategoryPrintPosition[];

    if (printPositions) {
      printPositions.forEach((printPosition, index) => {
        formData.append(`print_positions[${index}][position]`, printPosition.position);
        if (printPosition.id) {
          formData.append(`print_positions[${index}][id]`, printPosition.id.toString());
        }
      });
    }

    if (this.categoryImage.file) {
      formData.append('category_image', this.categoryImage.file);
    }

    if (this.deletedPrintPositions) {
      this.deletedPrintPositions.forEach((printPositionId, index) => {
        formData.append(`deleted_print_positions[${index}]`, printPositionId.toString());
      });
    }
    //if we want to create subcategory, we need to pass parent category id
    if (this.parentCategoryId) {
      formData.append('admin_category_id', this.parentCategoryId.toString());
    }

    if (this.category) {
      formData.append('_method', 'put');
    }
    return formData;
  }
}
