import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router } from '@angular/router';

import { AuthStore } from 'app/_store/auth.store';

import { AccessControlService } from '../helpers/access-control.service';

export const companyPermission: CanActivateFn = (route: ActivatedRouteSnapshot) => {
  const router = inject(Router);
  const accessControlService = inject(AccessControlService);
  const authStore = inject(AuthStore);

  //superadmin can pass anyway (because he doesn't have companeis)
  if (authStore.user() && authStore.isSuperAdmin()) {
    return true;
  }

  const action = route.data['action'];
  const companyId = route.paramMap.get('id');
  if (!companyId) {
    router.navigate(['../']);
    return false;
  }

  const hasPermission = accessControlService.canPerformCompanyAction(companyId, action);

  if (!hasPermission) {
    router.navigate(['../']);
    return false;
  }

  return true;
};
