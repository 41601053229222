import { Component, inject } from '@angular/core';
import { Router } from '@angular/router';

import { ConfirmationService, MenuItem } from 'primeng/api';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { TableLazyLoadEvent } from 'primeng/table';
import { BehaviorSubject, combineLatest, debounceTime, distinctUntilChanged, finalize } from 'rxjs';

import { StorefrontEditorDialogComponent } from 'app/_shared/components/storefront-editor-dialog/storefront-editor-dialog.component';
import { StorefrontsTableComponent } from 'app/_shared/components/storefronts-table/storefronts-table.component';
import { Company } from 'app/_shared/models/Company';
import { PaginatorOptions } from 'app/_shared/models/PaginatorOptions';
import { Storefront, StorefrontStatus } from 'app/_shared/models/Storefront';
import { CompanyService } from 'app/_shared/services/company.service';
import { StorefrontService } from 'app/_shared/services/storefront.service';
import { ToastService } from 'app/_shared/services/toast.service';

@Component({
  selector: 'app-storefront-list',
  imports: [StorefrontsTableComponent],
  providers: [ConfirmationService, DialogService],
  templateUrl: './storefront-list.component.html'
})
export class StorefrontListComponent {
  storefrontService = inject(StorefrontService);
  router = inject(Router);
  toastService = inject(ToastService);
  confirmationService = inject(ConfirmationService);
  dialogService = inject(DialogService);
  companyService = inject(CompanyService);

  dialogRef: DynamicDialogRef | undefined;
  isLoading: boolean;

  storefronts: Storefront[];
  paginatorOptions: PaginatorOptions = {
    total: 0,
    rows: 10,
    first: 0
  };

  splitButtonItemsMap: { [storefrontId: number]: MenuItem[] } = {};

  globalFilter: string;
  selectedStatus: string | null;
  filtersSubject = new BehaviorSubject<any>(null);
  filtersChanged = false;
  lastLazyLoadEvent: TableLazyLoadEvent | undefined;

  allCompanyForSuperadmin: Company[] = [];

  constructor() {
    this.loadCompanies();
    combineLatest([this.filtersSubject.pipe(debounceTime(300), distinctUntilChanged())]).subscribe(() => {
      if (this.filtersChanged) {
        this.loadStorefronts(this.lastLazyLoadEvent);
      }
      this.filtersChanged = false;
    });
  }

  loadCompanies($event?: TableLazyLoadEvent) {
    this.isLoading = true;
    this.companyService
      .paginatedIndex($event)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe({
        next: res => {
          this.allCompanyForSuperadmin = res.data.data;
        },
        error: error => console.error(error)
      });
  }

  loadStorefronts(event?: TableLazyLoadEvent) {
    if (event) {
      this.lastLazyLoadEvent = event;
    }
    const filters = this.filtersSubject.value || {};

    this.isLoading = true;
    this.storefrontService
      .indexPaginatedWithLogo(event, filters)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe({
        next: res => {
          this.storefronts = res.data.data;
          this.paginatorOptions = {
            total: res.data.total,
            rows: res.data.per_page,
            first: res.data.from - 1
          };
          this.populateSplitButtonItems();
        },
        error: error => console.error(error)
      });
  }

  populateSplitButtonItems() {
    this.storefronts.forEach(storefront => {
      this.splitButtonItemsMap[storefront.id] = [
        ...(storefront.status === StorefrontStatus.INACTIVE
          ? [
              {
                label: 'Reactive',
                icon: 'pi pi-file',
                command: () => {
                  this.activateStore(storefront);
                }
              },
              { separator: true }
            ]
          : []),
        {
          label: 'Edit',
          icon: 'pi pi-pencil',
          command: () => {
            this.handleOpenStorefrontEditor(storefront);
          }
        },
        { separator: true },
        {
          label: 'Settings',
          icon: 'pi pi-cog',
          command: () => {
            this.navigateToStorefrontSettings(storefront);
          }
        }
      ];
    });
  }

  onFilterChange(event: { key: string; value: string }): void {
    const { key, value } = event;
    const updatedFilters = { ...this.filtersSubject.value };

    if (event.value !== null && value !== undefined && value !== '') {
      updatedFilters[key] = value;
    } else {
      delete updatedFilters[key];
    }

    if (this.lastLazyLoadEvent) {
      this.lastLazyLoadEvent.first = 0;
    }

    this.filtersSubject.next(updatedFilters);
    this.filtersChanged = true;
  }

  openRestoreModal(id: number) {
    this.confirmationService.confirm({
      key: 'restoreStorefront',
      message: 'Do you want to restore this item? It will be returned to active store lists',
      icon: 'pi pi-question',
      accept: () => {
        this.restoreStorefront(id);
      },
      acceptButtonStyleClass: 'p-button-filled',
      rejectButtonStyleClass: 'p-button-outlined'
    });
  }

  restoreStorefront(id: number) {
    this.isLoading = true;
    this.storefrontService
      .restore(id)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe({
        next: () => {
          this.toastService.success('Storefront successfully restored!');
          this.loadStorefronts();
        },
        error: err => {
          console.error('Delete action failed:', err);
        }
      });
  }

  handleOpenStorefrontEditor(storefront?: Storefront) {
    this.dialogRef = this.dialogService.open(StorefrontEditorDialogComponent, {
      header: storefront ? 'Edit Storefront' : 'Create new Storefront',
      width: '600px',
      data: {
        storefront,
        companyOptions: this.allCompanyForSuperadmin,
        superAdminMode: true
      }
    });
    this.dialogRef.onClose.subscribe((res: { id: number | undefined; data: FormData }) => {
      if (res) {
        if (res.id) {
          this.updateStorefront(res.data, res.id);
        } else {
          this.createStorefront(res.data);
        }
      }
    });
  }

  private createStorefront(data: FormData) {
    this.isLoading = true;
    this.storefrontService
      .create(data)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe({
        next: () => {
          this.toastService.success('Successfully created storefront!');
          this.loadStorefronts();
        },
        error: err => {
          console.error('Creation failed:', err);
        }
      });
  }

  private updateStorefront(data: FormData, id: number) {
    this.isLoading = true;
    this.storefrontService
      .update(id, data)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe({
        next: () => {
          this.toastService.success('Successfully updated storefront!');
          this.loadStorefronts();
        },
        error: err => {
          console.error('Update failed:', err);
        }
      });
  }

  openInNewTab(storefront: Storefront) {
    window.open(storefront.url, '_blank');
  }

  activateStore(storefront: Storefront) {
    this.storefrontService.updateStatus({ status: StorefrontStatus.LIVE }, storefront.id).subscribe({
      next: () => {
        this.toastService.success('Successfully activated storefront!');
        this.loadStorefronts();
      },
      error: err => {
        console.error('Update failed:', err);
      }
    });
  }

  navigateToStorefrontSettings(storefront: Storefront) {
    this.router.navigate(['/companies', storefront.company_id, 'storefronts', storefront.id, 'settings']);
  }

  navigateToStorefrontProducts(storefront: Storefront) {
    this.router.navigate(['/companies', storefront.company_id, 'storefronts', storefront.id, 'products']);
  }
}
