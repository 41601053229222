import { CommonModule } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';

import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { InputTextModule } from 'primeng/inputtext';
import { PaginatorModule } from 'primeng/paginator';
import { ProgressSpinnerModule } from 'primeng/progressspinner';

import { DepositMoneyService } from 'app/_shared/services/deposit-money.service';
import { ToastService } from 'app/_shared/services/toast.service';
import { AuthStore } from 'app/_store/auth.store';

@Component({
  selector: 'app-deposit-money-modal',
  imports: [
    CommonModule,
    CardModule,
    ButtonModule,
    ConfirmDialogModule,
    ProgressSpinnerModule,
    InputTextModule,
    PaginatorModule,
    ReactiveFormsModule
  ],
  styleUrl: './deposit-money-modal.component.scss',
  templateUrl: './deposit-money-modal.component.html'
})
export class DepositMoneyModalComponent implements OnInit {
  toastService = inject(ToastService);
  dialogConfig = inject(DynamicDialogConfig);
  dialogRef = inject(DynamicDialogRef);
  authStore = inject(AuthStore);

  depositMoneyService = inject(DepositMoneyService);
  companyId;
  submitted = false;
  loading = false;

  form = new FormGroup({
    amount: new FormControl(null, [Validators.required, Validators.min(0.5)])
  });

  isSuperAdmin: boolean;

  isLoading = false;

  ngOnInit() {
    this.companyId = this.dialogConfig.data.companyId;
    this.isSuperAdmin = this.authStore.isSuperAdmin() ?? false;
  }

  onSubmit() {
    if (this.form.invalid) {
      this.toastService.error('Please fill all required fields correctly!');
      return;
    }
    const formData: any = this.form.value;

    this.loading = true;
    this.depositMoneyService.createDepositPaymentLink(this.companyId, formData.amount).subscribe({
      next: res => {
        this.submitted = true;
        this.loading = false;
        window.open(res.data, '_blank');
      },
      error: err => {
        this.loading = false;
        this.toastService.error('Failed to fetch payment link!');
      }
    });
  }

  addDeposit() {
    if (this.form.invalid) {
      this.toastService.error('Please fill all required fields correctly!');
      return;
    }
    const formData: any = this.form.value;

    this.depositMoneyService.createDepositSuperAdmin(this.companyId, formData.amount).subscribe({
      next: () => {
        this.closeModal(true);
        this.toastService.success('Successfully added transaction!');
      },
      error: err => {
        console.error(err);
      }
    });
  }

  closeModal(closeStatus: boolean) {
    this.dialogRef.close(closeStatus);
  }
}
