@if (generalSettingsFormReady) {
  <form [formGroup]="generalSettingsForm" (ngSubmit)="onSubmit()">
    <div class="grid">
      <div class="col-12">
        <div class="card">
          <h5>Store Name And Domain Settings</h5>
          <div class="grid">
            <div class="col-12 md:col-6">
              <label for="name">Name</label>
              <input id="name" type="text" pInputText class="w-full" formControlName="name" />
              @if (
                submitted ||
                (generalSettingsForm.get('name')?.invalid &&
                  (generalSettingsForm.get('name')?.dirty || generalSettingsForm.get('name')?.touched))
              ) {
                @if (generalSettingsForm.get('name')?.errors?.['required']) {
                  <div>
                    <small class="p-text-danger">Name is required</small>
                  </div>
                }
              }
            </div>
            <div class="col-12 md:col-6">
              <label for="subdomain">Url</label>
              <p-inputGroup class="w-full">
                <p-inputGroupAddon>{{ prefix }}</p-inputGroupAddon>
                <input id="subdomain" type="text" pInputText formControlName="subdomain" />
                <p-inputGroupAddon>.{{ suffix }}</p-inputGroupAddon>
              </p-inputGroup>
              @if (
                submitted ||
                (generalSettingsForm.get('subdomain')?.invalid &&
                  (generalSettingsForm.get('subdomain')?.dirty || generalSettingsForm.get('subdomain')?.touched))
              ) {
                @if (generalSettingsForm.get('subdomain')?.errors?.['required']) {
                  <div>
                    <small class="p-text-danger">Store subdomain is required</small>
                  </div>
                }
                @if (generalSettingsForm.get('subdomain')?.errors?.['invalidSubdomain']) {
                  <div>
                    <small class="p-text-danger">Invalid subdomain format. (Allowed a-z, A-Z, 0-9, -)</small>
                  </div>
                }
              }
            </div>
            <div class="col-12 md:col-6">
              <div>
                <p-checkbox
                  name="checkbox"
                  styleClass="mr-2"
                  [binary]="true"
                  formControlName="customDomain"
                ></p-checkbox>
                <label for="checkbox" class="font-medium mr-6">Connect store to own domain (optional)</label>
              </div>
            </div>
            @if (generalSettingsForm.get('customDomain')?.value) {
              <div class="col-12 md:col-6">
                <label for="domain">Domain</label>
                <input id="domain" type="text" pInputText class="w-full" formControlName="domain" />
                @if (generalStoreSettings.domain && !generalStoreSettings.domain_verified_at) {
                  <div class="mt-2">
                    <p-message severity="error" text="Domain is not verified"></p-message>
                  </div>
                }
                @if (
                  submitted ||
                  (generalSettingsForm.get('domain')?.invalid &&
                    (generalSettingsForm.get('domain')?.dirty || generalSettingsForm.get('domain')?.touched))
                ) {
                  @if (generalSettingsForm.get('domain')?.errors?.['required']) {
                    <div>
                      <small class="p-text-danger">Domain is required</small>
                    </div>
                  }
                }
              </div>
            }
          </div>
        </div>

        <div class="card">
          <h5>Store Status</h5>
          <p>Current store status is:</p>
          <p-dropdown
            [options]="storefrontStatusOptions"
            placeholder="Status"
            optionLabel="label"
            optionValue="value"
            (onChange)="changeStoreStatus()"
            formControlName="status"
            [readonly]="!userCompanyOrSuperAdmin"
          >
            <ng-template let-option>
              <div class="p-multiselect-representative-option">
                <span class="ml-2">{{ option.name }}</span>
              </div>
            </ng-template>
          </p-dropdown>
        </div>
        <div class="card">
          <div class="flex flex-wrap gap-2 align-items-center justify-content-between">
            <h5>Store Operator Information</h5>
            <p-button
              (click)="openEditOperatorDialog()"
              outlined
              class="w-full sm:w-auto flex-order-0 sm:flex-order-1"
              icon="pi pi-user-plus"
              label="Edit"
            ></p-button>
          </div>
          <div class="grid">
            <div class="col-12 md:col-6">
              <ul class="list-none p-0 m-0 text-600 mb-4 text-600">
                <li class="mb-3">
                  <span class="font-semibold">First name:</span>
                  {{ storeOperator?.first_name || 'not set' }}
                </li>
                <li class="mb-3">
                  <span class="font-semibold">Last name:</span>
                  {{ storeOperator?.last_name || 'not set' }}
                </li>
                <li class="mb-3">
                  <span class="font-semibold">Business name:</span>
                  {{ storeOperator?.business_name || 'not set' }}
                </li>
                <li class="mb-3">
                  <span class="font-semibold">Phone:</span>
                  {{ storeOperator?.phone || 'not set' }}
                </li>
                <li>
                  <span class="font-semibold">Address:</span>
                  {{ storeOperator?.address || 'not set' }}
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="card">
          <h5>Margins</h5>
          <div class="grid">
            <div class="col-12 md:col-6">
              <label for="shipping-margin">Custom shipping Margin</label>
              <p-inputGroup class="w-full">
                <input id="shipping-margin" type="number" pInputText class="w-full" formControlName="shipping_margin" />
                <p-inputGroupAddon>%</p-inputGroupAddon>
              </p-inputGroup>
            </div>
            <div class="col-12 md:col-6">
              <label for="product-margin">Product price margin</label>
              <p-inputGroup class="w-full">
                <input id="product-margin" type="number" pInputText class="w-full" formControlName="price_margin" />
                <p-inputGroupAddon>%</p-inputGroupAddon>
              </p-inputGroup>
              @if (
                submitted ||
                (generalSettingsForm.get('price_margin')?.invalid &&
                  (generalSettingsForm.get('price_margin')?.dirty || generalSettingsForm.get('price_margin')?.touched))
              ) {
                @if (generalSettingsForm.get('price_margin')?.errors?.['required']) {
                  <div>
                    <small class="p-text-danger">Price margin is required</small>
                  </div>
                }
              }
            </div>
            <div class="col-12 md:col-6">
              <label class="mt-2 mb-2 block">Select price rounding</label>
              <div class="flex gap-4">
                @for (type of priceRoundingTypes; track type.key) {
                  <div class="field-checkbox">
                    <p-radioButton [inputId]="type.key" [value]="type.key" formControlName="price_rounding" />
                    <label [for]="type.key" class="ml-2">
                      {{ type.name }}
                    </label>
                  </div>
                }
              </div>
              <div>
                <p-checkbox
                  name="singlePriceAllSizes"
                  styleClass="mr-2"
                  [binary]="true"
                  formControlName="single_variant_price"
                  inputId="singlePriceAllSizes"
                ></p-checkbox>
                <label for="singlePriceAllSizes" class="font-medium mr-6">One price for all the sizes</label>
              </div>
            </div>
          </div>
        </div>
        <div class="card">
          <h5>Salesperson</h5>
          <div class="grid">
            <div class="col-12 md:col-6">
              <label for="salesperson-email">Salesperson email</label>
              <input
                id="salesperson-email"
                type="email"
                pInputText
                class="w-full"
                formControlName="salesperson_email"
              />
              @if (
                submitted ||
                (generalSettingsForm.get('salesperson_email')?.invalid &&
                  (generalSettingsForm.get('salesperson_email')?.dirty ||
                    generalSettingsForm.get('salesperson_email')?.touched))
              ) {
                @if (generalSettingsForm.get('salesperson_email')?.errors?.['email']) {
                  <div>
                    <small class="p-text-danger">Salesperson email is not valied</small>
                  </div>
                }
              }
            </div>
          </div>
        </div>
        <div class="card">
          <p-button [disabled]="isLoading" pRipple label="Save" class="w-full mb-4" type="submit" />
        </div>
      </div>
    </div>
  </form>
}
