<p-confirmDialog header="Confirmation" key="confirmDelete" [style]="{ width: '28rem' }"></p-confirmDialog>
<p-confirmDialog header="Confirmation" key="confirmChangePaymentStatus" [style]="{ width: '28rem' }"></p-confirmDialog>
<div class="card">
  <h4>File prep requests</h4>
  <p-table
    #dt
    [value]="requests"
    [lazy]="true"
    (onLazyLoad)="loadFilePrepRequests($event)"
    dataKey="id"
    [paginator]="true"
    [rows]="rows"
    [totalRecords]="totalRecords"
    [showCurrentPageReport]="true"
    [loading]="loading"
    [showLoader]="false"
    responsiveLayout="scroll"
    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
    [rowsPerPageOptions]="[5, 10, 25, 50]"
  >
    <ng-template pTemplate="caption">
      <div class="flex flex-wrap gap-2 align-items-center justify-content-between pb-3">
        <span class="p-input-icon-left w-full sm:w-20rem flex-order-1 sm:flex-order-0">
          <i class="pi pi-search"></i>
          <input
            pInputText
            type="text"
            (input)="onGlobalFilter(dt, $event)"
            placeholder="Global Search"
            class="w-full"
          />
        </span>
      </div>
    </ng-template>

    <ng-template pTemplate="header">
      <tr>
        <th pSortableColumn="id" class="white-space-nowrap" style="width: 10%">
          Id
          <p-sortIcon field="id" />
        </th>
        <th class="white-space-nowrap" style="width: 15%">Image</th>
        <th pSortableColumn="design" class="white-space-nowrap" style="width: 20%">
          Design
          <p-sortIcon field="design" />
        </th>
        <th class="white-space-nowrap" style="width: 20%">Storefront</th>
        <th class="white-space-nowrap" style="width: 15%">Paid</th>
        <th class="white-space-nowrap" style="width: 15%">Actions</th>
      </tr>
    </ng-template>

    <ng-template pTemplate="body" let-request>
      <tr>
        <td>{{ request.id }}</td>
        <td>
          <img
            [src]="request.library.library_thumbnail.full_path"
            [alt]="request.library.library_thumbnail.name"
            style="object-fit: contain"
            [ngStyle]="{ maxWidth: '80px' }"
            class="shadow-4"
            priority
          />
        </td>
        <td>{{ request.library.name }}</td>
        <td>{{ request.storefront.name }}</td>
        <td>
          <p-chip [ngStyle]="{ background: request.paid ? 'green' : 'red', 'border-radius': '25px' }"></p-chip>
        </td>
        <td>
          <div class="flex gap-2">
            <p-button
              icon="pi pi-eye"
              outlined
              severity="secondary"
              (click)="openFilePrepDetailsDialog(request)"
            ></p-button>
            <p-button
              icon="pi pi-dollar"
              outlined
              pTooltip="Change payment status"
              (click)="openChangePaymentStatusDialog($event, request, request.storefront_id)"
            ></p-button>

            <p-button
              icon="pi pi-trash"
              outlined
              severity="danger"
              (click)="openDeleteModal($event, request.id, request.storefront_id)"
            ></p-button>
          </div>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
      <tr>
        <td colspan="12" class="text-center">There are no preparation requests</td>
      </tr>
    </ng-template>
    <ng-template pTemplate="loadingbody">
      @for (row of loadingRows; track row) {
        <tr>
          @for (col of ['first_name', 'last_name', 'email', 'phone', 'actions']; track col) {
            <td>
              <p-skeleton />
            </td>
          }
        </tr>
      }
    </ng-template>
  </p-table>
</div>
