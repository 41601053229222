import { SsActiveSupplierProduct } from 'app/_shared/interfaces/supplier';
import { SupplierProduct } from 'app/_shared/models/supplier-product.model';

export class SsActiveProductMapper extends SupplierProduct {
  constructor(product: SsActiveSupplierProduct) {
    super(product);
    this.name = product.name;
    this.description = product.additional_description;
    this.style_name = product.style_name;
  }
}
