<div class="wrapper">
  <div class="header">
    <div class="flex justify-content-end align-items-center">
      <p-button label="Publish changes" (onClick)="applyChanges()" />
    </div>
  </div>
  <div class="content">
    <div class="grid">
      <div class="col-12 xl:col-4 md:col-12 flex-order-0 lg:flex-order-0" [style]="{ maxWidth: '500px' }">
        <div class="card">
          <p-tabMenu
            [model]="items"
            [(activeItem)]="activeItem"
            (activeItemChange)="handleTabChange($event)"
          ></p-tabMenu>
          @if (activeItem.label === 'Pages') {
            <app-pages-list [(iframeUrl)]="iframeUrl" />
          }
          @if (activeItem.label === 'Menu') {
            <app-menu-list></app-menu-list>
          }
          @if (activeItem.label === 'Settings') {
            <app-settings />
          }
        </div>
      </div>
      <div class="col-12 md:col-12 xl:col-8 flex-order-2 lg:flex-order-1" [style]="{ flex: 1 }">
        <p-card [style]="{ minHeight: '1000px' }">
          @if (iframeUrlFormatted) {
            <app-iframe [iframeUrl]="iframeUrl()" [iframeUrlFormatted]="iframeUrlFormatted"></app-iframe>
          }
        </p-card>
      </div>
    </div>
  </div>
</div>
