<!--<div class="grid">-->
<div class="settings">
  <h3 class="header">Edit your settings</h3>
  <p-accordion [multiple]="true">
    <p-accordionTab header="Storefront Logo" [selected]="false" class="line-height-3 m-0">
      <p-fileUpload
        #fileUploader
        (onSelect)="onUpload($event)"
        [customUpload]="true"
        accept="image/*"
        [showUploadButton]="false"
        [showCancelButton]="false"
        [auto]="true"
        styleClass="border-1 surface-border surface-card p-0 border-round mb-4"
      >
        <ng-template pTemplate="content">
          <div class="m-1 border-round" [style]="{ minHeight: '100px' }">
            @if (!logo()) {
              <div
                class="flex flex-column w-full h-full justify-content-center align-items-center cursor-pointer"
                (click)="fileUploader.advancedFileInput.nativeElement.click()"
              >
                <i class="pi pi-fw pi-file text-4xl text-primary"></i>
                <span class="block font-semibold text-900 text-lg mt-3">Drop or select a site logo</span>
              </div>
            } @else {
              <div class="h-full relative border-round p-0 text-center">
                <img [src]="logo()" class="max-h-full max-w-full border-round text-center" alt="blog cover" />
                <button
                  pButton
                  pRipple
                  type="button"
                  aria-label="remove"
                  icon="pi pi-times"
                  class="p-button-rounded p-button-primary text-sm absolute justify-content-center align-items-center"
                  style="top: -10px; right: -10px"
                  (click)="$event.stopPropagation(); removeImage()"
                ></button>
              </div>
            }
          </div>
        </ng-template>
      </p-fileUpload>

      <p class="sub-header">Chose logo position on your store’s top navigation bar</p>
      <div class="grid formgrid">
        <div class="col-12 md:col-4">
          <div class="field-radiobutton">
            <p-radioButton
              name="logoPosition"
              value="left"
              [(ngModel)]="logoPosition"
              (onClick)="saveSettings({ logoPosition: $event.value })"
              inputId="left"
              id="left"
            ></p-radioButton>
            <label for="left">Left</label>
          </div>
        </div>
        <div class="col-12 md:col-4">
          <div class="field-radiobutton">
            <p-radioButton
              name="logoPosition"
              value="center"
              [(ngModel)]="logoPosition"
              (onClick)="saveSettings({ logoPosition: $event.value })"
              inputId="center"
              id="center"
            ></p-radioButton>
            <label for="center">Center</label>
          </div>
        </div>
      </div>
    </p-accordionTab>
    <p-accordionTab header="Website Color" [selected]="false" class="line-height-3 m-0">
      <compact-picker [(color)]="primaryColor" [control]="primaryColorControl"></compact-picker>
    </p-accordionTab>

    <p-accordionTab header="Buttons" [selected]="false" class="line-height-3 m-0">
      <p class="sub-header">Change a shape of buttons in your store</p>
      <p-dropdown
        [options]="buttonShapeOptions"
        [(ngModel)]="buttonShape"
        optionLabel="name"
        optionValue="code"
        placeholder="Select a City"
        (onChange)="handleButtonShapeChange($event)"
      />
      <div class="flex justify-content-between align-items-center gap-3 mt-4">
        <p-button
          label="Button"
          ariaLabel="Button"
          [ngClass]="buttonShape"
          class="w-full"
          [style]="{ flex: 1, width: '100%' }"
        />
        <p-button
          label="Button"
          ariaLabel="Button"
          class="w-full"
          [style]="{ flex: 1, width: '100%' }"
          [ngClass]="buttonShape"
          [outlined]="true"
        />
      </div>
    </p-accordionTab>
    <p-accordionTab header="Website Font" [selected]="false" class="line-height-3 m-0">
      <p class="sub-header">
        Change font for the entire store. You can override this setting in individual text areas.
      </p>
      <p-dropdown
        [options]="fontOptions"
        [(ngModel)]="font"
        (onChange)="handleFontChange($event)"
        optionLabel="name"
        optionValue="code"
        placeholder="Select font"
      />
    </p-accordionTab>
    <p-accordionTab header="Footer" [selected]="true" class="line-height-3 m-0">
      <p class="sub-header">Add footer text</p>
      <p-editor [(ngModel)]="footerText" [style]="{ height: '220px' }" (onTextChange)="onTextChange($event)">
        <ng-template pTemplate="header">
          <span class="ql-formats">
            <button type="button" class="ql-bold" aria-label="Bold"></button>
            <button type="button" class="ql-italic" aria-label="Italic"></button>
            <button type="button" class="ql-underline" aria-label="Underline"></button>
            <select class="ql-align"></select>
          </span>
        </ng-template>
      </p-editor>
      <p class="copyright">Copyright</p>
      <input
        class="round-input"
        pInputText
        [name]="'Size'"
        [(ngModel)]="copyright"
        (ngModelChange)="saveCopyright($event)"
        placeholder="Copyright"
      />
    </p-accordionTab>
    <p-accordionTab header="Social Media" [selected]="true" class="line-height-3 m-0">
      @if (showAddSocialIconForm) {
        <div class="social-icons-form mb-3" [@slideDownAnimation]>
          <form [formGroup]="socialIconsForm" (ngSubmit)="handleAddSocialIcon()">
            <div class="input-group mb-4">
              <div class="flex justify-content-end">
                <a href="#" (click)="$event.preventDefault(); showAddSocialIconForm = false">Remove</a>
              </div>
              <p-dropdown
                [options]="socialIconsOptions"
                formControlName="name"
                optionLabel="name"
                optionValue="name"
                placeholder="Select social network"
                appendTo="body"
              />
              <app-input
                name="url"
                label="URL"
                [control]="socialIconsForm.controls.url"
                placeholder="URL"
                [fullWidth]="true"
                shape="round"
              ></app-input>
            </div>
            <div class="mt-3">
              <p-button label="Add icon" type="submit" ariaLabel="Add icon" [style]="{ width: '100%' }" />
            </div>
          </form>
        </div>
      }
      <p-button
        icon="pi pi-plus"
        [outlined]="true"
        (click)="showAddSocialIconForm = true"
        label="Add Social Media"
        [style]="{ flex: 1, width: '100%', borderRadius: '25px' }"
      ></p-button>

      @for (icon of settings()?.socialIcons; track icon.url) {
        <div class="flex justify-content-between align-items-center mt-4">
          <div>
            <p class="m-0">{{ icon.name }}</p>
            <small>{{ icon.url }}</small>
          </div>
          <div>
            <img
              class="cursor-pointer"
              src="assets/images/icons/trash-dark.svg"
              alt="Remove icon"
              (click)="removeSocialIcon(icon)"
            />
          </div>
        </div>
      }
    </p-accordionTab>
  </p-accordion>
</div>
