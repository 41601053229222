import { Component, inject, input, OnInit, output } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { ChipModule } from 'primeng/chip';
import { DropdownModule } from 'primeng/dropdown';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { FileSelectEvent, FileUploadEvent, FileUploadModule } from 'primeng/fileupload';
import { InputTextModule } from 'primeng/inputtext';
import { SelectButtonModule } from 'primeng/selectbutton';

import { Library } from 'app/_shared/models/Library';
import { LibraryFolder } from 'app/_shared/models/LibraryFolder';
import { ToastService } from 'app/_shared/services/toast.service';
import { LibraryRequestFilePrepComponent } from 'app/admin/storefront/files/library-request-file-prep/library-request-file-prep.component';

@Component({
  selector: 'app-library-file-uploader',
  imports: [
    ReactiveFormsModule,
    FileUploadModule,
    DropdownModule,
    InputTextModule,
    SelectButtonModule,
    FormsModule,
    ChipModule
  ],
  providers: [DialogService],
  templateUrl: './library-file-uploader.component.html',
  styleUrls: ['./library-file-uploader.component.scss']
})
export class LibraryFileUploaderComponent implements OnInit {
  private router = inject(Router);
  private route = inject(ActivatedRoute);
  fb = inject(FormBuilder);
  toastService = inject(ToastService);

  storefrontLibraryFolders = input.required<LibraryFolder[]>();
  showRequestPrepButton = input<boolean>(false);
  isLoading = input<boolean>(false);
  library = input<Library>();
  formSubmitted = output<FormData>();
  startDownloadLibraryFiles = output<void>();
  dialogService = inject(DialogService);

  dialogRef: DynamicDialogRef | undefined;
  libraryThumbnail: any = {
    file: null,
    objectURL: null,
    name: null
  };

  uploadFileForm: FormGroup;
  additionaFiles: { id?: number; name: string; file?: File }[] = [];
  deletedAdditionalFilesIds: number[] = [];

  ngOnInit() {
    this.uploadFileForm = this.fb.group({
      name: [this.library()?.name ?? null, [Validators.required]],
      library_folder_id: [this.library()?.library_folder?.id ?? null, [Validators.required]]
    });
    this.initLibraryThumbnail();
    if (this.library()?.additional_files) {
      this.initializeAdditionalFiles();
    }
  }

  initLibraryThumbnail() {
    if (this.library()?.library_thumbnail) {
      this.libraryThumbnail = {
        name: this.library()?.library_thumbnail.name,
        file: null,
        objectURL: this.library()?.library_thumbnail?.full_path
      };
    }
  }

  initializeAdditionalFiles() {
    this.library()?.additional_files?.forEach(additionalFile =>
      this.additionaFiles.push({ id: additionalFile.id, name: additionalFile.name })
    );
  }

  removeThumbnail() {
    this.libraryThumbnail.file = null;
    this.libraryThumbnail.objectURL = null;
  }

  onThumbnailUpload(event: FileUploadEvent | FileSelectEvent) {
    const file = event.files[0];
    const fileName = event.files[0].name;
    const objectURL = URL.createObjectURL(file);
    this.libraryThumbnail.file = file;
    this.libraryThumbnail.objectURL = objectURL;
    this.libraryThumbnail.name = fileName;
  }

  openLibraryFilePrepRequestDialog() {
    this.dialogRef = this.dialogService.open(LibraryRequestFilePrepComponent, {
      header: 'Create Admin files prep request',
      width: '600px',
      data: {
        libraryId: this.library()?.id
      }
    });
    this.dialogRef.onClose.subscribe((res: boolean) => {
      if (res) {
        this.toastService.success('Preparation file request successfully created.');
        this.router.navigate([`../../`], { relativeTo: this.route });
      }
    });
  }

  onAdditionalFilesUpload(event: FileUploadEvent | FileSelectEvent) {
    for (const file of event.files) {
      this.additionaFiles.push({
        file,
        name: file.name
      });
    }
  }

  onAdditionalFileRemove(index: number) {
    const removedFile = this.additionaFiles[index];
    this.additionaFiles.splice(index, 1);
    if (removedFile.id) {
      this.deletedAdditionalFilesIds.push(removedFile.id);
    }
  }

  downloadLibraryFiles() {
    this.startDownloadLibraryFiles.emit();
  }

  prepareFormData() {
    const formData = new FormData();
    const formValue = this.uploadFileForm.value;
    formData.append('name', formValue.name);
    formData.append('library_folder_id', formValue.library_folder_id);
    if (this.libraryThumbnail.file) {
      formData.append('library_thumbnail', this.libraryThumbnail.file);
    }

    if (this.additionaFiles) {
      this.additionaFiles.forEach((additionalFile, index) => {
        if (additionalFile.file) {
          formData.append(`additional_files[${index}]`, additionalFile.file);
        }
      });
    }

    if (this.deletedAdditionalFilesIds) {
      this.deletedAdditionalFilesIds.forEach((fileId, index) => {
        formData.append(`deleted_additional_file_ids[${index}]`, fileId.toString());
      });
    }

    if (this.library()) {
      formData.append('_method', 'put');
    }

    return formData;
  }

  onSubmit() {
    if (this.uploadFileForm.invalid) {
      this.toastService.error('Please fill all required fields correctly!');
      this.uploadFileForm.markAllAsTouched();
      return;
    }
    if (!this.libraryThumbnail.objectURL) {
      this.toastService.error('Please upload mockup files!');
      return;
    }
    const formData = this.prepareFormData();
    this.formSubmitted.emit(formData);
  }
}
