<div>
  <p class="sub-header mb-0">Title Font</p>
  <p-dropdown
    [options]="fontOptions"
    [(ngModel)]="font"
    optionLabel="name"
    optionValue="code"
    placeholder="Select font"
    appendTo="body"
  />
  <p class="sub-header mb-0 mt-3">Title Font Size</p>
  <p-dropdown [options]="fontSizeOptions" [(ngModel)]="fontSize" placeholder="Select font size" appendTo="body" />
  <p class="sub-header mb-0 mt-3">Title</p>
  <p-editor #editor (onInit)="setDefaultValue()" [(ngModel)]="title" [style]="{ height: '50px' }">
    <ng-template pTemplate="header">
      <span class="ql-formats">
        <button type="button" class="ql-bold" aria-label="Bold"></button>
        <button type="button" class="ql-italic" aria-label="Italic"></button>
        <button type="button" class="ql-underline" aria-label="Underline"></button>
        <select class="ql-align"></select>
      </span>
    </ng-template>
  </p-editor>

  <!--  <input class="round-input" pInputText name="title" [(ngModel)]="title" placeholder="Title" />-->
  <div class="flex justify-content-end mt-4">
    <button [disabled]="!title" pButton icon="pi pi-check" (click)="onSubmit()" label="Save"></button>
  </div>
</div>
