import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';

import { ToastModule } from 'primeng/toast';

import packageInfo from '../../package.json';

@Component({
    selector: 'app-root',
    imports: [RouterOutlet, ToastModule],
    template: `
    <router-outlet />
    <p-toast />
    <div style="position: fixed; bottom: 5px; left: 5px; color: #5b5a8a">Build: {{ appVersion }}</div>
  `
})
export class AppComponent {
  title = 'Admin';
  appVersion = packageInfo.version;
}
