import { Component, inject, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

import { ConfirmationService } from 'primeng/api';
import { Button } from 'primeng/button';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { InputTextModule } from 'primeng/inputtext';
import { SkeletonModule } from 'primeng/skeleton';
import { finalize } from 'rxjs';

import { Supplier } from 'app/_shared/interfaces/supplier';
import { ColorDictionaryService } from 'app/_shared/services/color-dictionary.service';
import { SupplierService } from 'app/_shared/services/supplier.service';
import { ToastService } from 'app/_shared/services/toast.service';

@Component({
  selector: 'app-supplier-dictionary',
  imports: [ReactiveFormsModule, Button, ConfirmDialogModule, InputTextModule, SkeletonModule],
  providers: [ColorDictionaryService, ConfirmationService],
  templateUrl: './supplier-dictionary.component.html',
  styleUrl: './supplier-dictionary.component.scss'
})
export class SupplierDictionaryComponent implements OnInit {
  private activatedRoute = inject(ActivatedRoute);
  private supplierService = inject(SupplierService);
  private fb = inject(FormBuilder);
  private colorDictionaryService = inject(ColorDictionaryService);
  private confirmationService = inject(ConfirmationService);
  private toastService = inject(ToastService);

  supplier: Supplier;
  form: FormGroup;
  private dictionary: { id: number; key: string; value: string }[];
  isLoading: boolean = false;

  ngOnInit() {
    const slug = this.activatedRoute.snapshot.paramMap.get('slug') ?? '';
    this.isLoading = true;
    this.supplierService.getSupplierBySlug(slug).subscribe(next => {
      this.supplier = next.data;
      this.fetchDictionary();
    });
  }

  fetchDictionary() {
    this.colorDictionaryService.getDictionary(this.supplier.id).subscribe({
      next: res => {
        this.dictionary = res.data;
        this.buildForm();
      },
      error: err => console.error(err),
      complete: () => (this.isLoading = false)
    });
  }

  private buildForm() {
    const formControls = this.dictionary.map(x => {
      return this.fb.group({
        id: [x.id],
        key: [x.key, Validators.required],
        value: [x.value, Validators.required]
      });
    });

    this.form = this.fb.group({
      records: this.fb.array(formControls)
    });
  }

  get records(): FormArray {
    return this.form.get('records') as FormArray;
  }

  // Update function (can have additional logic)
  updateRecord(index: number) {
    const record = this.records.at(index);
    this.isLoading = true;
    this.colorDictionaryService
      .addToDictionary(this.supplier.id, record.value.key, record.value.value.trim())
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe(next => {
        if (next.status === 200) {
          this.toastService.success('Saved!');
          this.fetchDictionary();
        }
      });
  }

  // Confirm delete action
  confirmDelete(index: number) {
    const record = this.records.at(index);

    if (!record.value.id) {
      return this.records.removeAt(index);
    }

    this.confirmationService.confirm({
      message: 'Are you sure you want to delete this record?',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.deleteRecord(index);
      },
      acceptButtonStyleClass: 'p-button-filled p-button-danger',
      rejectButtonStyleClass: 'p-button-outlined'
    });
  }

  // Delete record from the FormArray
  deleteRecord(index: number) {
    const record = this.records.at(index);

    this.isLoading = true;

    this.colorDictionaryService
      .removeFromDictionary(record.value.id)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe(next => {
        if (next.status === 200) {
          this.toastService.success('Successfully deleted!', 'Deleted');
          this.records.removeAt(index);
        }
      });
  }

  // Submit the form
  submitForm() {
    return;
  }

  addNewRow() {
    const newRecord = this.fb.group({
      key: ['', Validators.required],
      value: ['', Validators.required]
    });

    this.records.push(newRecord);
  }
}
