import { animate, state, style, transition, trigger } from '@angular/animations';
import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

import { ColorPickerControl, CompactPickerComponent } from '@iplab/ngx-color-picker';
import { AccordionModule } from 'primeng/accordion';
import { SharedModule } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { DividerModule } from 'primeng/divider';
import { DropdownChangeEvent, DropdownModule } from 'primeng/dropdown';
import { EditorModule, EditorTextChangeEvent } from 'primeng/editor';
import { FileSelectEvent, FileUploadModule } from 'primeng/fileupload';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { RadioButtonModule } from 'primeng/radiobutton';
import { debounceTime, Subject } from 'rxjs';

import { InputComponent } from 'app/_shared/components/input/input.component';
import { DesignerService } from 'app/_shared/services/designer.service';
import { DesignerStore, Settings } from 'app/_store/designer.store';
import { StorefrontStore } from 'app/_store/storefront.store';
import { DesignerHelperService } from 'app/admin/storefront/designer/designer-helper.service';
import { fontOptions } from 'app/admin/storefront/designer/page-builder/page-builder.component';

@Component({
  selector: 'app-settings',
  imports: [
    ButtonModule,
    EditorModule,
    FileUploadModule,
    RadioButtonModule,
    SharedModule,
    FormsModule,
    CardModule,
    DividerModule,
    CompactPickerComponent,
    InputTextareaModule,
    DropdownModule,
    NgClass,
    AccordionModule,
    InputTextModule,
    ReactiveFormsModule,
    InputComponent
  ],
  templateUrl: './settings.component.html',
  styleUrl: './settings.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('slideDownAnimation', [
      state(
        'void',
        style({
          height: '0',
          opacity: 0,
          overflow: 'hidden'
        })
      ),
      state(
        '*',
        style({
          height: '*',
          opacity: 1
        })
      ),
      transition('void <=> *', [animate('300ms ease-in-out')])
    ])
  ]
})
export class SettingsComponent implements OnInit {
  public storefrontStore = inject(StorefrontStore);
  private designerService = inject(DesignerService);
  private designerHelperService = inject(DesignerHelperService);
  private designerStore = inject(DesignerStore);
  private fb = inject(FormBuilder);
  private textChangeSubject = new Subject<string>();
  private copyrightChangeSubject = new Subject<string>();
  storefrontId = computed(() => this.storefrontStore.selectedStorefront?.()?.id);
  selectedStorefront = computed(() => this.storefrontStore.selectedStorefront()!);
  settings = computed(() => this.designerStore?.config?.().settings);
  logo = computed(() => this.settings()?.logo ?? '');
  primaryColor = '#4e60cc';
  secondaryColor = '#4e60cc';
  logoPosition: string = 'left';
  footerText: string;
  showAddSocialIconForm: boolean = false;
  iframeUrl: SafeUrl;
  buttonShape = 'square';
  buttonShapeOptions = [
    { name: 'Square', code: 'square' },
    { name: 'Round', code: 'round' },
    { name: 'Pill', code: 'pill' }
  ];
  font = 'Poppins';

  copyright: string;
  public primaryColorControl = new ColorPickerControl().hidePresets();
  socialIconsForm: FormGroup;
  socialIconsOptions = [
    { name: 'Instagram', code: '1' },
    { name: 'Facebook', code: '2' },
    { name: 'Linkedin', code: '3' }
  ];

  constructor(private sanitizer: DomSanitizer) {
    this.iframeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(`${this.selectedStorefront()?.url}/?preview=true`);

    this.socialIconsForm = this.fb.group({
      name: this.fb.control('', [Validators.required]),
      url: this.fb.control('', [Validators.required])
    });

    // Add debounce for color change
    this.primaryColorControl.valueChanges.pipe(debounceTime(500)).subscribe(next => {
      this.saveSettings({ primaryColor: next.toHexString() });
    });
    this.textChangeSubject.pipe(debounceTime(500)).subscribe(value => {
      this.saveSettings({ footerContent: value });
    });
    this.copyrightChangeSubject.pipe(debounceTime(500)).subscribe(value => {
      console.log(value);
      this.saveSettings({ copyrightText: value });
    });
  }

  ngOnInit() {
    this.primaryColor = this.settings()?.primaryColor ?? '';
    this.secondaryColor = this.settings()?.secondaryColor ?? '';
    this.logoPosition = this.settings()?.logoPosition || 'left';
    this.buttonShape = this.settings()?.buttonShape || 'square';
    this.footerText = this.settings()?.footerContent || '';
    this.font = this.settings()?.websiteFont || 'Poppins';
    this.copyright = this.settings()?.copyrightText || `@${new Date().getFullYear()} ${this.selectedStorefront().name}`;
  }

  onUpload(event: FileSelectEvent) {
    const file = event.files[0];
    const logoFormData = new FormData();
    logoFormData.append('preview_logo', file);
    this.designerService.uploadLogoPreview(logoFormData, this.selectedStorefront().id).subscribe(next => {
      this.saveSettings({ logo: next.data.preview_logo.full_path });
    });
  }

  saveSettings(settings: Settings) {
    const prevSettings = this.settings();
    this.designerStore
      .saveConfig(this.selectedStorefront().id!, { settings: { ...prevSettings, ...settings } }, true)
      .subscribe(next => {
        this.designerHelperService.sendToPreview(this.storefrontId()!, next);
      });
  }

  removeImage() {
    this.designerService.removeLogo(this.storefrontId()!).subscribe(() => {
      this.saveSettings({ logo: undefined });
    });
  }

  onTextChange(value: EditorTextChangeEvent) {
    this.textChangeSubject.next(value.htmlValue);
  }

  handleButtonShapeChange($event: DropdownChangeEvent) {
    this.saveSettings({ buttonShape: $event.value });
  }

  saveCopyright($event: string) {
    this.copyrightChangeSubject.next($event);
  }

  handleAddSocialIcon() {
    if (this.socialIconsForm.valid) {
      const { name, url } = this.socialIconsForm.value;
      const prevIcons = this.settings()?.socialIcons ?? [];
      this.saveSettings({ socialIcons: [...prevIcons, { name, url }] });
      this.socialIconsForm.reset();
      this.showAddSocialIconForm = false;
    }
  }

  removeSocialIcon(icon: any) {
    const icons = this.settings()?.socialIcons ?? [];
    this.saveSettings({ socialIcons: icons.filter(i => i.url !== icon.url) });
  }

  protected readonly fontOptions = fontOptions;

  handleFontChange($event: DropdownChangeEvent) {
    this.saveSettings({ websiteFont: $event.value });
  }
}
