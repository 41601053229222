import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  standalone: true,
  name: 'price'
})
export class PricePipe implements PipeTransform {
  transform(value: string | number | undefined): string {
    if (value === undefined || value === null) {
      return '';
    }
    return (+value / 100).toFixed(2);
  }
}
