import { Component, inject, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { StripeElements, StripePaymentElement } from '@stripe/stripe-js';
import { environment } from 'environments/environment';
import { injectStripe } from 'ngx-stripe';
import { ButtonModule } from 'primeng/button';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { ProgressSpinnerModule } from 'primeng/progressspinner';

import { PaymentService } from 'app/_shared/services/payment.service';

@Component({
  selector: 'app-add-payment-method-modal',
  imports: [ButtonModule, ProgressSpinnerModule],
  templateUrl: './add-payment-method-modal.component.html'
})
export class AddPaymentMethodModalComponent implements OnInit {
  stripe = injectStripe(environment.stripeKey);
  dialogRef = inject(DynamicDialogRef);
  paymentService = inject(PaymentService);
  route = inject(ActivatedRoute);
  companyId: number;

  private clientSecret: string;
  elements: StripeElements;
  paymentElement: StripePaymentElement;
  loading = true;

  ngOnInit() {
    const companyId = this.route.snapshot.paramMap.get('id');
    if (!companyId) {
      console.error('Company ID is null.');
      return;
    }
    this.companyId = +companyId;
    this.fetchClientSecretAndInitStripe();
  }

  fetchClientSecretAndInitStripe() {
    this.paymentService.generateSecret(this.companyId).subscribe({
      next: res => {
        this.clientSecret = res.data.secret;
        this.initStripeElement();
      },
      error: error => console.error(error)
    });
  }

  initStripeElement() {
    this.stripe.elements({ clientSecret: this.clientSecret }).subscribe(res => {
      this.elements = res;
      this.paymentElement = this.elements.create('payment');
      this.paymentElement.mount('#payment-element');
      this.loading = false;
    });
  }

  handleFormSubmit(event: Event) {
    event.preventDefault();
    this.stripe
      .confirmSetup({
        elements: this.elements,
        redirect: 'if_required',
        confirmParams: { return_url: '' }
      })
      .subscribe({
        next: res => {
          if (!res.error) {
            this.dialogRef.close(true);
          } else {
            this.dialogRef.close(false);
          }
        },
        error: err => {
          console.error('Operation failed:', err);
        }
      });
  }
}
