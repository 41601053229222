import { Component, inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { ToastModule } from 'primeng/toast';
import { finalize } from 'rxjs';

import { InputComponent } from 'app/_shared/components/input/input.component';
import { SupplierNameFromSlug, SuppliersEnum } from 'app/_shared/interfaces/supplier';
import { SupplierService } from 'app/_shared/services/supplier.service';

@Component({
  selector: 'app-import-product',
  imports: [ReactiveFormsModule, InputComponent, ButtonModule, CardModule, ToastModule],
  templateUrl: './import-product.component.html',
  styleUrl: './import-product.component.scss'
})
export class ImportProductComponent implements OnInit {
  protected readonly SupplierNameFromSlug = SupplierNameFromSlug;

  private router = inject(Router);
  private supplierService = inject(SupplierService);
  private activatedRoute = inject(ActivatedRoute);
  private messageService = inject(MessageService);
  slug: SuppliersEnum;
  loading = false;

  productForm = new FormGroup({
    productId: new FormControl('', [Validators.required])
  });

  ngOnInit() {
    this.slug = this.activatedRoute.snapshot.paramMap.get('slug') as SuppliersEnum;
  }

  get productId() {
    return this.productForm.get('productId') as FormControl;
  }

  handleCancel() {
    this.router.navigate(['../'], { relativeTo: this.activatedRoute });
  }

  importProduct() {
    if (this.productForm.valid) {
      this.loading = true;
      this.supplierService
        .importSupplierProduct(this.slug, this.productForm.value.productId as string)
        .pipe(finalize(() => (this.loading = false)))
        .subscribe({
          next: () => {
            this.messageService.add({
              severity: 'success',
              summary: 'Successful',
              detail: 'Product Added',
              life: 3000
            });
            this.router.navigate(['suppliers', this.slug, 'products']);
          },
          error: err => console.error(err)
        });
    }
  }
}
