@if (order) {
  <div class="flex justify-content-between gap-7 mt-5 pb-3">
    <div class="w-full lg:w-6 pl-3">
      <span class="font-medium font-bold">
        Order
        <span>
          <a [routerLink]="['../orders', order.id]" class="text-blue-500 font-medium">#{{ order.id }}</a>
        </span>
      </span>
      <div class="flex flex-column text-900 mt-3 mb-5">
        <div class="flex justify-content-between mb-3">
          <span class="text-900">Subtotal</span>
          <span class="text-900 font-medium text-lg">{{ order?.subtotal | price | usdPipe }}</span>
        </div>
        <div class="flex justify-content-between mb-3">
          <span class="text-900">Tax</span>
          <span class="text-900 font-medium text-lg">{{ order?.sales_tax | price | usdPipe }}</span>
        </div>
        @if (order.discount_savings) {
          <div class="flex justify-content-between mb-3">
            <span class="text-900">Discount</span>
            <span class="text-900 font-medium text-lg">{{ order.discount_savings | price | usdPipe }}</span>
          </div>
        }
        <div class="flex justify-content-between border-top-1 surface-border py-3">
          <span class="text-900 font-medium">Total</span>
          <span class="text-900 font-bold text-lg">{{ order.total | price | usdPipe }}</span>
        </div>
      </div>
    </div>
    <div class="w-full lg:w-6 pl-3 lg:pl-0 lg:pr-3 adivgn-items-end mt-5 lg:mt-0">
      <div class="mb-3"><span class="font-medium font-bold">Summary</span></div>
      <div class="flex justify-content-between mb-3">
        <span class="text-900">Charged amount</span>
        <span class="text-900 font-medium text-lg">{{ transactionDetails.amount_charged | price | usdPipe }}</span>
      </div>
      <div class="flex justify-content-between mb-3">
        <span class="text-900">Payment processor fee</span>
        <span class="text-900 font-medium text-lg">
          {{ transactionDetails.payment_processor_fee | price | usdPipe }}
        </span>
      </div>
      <div class="flex justify-content-between mb-3">
        <span class="text-900">Platform fee</span>
        <span class="text-900 font-medium text-lg">{{ transactionDetails.platform_fee_amount | price | usdPipe }}</span>
      </div>
      <div class="flex justify-content-between mb-3">
        <span class="text-900">Shipping fee</span>
        <span class="text-900 font-medium text-lg">
          {{ transactionDetails.shipping_price_after_gift_card | price | usdPipe }}
        </span>
      </div>

      <div class="flex justify-content-between border-top-1 surface-border py-3">
        <span class="text-900 font-medium">Balance</span>
        <span class="text-900 font-bold text-lg">{{ transactionBalance | price | usdPipe }}</span>
      </div>
    </div>
  </div>
} @else {
  <div class="text-center text-xl font-bold">{{ transactionDetails.description }}</div>
}
