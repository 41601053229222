@if (product) {
  <p-card>
    <div class="mb-2">
      <p-table [value]="details">
        <ng-template pTemplate="header">
          <tr>
            <th colspan="2">{{ product.name }}</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-row>
          <tr>
            <td style="width: 200px">{{ row.label }}</td>
            <td>{{ row.value }}</td>
          </tr>
        </ng-template>
      </p-table>
    </div>

    <p>Description</p>
    <div [innerHTML]="sanitizedHtml"></div>

    <h5 class="text-center">Variants</h5>
    <div class="grid grid-nogutter">
      @for (variant of product.product_variants; track $index) {
        <div class="col-12 md:col-6 lg:col-4 border-1 surface-border text-center p-2">
          <h6>{{ variant.color }}</h6>
          <div>
            <div class="flex justify-content-center gap-5 flex-wrap mt-3">
              <div class="flex flex-column align-items-center">
                @if (variant.front_image) {
                  <img
                    [src]="guessImagePath(variant.front_image.full_path)"
                    alt="variant"
                    class="shadow-4"
                    width="100"
                    style="object-fit: contain"
                  />
                } @else {
                  <div class="noMediaContainer">
                    <p>Front Image</p>
                  </div>
                }
              </div>
              <div class="flex flex-column align-items-center">
                @if (variant.rear_image) {
                  <img
                    [src]="guessImagePath(variant.rear_image.full_path)"
                    alt="variant"
                    class="shadow-4"
                    width="100"
                    style="object-fit: contain"
                  />
                } @else {
                  <div class="noMediaContainer">
                    <p>Rear Image</p>
                  </div>
                }
              </div>
              <div class="flex flex-column align-items-center">
                @if (variant.side_image) {
                  <img
                    [src]="guessImagePath(variant.side_image?.full_path || '')"
                    alt="variant"
                    class="shadow-4"
                    width="100"
                    style="object-fit: contain"
                  />
                } @else {
                  <div class="noMediaContainer">
                    <p>Side Image</p>
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
      }
    </div>
  </p-card>
}
