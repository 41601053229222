import { NgClass } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';

import { Button } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { FileSelectEvent, FileUploadEvent, FileUploadModule } from 'primeng/fileupload';
import { InputTextModule } from 'primeng/inputtext';
import { SelectButtonModule } from 'primeng/selectbutton';
import { TabViewModule } from 'primeng/tabview';

import { CustomUploadedFile } from 'app/_shared/models/CustomUplaodedFile';
import { DecorationMethod, Library } from 'app/_shared/models/LibraryFile';
import { DecorationMethodService } from 'app/_shared/services/decoration-method.service';
import { LibraryService } from 'app/_shared/services/library.service';
import { ToastService } from 'app/_shared/services/toast.service';

@Component({
  selector: 'app-library-upload',
  imports: [
    TabViewModule,
    Button,
    DropdownModule,
    FileUploadModule,
    InputTextModule,
    ReactiveFormsModule,
    NgClass,
    FormsModule,
    SelectButtonModule
  ],
  templateUrl: './library-upload.component.html',
  styleUrl: './library-upload.component.scss'
})
export class LibraryUploadComponent implements OnInit {
  dialogRef = inject(DynamicDialogRef);
  dialogConfig = inject(DynamicDialogConfig);
  toastService = inject(ToastService);
  libraryService = inject(LibraryService);
  fb = inject(FormBuilder);
  decorationService = inject(DecorationMethodService);

  file: CustomUploadedFile;
  uploadFileForm: FormGroup;
  activeIndex = 0;
  storefrontId: any;
  storefrontLibraryFolders: any[] = [];
  allLibraries: Library[] = [];
  selectedLibrary: Library;
  decorationMethods: DecorationMethod[];
  selectedDecoration: DecorationMethod;
  submitted = false;

  printPositions = [
    { label: 'Front Left Chest' },
    { label: 'Front Right Chest' },
    { label: 'Back Left' },
    { label: 'Back Right' },
    { label: 'Back Center' }
  ];
  selectedPrintPosition: { label: string };

  ngOnInit() {
    this.storefrontId = this.dialogConfig.data?.storefrontId;
    const printPos = this.dialogConfig.data?.printPositions;
    if (printPos && printPos.length > 0) {
      this.printPositions = printPos.map(pos => ({ label: pos.position }));
    }
    // this.fetchStorefrontLibraryFolders(this.storefrontId);
    this.uploadFileForm = this.fb.group({
      name: ['', [Validators.required]],
      library_folder_id: ['', [Validators.required]]
    });
    this.decorationService.index().subscribe(next => (this.decorationMethods = next.data));
    this.fetchStorefrontLibraryFolders(this.storefrontId);
    this.libraryService.getLibraries(this.storefrontId).subscribe(next => {
      this.allLibraries = next.data;
    });
  }

  fetchStorefrontLibraryFolders(storefrontId: number) {
    this.libraryService.getStorefrontLibraryFolders(storefrontId).subscribe({
      next: response => {
        this.storefrontLibraryFolders = response.data;
      },
      error: error => {
        console.error(error);
      }
    });
  }

  onUpload(event: FileUploadEvent | FileSelectEvent) {
    const file = event.files[0];
    const objectURL = URL.createObjectURL(file);

    this.file = {
      file: file,
      objectURL: objectURL,
      name: file.name
    };
  }

  onUploadFile() {
    this.submitted = true;
    if (this.uploadFileForm.invalid) {
      this.toastService.error('Please fill all required fields correctly!');
      this.uploadFileForm.markAllAsTouched();
      return;
    }
    if (!this.file?.file || !this.file?.objectURL) {
      this.toastService.error('Please fill all required fields correctly!');
      return;
    }
    const formData = new FormData();
    const { name, library_folder_id } = this.uploadFileForm.value;
    formData.append('name', name);
    formData.append('library_folder_id', library_folder_id);
    formData.append('library_thumbnail', this.file.file);
    this.libraryService.uploadLibrary(this.storefrontId, formData).subscribe({
      next: res => {
        this.toastService.success('Successfully saved library file!');
        this.libraryService.getLibraries(this.storefrontId).subscribe(next => {
          this.allLibraries = next.data;
          this.activeIndex = 1;
        });
      },
      error: err => {
        console.error('Creation failed:', err);
      }
    });
  }

  removeMockupVersion() {
    this.file = {
      file: null,
      objectURL: null
    };
  }

  onSaveAndClose() {
    if (!this.selectedLibrary || !this.selectedDecoration) {
      this.toastService.error('Please select library and decoration method and print position');
      return;
    }
    this.dialogRef.close({
      library: this.selectedLibrary,
      decorationMethod: this.selectedDecoration,
      printPosition: this.selectedPrintPosition.label
    });
  }
}
