@if (formReady) {
  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <div class="grid">
      <div class="col-12">
        <div class="card">
          <h5>Store Contact</h5>
          <p>Enter any email addresses that will receive contact forms from your store.</p>
          <div class="grid">
            @for (contactEmail of contactEmails; track contactEmail) {
              <div class="col-12 md:col-6">
                <div class="flex">
                  <input
                    [disabled]="true"
                    [id]="contactEmail.id"
                    type="email"
                    pInputText
                    class="w-full"
                    [value]="contactEmail.email"
                  />
                  <i class="pi pi-pencil p-3" (click)="openStoreContactEmailDialog(contactEmail)"></i>
                  <i class="pi pi-trash pt-3"></i>
                </div>
              </div>
            }
          </div>
          <p-button
            (click)="openStoreContactEmailDialog()"
            [outlined]="true"
            [iconPos]="'left'"
            icon="pi pi-plus"
            label="Add email address"
          />
        </div>
        @for (customEmail of customEmails.controls; track customEmail) {
          <ng-container formArrayName="custom_mails">
            @if (customEmail.get('type')?.value === 'tracking_confirmation') {
              <div class="card" [formGroup]="customEmail">
                <h5>Tracking Confirmation Mail</h5>
                <p-panel
                  [collapseIcon]="'pi pi-arrow-down'"
                  [expandIcon]="'pi pi-arrow-up'"
                  [collapsed]="true"
                  [toggleable]="true"
                  header="Customize the email message containing order tracking link."
                >
                  <div class="grid" formGroupName="data">
                    <div class="col-12 md:col-6">
                      <label for="subject">Subject</label>
                      <input type="text" pInputText class="w-full" formControlName="subject" />
                      @if (
                        (submitted || customEmail.get('data.subject')?.touched) &&
                        customEmail.get('data.subject')?.errors?.['required']
                      ) {
                        <div>
                          <small class="p-text-danger">Subject is required</small>
                        </div>
                      }
                    </div>
                    <div class="col-12 md:col-6">
                      <label for="greeting">Greeting</label>
                      <input type="text" pInputText class="w-full" formControlName="greeting" />
                      @if (
                        (submitted || customEmail.get('data.greeting')?.touched) &&
                        customEmail.get('data.greeting')?.errors?.['required']
                      ) {
                        <div>
                          <small class="p-text-danger">Greeting is required</small>
                        </div>
                      }
                    </div>
                    <div class="col-12 md:col-6">
                      <label for="tracking_details_1">Tracking details 1</label>
                      <input type="text" pInputText class="w-full" formControlName="tracking_details_1" />
                      @if (
                        (submitted || customEmail.get('data.tracking_details_1')?.touched) &&
                        customEmail.get('data.tracking_details_1')?.errors?.['required']
                      ) {
                        <div>
                          <small class="p-text-danger">Tracking details one is required</small>
                        </div>
                      }
                    </div>
                    <div class="col-12 md:col-6">
                      <label for="tracking_details_2">Tracking details 2</label>
                      <input type="text" pInputText class="w-full" formControlName="tracking_details_2" />
                      @if (
                        (submitted || customEmail.get('data.tracking_details_2')?.touched) &&
                        customEmail.get('data.tracking_details_2')?.errors?.['required']
                      ) {
                        <div>
                          <small class="p-text-danger">Tracking details two is required</small>
                        </div>
                      }
                    </div>
                    <div class="col-12 md:col-6">
                      <label for="tracking_details_3">Tracking details 3</label>
                      <input type="text" pInputText class="w-full" formControlName="tracking_details_3" />
                      @if (
                        (submitted || customEmail.get('data.tracking_details_3')?.touched) &&
                        customEmail.get('data.tracking_details_3')?.errors?.['required']
                      ) {
                        <div>
                          <small class="p-text-danger">Tracking details three is required</small>
                        </div>
                      }
                    </div>
                    <div class="col-12 md:col-6">
                      <label for="track_info">Track info</label>
                      <input type="text" pInputText class="w-full" formControlName="track_info" />
                      @if (
                        (submitted || customEmail.get('data.track_info')?.touched) &&
                        customEmail.get('data.track_info')?.errors?.['required']
                      ) {
                        <div>
                          <small class="p-text-danger">Track info is required</small>
                        </div>
                      }
                    </div>
                    <div class="col-12 md:col-6">
                      <label for="end_info">End info</label>
                      <input type="text" pInputText class="w-full" formControlName="end_info" />
                      @if (
                        (submitted || customEmail.get('data.end_info')?.touched) &&
                        customEmail.get('data.end_info')?.errors?.['required']
                      ) {
                        <div>
                          <small class="p-text-danger">End info is required</small>
                        </div>
                      }
                    </div>
                    <div class="col-12 md:col-6">
                      <label for="goodbye_message">Goodbye message</label>
                      <input type="text" pInputText class="w-full" formControlName="goodbye_message" />
                      @if (
                        (submitted || customEmail.get('data.goodbye_message')?.touched) &&
                        customEmail.get('data.goodbye_message')?.errors?.['required']
                      ) {
                        <div>
                          <small class="p-text-danger">Goodbye message is required</small>
                        </div>
                      }
                    </div>
                  </div>
                  <hr />
                  <h4>Email Preview:</h4>
                  <div class="flex flex-column gap-1">
                    <p>Dear [RECIPIENT],</p>
                    <p>etc</p>
                  </div>
                </p-panel>
              </div>
            }
            @if (customEmail.get('type')?.value === 'order_confirmation') {
              <div class="card" [formGroup]="customEmail">
                <h5>Order Confirmation Mail</h5>
                <p-panel
                  [collapsed]="true"
                  [toggleable]="true"
                  [collapseIcon]="'pi pi-arrow-down'"
                  [expandIcon]="'pi pi-arrow-up'"
                  header="Customize the message that’s sent out to your customers after they
              place an order."
                >
                  <div class="grid" formGroupName="data">
                    <div class="col-12 md:col-6">
                      <label for="subject">Subject</label>
                      <input type="text" pInputText class="w-full" formControlName="subject" />
                      @if (
                        (submitted || customEmail.get('data.subject')?.touched) &&
                        customEmail.get('data.subject')?.errors?.['required']
                      ) {
                        <div>
                          <small class="p-text-danger">Subject message is required</small>
                        </div>
                      }
                    </div>
                    <div class="col-12 md:col-6">
                      <label for="thanks_for_shopping">Thanks for shopping</label>
                      <input type="text" pInputText class="w-full" formControlName="thanks_for_shopping" />
                      @if (
                        (submitted || customEmail.get('data.thanks_for_shopping')?.touched) &&
                        customEmail.get('data.thanks_for_shopping')?.errors?.['required']
                      ) {
                        <div>
                          <small class="p-text-danger">Thanks message is required</small>
                        </div>
                      }
                    </div>
                    <div class="col-12 md:col-6">
                      <label for="order_reference">Order Reference</label>
                      <input type="text" pInputText class="w-full" formControlName="order_reference" />
                      @if (
                        (submitted || customEmail.get('data.order_reference')?.touched) &&
                        customEmail.get('data.order_reference')?.errors?.['required']
                      ) {
                        <div>
                          <small class="p-text-danger">Order reference is required</small>
                        </div>
                      }
                    </div>
                    <div class="col-12 md:col-6">
                      <label for="custom_made">Custom made</label>
                      <input type="text" pInputText class="w-full" formControlName="custom_made" />
                      @if (
                        (submitted || customEmail.get('data.custom_made')?.touched) &&
                        customEmail.get('data.custom_made')?.errors?.['required']
                      ) {
                        <div>
                          <small class="p-text-danger">Custom made is required</small>
                        </div>
                      }
                    </div>
                    <div class="col-12 md:col-6">
                      <label for="note">Note</label>
                      <input type="text" pInputText class="w-full" formControlName="note" />
                      @if (
                        (submitted || customEmail.get('data.note')?.touched) &&
                        customEmail.get('data.note')?.errors?.['required']
                      ) {
                        <div>
                          <small class="p-text-danger">Note field is required</small>
                        </div>
                      }
                    </div>
                    <div class="col-12 md:col-6">
                      <label for="edit_address">Edit address</label>
                      <input type="text" pInputText class="w-full" formControlName="edit_address" />
                      @if (
                        (submitted || customEmail.get('data.edit_address')?.touched) &&
                        customEmail.get('data.edit_address')?.errors?.['required']
                      ) {
                        <div>
                          <small class="p-text-danger">Edit address is required</small>
                        </div>
                      }
                    </div>
                    <div class="col-12 md:col-6">
                      <label for="multiple_shipments">Multiple shipments</label>
                      <input type="text" pInputText class="w-full" formControlName="multiple_shipments" />
                      @if (
                        (submitted || customEmail.get('data.multiple_shipments')?.touched) &&
                        customEmail.get('data.multiple_shipments')?.errors?.['required']
                      ) {
                        <div>
                          <small class="p-text-danger">Multiple shipments field is required</small>
                        </div>
                      }
                    </div>
                    <div class="col-12 md:col-6">
                      <label for="goodbye_message">Goodbye message</label>
                      <input type="text" pInputText class="w-full" formControlName="goodbye_message" />
                      @if (
                        (submitted || customEmail.get('data.goodbye_message')?.touched) &&
                        customEmail.get('data.goodbye_message')?.errors?.['required']
                      ) {
                        <div>
                          <small class="p-text-danger">Goodbye message is required</small>
                        </div>
                      }
                    </div>
                  </div>
                </p-panel>
              </div>
            }
            @if (customEmail.get('type')?.value === 'store_invitation') {
              <div class="card" [formGroup]="customEmail">
                <h5>Store Invitation Mail</h5>

                <p-panel
                  [collapsed]="true"
                  [toggleable]="true"
                  [collapseIcon]="'pi pi-arrow-down'"
                  [expandIcon]="'pi pi-arrow-up'"
                  header="Customize the message that’s sent out to your the person that you
              invite to create an account on your store."
                >
                  <div class="grid" formGroupName="data">
                    <div class="col-12 md:col-6">
                      <label for="subject">Subject</label>
                      <input type="text" pInputText class="w-full" formControlName="subject" />
                      @if (
                        (submitted || customEmail.get('data.subject')?.touched) &&
                        customEmail.get('data.subject')?.errors?.['required']
                      ) {
                        <div>
                          <small class="p-text-danger">Subject is required</small>
                        </div>
                      }
                    </div>
                    <div class="col-12 md:col-6">
                      <label for="welcome_text">Welcome text</label>
                      <input type="text" pInputText class="w-full" formControlName="welcome_text" />
                      @if (
                        (submitted || customEmail.get('data.welcome_text')?.touched) &&
                        customEmail.get('data.welcome_text')?.errors?.['required']
                      ) {
                        <div>
                          <small class="p-text-danger">Welcome text is required</small>
                        </div>
                      }
                    </div>
                    <div class="col-12 md:col-6">
                      <label for="first_sentence">First sentence</label>
                      <input type="text" pInputText class="w-full" formControlName="first_sentence" />
                      @if (
                        (submitted || customEmail.get('data.first_sentence')?.touched) &&
                        customEmail.get('data.first_sentence')?.errors?.['required']
                      ) {
                        <div>
                          <small class="p-text-danger">First sentence is required</small>
                        </div>
                      }
                    </div>
                    <div class="col-12 md:col-6">
                      <label for="second_sentence">Second sentence</label>
                      <input type="text" pInputText class="w-full" formControlName="second_sentence" />
                      @if (
                        (submitted || customEmail.get('data.second_sentence')?.touched) &&
                        customEmail.get('data.second_sentence')?.errors?.['required']
                      ) {
                        <div>
                          <small class="p-text-danger">Second sentence is required</small>
                        </div>
                      }
                    </div>
                    <div class="col-12 md:col-6">
                      <label for="button_text">Button text</label>
                      <input type="text" pInputText class="w-full" formControlName="button_text" />
                      @if (
                        (submitted || customEmail.get('data.button_text')?.touched) &&
                        customEmail.get('data.button_text')?.errors?.['required']
                      ) {
                        <div>
                          <small class="p-text-danger">Button text is required</small>
                        </div>
                      }
                    </div>
                    <div class="col-12 md:col-6">
                      <label for="goodbye_message">Goodbye message</label>
                      <input type="text" pInputText class="w-full" formControlName="goodbye_message" />
                      @if (
                        (submitted || customEmail.get('data.goodbye_message')?.touched) &&
                        customEmail.get('data.goodbye_message')?.errors?.['required']
                      ) {
                        <div>
                          <small class="p-text-danger">Goodbye message is required</small>
                        </div>
                      }
                    </div>
                  </div>
                </p-panel>
              </div>
            }
          </ng-container>
        }
        <div class="card">
          <div class="flex flex-wrap gap-2 align-items-center justify-content-between">
            <h5>External SMTP</h5>
            <p-inputSwitch formControlName="customSmtp" binary="true"></p-inputSwitch>
          </div>
          @if (form.get('customSmtp')?.value) {
            <div formGroupName="smtp_settings">
              <div class="grid">
                <div class="col-12 md:col-6">
                  <label for="host">Host</label>
                  <input id="host" type="text" pInputText class="w-full" formControlName="host" />
                  @if (
                    (submitted || form.get('smtp_settings.host')?.touched) &&
                    form.get('smtp_settings.host')?.errors?.['required']
                  ) {
                    <div>
                      <small class="p-text-danger">Host is required</small>
                    </div>
                  }
                </div>
                <div class="col-12 md:col-6">
                  <label for="port">Port</label>
                  <input id="port" type="text" pInputText class="w-full" formControlName="port" />
                  @if (
                    (submitted || form.get('smtp_settings.port')?.touched) &&
                    form.get('smtp_settings.port')?.errors?.['required']
                  ) {
                    <div>
                      <small class="p-text-danger">Port is required</small>
                    </div>
                  }
                </div>
                <div class="col-12 md:col-6">
                  <label for="username">Username</label>
                  <input id="username" type="text" pInputText class="w-full" formControlName="username" />
                  @if (
                    (submitted || form.get('smtp_settings.username')?.touched) &&
                    form.get('smtp_settings.username')?.errors?.['required']
                  ) {
                    <div>
                      <small class="p-text-danger">Username is required</small>
                    </div>
                  }
                </div>
                <div class="col-12 md:col-6">
                  <label for="password">Password</label>
                  <input id="password" type="password" pInputText class="w-full" formControlName="password" />
                  @if (
                    (submitted || form.get('smtp_settings.password')?.touched) &&
                    form.get('smtp_settings.password')?.errors?.['required']
                  ) {
                    <div>
                      <small class="p-text-danger">Password is required</small>
                    </div>
                  }
                </div>
                <div class="col-12 md:col-6">
                  <label for="encryption">Encryption</label>
                  <input id="encryption" type="text" pInputText class="w-full" formControlName="encryption" />
                  @if (
                    (submitted || form.get('smtp_settings.encryption')?.touched) &&
                    form.get('smtp_settings.encryption')?.errors?.['required']
                  ) {
                    <div>
                      <small class="p-text-danger">Encryption is required</small>
                    </div>
                  }
                </div>
                <div class="col-12">
                  <p-button (onClick)="testSmtp()" pRipple label="Test" class="w-full" type="button" />
                </div>
              </div>
            </div>
          }
        </div>
        <div class="card">
          <p-button [disabled]="isLoading" pRipple label="Save" class="w-full mb-4" type="submit" />
        </div>
      </div>
    </div>
  </form>
}
