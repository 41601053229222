import { Component, ElementRef, ViewChild } from '@angular/core';
import { RouterLink } from '@angular/router';

import { NavComponent } from 'app/admin/layout/nav/nav.component';

@Component({
    selector: 'app-sidebar',
    imports: [RouterLink, NavComponent],
    templateUrl: './sidebar.component.html',
    styleUrl: './sidebar.component.scss'
})
export class SidebarComponent {
  @ViewChild('menuContainer') menuContainer!: ElementRef;
  // el: ElementRef = inject(ElementRef);
}
