<div class="border-round surface-border border-1">
  <ul class="list-none p-0 m-0">
    @for (item of order.items; track $index; let i = $index, e = $even) {
      <li
        class="p-3 surface-border flex align-items-start sm:align-items-center"
        [ngClass]="{ 'border-bottom-1': i !== order!.items!.length - 1 }"
      >
        <img
          [src]="item.product.product_thumbnail.full_path"
          [alt]="item.product.name"
          width="150"
          height="100"
          style="object-fit: contain"
        />
        <div class="flex flex-column ml-2">
          <span class="text-900 font-semibold text-xl mb-2">{{ item.product.name }}</span>
          <span class="text-700 font-medium mb-3">{{ item.product_variant.color }} | {{ item.product_size.size }}</span>
          <span class="text-900 font-medium">Quantity: {{ item.quantity }}</span>
        </div>
        <span class="text-900 font-medium text-lg ml-auto">{{ item.price | price | usdPipe }}</span>
      </li>
    }
  </ul>
</div>
<div class="flex flex-wrap mt-5 pb-3">
  <div class="w-full lg:w-6 pl-3">
    <span class="font-medium font-bold">Contact Info</span>
    <div class="flex flex-column text-900 mt-3 mb-5">
      <span class="mb-1">{{ order.contact_info.first_name }} {{ order.contact_info.last_name }}</span>
      <span class="mb-1">{{ order.contact_info.address_1 }}</span>
      <span class="mb-1">{{ order.contact_info.state.name }}, {{ order.contact_info.country.name }}</span>
      <span>{{ order.contact_info.phone_number }}</span>
    </div>
    @if (order.card_number_last_four) {
      <div class="flex flex-column">
        <span class="font-medium font-bold">Card Number</span>
        <span>{{ order.card_number_last_four | cardMask }}</span>
      </div>
    }
  </div>
  <div class="w-full lg:w-6 pl-3 lg:pl-0 lg:pr-3 flex align-items-end mt-5 lg:mt-0">
    <ul class="list-none p-0 m-0 w-full">
      <li class="mb-3"><span class="font-medium font-bold">Summary</span></li>
      <li class="flex justify-content-between mb-3">
        <span class="text-900">Shipping</span>
        <span class="text-900 font-medium text-lg">{{ order.shipping_price | price | usdPipe }}</span>
      </li>
      <li class="flex justify-content-between mb-3">
        <span class="text-900">Sales Tax</span>
        <span class="text-900 font-medium text-lg">{{ order.sales_tax | price | usdPipe }}</span>
      </li>

      <li class="flex justify-content-between border-top-1 surface-border py-3">
        <span class="text-900 font-medium">Total</span>
        <span class="text-900 font-bold text-lg">{{ order.total | price | usdPipe }}</span>
      </li>
    </ul>
  </div>
</div>
