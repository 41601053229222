import { inject } from '@angular/core';

import { withDevtools } from '@angular-architects/ngrx-toolkit';
import { patchState, signalStore, withState, withMethods, withHooks } from '@ngrx/signals';

import { Storefront } from 'app/_shared/models/Storefront';
import { StorefrontStore } from 'app/_store/storefront.store';
import { withLocalStorageSync } from 'app/_store/with-local-storage-sync';

import { Company } from '../_shared/models/Company';

export type CompanyState = {
  workingCompany: Company | null;
  isLoading: boolean;
};

const initialWorkingCompanyState: CompanyState = {
  workingCompany: null,
  isLoading: false
};

export const CompanyStore = signalStore(
  { providedIn: 'root' },
  withState(initialWorkingCompanyState),
  withDevtools('workingCompany'),
  withLocalStorageSync('workingCompany'),
  withMethods(store => {
    const storefrontStore = inject(StorefrontStore);
    return {
      setWorkingCompany: (company: Company) => {
        if (storefrontStore.selectedStorefront()) {
          storefrontStore.unsetSelectedStorefront();
        }
        patchState(store, { workingCompany: company });
        store.saveToLocalStorage();
      },
      addEditStorefront: (storefront: Storefront, storefrontId: number | null | undefined = null) => {
        const currentWorkingCompany = store.workingCompany();
        console.log(storefront);
        if (!currentWorkingCompany) return;

        const updatedStorefronts = storefrontId
          ? currentWorkingCompany.storefronts.map(item => (item.id === storefrontId ? storefront : item))
          : [...currentWorkingCompany.storefronts, { ...storefront }];

        const updatedCompany = {
          ...currentWorkingCompany,
          storefronts: updatedStorefronts
        };
        console.log(updatedCompany);
        patchState(store, { workingCompany: updatedCompany });
        store.saveToLocalStorage();
      },
      removeCompanyStorefront: (storefrontId: number) => {
        const currentWorkingCompany = store.workingCompany();
        if (currentWorkingCompany) {
          const updatedStorefronts = currentWorkingCompany.storefronts.filter(
            storefront => storefrontId !== storefront.id
          );
          patchState(store, { workingCompany: { ...currentWorkingCompany, storefronts: updatedStorefronts } });
        }
      },
      unsetWorkingCompany: () => {
        patchState(store, { workingCompany: undefined });
        store.removeFromLocalStorage();
      },
      setLoading: (isLoading: boolean) => patchState(store, { isLoading })
    };
  }),
  withHooks(store => ({
    onInit() {
      if (store.loadFromLocalStorage()) {
        return;
      }
    }
  }))
);
