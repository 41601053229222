import { Component, inject, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';

import { ButtonModule } from 'primeng/button';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { InputTextModule } from 'primeng/inputtext';

import { CompanyService } from 'app/_shared/services/company.service';
import { markAllAsTouched } from 'app/_shared/services/helper.service';
import { ToastService } from 'app/_shared/services/toast.service';

@Component({
  selector: 'app-selections-send-emails-dialog',
  imports: [ReactiveFormsModule, ButtonModule, InputTextModule],
  templateUrl: './selection-send-emails-dialog.component.html',
  styleUrl: './selection-send-emails-dialog.component.scss'
})
export class SelectionSendEmailsDialogComponent implements OnInit {
  fb: FormBuilder = inject(FormBuilder);
  toastService = inject(ToastService);
  companyService = inject(CompanyService);
  dialogConfig = inject(DynamicDialogConfig);
  dialogRef = inject(DynamicDialogRef);

  form: FormGroup;
  companyId: number;
  selectionId: number;

  ngOnInit() {
    this.buildForm();
    this.companyId = this.dialogConfig.data.companyId;
    this.selectionId = this.dialogConfig.data.selectionId;
  }

  buildForm() {
    this.form = this.fb.group({
      emails: this.fb.array([])
    });
    this.addRecepient();
  }

  get emails() {
    return this.form.get('emails') as FormArray;
  }

  addRecepient() {
    const positionGroup = this.fb.group({
      email: [null, [Validators.required, Validators.email]]
    });
    this.emails.push(positionGroup);
  }

  removeRecepient(index: number) {
    this.emails.removeAt(index);
  }

  sendSelectionEmails(emails: string[]) {
    this.companyService.sendEmailsForSelection(this.companyId, this.selectionId, emails).subscribe({
      next: () => {
        this.dialogRef.close(true);
      },
      error: err => {
        console.error(err);
        this.dialogRef.close(false);
      }
    });
  }

  onSubmit() {
    markAllAsTouched(this.form);
    if (this.form.invalid) {
      this.toastService.error('Please fill all required fields correctly!');
      return;
    }
    const emailArray: string[] = this.emails.value.map(emailRow => emailRow.email);
    this.sendSelectionEmails(emailArray);
  }
}
