import { NgStyle } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';

import { DynamicDialogConfig } from 'primeng/dynamicdialog';

import { FilePrepRequest } from 'app/_shared/models/FilePrepRequest';
import { UsdPipe } from 'app/_shared/pipes/usd-currency.pipe';

@Component({
    selector: 'app-file-prep-details-dialog',
    imports: [UsdPipe, NgStyle],
    templateUrl: './file-prep-details-dialog.component.html',
    styleUrl: './file-prep-details-dialog.component.scss'
})
export class FilePrepDetailsDialogComponent implements OnInit {
  private dialogConfig = inject(DynamicDialogConfig);

  filePrepRequest: FilePrepRequest;

  ngOnInit(): void {
    this.filePrepRequest = this.dialogConfig.data.request;
  }
}
