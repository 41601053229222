import { CommonModule, NgOptimizedImage } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { ConfirmationService, TreeNode } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { SkeletonModule } from 'primeng/skeleton';
import { TableModule } from 'primeng/table';
import { TooltipModule } from 'primeng/tooltip';
import { TreeTableModule } from 'primeng/treetable';
import { finalize } from 'rxjs';

import { CategoryEditorComponent } from 'app/_shared/components/category-editor/category-editor.component';
import { CatalogProductCategory } from 'app/_shared/models/CatalogProductCategory';
import { CatalogProductCategoryService } from 'app/_shared/services/catalog-product-category.service';
import { ToastService } from 'app/_shared/services/toast.service';

@Component({
  selector: 'app-category-management',
  imports: [
    FormsModule,
    ButtonModule,
    TableModule,
    CommonModule,
    ConfirmDialogModule,
    TooltipModule,
    SkeletonModule,
    NgOptimizedImage,
    TreeTableModule
  ],
  providers: [ConfirmationService, DialogService],
  templateUrl: './catalog-product-category-management.component.html',
  styleUrl: './catalog-product-category-management.component.scss'
})
export class CatalogProductCategoryManagementComponent implements OnInit {
  private categoryService = inject(CatalogProductCategoryService);
  confirmationService = inject(ConfirmationService);
  dialogService = inject(DialogService);
  toastService = inject(ToastService);
  dialogRef: DynamicDialogRef | undefined;

  productCategories: TreeNode[] = [];
  isLoading: boolean = false;
  isLoadingDelete: boolean = false;
  loadingRows = Array(10)
    .fill(0)
    .map((_, index) => {
      return index + 1;
    });

  ngOnInit() {
    this.loadCategories();
  }

  loadCategories() {
    this.isLoading = true;
    this.categoryService
      .index()
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe({
        next: res => {
          this.productCategories = res.data.map((x: CatalogProductCategory) => this.mapCategoryToTreeNode(x));
        },
        error: error => console.error(error)
      });
  }

  openDeleteModal(event: Event, id: number) {
    this.confirmationService.confirm({
      key: 'confirmDeleteCategory',
      target: event.target || new EventTarget(),
      message: 'Are you sure that you want to delete this category?',
      accept: () => {
        this.deleteCategoryOrSubcategory(id);
      },
      acceptButtonStyleClass: 'p-button-filled p-button-danger',
      rejectButtonStyleClass: 'p-button-outlined'
    });
  }

  deleteCategoryOrSubcategory(id: number) {
    this.isLoadingDelete = true;
    this.categoryService
      .deleteCategory(id)
      .pipe(finalize(() => (this.isLoadingDelete = false)))
      .subscribe({
        next: () => {
          this.toastService.success('Category successfully deleted!');
          this.loadCategories();
        },
        error: err => {
          console.error('Delete action failed:', err);
        }
      });
  }

  openCategoryEditor(category?: CatalogProductCategory, parentCategoryId?: number) {
    this.dialogRef = this.dialogService.open(CategoryEditorComponent, {
      header: category ? 'Edit Category' : 'Add New Category',
      width: '38rem',
      data: {
        category,
        parentCategoryId
      }
    });
    this.dialogRef.onClose.subscribe((res: boolean) => {
      if (res) {
        this.toastService.success('Category updated successfully!');
        this.loadCategories();
      }
    });
  }

  mapCategoryToTreeNode(category: CatalogProductCategory, depth = 0): TreeNode {
    return {
      key: '' + category.id,
      data: {
        ...category,
        depth: depth
      },
      children: (category.sub_categories || []).map(x => this.mapCategoryToTreeNode(x, depth + 1))
    };
  }
}
