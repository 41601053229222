import { NgClass } from '@angular/common';
import { Component, effect, inject, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';

import { TreeNode } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { DividerModule } from 'primeng/divider';
import { DropdownModule } from 'primeng/dropdown';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { IconFieldModule } from 'primeng/iconfield';
import { InputIconModule } from 'primeng/inputicon';
import { InputTextModule } from 'primeng/inputtext';
import { StepperModule } from 'primeng/stepper';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { TreeModule } from 'primeng/tree';
import { finalize } from 'rxjs';

import ChangeUserPermissionsData from 'app/_shared/models/ChangeUserPermissionsData';
import { Company } from 'app/_shared/models/Company';
import { RoleName, UserRole } from 'app/_shared/models/UserRole';
import { CompanyStore } from 'app/_store/company.store';

import { Permission } from '../../models/Permission';
import { Storefront } from '../../models/Storefront';
import UserInviteData from '../../models/UserInviteData';
import { CompanyService } from '../../services/company.service';
import { markAllAsTouched } from '../../services/helper.service';
import { ToastService } from '../../services/toast.service';

@Component({
  selector: 'app-invite-user-modal',
  imports: [
    ToggleButtonModule,
    ReactiveFormsModule,
    InputTextModule,
    DropdownModule,
    ButtonModule,
    NgClass,
    StepperModule,
    IconFieldModule,
    InputIconModule,
    DividerModule,
    TreeModule
  ],
  templateUrl: './user-permissions-modal.component.html',
  styles: [
    `
      .p-stepper {
        flex-basis: 40rem;
      }
    `,
    `
      ::ng-deep .p-stepper-nav {
        display: flex;
        justify-content: space-between;
        margin: 0;
        padding: 0;
        list-style-type: none;
      }
    `
  ]
})
export class UserPerrmissionsModalComponent implements OnInit {
  dialogRef = inject(DynamicDialogRef);
  companyStore = inject(CompanyStore);
  companyService = inject(CompanyService);
  toastService = inject(ToastService);
  dialogConfig = inject(DynamicDialogConfig);

  allCompanyPermissions: Permission[] = [];
  allStorefrontPermissions: Permission[] = [];
  companyStorefronts: Storefront[] = [];
  activeStep = 0;

  form: FormGroup;

  selectedStorefrontPermissions!: TreeNode[];
  permissionTreeNodes: TreeNode[] = [];
  userCompanyForEditPermissions: Company;
  userIdForEditPermissions: number;
  allRoles: UserRole[] = [];
  UserRoleNames = RoleName;
  selectedUserRoleName: string;

  isLoading = false;

  constructor() {
    effect(() => {
      const company = this.userCompanyForEditPermissions ?? this.companyStore.workingCompany();
      this.allCompanyPermissions = (company?.permissions || []).map(
        x =>
          ({
            id: x.id,
            full_name: x.full_name,
            name: x.name
          }) as Permission
      );

      this.initializeCompanyPermissions();
      this.initializeStorefronts(company!);
    });
  }

  ngOnInit(): void {
    this.userCompanyForEditPermissions = this.dialogConfig?.data?.userCompany;
    this.userIdForEditPermissions = this.dialogConfig?.data?.userId;
    this.loadRoles();
    this.initForm();
  }

  loadRoles(): void {
    const companyId = this.userCompanyForEditPermissions
      ? this.userCompanyForEditPermissions.id
      : this.companyStore.workingCompany()?.id;

    if (companyId) {
      this.companyService.rolesIndex(companyId).subscribe({
        next: res => {
          this.allRoles = res.data;
        },
        error: err => {
          console.error('Error loading roles:', err);
        }
      });
    }
  }

  initForm() {
    if (this.userCompanyForEditPermissions) {
      const selectedRole = this.userCompanyForEditPermissions.roles.find(role => role.granted);
      if (selectedRole) {
        this.selectedUserRoleName = selectedRole.name;
      }

      this.form = new FormGroup({
        role_id: new FormControl(selectedRole ? selectedRole.id : null, [Validators.required]),
        permissions: new FormArray([]),
        storefront_ids: new FormArray([])
      });
    } else {
      this.form = new FormGroup({
        email: new FormControl('', [Validators.required, Validators.email]),
        role_id: new FormControl('', [Validators.required]),
        permissions: new FormArray([]),
        storefront_ids: new FormArray([])
      });
    }
    this.subscribeToStorefrontChange();
    this.subscribeToRoleChange();
  }

  subscribeToStorefrontChange() {
    this.form.get('storefront_ids')?.valueChanges.subscribe(res => {
      if (this.companyStorefronts.length) {
        this.handleStorefrontsChange(res);
      }
    });
  }

  subscribeToRoleChange() {
    this.form.get('role_id')?.valueChanges.subscribe((roleId: number) => {
      const roleName = this.allRoles.find(role => role.id === roleId)?.name;
      if (roleName) {
        this.selectedUserRoleName = roleName;
        if (this.userCompanyForEditPermissions && roleName === this.UserRoleNames.CompanyAdmin) {
          this.clearPermissionsAndStorefronts();
        }
      }
    });
  }

  clearPermissionsAndStorefronts() {
    this.permissions.controls.forEach(permissionControl => {
      permissionControl.reset();
      permissionControl.updateValueAndValidity();
    });
    this.storefronts.controls.forEach(storefrontControl => {
      storefrontControl.reset();
      storefrontControl.updateValueAndValidity();
    });
    this.selectedStorefrontPermissions = [];
  }

  initializeCompanyPermissions() {
    //update
    if (this.userCompanyForEditPermissions) {
      this.userCompanyForEditPermissions.permissions.forEach(permission => {
        const permissionGranted = permission.granted;
        this.permissions.push(new FormGroup({ [permission.id]: new FormControl(permissionGranted) }));
      });
      return;
    }
    //create
    this.allCompanyPermissions.forEach(permission => {
      this.permissions.push(new FormGroup({ [permission.id]: new FormControl(false) }));
    });
  }

  initializeStorefronts(workingCompany: Company) {
    const storefrontOptions = this.userCompanyForEditPermissions
      ? this.userCompanyForEditPermissions.storefronts
      : workingCompany?.storefronts;

    this.companyStorefronts = storefrontOptions.map(storefront => {
      const isChecked = storefront.granted;
      this.storefronts.push(new FormGroup({ [storefront.id!]: new FormControl(isChecked) }));
      return storefront;
    });

    this.handleStorefrontsChange(this.storefronts.value);
  }

  handleStorefrontsChange(formStorefronts: any[]) {
    this.permissionTreeNodes = [];

    const selectedStorefronts: number[] = [];
    formStorefronts.map(storefront => {
      const id = Number(Object.keys(storefront)[0]);
      if (storefront[id]) {
        selectedStorefronts.push(id);
      }
    });

    this.companyStorefronts
      .filter(storefront => selectedStorefronts.includes(storefront.id))
      .forEach(storefront => {
        const storefrontPermissions: TreeNode[] = storefront.permissions.map(permission => ({
          key: permission.name,
          label: permission.full_name,
          data: permission,
          checked: permission.granted
        }));

        this.permissionTreeNodes.push({
          key: String(storefront.id),
          label: storefront.name,
          data: true,
          checked: true,
          expanded: true,
          icon: 'pi pi-building-columns',
          children: storefrontPermissions
        });
      });

    if (this.userCompanyForEditPermissions) {
      this.updateSelectedStorefrontPermissions();
    }
  }

  updateSelectedStorefrontPermissions() {
    this.selectedStorefrontPermissions = this.permissionTreeNodes.flatMap(storefrontNode => {
      return storefrontNode.children?.filter(child => child.checked) || [];
    });
  }

  get permissions(): FormArray {
    return this.form.get('permissions') as FormArray;
  }

  get storefronts(): FormArray {
    return this.form.get('storefront_ids') as FormArray;
  }

  closeModal() {
    this.dialogRef.close();
  }

  onSubmit() {
    if (this.form.invalid) {
      markAllAsTouched(this.form);
      return;
    }
    const submitData = this.prepareSubmitData();
    if (this.userCompanyForEditPermissions) {
      const userPermissionsData: ChangeUserPermissionsData = {
        storefront_ids: submitData.storefront_ids,
        permissions: submitData.permissions,
        role_id: submitData.role_id
      };
      this.updateUserPermissions(userPermissionsData);
    } else {
      const inviteData: UserInviteData = {
        email: this.form.value.email,
        company_id: this.companyStore.workingCompany()?.id,
        role_id: submitData.role_id,
        storefront_ids: submitData.storefront_ids,
        permissions: submitData.permissions
      };
      this.sendInvite(inviteData);
    }
  }

  prepareSubmitData() {
    const companyPermissions: number[] = [];
    const formCompanyPermissions = this.form.value.permissions || [];
    const userRoleId = this.form.value.role_id;

    formCompanyPermissions.forEach(permission => {
      const permissionId = Object.keys(permission)[0];
      if (permission[permissionId]) {
        companyPermissions.push(+permissionId);
      }
    });

    const formSelectedStorefronts = this.form.value.storefront_ids || [];
    const selectedStorefrontIds: number[] = [];
    formSelectedStorefronts.forEach(storefront => {
      const storefrontId = Object.keys(storefront)[0];
      if (storefront[storefrontId]) {
        selectedStorefrontIds.push(+storefrontId);
      }
    });

    const userPermissions = this.selectedStorefrontPermissions
      ?.filter(
        permissionNode => permissionNode.parent?.key && selectedStorefrontIds.includes(+permissionNode.parent.key)
      )
      .map(permissionNode => permissionNode.data.id);

    return {
      storefront_ids: selectedStorefrontIds,
      permissions: [...(companyPermissions || []), ...(userPermissions || [])],
      role_id: userRoleId
    };
  }

  updateUserPermissions(userPermissionsData: ChangeUserPermissionsData) {
    const companyId = this.userCompanyForEditPermissions.id ?? this.companyStore.workingCompany()?.id;

    this.isLoading = true;

    this.companyService
      .updateCompanyUserPersmissions(this.userIdForEditPermissions, userPermissionsData, companyId)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe({
        next: () => {
          this.dialogRef.close(true);
        },
        error: err => {
          console.error('Update failed:', err);
        }
      });
  }

  sendInvite(inviteData: UserInviteData) {
    this.isLoading = true;
    this.companyService
      .inviteUser(inviteData, this.companyStore.workingCompany()?.id)
      .pipe(finalize(() => (this.isLoading = false)))

      .subscribe({
        next: () => {
          this.dialogRef.close(true);
        },
        error: err => {
          console.error('Creation failed:', err);
        }
      });
  }

  nextStep(nextCallback: any) {
    if (this.form.invalid) {
      markAllAsTouched(this.form);
    } else {
      if (this.selectedUserRoleName === this.UserRoleNames.CompanyAdmin) {
        this.onSubmit();
        return;
      }
      nextCallback.emit();
    }
  }
}
