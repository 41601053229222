import { CommonModule } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';

import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { InputTextModule } from 'primeng/inputtext';
import { finalize } from 'rxjs';

import { Country } from '../../models/Country';
import { State } from '../../models/State';
import { StoreOperator } from '../../models/StoreOperator';
import { StorefrontService } from '../../services/storefront.service';
import { ToastService } from '../../services/toast.service';

@Component({
  selector: 'app-store-operator-editor-dialog',
  imports: [ButtonModule, InputTextModule, DialogModule, CommonModule, ReactiveFormsModule, DropdownModule],
  templateUrl: './store-operator-editor-dialog.component.html'
})
export class StoreOperatorEditorDialogComponent implements OnInit {
  fb = inject(FormBuilder);
  form: FormGroup;
  dialogConfig = inject(DynamicDialogConfig);
  dialogRef = inject(DynamicDialogRef);
  toastService = inject(ToastService);
  storefrontService = inject(StorefrontService);

  storeOperator: StoreOperator;
  countries: Country[] = [];
  states: State[] | undefined = [];
  storefrontId: number;

  submitted = false;
  isLoading = false;

  ngOnInit() {
    this.storeOperator = this.dialogConfig.data.storeOperator;
    this.storefrontId = this.dialogConfig.data.storefrontId;
    this.countries = this.dialogConfig.data.countries;
    const selectedCountry = this.countries.find(country => country.id === this.storeOperator?.country?.id);
    this.states = selectedCountry?.states;
    this.buildForm();
  }

  buildForm() {
    this.form = this.fb.group({
      first_name: [this.storeOperator?.first_name, [Validators.required]],
      last_name: [this.storeOperator?.last_name, [Validators.required]],
      business_name: [this.storeOperator?.business_name],
      email: [this.storeOperator?.email, [Validators.email]],
      address: [this.storeOperator?.address, [Validators.required]],
      unit: [this.storeOperator?.unit],
      city: [this.storeOperator?.city, [Validators.required]],
      state_id: [this.storeOperator?.state.id, [Validators.required]],
      zip_code: [this.storeOperator?.zip_code, [Validators.required]],
      country_id: [this.storeOperator?.country.id, [Validators.required]],
      phone: [this.storeOperator?.phone, [Validators.required]]
    });

    this.form.get('country_id')?.valueChanges.subscribe(countryId => {
      this.onCountryChange(countryId);
    });
  }

  onCountryChange(countryId: number) {
    const selectedCountry = this.countries.find(country => country.id === countryId);
    this.states = selectedCountry?.states;
    this.form.get('state_id')?.reset();
  }

  onSubmit() {
    if (this.form.invalid) {
      this.submitted = true;
      this.toastService.error('Please fill all required fields correctly!');
      return;
    }

    this.updateStoreOperatorInfo();
  }

  updateStoreOperatorInfo() {
    this.isLoading = true;
    this.storefrontService
      .createOrUpdateStoreOperatorInfo(this.form.value, this.storefrontId)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe({
        next: response => {
          const updatedOperatorInfo = response.data;
          this.dialogRef.close(updatedOperatorInfo);
        },
        error: err => {
          console.error('Operation failed:', err);
        }
      });
  }
}
