import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { LibraryFolder } from 'app/_shared/models/LibraryFolder';

import { Library } from '../models/Library';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class LibraryService extends ApiService {
  getStorefrontLibraryFolders(storefrontId: number) {
    return this.get<LibraryFolder[]>(`${this.apiUrl}storefronts/${storefrontId}/libraries/folders`);
  }

  createStorefrontLibraryFolder(storefrontId: number, folder: LibraryFolder) {
    return this.post<LibraryFolder[]>(`${this.apiUrl}storefronts/${storefrontId}/libraries/folders`, folder);
  }

  updateStorefrontLibraryFolder(storefrontId: number, folderId: number, folder: LibraryFolder) {
    return this.put<LibraryFolder[]>(`${this.apiUrl}storefronts/${storefrontId}/libraries/folders/${folderId}`, folder);
  }

  deleteStorefrontLibraryFolder(storefrontId: number, folderId: number) {
    return this.delete(`${this.apiUrl}storefronts/${storefrontId}/libraries/folders/${folderId}`);
  }

  getLibraryFolderLibraries(storefrontId: number, folderId: number) {
    return this.get(`${this.apiUrl}storefronts/${storefrontId}/libraries/library-folders/${folderId}`);
  }

  getLibrary(storefrontId: string, libraryId: string, params?: any) {
    return this.get<Library>(`${this.apiUrl}storefronts/${storefrontId}/libraries/${libraryId}`, {
      params
    });
  }

  createLibrary(storefrontId: number, data: FormData) {
    return this.post(`${this.apiUrl}storefronts/${storefrontId}/libraries`, data);
  }

  updateLibrary(storefrontId: number, libraryId: number, data: FormData) {
    return this.post(`${this.apiUrl}storefronts/${storefrontId}/libraries/${libraryId}`, data);
  }

  getLatestUsedFiles(storefrontId: number) {
    return this.get(`${this.apiUrl}storefronts/${storefrontId}/libraries/latest-used`);
  }

  createPrepFileRequest(storefrontId: number, data: any) {
    return this.post(`${this.apiUrl}storefronts/${storefrontId}/libraries/preparation-requests`, data);
  }

  deletePreparationRequest(storefrontId: number, prepRequestId: number) {
    return this.delete(`${this.apiUrl}storefronts/${storefrontId}/libraries/preparation-requests/${prepRequestId}`);
  }

  storefrontFilePrepRequestsPaginated(storefrontId: number, $event?: any) {
    return this.get(`${this.apiUrl}storefronts/${storefrontId}/libraries/preparation-requests`, {
      params: this.handleTableLazyLoadEvent($event)
    });
  }

  allFilePrepRequestsPaginated($event?: any) {
    const params = {
      ...this.handleTableLazyLoadEvent($event),
      'relations[]': ['library.libraryThumbnail', 'storefront']
    };
    return this.get(`${this.apiUrl}preparation-requests`, {
      params
    });
  }

  superAdminUpdateLibraryFiles(storefrontId: number, data: FormData, libraryId?: number) {
    return this.post(`${this.apiUrl}storefronts/${storefrontId}/libraries/${libraryId}/library-files`, data);
  }

  superAdminDeleteibraryFile(storefrontId: number, libraryFileId?: number) {
    return this.delete(`${this.apiUrl}storefronts/${storefrontId}/libraries/library-files/${libraryFileId}`);
  }

  updatePrepRequestPaidStatus(storefrontId: number, paidStatus: number, prepRequestId?: number) {
    return this.put(
      `${this.apiUrl}storefronts/${storefrontId}/libraries/preparation-requests/${prepRequestId}/change-payment-status`,
      {
        status: 0
      }
    );
  }

  createPrepFileRequestWithLibrabry(storefrontId: number, data: any) {
    return this.post(`${this.apiUrl}storefronts/${storefrontId}/libraries/create-library/preparation-requests`, data);
  }

  uploadLibrary(storefrontId: number, data: FormData) {
    return this.post(`${this.apiUrl}storefronts/${storefrontId}/libraries`, data);
  }

  getLibraries(storefrontId: number) {
    return this.get(`${this.apiUrl}storefronts/${storefrontId}/libraries`, {
      params: { 'relations[]': ['libraryFiles.decorationMethods', 'libraryFiles.thumbnails', 'libraryThumbnail'] }
    });
  }

  changeApproveStatus(storefrontId: number, librabryId: number) {
    return this.get(`${this.apiUrl}storefronts/${storefrontId}/libraries/${librabryId}/change-approve-status`);
  }

  downloadLibraryFiles(storefrontId: number, libraryId: number): Observable<Blob> {
    const url = `${this.apiUrl}storefronts/${storefrontId}/libraries/${libraryId}/download`;
    return this.getHttpClient().get<Blob>(url, { responseType: 'blob' as 'json' });
  }
}
