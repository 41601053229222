<p-table
  #dt
  [value]="users"
  [lazy]="true"
  (onLazyLoad)="lazyLoadUsers.emit($event)"
  dataKey="id"
  [paginator]="true"
  [rows]="rows"
  [totalRecords]="totalRecords"
  [showCurrentPageReport]="true"
  [loading]="loading"
  [showLoader]="false"
  responsiveLayout="scroll"
  currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
  [rowsPerPageOptions]="[5, 10, 25, 50]"
  [globalFilterFields]="globalFilterFields"
>
  <ng-template pTemplate="caption">
    <div class="flex flex-wrap gap-2 align-items-center justify-content-between pb-3">
      <span class="p-input-icon-left w-full sm:w-20rem flex-order-1 sm:flex-order-0">
        <i class="pi pi-search"></i>
        <input pInputText type="text" (input)="onGlobalFilter(dt, $event)" placeholder="Global Search" class="w-full" />
      </span>
    </div>
  </ng-template>

  <ng-template pTemplate="header">
    <tr>
      <th pSortableColumn="first_name" class="white-space-nowrap" style="width: 21.25%">
        First Name
        <p-sortIcon field="first_name" />
      </th>
      <th pSortableColumn="last_name" class="white-space-nowrap" style="width: 21.25%">
        Last Name
        <p-sortIcon field="last_name" />
      </th>
      <th pSortableColumn="email" class="white-space-nowrap" style="width: 21.25%">
        Email
        <p-sortIcon field="email" />
      </th>
      <th pSortableColumn="phone" class="white-space-nowrap" style="width: 21.25%">
        Phone
        <p-sortIcon field="phone" />
      </th>
      <th class="white-space-nowrap" style="width: 15%">Actions</th>
    </tr>
  </ng-template>

  <ng-template pTemplate="body" let-user>
    <tr>
      <td>{{ user.first_name }}</td>
      <td>{{ user.last_name }}</td>
      <td>{{ user.email }}</td>
      <td>{{ user.phone ?? '/' }}</td>
      <td>
        <div class="flex gap-2">
          <p-button [routerLink]="['./', user.id]" icon="pi pi-eye" outlined severity="secondary"></p-button>
          <p-button icon="pi pi-pen-to-square" outlined (click)="editUser(user)"></p-button>
          @if (
            (!companyUsers && user?.role?.name !== UserRoleNames.SuperAdmin) ||
            (companyUsers && user?.role?.name !== UserRoleNames.CompanyAdmin)
          ) {
            <p-button icon="pi pi-trash" (click)="openDeleteModal($event, user.id)" severity="danger" outlined />
          }
        </div>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage">
    <tr>
      <td colspan="5" class="text-center">There are no users</td>
    </tr>
  </ng-template>
  <ng-template pTemplate="loadingbody">
    @for (row of loadingRows; track row) {
      <tr>
        @for (col of ['first_name', 'last_name', 'email', 'phone', 'actions']; track col) {
          <td>
            <p-skeleton />
          </td>
        }
      </tr>
    }
  </ng-template>
</p-table>
<p-confirmDialog
  header="Confirmation"
  key="confirmDelete"
  icon="pi pi-exclamation-triangle"
  message="Are you sure you want to proceed?"
  [style]="{ width: '28rem' }"
></p-confirmDialog>
<app-edit-user-dialog
  [user]="userForEdit"
  [visible]="editUserDialog"
  [isLoading]="loading"
  (cancelEdit)="hideDialog()"
  (validFormEmitter)="emitEditUser($event)"
></app-edit-user-dialog>
