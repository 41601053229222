<form [formGroup]="storefrontForm" (ngSubmit)="onSubmit()">
  <div class="grid">
    <div class="col-12">
      <div class="p-fluid">
        <div class="field">
          <label for="name-input">Name</label>
          <input id="name-input" type="text" pInputText formControlName="name" />
          @if (
            submitted ||
            (storefrontForm.get('name')?.invalid &&
              (storefrontForm.get('name')?.dirty || storefrontForm.get('name')?.touched))
          ) {
            @if (storefrontForm.get('name')?.errors?.['required']) {
              <div>
                <small class="p-text-danger">Name is required</small>
              </div>
            }
          }
        </div>
        <div class="field">
          <label for="subdomain">Url</label>
          <p-inputGroup class="w-full">
            <p-inputGroupAddon>{{ prefix }}</p-inputGroupAddon>
            <input id="subdomain" type="text" pInputText formControlName="subdomain" />
            <p-inputGroupAddon>{{ suffix }}</p-inputGroupAddon>
          </p-inputGroup>
          @if (
            submitted ||
            (storefrontForm.get('subdomain')?.invalid &&
              (storefrontForm.get('subdomain')?.dirty || storefrontForm.get('subdomain')?.touched))
          ) {
            @if (storefrontForm.get('subdomain')?.errors?.['required']) {
              <div>
                <small class="p-text-danger">Store subdomain is required</small>
              </div>
            }
            @if (storefrontForm.get('subdomain')?.errors?.['invalidSubdomain']) {
              <div>
                <small class="p-text-danger">Invalid subdomain format. (Allowed a-z, A-Z, 0-9, -)</small>
              </div>
            }
          }
        </div>
        @if (superAdminMode) {
          <div class="field">
            <label for="company-select">Select Company</label>
            <p-dropdown
              id="company-select"
              formControlName="company_id"
              [options]="companyOptions"
              optionLabel="name"
              optionValue="id"
              placeholder="Select a company"
            ></p-dropdown>
            @if (
              submitted ||
              (storefrontForm.get('company_id')?.invalid &&
                (storefrontForm.get('company_id')?.dirty || storefrontForm.get('company_id')?.touched))
            ) {
              @if (storefrontForm.get('company_id')?.errors?.['required']) {
                <div>
                  <small class="p-text-danger">Company is required</small>
                </div>
              }
            }
          </div>
        }
        <div class="mb-3 custom-upload">
          <p-fileUpload
            #fileUploader
            name="logo-uploader"
            [multiple]="false"
            (onUpload)="onLogoUpload($event)"
            [customUpload]="true"
            (onSelect)="onLogoUpload($event)"
            accept="image/*"
            maxFileSize="1000000"
            showUploadButton="false"
            styleClass="border-1 surface-border surface-card p-0 border-round mb-1"
          >
            <ng-template pTemplate="content">
              <div class="min-h-10 m-1 border-round py-4">
                @if (!this.logo?.objectURL) {
                  <div
                    class="flex flex-column w-full h-full justify-content-center align-items-center cursor-pointer"
                    (click)="fileUploader.advancedFileInput.nativeElement.click()"
                  >
                    <i class="pi pi-fw pi-file text-4xl text-primary"></i>
                    <span class="block font-semibold text-900 text-lg mt-3">Drop or select storefront logo</span>
                  </div>
                }
                @if (this.logo?.objectURL) {
                  <div class="flex justify-content-center align-items-center h-full w-full">
                    <img [src]="this.logo?.objectURL" height="80" alt="storefront logo" style="object-fit: contain" />
                    <button
                      pButton
                      type="button"
                      aria-label="remove"
                      icon="pi pi-times"
                      class="p-button-rounded p-button-primary text-sm absolute justify-content-center align-items-center"
                      style="top: 10px; right: 10px"
                      (click)="$event.stopPropagation(); removeLogo()"
                    ></button>
                  </div>
                }
              </div>
            </ng-template>
          </p-fileUpload>
        </div>
        <div class="field w-10rem">
          <p-button [disabled]="isLoading" label="Submit" type="submit"></p-button>
        </div>
      </div>
    </div>
  </div>
</form>
