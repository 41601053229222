import { Component, inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';

import { ButtonModule } from 'primeng/button';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { FileSelectEvent, FileUploadEvent, FileUploadModule } from 'primeng/fileupload';

import { InputComponent } from 'app/_shared/components/input/input.component';
import { markAllAsTouched } from 'app/_shared/services/helper.service';
import { ToastService } from 'app/_shared/services/toast.service';

@Component({
  selector: 'app-new-variant-media-modal',
  imports: [ReactiveFormsModule, FileUploadModule, ButtonModule, InputComponent],
  templateUrl: './new-variant-media-modal.component.html',
  styleUrl: './new-variant-media-modal.component.scss'
})
export class NewVariantMediaModalComponent implements OnInit {
  fb = inject(FormBuilder);
  toastService = inject(ToastService);
  private dialogRef = inject(DynamicDialogRef);

  form: FormGroup;

  ngOnInit(): void {
    this.form = this.fb.group({
      side: ['', [Validators.required]],
      full_path: ['', Validators.required]
    });
  }

  onImageUpload(event: FileUploadEvent | FileSelectEvent) {
    const fileInput = event.files[0];
    if (fileInput) {
      const reader = new FileReader();
      reader.onload = e => {
        const imageUrl = e.target?.result as string;
        this.form.patchValue({ full_path: imageUrl });
      };
      reader.readAsDataURL(fileInput);
    }
  }

  removeImage() {
    this.form.patchValue({ full_path: '' });
  }

  closeDialog() {
    this.dialogRef.close();
  }

  onSubmit() {
    markAllAsTouched(this.form);
    if (this.form.invalid) {
      this.toastService.error('Please fill all required fields correctly!');
      return;
    }
    this.dialogRef.close(this.form.value);
  }
}
