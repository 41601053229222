import { Component, inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { ButtonModule } from 'primeng/button';
import { ProgressSpinnerModule } from 'primeng/progressspinner';

import { User } from '../../_shared/models/User';
import { InviteService } from '../../_shared/services/invite.service';
import { ToastService } from '../../_shared/services/toast.service';
import { AuthStore } from '../../_store/auth.store';

@Component({
    selector: 'app-user-invite-status',
    imports: [ButtonModule, ProgressSpinnerModule],
    templateUrl: './user-invite-status.component.html'
})
export class UserInviteStatusComponent implements OnInit {
  route = inject(ActivatedRoute);
  router = inject(Router);
  authStore = inject(AuthStore);

  inviteService = inject(InviteService);
  toastService = inject(ToastService);

  token: string;
  companyId: string;

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.token = params.token;

      if (this.token) {
        this.checkUserInviteStatus();
      }
    });
  }

  checkUserInviteStatus() {
    this.inviteService.accept(this.token).subscribe({
      next: res => {
        this.loginUser(res.data.access_token, res.data.user);
      },
      error: err => {
        if (err.status === 400) {
          this.toastService.error('Invalid invite token. Please try again.');
          this.router.navigateByUrl('/auth/login');
        } else if (err.status === 432) {
          this.router.navigateByUrl(
            `/auth/register?token=${this.token}&email=${err.error.data.email}&companyId=${err.error.data.company_id}`
          );
        } else {
          console.error('Action failed:', err);
        }
      }
    });
  }

  loginUser(accessToken: string, user: User) {
    this.authStore.setToken(accessToken);
    this.authStore.setAuthUser(user);
    this.router.navigateByUrl('/');
  }
}
