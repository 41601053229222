import { CommonModule } from '@angular/common';
import * as i0 from '@angular/core';
import { Component, ChangeDetectionStrategy, ViewEncapsulation, NgModule } from '@angular/core';
const _c0 = ["*"];
class ButtonGroup {
  static ɵfac = function ButtonGroup_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || ButtonGroup)();
  };
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: ButtonGroup,
    selectors: [["p-buttonGroup"]],
    standalone: false,
    ngContentSelectors: _c0,
    decls: 2,
    vars: 0,
    consts: [["role", "group", 1, "p-button-group", "p-component"]],
    template: function ButtonGroup_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵprojectionDef();
        i0.ɵɵelementStart(0, "span", 0);
        i0.ɵɵprojection(1);
        i0.ɵɵelementEnd();
      }
    },
    encapsulation: 2,
    changeDetection: 0
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ButtonGroup, [{
    type: Component,
    args: [{
      selector: 'p-buttonGroup',
      template: `
        <span class="p-button-group p-component" role="group">
            <ng-content></ng-content>
        </span>
    `,
      changeDetection: ChangeDetectionStrategy.OnPush,
      encapsulation: ViewEncapsulation.None
    }]
  }], null, null);
})();
class ButtonGroupModule {
  static ɵfac = function ButtonGroupModule_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || ButtonGroupModule)();
  };
  static ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
    type: ButtonGroupModule
  });
  static ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
    imports: [CommonModule]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ButtonGroupModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule],
      exports: [ButtonGroup],
      declarations: [ButtonGroup]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { ButtonGroup, ButtonGroupModule };
