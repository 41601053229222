import { Component, inject, input, OnInit, signal } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';

import { ConfirmationService } from 'primeng/api';
import { ChipModule } from 'primeng/chip';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DropdownModule } from 'primeng/dropdown';
import { DialogService } from 'primeng/dynamicdialog';
import { FileSelectEvent, FileUploadEvent, FileUploadModule } from 'primeng/fileupload';
import { InputTextModule } from 'primeng/inputtext';
import { SelectButtonModule } from 'primeng/selectbutton';
import { TabMenuModule } from 'primeng/tabmenu';
import { TabViewModule } from 'primeng/tabview';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { finalize } from 'rxjs';

import { FileDownloadService } from 'app/_shared/helpers/file-download.service';
import { DecorationMethod } from 'app/_shared/interfaces/catalog';
import { Library, LibraryFile } from 'app/_shared/models/Library';
import { DecorationMethodService } from 'app/_shared/services/decoration-method.service';
import { LibraryService } from 'app/_shared/services/library.service';
import { ToastService } from 'app/_shared/services/toast.service';

@Component({
  selector: 'app-superadmin-file-prep',
  imports: [
    ReactiveFormsModule,
    FileUploadModule,
    DropdownModule,
    InputTextModule,
    SelectButtonModule,
    FormsModule,
    ChipModule,
    TabMenuModule,
    TabViewModule,
    ConfirmDialogModule,
    ToggleButtonModule
  ],
  providers: [DialogService, ConfirmationService],
  templateUrl: './superadmin-file-prep.component.html',
  styleUrl: './superadmin-file-prep.component.scss'
})
export class SuperadminFilePrepComponent implements OnInit {
  private decorationMethodService = inject(DecorationMethodService);
  private libraryService = inject(LibraryService);
  private fb = inject(FormBuilder);
  private toastService = inject(ToastService);
  private confirmationService = inject(ConfirmationService);
  private fileDownloadService = inject(FileDownloadService);

  storefrontId = input.required<string>();
  libraryId = input.required<string>();
  library: Library;

  form: FormGroup;
  decorationMethodOptions: DecorationMethod[] = [];
  activeIndex = signal<number>(0);

  decorationMethodThumbnails: { [key: number]: { id?: number; name: string; file: string } } = {};
  libraryEmbroideryFile: { id?: number; name?: string; file?: string } = { name: '', file: '' };
  decorationMethodProcessedFiles: { [key: number]: { id?: number; name: string; file: string }[] } = {};
  deletedProcessedAdditionalFilesIds: number[] = [];
  deletedEmbroideryFile: number;

  libraryApproveStatus: boolean;
  isLoading = false;

  ngOnInit() {
    this.fetchDecorationMethods();
    this.getLibrary();
  }

  fetchDecorationMethods() {
    this.decorationMethodService.index().subscribe({
      next: res => {
        this.decorationMethodOptions = res.data;
      },
      error: error => console.error(error)
    });
  }

  getLibrary() {
    const params = {
      'relations[]': [
        'libraryFiles.decorationMethods',
        'libraryFiles.thumbnails',
        'libraryFiles.embroideryFile',
        'libraryFiles.processedAdditionalFiles',
        'libraryThumbnail'
      ]
    };
    this.libraryService.getLibrary(this.storefrontId(), this.libraryId(), params).subscribe(next => {
      this.library = next.data;
      this.libraryApproveStatus = this.library.approved;
      this.buildFormForActiveIndex(this.activeIndex());
      this.initializeThumbnailsForActiveLibraryFile();
      this.initializeEmbroideryFile();
      this.initializeProcessedAdditionalFilesForActiveLibraryFile();
    });
  }

  initializeThumbnailsForActiveLibraryFile() {
    const libraryFile = this.library?.library_files[this.activeIndex()];
    if (!libraryFile) return;

    this.decorationMethodThumbnails = {};

    libraryFile?.thumbnails?.forEach(thumbnail => {
      const decorationMethodId = thumbnail.decoration_method_id;
      this.decorationMethodThumbnails[decorationMethodId] = {
        id: thumbnail.id,
        name: thumbnail.name,
        file: thumbnail.full_path
      };
    });
  }

  initializeEmbroideryFile() {
    const libraryFile = this.library?.library_files[this.activeIndex()];
    if (!libraryFile) return;

    this.libraryEmbroideryFile = {
      id: libraryFile.embroidery_file?.id,
      name: libraryFile.embroidery_file?.name,
      file: libraryFile.embroidery_file?.full_path
    };
  }

  initializeProcessedAdditionalFilesForActiveLibraryFile() {
    const libraryFile = this.library?.library_files[this.activeIndex()];
    if (!libraryFile) return;

    this.decorationMethodProcessedFiles = {};

    libraryFile?.processed_additional_files?.forEach(file => {
      const decorationMethodId = file.decoration_method_id;

      if (!this.decorationMethodProcessedFiles[decorationMethodId]) {
        this.decorationMethodProcessedFiles[decorationMethodId] = [];
      }

      this.decorationMethodProcessedFiles[decorationMethodId].push({
        id: file.id,
        name: file.name,
        file: file.full_path
      });
    });
  }

  onTabChange(event: any) {
    this.activeIndex.set(event.index);
    this.buildFormForActiveIndex(this.activeIndex());
    this.clearDecorationMethodThumbnails();
    this.clearDecorationMethodProcessedFiles();
    this.initializeThumbnailsForActiveLibraryFile();
    this.initializeEmbroideryFile();
    this.initializeProcessedAdditionalFilesForActiveLibraryFile();
  }

  buildFormForActiveIndex(index: number) {
    const initNumberOfStitches = this.library?.number_of_stitches;
    const initPrice = (this.library?.price / 100).toFixed(2);
    if (this.library?.library_files[index]) {
      const libraryFile = this.library?.library_files[index];
      this.form = this.fb.group({
        test: true,
        id: [libraryFile?.id ?? null],
        label: [libraryFile?.label ?? null, [Validators.required]],
        decoration_method_id: [1, [Validators.required]],
        number_of_stitches: [initNumberOfStitches ?? null, [Validators.required]],
        price: [initPrice ?? null, [Validators.required]]
      });

      this.subscribeToDecorationMethodChange();
      this.subscribeToNumberOfStitchesChange();
    }
  }

  subscribeToDecorationMethodChange() {
    this.form.get('decoration_method_id')?.valueChanges.subscribe(decorationMethodId => {
      const numberOfStitchesControl = this.form.get('number_of_stitches');
      const priceControl = this.form.get('price');
      if (decorationMethodId === 1) {
        numberOfStitchesControl?.setValidators([Validators.required]);
        priceControl?.setValidators([Validators.required]);
      } else {
        numberOfStitchesControl?.clearValidators();
        priceControl?.clearValidators();
      }
      numberOfStitchesControl?.updateValueAndValidity();
      priceControl?.updateValueAndValidity();
    });
  }

  subscribeToNumberOfStitchesChange() {
    this.form.get('number_of_stitches')?.valueChanges.subscribe(numberOfStitches => {
      const priceControl = this.form.get('price');
      if (numberOfStitches) {
        const calculatedPrice = numberOfStitches / 1000;
        const roundedPrice = Math.ceil(calculatedPrice);
        priceControl?.setValue(roundedPrice);
      } else {
        priceControl?.setValue(0);
      }

      priceControl?.updateValueAndValidity();
    });
  }

  clearDecorationMethodThumbnails() {
    this.decorationMethodThumbnails = {};
  }

  clearDecorationMethodProcessedFiles() {
    this.decorationMethodProcessedFiles = {};
  }

  onThumbnailUpload(event: FileUploadEvent | FileSelectEvent) {
    const file = event.files[0];
    const fileName = event.files[0].name;

    const reader = new FileReader();
    reader.onloadend = () => {
      const base64File = reader.result as string;

      const decorationMethodId = this.form.get('decoration_method_id')?.value;
      this.decorationMethodThumbnails[decorationMethodId] = {
        name: fileName,
        file: base64File
      };
    };
    reader.readAsDataURL(file);
  }

  onProcessedAdditionalFilesUpload(event: FileUploadEvent | FileSelectEvent) {
    for (const file of event.files) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64File = reader.result as string;

        const decorationMethodId = this.form.get('decoration_method_id')?.value;

        if (!this.decorationMethodProcessedFiles[decorationMethodId]) {
          this.decorationMethodProcessedFiles[decorationMethodId] = [];
        }

        this.decorationMethodProcessedFiles[decorationMethodId].push({
          name: file.name,
          file: base64File
        });
      };
      reader.readAsDataURL(file);
    }
  }

  onEmbroideryFileUpload(event: FileUploadEvent | FileSelectEvent) {
    const file = event.files[0];
    const fileName = event.files[0].name;

    const reader = new FileReader();
    reader.onloadend = () => {
      const base64File = reader.result as string;

      this.libraryEmbroideryFile = {
        name: fileName,
        file: base64File
      };
    };
    reader.readAsDataURL(file);
  }

  removeThumbnailForDecorationMethod(decorationMethodId: number) {
    if (this.decorationMethodThumbnails[decorationMethodId]) {
      this.decorationMethodThumbnails[decorationMethodId] = { file: '', name: '' };
    }
  }

  removeEmbroideryFile() {
    if (this.libraryEmbroideryFile.id) {
      this.deletedEmbroideryFile = this.libraryEmbroideryFile.id;
    }
    this.libraryEmbroideryFile = { file: '', name: '', id: undefined };
  }

  removeProcessedFileForDecorationMethod(index: number, decorationMethodId: number) {
    const removedFile = this.decorationMethodProcessedFiles[decorationMethodId]?.[index];
    this.decorationMethodProcessedFiles[decorationMethodId]?.splice(index, 1);
    if (removedFile?.id) {
      this.deletedProcessedAdditionalFilesIds.push(removedFile.id);
    }
  }

  onAddNewLibraryFile() {
    const newLibraryFile: LibraryFile = {
      label: '',
      decoration_methods: [],
      library_id: this.library?.id,
      valid: false,
      thumbnails: [],
      processed_additional_files: []
    };

    this.library.library_files.push(newLibraryFile);

    const newIndex = this.library.library_files.length - 1;
    this.buildFormForActiveIndex(newIndex);
    this.activeIndex.set(newIndex);
  }

  changeLibraryApproveStatus() {
    this.libraryService.changeApproveStatus(+this.storefrontId(), this.library.id).subscribe({
      next: () => {
        this.toastService.success('The library status successfully chenged.');
        this.libraryApproveStatus = !this.libraryApproveStatus;
      },
      error: err => {
        console.error(err);
      }
    });
  }

  downloadLibraryfiles() {
    this.libraryService.downloadLibraryFiles(+this.storefrontId(), this.library.id).subscribe({
      next: res => {
        this.fileDownloadService.downloadFile(res, 'library.zip');
      }
    });
  }

  openDeleteLibraryFileConfirmation(event: Event) {
    this.confirmationService.confirm({
      key: 'confirmDelete',
      target: event.target || new EventTarget(),
      message: 'Are you sure you want to delete this library file?',
      icon: 'pi pi-exclamation-triangle',
      accept: () => this.deleteLibraryFile()
    });
  }

  deleteLibraryFile() {
    const libraryFileId = this.library?.library_files[this.activeIndex()]?.id;
    if (!libraryFileId) {
      this.library.library_files.splice(this.activeIndex(), 1);
      this.toastService.success('Library file removed successfully!');
      this.activeIndex.set(this.activeIndex() - 1);
      this.buildFormForActiveIndex(this.activeIndex());

      return;
    }
    this.libraryService.superAdminDeleteibraryFile(+this.storefrontId(), libraryFileId).subscribe({
      next: () => {
        this.toastService.success('Library file removed successfully!');
        this.activeIndex.set(this.activeIndex() - 1);
        this.getLibrary();
      },
      error: err => {
        console.error('Creation failed:', err);
      }
    });
  }

  updateLibraryFiles(formData: any) {
    this.isLoading = true;
    this.libraryService
      .superAdminUpdateLibraryFiles(+this.storefrontId(), formData, this.library?.id)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe({
        next: () => {
          this.toastService.success('Successfully updated library file!');
          this.deletedProcessedAdditionalFilesIds = [];
          this.getLibrary();
        },
        error: err => {
          console.error('Creation failed:', err);
        }
      });
  }

  prepareData() {
    const formValue = this.form.value;
    const { id, label, number_of_stitches, price } = formValue;

    const dataToSend: any = {
      ...(id && { library_file_id: id }),
      label: label,
      library: {
        ...(number_of_stitches && { number_of_stitches }),
        ...(price && { price: price * 100 })
      },
      ...(this.deletedEmbroideryFile && { deleted_embroidery_file: this.deletedEmbroideryFile }),
      ...(this.deletedProcessedAdditionalFilesIds.length > 0 && {
        deleted_processed_additional_files: this.deletedProcessedAdditionalFilesIds
      }),
      _method: 'PUT'
    };

    const decorationMethods: any[] = [];

    Object.keys(this.decorationMethodThumbnails).forEach(decoration_method_id_str => {
      const decoration_method_id = +decoration_method_id_str;

      const decorationMethodData: {
        decoration_method_id: number;
        thumbnail?: string;
        embroidery_file?: string;
        processed_additional_files?: string[];
      } = {
        decoration_method_id: decoration_method_id
      };

      const thumbnailFile = this.decorationMethodThumbnails[decoration_method_id]?.file;
      if (thumbnailFile && !this.decorationMethodThumbnails[decoration_method_id]?.id) {
        decorationMethodData.thumbnail = thumbnailFile;
      }

      const embroideryFile = this.libraryEmbroideryFile?.file;
      if (embroideryFile && !this.libraryEmbroideryFile?.id && decoration_method_id === 1) {
        decorationMethodData.embroidery_file = embroideryFile;
      }

      const processedFiles = this.decorationMethodProcessedFiles[decoration_method_id] || [];
      const additionalFiles = processedFiles.filter(file => file.file && !file.id).map(file => file.file);

      if (additionalFiles.length > 0) {
        decorationMethodData.processed_additional_files = additionalFiles;
      }

      if (
        decorationMethodData.thumbnail ||
        decorationMethodData.processed_additional_files ||
        decorationMethodData.embroidery_file
      ) {
        decorationMethods.push(decorationMethodData);
      }
    });

    if (decorationMethods.length > 0) {
      dataToSend.decoration_methods = decorationMethods;
    }

    return dataToSend;
  }

  formSubmit() {
    if (this.form.invalid) {
      this.toastService.error('Please fill all required fields correctly!');
      this.form.markAllAsTouched();
      return;
    }

    this.updateLibraryFiles(this.prepareData());
  }
}
