<div class="p-3">
  <p-fileUpload
    #fileUploader
    name="demo[]"
    url="./upload.php"
    (onSelect)="onUpload($event)"
    [customUpload]="true"
    accept="image/*"
    [showUploadButton]="false"
    [showCancelButton]="false"
    [auto]="true"
    styleClass="border-1 surface-border surface-card p-0 border-round mb-4 h-full"
  >
    <ng-template pTemplate="content">
      <div class="h-20rem m-1 border-round">
        @if (image?.url) {
          <div class="flex w-full h-full relative border-round p-0 justify-content-center align-items-center">
            <img
              [src]="image?.url"
              class="h-full border-round text-center"
              alt="blog cover"
              [style]="{ maxWidth: '100%', maxHeight: '100%' }"
            />
            <button
              pButton
              pRipple
              type="button"
              icon="pi pi-times"
              class="p-button-rounded p-button-primary text-sm absolute justify-content-center align-items-center"
              style="top: -10px; right: -10px"
              (click)="$event.stopPropagation(); removeImage()"
            ></button>
          </div>
        } @else {
          <div
            class="flex flex-column w-full h-full justify-content-center align-items-center cursor-pointer"
            (click)="fileUploader.advancedFileInput.nativeElement.click()"
          >
            <i class="pi pi-fw pi-file text-4xl text-primary"></i>
            <span class="block font-semibold text-900 text-lg mt-3">Drop or select section image</span>
          </div>
        }
      </div>
    </ng-template>
  </p-fileUpload>
  <div class="flex justify-content-end mt-4">
    <button pButton icon="pi pi-check" (click)="ref.close()" label="Save"></button>
  </div>
</div>
