import { Component, inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';

import { ButtonModule } from 'primeng/button';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { finalize } from 'rxjs';

import { InputComponent } from 'app/_shared/components/input/input.component';
import { LibraryFolder } from 'app/_shared/models/LibraryFolder';
import { markAllAsTouched } from 'app/_shared/services/helper.service';
import { LibraryService } from 'app/_shared/services/library.service';
import { ToastService } from 'app/_shared/services/toast.service';

@Component({
  selector: 'app-folder-editor-dialog',
  imports: [InputComponent, ReactiveFormsModule, ButtonModule],
  templateUrl: './folder-editor-dialog.component.html',
  styleUrl: './folder-editor-dialog.component.scss'
})
export class FolderEditorDialogComponent implements OnInit {
  private dialogConfig = inject(DynamicDialogConfig);
  private toastService = inject(ToastService);
  private fb = inject(FormBuilder);
  private libraryService = inject(LibraryService);
  private dialogRef = inject(DynamicDialogRef);

  folder: LibraryFolder;
  storefrontId: number;
  form: FormGroup;
  isLoading = false;

  ngOnInit(): void {
    this.folder = this.dialogConfig.data.folder;
    this.storefrontId = this.dialogConfig.data.storefrontId;
    this.form = this.fb.group({
      name: [this.folder?.name ?? '', [Validators.required]]
    });
  }

  onSubmit() {
    if (!this.form.valid) {
      markAllAsTouched(this.form);
      this.toastService.error('Please fill folder name');
      return;
    }
    this.folder ? this.updateFolder() : this.createFolder();
  }

  createFolder() {
    this.isLoading = true;
    this.libraryService
      .createStorefrontLibraryFolder(this.storefrontId, this.form.value)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe({
        next: () => {
          this.toastService.success('Storefront folder created.');
          this.dialogRef.close(true);
        },
        error: error => {
          console.error(error);
        }
      });
  }

  updateFolder() {
    this.isLoading = true;
    this.libraryService
      .updateStorefrontLibraryFolder(this.storefrontId, this.folder.id, this.form.value)
      .pipe(finalize(() => (this.isLoading = false)))

      .subscribe({
        next: () => {
          this.toastService.success('Storefront folder updated.');
          this.dialogRef.close(true);
        },
        error: error => {
          console.error(error);
        }
      });
  }
}
