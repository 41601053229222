import { Component, inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { finalize } from 'rxjs';

import { LibraryFileUploaderComponent } from 'app/_shared/components/media-library/library-file-uploader/library-file-uploader.component';
import { FileDownloadService } from 'app/_shared/helpers/file-download.service';
import { Library } from 'app/_shared/models/Library';
import { LibraryFolder } from 'app/_shared/models/LibraryFolder';
import { User } from 'app/_shared/models/User';
import { RoleName } from 'app/_shared/models/UserRole';
import { LibraryService } from 'app/_shared/services/library.service';
import { ToastService } from 'app/_shared/services/toast.service';
import { AuthStore } from 'app/_store/auth.store';

import { SuperadminFilePrepComponent } from '../../../../_shared/components/media-library/superadmin-file-prep/superadmin-file-prep.component';

@Component({
  selector: 'app-libraries-list',
  imports: [LibraryFileUploaderComponent, SuperadminFilePrepComponent],
  templateUrl: './library-editor.component.html',
  styleUrl: './library-editor.component.scss'
})
export class LibraryEditorComponent implements OnInit {
  private libraryService = inject(LibraryService);
  private activatedRoute = inject(ActivatedRoute);
  private router = inject(Router);
  toastService = inject(ToastService);
  authStore = inject(AuthStore);
  fileDownloadService = inject(FileDownloadService);

  storefrontLibraryFolders: LibraryFolder[] = [];
  storefrontId: string;
  libraryFolderId: string;
  libraryId: string;
  library: Library;
  account: User | null = null;
  UserRoleNames = RoleName;
  loading = false;
  createMode: boolean;
  isLoading = false;

  ngOnInit() {
    this.activatedRoute.params.subscribe(next => {
      this.account = this.authStore.user();
      this.storefrontId = next.storefrontId;
      this.libraryFolderId = next.libraryFolderId;
      this.libraryId = next.libraryId;

      this.fetchStorefrontLibraryFolders(this.storefrontId);
      if (this.libraryId) {
        this.getLibrary(this.storefrontId, this.libraryId);
        this.createMode = false;
      } else {
        this.createMode = true;
      }
    });
  }

  getLibrary(storefrontId: string, libraryId: string) {
    const params = {
      'relations[]': ['libraryThumbnail', 'additionalFiles', 'libraryFolder', 'preparationRequest']
    };
    this.loading = true;
    this.libraryService
      .getLibrary(storefrontId, libraryId, params)
      .pipe(finalize(() => (this.loading = false)))
      .subscribe(next => {
        this.library = next.data;
      });
  }

  fetchStorefrontLibraryFolders(storefrontId: string) {
    this.libraryService.getStorefrontLibraryFolders(+storefrontId).subscribe({
      next: response => {
        this.storefrontLibraryFolders = response.data;
      },
      error: error => {
        console.error(error);
      }
    });
  }

  downloadAllLibraryFiles() {
    this.libraryService.downloadLibraryFiles(+this.storefrontId, +this.libraryId).subscribe({
      next: res => {
        this.fileDownloadService.downloadFile(res, 'library.zip');
      }
    });
  }

  onSubmit(formData: FormData) {
    if (this.library) {
      this.updateLibrary(formData);
      return;
    }
    this.createLibrabry(formData);
  }

  updateLibrary(formData: FormData) {
    this.isLoading = true;
    this.libraryService
      .updateLibrary(+this.storefrontId, +this.libraryId, formData)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe({
        next: () => {
          this.toastService.success('Successfully updated library!');
          this.router.navigate(['../../'], { relativeTo: this.activatedRoute });
        },
        error: err => {
          console.error('Creation failed:', err);
        }
      });
  }

  createLibrabry(formData: FormData) {
    this.isLoading = true;

    this.libraryService
      .createLibrary(+this.storefrontId, formData)
      .pipe(finalize(() => (this.isLoading = false)))

      .subscribe({
        next: () => {
          this.toastService.success('Successfully saved library!');
          this.router.navigate(['../../'], { relativeTo: this.activatedRoute });
        },
        error: err => {
          console.error('Creation failed:', err);
        }
      });
  }
}
