<div class="card">
  <div class="flex justify-content-between">
    <h5>Libraries</h5>
    <div>
      <p-button outlined="true" [routerLink]="['libraries/create']" label="Create new Library" />
    </div>
  </div>
  <p-dataView
    #dv
    [value]="libraries()"
    [paginator]="false"
    [rows]="paginatorOptions.rows"
    [totalRecords]="paginatorOptions.total"
    [first]="paginatorOptions.first"
    filterBy="name"
    layout="grid"
    [lazy]="true"
    [lazyLoadOnInit]="true"
    (onLazyLoad)="getLibraries()"
  >
    <ng-template let-libraries pTemplate="gridItem">
      <div class="grid grid-nogutter">
        @for (library of libraries; track library.id) {
          <div class="col-12 md:col-6 xl:col-3">
            <a [routerLink]="['libraries', library.id]">
              <div class="card m-3 border-1 surface-border item">
                <div class="flex flex-column align-items-center text-center mb-3">
                  <img
                    [style]="{ height: '100px' }"
                    [src]="library.library_thumbnail.full_path"
                    [alt]="library.name"
                    class="shadow-2 my-3 mx-0"
                  />
                  <div class="text-2xl font-bold">{{ library?.name }}</div>

                  @if (account?.role?.name === UserRoleNames.SuperAdmin) {
                    <p-toggleButton
                      [ngModel]="library.approved"
                      onLabel="Approved"
                      offLabel="Not Approved"
                      onIcon="pi pi-lock-open"
                      offIcon="pi pi-lock"
                      styleClass="w-36"
                      (onChange)="changeLibraryApproveStatus($event, library.id)"
                    />
                  } @else {
                    @if (!!library?.approved) {
                      <p class="p-text-success font-italic">Approved</p>
                    } @else {
                      <p class="p-text-warning font-italic">Not approved</p>
                    }
                  }
                </div>
              </div>
            </a>
          </div>
        }
      </div>
    </ng-template>
  </p-dataView>
</div>
