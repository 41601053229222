<p-confirmDialog
  header="Confirmation"
  key="confirmDelete"
  icon="pi pi-exclamation-triangle"
  [style]="{ width: '350px' }"
  acceptButtonStyleClass="p-button-danger p-button-text"
  rejectButtonStyleClass="p-button-text"
></p-confirmDialog>
<div class="flex gap-3 align-items-center">
  <h5 class="m-0">Library File Versions</h5>
  @if (library) {
    <p-toggleButton
      [ngModel]="libraryApproveStatus"
      onLabel="Approved"
      offLabel="Not Approved"
      onIcon="pi pi-lock-open"
      offIcon="pi pi-lock"
      styleClass="w-36"
      (onChange)="changeLibraryApproveStatus()"
    />
  }
</div>
<div class="flex justify-content-end pt-3 gap-3">
  <p-button icon="pi pi-plus" outlined label="New Library File" (click)="onAddNewLibraryFile()" />
  <p-button icon="pi pi-download" outlined label="Download Files" (click)="downloadLibraryfiles()" />
</div>

<p-tabView [activeIndex]="activeIndex()" (onChange)="onTabChange($event)">
  @for (libraryFile of library?.library_files; let i = $index; track $index) {
    <p-tabPanel [header]="libraryFile.label || 'New Library File'" [selected]="activeIndex() === i">
      <form [formGroup]="form" (ngSubmit)="formSubmit()">
        <div class="grid">
          <div class="col-12 md:col-6">
            <div class="grid">
              <div class="col-12">
                <label for="label">Version Name</label>
                <input type="text" pInputText class="w-full" formControlName="label" />
                @if (form.get('label')?.touched && form.get('label')?.invalid) {
                  <div>
                    <small class="p-text-danger">Label is required</small>
                  </div>
                }
              </div>
              <div class="col-12">
                <label for="decoration_method">Decoration Method</label>
                <div class="full-width-select">
                  <p-selectButton
                    [options]="decorationMethodOptions"
                    optionLabel="name"
                    optionValue="id"
                    formControlName="decoration_method_id"
                  ></p-selectButton>
                  @if (form.get('decoration_method_id')?.touched && form.get('decoration_method_id')?.invalid) {
                    <div>
                      <small class="p-text-danger">Decoration method is required</small>
                    </div>
                  }
                </div>
              </div>

              @if (form.get('decoration_method_id')?.value === 1) {
                <div class="col-12">
                  <label for="number_of_stitches">Number of Stitches</label>
                  <input type="number" pInputText class="w-full" formControlName="number_of_stitches" [min]="0" />
                  @if (form.get('number_of_stitches')?.touched && form.get('number_of_stitches')?.invalid) {
                    <div>
                      <small class="p-text-danger">Number of stitches is required</small>
                    </div>
                  }
                </div>
                <div class="col-12">
                  <label for="number_of_stitches">Price</label>
                  <input
                    type="number"
                    pInputText
                    class="w-full"
                    formControlName="price"
                    [min]="0"
                    placeholder="Per 1000 stitches"
                  />
                  @if (form.get('price')?.touched && form.get('price')?.invalid) {
                    <div>
                      <small class="p-text-danger">Price is required</small>
                    </div>
                  }
                </div>
              }
              <div class="col-12 custom-upload">
                <label for="thumbnail">Library file thumbnail</label>
                <p-fileUpload
                  #thumbnailUplaoder
                  [multiple]="false"
                  [customUpload]="true"
                  (onSelect)="onThumbnailUpload($event)"
                  accept=".png"
                  maxFileSize="1000000"
                  showUploadButton="false"
                  customUpload="true"
                  showUploadButton="false"
                  styleClass="border-1 surface-border surface-card p-0 border-round mb-1"
                >
                  <ng-template pTemplate="file"></ng-template>
                  <ng-template pTemplate="content">
                    <div class="h-10rem m-1 border-round">
                      @if (!decorationMethodThumbnails[form.get('decoration_method_id')?.value]?.file) {
                        <div
                          class="flex flex-column w-full h-full justify-content-center align-items-center cursor-pointer"
                          (click)="thumbnailUplaoder.advancedFileInput.nativeElement.click()"
                        >
                          <i class="pi pi-fw pi-file text-4xl text-primary"></i>
                          <span class="block font-semibold text-900 text-lg mt-3">
                            Drag and drop version thumbnail.
                          </span>
                        </div>
                      }
                      @if (decorationMethodThumbnails[form.get('decoration_method_id')?.value]?.file) {
                        <div class="flex justify-content-center align-items-center h-full w-full">
                          <img
                            [src]="decorationMethodThumbnails[form.get('decoration_method_id')?.value]?.file"
                            height="80"
                            alt="Version thumbnail"
                            style="object-fit: contain"
                          />
                          <button
                            pButton
                            type="button"
                            aria-label="remove"
                            icon="pi pi-times"
                            class="p-button-rounded p-button-primary text-sm absolute justify-content-center align-items-center"
                            style="top: 10px; right: 10px"
                            (click)="
                              $event.stopPropagation();
                              removeThumbnailForDecorationMethod(form.get('decoration_method_id')?.value)
                            "
                          ></button>
                        </div>
                      }
                    </div>
                  </ng-template>
                </p-fileUpload>
              </div>
              <div class="col-12 custom-upload">
                <p class="p-0 m-0">Provide processed additional files</p>
                <p-fileUpload
                  #additionalFileUploader
                  [multiple]="true"
                  [customUpload]="true"
                  (onSelect)="onProcessedAdditionalFilesUpload($event)"
                  maxFileSize="1000000"
                  showUploadButton="false"
                  customUpload="true"
                  showUploadButton="false"
                  accept=".dst,.emb,.pdf,.eps,.ai"
                  styleClass="border-1 surface-border surface-card p-0 border-round mb-1"
                >
                  <ng-template pTemplate="content">
                    <div class="min-h-10 m-1 border-round py-4">
                      @if (!decorationMethodProcessedFiles[form.get('decoration_method_id')?.value]?.length) {
                        <div
                          class="flex flex-column w-full h-full justify-content-center align-items-center cursor-pointer"
                          (click)="additionalFileUploader.advancedFileInput.nativeElement.click()"
                        >
                          <i class="pi pi-fw pi-file text-4xl text-primary"></i>
                          <span class="block font-semibold text-900 text-lg mt-3">Drop or select Additional files</span>
                        </div>
                      }
                      <div class="flex flex-wrap gap-5">
                        @for (
                          additionalItem of decorationMethodProcessedFiles[form.get('decoration_method_id')?.value];
                          track $index;
                          let j = $index
                        ) {
                          <p-chip
                            styleClass="mr-2 py-2 px-3 text-900 font-bold surface-card border-1 surface-border"
                            [style]="{ 'border-radius': '20px' }"
                            onclick=""
                          >
                            <span class="mr-3">{{ additionalItem.name }}</span>
                            <span
                              class="chip-remove-icon flex align-items-center justify-content-center border-1 surface-border bg-gray-100 border-circle cursor-pointer"
                              (click)="
                                removeProcessedFileForDecorationMethod(j, form.get('decoration_method_id')?.value)
                              "
                            >
                              <i class="pi pi-fw pi-times text-black-alpha-60"></i>
                            </span>
                          </p-chip>
                        }
                      </div>
                    </div>
                  </ng-template>
                  <ng-template pTemplate="file"></ng-template>
                </p-fileUpload>
              </div>

              @if (form.get('decoration_method_id')?.value === 1) {
                <div class="col-12 custom-upload">
                  <p class="p-0 m-0">Provide embroidery file</p>
                  <p-fileUpload
                    #embroideryUploaded
                    [multiple]="false"
                    [customUpload]="true"
                    (onSelect)="onEmbroideryFileUpload($event)"
                    maxFileSize="1000000"
                    showUploadButton="false"
                    customUpload="true"
                    showUploadButton="false"
                    accept=".dst,.emb,.pdf,.eps,.ai"
                    styleClass="border-1 surface-border surface-card p-0 border-round mb-1"
                  >
                    <ng-template
                      pTemplate="header"
                      let-chooseCallback="chooseCallback"
                      let-clearCallback="clearCallback"
                    >
                      <div class="flex flex-wrap justify-content-between align-items-center flex-1 gap-2">
                        <div class="w-full flex justify-content-between gap-2">
                          <p-button
                            (onClick)="chooseCallback()"
                            icon="pi pi-images"
                            outlined
                            label="Upload Embroidery File"
                          />
                        </div>
                      </div>
                    </ng-template>
                    <ng-template pTemplate="file"></ng-template>

                    <ng-template pTemplate="content">
                      <div class="min-h-10 m-1 border-round py-4">
                        @if (!libraryEmbroideryFile?.file) {
                          <div
                            class="flex flex-column w-full h-full justify-content-center align-items-center cursor-pointer"
                            (click)="embroideryUploaded.advancedFileInput.nativeElement.click()"
                          >
                            <i class="pi pi-fw pi-file text-4xl text-primary"></i>
                            <span class="block font-semibold text-900 text-lg mt-3">
                              Drop or select Embroidery file
                            </span>
                          </div>
                        }
                        @if (libraryEmbroideryFile?.file) {
                          <p-chip
                            styleClass="mr-2 py-2 px-3 text-900 font-bold surface-card border-1 surface-border"
                            [style]="{ 'border-radius': '20px' }"
                            onclick=""
                          >
                            <span class="mr-3">
                              {{ libraryEmbroideryFile?.name }}
                            </span>
                            <span
                              class="chip-remove-icon flex align-items-center justify-content-center border-1 surface-border bg-gray-100 border-circle cursor-pointer"
                              (click)="removeEmbroideryFile()"
                            >
                              <i class="pi pi-fw pi-times text-black-alpha-60"></i>
                            </span>
                          </p-chip>
                        }
                      </div>
                    </ng-template>
                  </p-fileUpload>
                </div>
              }
              <div class="col-12 mb-3">
                <p-button [disabled]="isLoading" type="submit" label="Update Version" />
              </div>
            </div>
          </div>
          <div class="col-12 md:col-6 flex justify-content-end align-items-center">
            <p-button
              icon="pi pi-trash"
              severity="danger"
              outlined
              (click)="openDeleteLibraryFileConfirmation($event)"
            />
          </div>
        </div>
      </form>
    </p-tabPanel>
  }
</p-tabView>
