<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <div>
    <label for="productsPerRow">Products per row</label>
  </div>
  <p-dropdown
    id="productsPerRow"
    [options]="[3, 4]"
    formControlName="productsPerRow"
    placeholder="Products per row"
    appendTo="body"
  />
  <div class="mt-3">
    <label for=""><b>Select categories</b></label>
  </div>
  <small>Leaving this empty will display products from all categories</small>
  <div>
    <p-multiSelect
      appendTo="body"
      [options]="productCategories"
      formControlName="categories"
      optionLabel="name"
      optionValue="id"
      [style]="{ width: '100%' }"
    ></p-multiSelect>
  </div>

  <div class="mt-3">
    <p-checkbox [binary]="true" id="all" name="listAllProducts" formControlName="listAllProducts" [value]="true" />
    <label class="ml-1" for="all">All products</label>
  </div>
  <div>
    <small>Checking this will add paginated list of all products</small>
  </div>
  <small>Unchecking this will list 12 products</small>
  <div class="mt-3">
    <p-button label="Save" type="submit" />
  </div>
</form>
