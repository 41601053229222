import { Component, inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { PanelModule } from 'primeng/panel';
import { TabMenuModule } from 'primeng/tabmenu';
import { TabViewModule } from 'primeng/tabview';
import { finalize } from 'rxjs';

import { CustomMailData, GiftCardCustomMail } from 'app/_shared/models/Coupon';
import { Storefront } from 'app/_shared/models/Storefront';
import { UsdPipe } from 'app/_shared/pipes/usd-currency.pipe';
import { StorefrontService } from 'app/_shared/services/storefront.service';
import { ToastService } from 'app/_shared/services/toast.service';

@Component({
  selector: 'app-gift-card-email-template-editor',
  imports: [ReactiveFormsModule, ButtonModule, InputTextModule, PanelModule, TabMenuModule, TabViewModule, UsdPipe],
  templateUrl: './gift-card-email-template-editor.component.html',
  styleUrl: './gift-card-email-template-editor.component.scss'
})
export class GiftCardEmailTemplateEditorComponent implements OnInit {
  private fb: FormBuilder = inject(FormBuilder);
  private toastService: ToastService = inject(ToastService);
  private storefrontService = inject(StorefrontService);
  private activatedRoute = inject(ActivatedRoute);

  form: FormGroup;
  storefrontId: string;
  giftCardCustomMails: GiftCardCustomMail[];
  storefront: Storefront;
  activeIndex = 0;
  isLoading = false;

  ngOnInit() {
    this.activatedRoute.params.subscribe(param => {
      this.storefrontId = param.storefrontId;
    });
    this.getGiftCardCustomMail();
  }

  getGiftCardCustomMail() {
    this.storefrontService.getStorefrontWithGiftCardMail(+this.storefrontId).subscribe({
      next: res => {
        this.storefront = res.data;
        this.giftCardCustomMails = res.data.gift_card_custom_mails;
        this.buildFormForTab(this.activeIndex);
      }
    });
  }

  buildFormForTab(index: number) {
    if (this.giftCardCustomMails[index]) {
      const mailData = this.giftCardCustomMails[index].data;
      this.form = this.fb.group({
        template_name: [mailData.template_name, Validators.required],
        email_subject: [mailData.email_subject, Validators.required],
        greeting: [mailData.greeting, Validators.required],
        line_1: [mailData.line_1, Validators.required],
        line_2: [mailData.line_2, Validators.required],
        line_3: [mailData.line_3, Validators.required],
        button_text: [mailData.button_text, Validators.required],
        goodbye_message: [mailData.goodbye_message, Validators.required],
        sender: [mailData.sender, Validators.required]
      });
    }
  }

  onTabChange(event: any) {
    this.activeIndex = event.index;
    this.buildFormForTab(this.activeIndex);
  }

  formSubmit() {
    if (this.form.valid) {
      const updatedMailData = this.form.value;
      const currentMail = this.giftCardCustomMails[this.activeIndex];

      this.updateMailTemplate(currentMail.id, updatedMailData);
    } else {
      this.toastService.error('Please fill in all the required fields.');
    }
  }

  updateMailTemplate(mailId: number, updatedMailData: CustomMailData) {
    this.isLoading = true;
    this.storefrontService
      .updateCustomMailTemplate(+this.storefrontId, mailId, updatedMailData)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe({
        next: () => {
          this.getGiftCardCustomMail();
          this.toastService.success('Gift Card email template updated successfully!');
        },
        error: () => {
          this.toastService.error('Failed to update gift card template.');
        }
      });
  }
}
