import { Component, inject, OnInit, signal } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';

import { ButtonModule } from 'primeng/button';
import { PasswordModule } from 'primeng/password';
import { RippleModule } from 'primeng/ripple';
import { finalize } from 'rxjs';

import { FormGroupValue } from 'app/_shared/models/FormGroup';
import { AuthService } from 'app/_shared/services/auth.service';
import { CustomValidators } from 'app/_shared/services/custom-validators.service';
import { ToastService } from 'app/_shared/services/toast.service';

@Component({
    selector: 'app-update-password',
    imports: [ButtonModule, RippleModule, PasswordModule, RouterLink, ReactiveFormsModule],
    templateUrl: './update-password.component.html'
})
export class UpdatePasswordComponent implements OnInit {
  route = inject(ActivatedRoute);
  router = inject(Router);
  fb = inject(FormBuilder);
  toastService = inject(ToastService);
  authService = inject(AuthService);

  token: string;
  email: string;
  form: FormGroup;
  submitted = signal(false);
  isLoading = signal(false);

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.token = params.token;
      this.email = params.email;
      this.buildForm();
    });
  }

  buildForm() {
    this.form = this.fb.group(
      {
        password: [null, [Validators.required, CustomValidators.passwordPattern()]],
        password_confirmation: [null, [Validators.required, CustomValidators.passwordPattern()]]
      },
      { validators: CustomValidators.passwordsMatch }
    );
  }

  onSubmit() {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      this.submitted.set(true);
      this.toastService.error('Please fill all required fields correctly!');
      return;
    }
    const formattedData = this.prepareSubmitData();
    this.resetPassword(formattedData);
  }

  prepareSubmitData() {
    const formData = this.form.value;
    const formattedData = {
      ...formData,
      email: this.email,
      token: this.token
    };
    return formattedData;
  }

  resetPassword(formattedData: FormGroupValue) {
    this.isLoading.set(true);
    this.authService
      .resetPassword(formattedData)
      .pipe(finalize(() => this.isLoading.set(false)))
      .subscribe({
        next: () => {
          this.toastService.success('Password has been successfully updated!');
          this.router.navigateByUrl('/auth/login');
        },
        error: err => {
          console.error(err);
        }
      });
  }
}
