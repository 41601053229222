<p-orderList [value]="sections" [dragdrop]="true" (onReorder)="onSectionReorder()">
  <!--  <ng-template pTemplate="header">-->
  <!--    <div class="flex flex-wrap justify-content-between align-items-center">-->
  <!--      <p class="m-0 font-bold">{{ selectedPage()?.title }}</p>-->
  <!--      <p-button-->
  <!--        [outlined]="true"-->
  <!--        [iconPos]="'left'"-->
  <!--        icon="pi pi-plus"-->
  <!--        [label]="'Add section'"-->
  <!--        (click)="showAddNewContentSectionDialog = true"-->
  <!--      />-->
  <!--    </div>-->
  <!--  </ng-template>-->
  <ng-template let-section pTemplate="item">
    <p-panel [toggleable]="false">
      <ng-template pTemplate="header">
        <div class="flex align-items-center gap-2">
          <span>{{ section.label }}</span>
        </div>
      </ng-template>
      <ng-template pTemplate="icons">
        <button class="p-panel-header-icon p-link mr-2" (click)="toggleSectionPopup($event, menu, section)">
          <span class="pi pi-cog"></span>
        </button>
        <p-menu #menu id="selectedPageMenuItems" [model]="sectionPopupItems" [popup]="true" />
      </ng-template>
    </p-panel>
  </ng-template>
</p-orderList>
<div class="mt-3">
  <p-button
    [outlined]="true"
    [iconPos]="'left'"
    icon="pi pi-plus"
    [label]="'Add section'"
    [style]="{ flex: 1, width: '100%' }"
    (click)="showAddNewContentSectionDialog = true"
  />
</div>
<p-dialog
  header="Selecte section you want to add to the page"
  [(visible)]="showAddNewContentSectionDialog"
  [modal]="true"
  showEffect="fade"
  [style]="{ maxWidth: '50vw' }"
  [breakpoints]="{ '960px': '75vw' }"
  [draggable]="false"
>
  <div class="new-section">
    @for (element of elements; track element.id) {
      <div class="text-editor section-item">
        <label [for]="element.id" class="cursor-pointer text-center">
          <img src="{{ element.image }}" alt="" width="30px" height="30px" />
          <p class="text-center m-0 font-bold">{{ element.label }}</p>
        </label>
        <div class="field-checkbox mt-2">
          <p-radioButton [inputId]="element.id" name="category" [value]="element" [(ngModel)]="selectedElement" />
        </div>
      </div>
    }
  </div>
  <ng-template pTemplate="footer">
    <button
      [disabled]="!selectedElement"
      pButton
      type="button"
      label="Add section"
      (click)="handleAddSection()"
    ></button>
  </ng-template>
</p-dialog>
