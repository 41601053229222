import { inject } from '@angular/core';

import { patchState, signalStore, withHooks, withMethods, withState } from '@ngrx/signals';
import { map, Observable, of, tap } from 'rxjs';

import { Menu } from 'app/_shared/interfaces/designer/menu';
import { Page } from 'app/_shared/interfaces/designer/page';
import { DesignerService } from 'app/_shared/services/designer.service';

export interface Settings {
  primaryColor?: string;
  secondaryColor?: string;
  logoPosition?: string;
  buttonShape?: string;
  logo?: string;
  footerContent?: string;
  copyrightText?: string;
  socialIcons?: { name: string; url: string }[];
  websiteFont?: string;
}
export interface Config {
  settings?: Settings;
  menu?: Menu[];
  pages?: Page[];
}

type DesignerState = {
  config: Config;
  isLoading: boolean;
};

const initialState: DesignerState = {
  config: {},
  isLoading: false
};

export const DesignerStore = signalStore(
  { providedIn: 'root' },
  withState(initialState),
  withMethods(store => {
    const designerService = inject(DesignerService);
    return {
      saveConfig(storefrontId: number, config: Partial<Config>, onlyInStore = false): Observable<Config> {
        const configData = { ...store.config(), ...config };
        if (onlyInStore) {
          patchState(store, state => ({ config: configData }));
          return of(configData); // Return an observable with the updated configData
        } else {
          patchState(store, state => ({ isLoading: true }));
          return designerService
            .saveConfig(storefrontId, {
              data: JSON.stringify(configData)
            })
            .pipe(
              map(response => {
                return configData; // Transform the response to the configData
              }),
              tap({
                next: () => {
                  patchState(store, state => ({
                    config: configData
                  }));
                },
                error: err => {
                  console.error(err);
                  throw err;
                }
              }),
              // Ensuring the observable chain returns the updated configData
              tap({
                next: () => {
                  patchState(store, state => ({ isLoading: false }));
                  return configData;
                }
              })
            );
        }
      },
      resetConfig: () => patchState(store, initialState),
      setLoading: (isLoading: boolean) => patchState(store, { isLoading })
    };
  }),
  withHooks(store => ({
    onInit() {
      // const storefrontStore = inject(StorefrontStore);
      // const selectedStorefront = storefrontStore.selectedStorefront?.();
      // if (selectedStorefront) {
      //   store.saveConfig(selectedStorefront.data);
      // }
    }
  }))
);
