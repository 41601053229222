import { Component, input, output } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { MenuItem } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { DataViewModule } from 'primeng/dataview';
import { DropdownModule } from 'primeng/dropdown';
import { InputGroupModule } from 'primeng/inputgroup';
import { InputTextModule } from 'primeng/inputtext';
import { SkeletonModule } from 'primeng/skeleton';
import { SplitButtonModule } from 'primeng/splitbutton';
import { TableLazyLoadEvent, TableModule } from 'primeng/table';

import { PaginatorOptions } from 'app/_shared/models/PaginatorOptions';
import { Storefront, storefrontStatusDropdownOptions } from 'app/_shared/models/Storefront';

import { DisplayDatePipe } from '../../pipes/date.pipe';
import { StatusBadgeComponent } from '../status-badge/status-badge.component';
import { StorefrontCardComponent } from '../storefront-card/storefront-card.component';

@Component({
  selector: 'app-storefronts-table',
  imports: [
    ButtonModule,
    DataViewModule,
    InputGroupModule,
    InputTextModule,
    DropdownModule,
    TableModule,
    StorefrontCardComponent,
    SplitButtonModule,
    SkeletonModule,
    FormsModule,
    StatusBadgeComponent,
    DisplayDatePipe
  ],
  templateUrl: './storefronts-table.component.html',
  styleUrl: './storefronts-table.component.scss'
})
export class StorefrontsTableComponent {
  storefronts = input.required<Storefront[]>();
  loading = input.required<boolean>();
  paginatorOptions = input.required<PaginatorOptions>();
  globalFilter = input.required<string | null>();
  selectedStatus = input.required<string | null>();
  userCompanyOrSuperAdmin = input<boolean>(false);
  lazyLoadStorefronts = output<TableLazyLoadEvent>();
  openStorefrontEditor = output<Storefront | undefined>();
  openStorefront = output<Storefront>();
  openStorefrontSettings = output<Storefront>();
  reactiveStorefront = output<Storefront>();
  filterChanged = output<{ key: string; value: string }>();

  splitButtonItemsMap = input.required<{ [key: string]: MenuItem[] }>();

  storefrontStatusOptions = storefrontStatusDropdownOptions;

  loadingRows = Array(10)
    .fill(0)
    .map((_, index) => index + 1);

  loadStorefronts($event: TableLazyLoadEvent) {
    this.lazyLoadStorefronts.emit($event);
  }

  handleEditStorefront(storefront?: Storefront) {
    this.openStorefrontEditor.emit(storefront);
  }

  handleStorefrontSettings(storefront: Storefront) {
    this.openStorefrontSettings.emit(storefront);
  }

  openStorefrontInNewTab(storefront: Storefront) {
    this.openStorefront.emit(storefront);
  }

  handleReactiveStorefront(storefront: Storefront) {
    this.reactiveStorefront.emit(storefront);
  }

  onFilterChange(filterKey: string, filterValue: string): void {
    this.filterChanged.emit({ key: filterKey, value: filterValue });
  }
}
