<div class="layout-topbar">
  <div class="topbar-start">
    <button
      #menubutton
      type="button"
      class="topbar-menubutton p-link p-trigger lg:hidden"
      (click)="onMenuButtonClick()"
    >
      <i class="pi pi-bars"></i>
    </button>
    <app-breadcrumb class="topbar-breadcrumb" />
  </div>

  <div class="topbar-end">
    <div class="flex justify-content-center align-items-center gap-3">
      <div class="notification-bell">
        <p-button
          class="p-button-rounded p-button-outlined"
          [style]="{ padding: '8.5px' }"
          badgeClass="p-badge-contrast"
          (click)="op1.toggle($event)"
          [outlined]="true"
          [rounded]="true"
        >
          <img src="assets/images/icons/notification-dark.svg" width="24" height="24" alt="" />
        </p-button>
        @if (notificationCount()) {
          <div class="count">
            <span>{{ notificationCount() }}</span>
          </div>
        }
        <p-overlayPanel #op1 [style]="{ maxHeight: '500px', overflow: 'auto' }">
          @if (!notifications().length) {
            <p>There are no notifications</p>
          } @else {
            @for (notification of notifications(); track notification.id) {
              <p [style]="{ cursor: 'pointer' }">{{ notification.data.title }}</p>
            }
          }
        </p-overlayPanel>
      </div>
      <p-button
        class="p-button-rounded p-button-outlined"
        [style]="{ padding: '8.5px' }"
        [outlined]="true"
        [rounded]="true"
        (click)="onProfileButtonClick()"
      >
        <img src="assets/images/icons/user-dark.svg" width="24" height="24" alt="" />
      </p-button>
    </div>
  </div>
</div>
<div class="mb-6"></div>
