import { CurrencyPipe } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, ReactiveFormsModule } from '@angular/forms';

import { ButtonModule } from 'primeng/button';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { InputTextModule } from 'primeng/inputtext';

import { markAllAsTouched } from 'app/_shared/services/helper.service';
import { ToastService } from 'app/_shared/services/toast.service';
import { TransferService } from 'app/_shared/services/transfer.service';

@Component({
  selector: 'app-superaadmin-company-transfer-dialog',
  imports: [ButtonModule, InputTextModule, ReactiveFormsModule, CurrencyPipe],
  templateUrl: './superadmin-company-transfer-dialog.component.html',
  styleUrl: './superadmin-company-transfer-dialog.component.scss'
})
export class SuperadminCompanyTransferDialogComponent implements OnInit {
  dialogConfig = inject(DynamicDialogConfig);
  dialogRef = inject(DynamicDialogRef);
  toastService = inject(ToastService);
  transferService = inject(TransferService);
  companyId: string;
  form: FormGroup;

  ngOnInit() {
    this.companyId = this.dialogConfig.data.companyId;
    this.buildForm();
  }

  buildForm() {
    this.form = new FormGroup({
      amount: new FormControl(null, [Validators.required, Validators.min(0.5)]),
      company_id: new FormControl(this.companyId)
    });
  }

  hideDialog() {
    this.dialogRef.close();
  }

  superadminTransferMoneyToCompany(formData: any) {
    this.transferService.transferMoneyToCompany(formData).subscribe({
      next: () => {
        this.toastService.success('Successfully transfered money to company!');
        this.dialogRef.close(true);
      },
      error: err => {
        console.error(err);
      }
    });
  }

  onSubmit() {
    markAllAsTouched(this.form);
    if (this.form.invalid) {
      this.toastService.error('Please fill all required fields correctly!');
      return;
    }

    const formData = { ...this.form.value };
    formData.amount = formData.amount * 100;

    this.superadminTransferMoneyToCompany(formData);
  }
}
