import { computed, inject, Injectable } from '@angular/core';

import { Page, Section } from 'app/_shared/interfaces/designer/page';
import { DesignerService } from 'app/_shared/services/designer.service';
import { Config, DesignerStore } from 'app/_store/designer.store';

@Injectable({
  providedIn: 'root'
})
export class DesignerHelperService {
  designerStore = inject(DesignerStore);
  designerService = inject(DesignerService);
  pages = computed(() => this.designerStore.config().pages ?? []);

  sendToPreview(storefrontId: number, data: Config, onComplete?: () => void, onError?: () => void) {
    this.designerStore.setLoading(true);
    this.designerService
      .sendToPreview(storefrontId, {
        preview_data: JSON.stringify(data)
      })
      .subscribe({
        next: res => {
          onComplete && onComplete();
        },
        error: err => {
          onError && onError();
          console.error(err);
        },
        complete: () => {}
      });
  }

  saveSectionData(selectedPage: Page, selectedSection: Section, data: any) {
    const updateSections = () => {
      const { sections } = selectedPage;
      const sectionExists = sections.some(section => section.id === selectedSection.id);
      const updatedSection = { ...selectedSection, content: data };

      return sectionExists
        ? sections.map(section => (section.id === selectedSection.id ? updatedSection : section))
        : [...sections, updatedSection];
    };

    const newSections = updateSections();
    return this.pages().map(page =>
      page.id === selectedPage.id ? { ...selectedPage, sections: [...newSections] } : page
    );
  }
}
